import React from "react";
import classnames from "classnames";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import assessorRoutes from "assessorRoutes";
import contributeRoutes from "contributeRoutes";
import supportRoutes from "supportRoutes";

// import routes from "../../routes";
import routes from "../../assessorRoutes";

function AdminNavbar(props) {
  const history = useHistory();

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const location = useLocation();

  console.log("WINDOW.LOCATION.PATHNAME");
  console.log(window.location.pathname);
  let crumbs = window.location.pathname.split("/");
  console.log("CRUMBS");
  console.log(crumbs);
  let currentPath;
  currentPath = routes.find((obj) => obj?.path?.split("/")[1] == crumbs[2]);
  console.log(currentPath);
  if (currentPath?.views) {
    console.log("CRUMBS[3]");
    console.log(crumbs[3]);
    console.log("CURRENTPATH.VIEWS");
    console.log(currentPath.views);
    currentPath = currentPath.views.find(
      (obj) => obj?.path?.split("/")[2] == crumbs[3]
    );
  }
  console.log("CURRENTPATH");
  console.log(currentPath);
  console.log("CURRENTPATH.NAME");
  console.log(currentPath?.name);

  let parentPath;
  if (currentPath?.parentPath) {
    parentPath = routes.find((obj) => obj?.path == currentPath.parentPath);
  }
  console.log("PARENTPATH");
  console.log(parentPath);

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
  });
  React.useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpen(!sidebarOpen);
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar
        className={classnames("navbar-absolute fixed-top", color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <span style={{ cursor: "default" }} className="d-none d-md-block">
                {parentPath && window.localStorage.getItem("currentLoginUserRole") == 0 && (
                  <>
                    <span
                      onClick={() => history.push("/user" + parentPath?.path)}
                      className="parent-crumb"
                    >
                      {parentPath?.name}
                    </span>
                    {" / "}
                  </>
                )}
                {currentPath?.name}
              </span>
              <span className="d-block d-md-none"></span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            {/* <Form>
              <InputGroup className="no-border">
                <Input defaultValue="" placeholder="Search..." type="text" />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form> */}
            <Nav navbar>
              <NavItem>
                {
                  JSON.parse(window.localStorage.getItem("currentLoginUserRole")) !== 2 ? (
                    <NavLink
                      className="btn-magnify"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/user/dashboard")
                      }}
                    >
                      <i className="nc-icon nc-layout-11" />
                      <p>
                        <span className="d-lg-none d-md-block">Stats</span>
                      </p>
                    </NavLink>) : ('')
                }

              </NavItem>
              {/* <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i className="nc-icon nc-bell-55" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                >
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Action
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another action
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Something else here
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {/* <NavItem>
                <NavLink
                  className="btn-rotate"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Account</span>
                  </p>
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
