import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap";
import { CheckBox } from "../../tables/CheckBox";

import Sections from "./Sections";
import {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
  CreateAudit,
  GetSingleAudit,
  EditAudit,
  AddSectionsForCreatingAudit,
  AddRequirementsForCreatingAudit,
} from "../../../store/actions/auditActions";
import Requirments from "./Requirments";
import { GetSingleAuditingTemplate } from "store/actions/auditingTemplateActions";

import { FcMultipleInputs } from "react-icons/fc";

const AddAudit = (props) => {
  const {
    addSectionsForCreatingAudit,
    addRequirementsForCreatingAudit,
    allAudit,
  } = props.audit;
  const { allSections, allRequirements } = props.requirment;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddSectionsModal,
    company,
    isEditing,
    auditingTemplate,
    GetSingleAuditingTemplate,
    CreateAudit,
    EditAudit,
    GetSingleAudit,
    audit,
    AddSectionsForCreatingAudit,
    AddRequirementsForCreatingAudit,
  } = props;
  const { currentClickedCompanyData } = company;
  const { singleAudit, allContributeLead } = audit;

  const { change } = audit;
  const { allAuditingTemplate, singleAuditingTemplate } = auditingTemplate;
  console.log(allAuditingTemplate);

  console.log(
    "::::::::::::::::::::::::::::::::::::::::::::::::: audit single ::::::::::::::::::::::: ",
    singleAudit
  );

  const [selectedSectionsData, setSelectedSectionsData] = useState([]);
  const [selectedSectionsRequirements, setSelectedSectionsRequirements] =
    useState([]);
  const [selectedRequirementsData, setSelectedRequirementsData] = useState([]);

  const [startVersionDate, setStartVersionDate] = useState();
  const [endVersionDate, setEndVersionDate] = useState();

  // const [auditingTemplateSectionsData, setAuditingTemplateSectionsData] =
  //   useState([]);
  // const [
  //   auditingTemplateRequirementsData,
  //   setAuditingTemplateRequirementsData,
  // ] = useState([]);
  // const [auditingTemplateSectionsIds, setAuditingTemplateSectionsIds] =
  //   useState([]);
  // const [auditingTemplateRequirementsIds, setAuditingTemplateRequirementsIds] =
  //   useState([]);

  const [auditName, setAuditName] = useState(
    isEditing && singleAudit?.name ? singleAudit?.name : ""
  );
  const [auditVersionName, setAuditVersionName] = useState(
    isEditing && singleAudit?.version_name ? singleAudit?.version_name : ""
  );
  const [standardName, setStandardName] = useState(
    isEditing && singleAudit?.standard ? singleAudit?.standard : ""
  );
  const [contributeLead, setContributeLead] = useState(
    isEditing && singleAudit?.entity_id
      ? {
        label: singleAudit?.contribute_lead?.name,
        value: singleAudit?.contribute_lead?.id,
      }
      : {}
  );
  const [auditTemplate, setAuditTemplate] = useState(
    isEditing && singleAudit?.template?.name
      ? { label: singleAudit?.template?.name, value: singleAudit?.template.id }
      : ""
  );
  const [supportTeam, setSupportTeam] = useState("");
  const [deadline, setDeadline] = useState(
    isEditing && singleAudit?.deadline ? singleAudit?.deadline : ""
  );
  const [description, setDescription] = useState(
    isEditing && singleAudit?.description ? singleAudit?.description : ""
  );

  // const requirementsEditAudit =
  //   isEditing &&
  //   singleAudit.requirements.map((requirement) => requirement.requirement_id);
  // console.log(requirementsEditAudit);

  // const sectionsEditAudit =
  //   isEditing && singleAudit.sections.map((section) => section.section_id);
  // console.log(sectionsEditAudit);

  useEffect(() => {
    console.log("====AUDIT EDIT DATA inside===");
    console.log(allSections);
    console.log(addSectionsForCreatingAudit);
    // let sectionsData = allSections.filter((section) => {
    // !isEditing && addSectionsForCreatingAudit.includes(section.id);
    // isEditing && sectionsEditAudit.includes(section.id);
    //   addSectionsForCreatingAudit.includes(section.id);
    // });

    let sectionsData = allSections?.filter((section) =>
      addSectionsForCreatingAudit?.includes(section.id)
    );
    console.log(sectionsData);
    setSelectedSectionsData(sectionsData.flat());
  }, [addSectionsForCreatingAudit]);

  console.log("====AUDIT EDIT DATA===");
  console.log(allSections);
  console.log(addSectionsForCreatingAudit);
  console.log(selectedSectionsData);

  useEffect(() => {
    let requirementsData = allRequirements?.filter((requirement) =>
      // !isEditing && addRequirementsForCreatingAudit.includes(requirement.id);
      // isEditing && requirementsEditAudit.includes(requirement.id);
      addRequirementsForCreatingAudit?.includes(requirement.id)
    );
    setSelectedRequirementsData(requirementsData.flat());
    console.log(requirementsData);
  }, [addRequirementsForCreatingAudit]);

  useEffect(() => {
    let sectionsRequirements = selectedSectionsData.map(
      (section) => section.requirements
    );
    // setSelectedSectionsRequirements(sectionsRequirements.flat());
    console.log(sectionsRequirements);
  }, [selectedSectionsData]);

  useEffect(() => {
    // setSelectedSectionsRequirements(selectedSectionsRequirements);
  }, [selectedRequirementsData]);

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };
  var dataTable = useMemo(
    () => [
      {
        section: "Section 1",
        standardRef: "Requirment 1",
        desc: "Requirment 1 desc",
        requirmentList: "Req List 1",
        references: RenderTags(["ref#1", "ref#2"]),
        requirementMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Standard Ref.",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirment List",
        accessor: "requirmentList",
      },
      {
        Header: "Requirement Mapping",
        accessor: "requirementMapping",
      },
    ],
    []
  );
  const [data, SetData] = useState(dataTable);
  const [members, ChangeMembers] = useState(null);
  const [template, ChangeTemplate] = useState(null);
  const [entity, ChangeEntity] = useState(null);
  const [horizontalTabs, setHorizontalTabs] = React.useState("sections");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = useState(null);
  const successDelete = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const [auditFormErrors, setAuditFormErrors] = useState(null);

  const handleFormSubmit = () => {
    // const requirementsId = selectedSectionsRequirements.map(
    //   (requirement) => requirement.id
    // );

    if (isEditing && singleAudit) {
      console.log("pppp", contributeLead)
      const data = {
        id: singleAudit.id,
        company_id: currentClickedCompanyData,
        user_id: JSON.parse(window.localStorage.getItem("user"))?.id,
        audit_name: auditName,
        entity_id: contributeLead.value,
        audit_template: auditTemplate.value,
        deadline: deadline,
        description: description,
        section_id: addSectionsForCreatingAudit,
        requirement_id: addRequirementsForCreatingAudit,
        standard: standardName,
      };

      EditAudit(data, setAuditFormErrors, ChangeShowAddAuditModal);
      console.log("EDIT AUDIT DEBUGGING");
      console.log(data);
    } else {
      const data = {
        company_id: currentClickedCompanyData,
        user_id: JSON.parse(window.localStorage.getItem("user"))?.id,
        status: 0,
        audit_name: auditName,
        // entity_id: contributeLead.value,
        audit_template: auditTemplate.value,
        deadline: deadline,
        description: description,
        section_id: addSectionsForCreatingAudit,
        requirement_id: addRequirementsForCreatingAudit,
        standard: standardName,
        version_name: auditVersionName,
      };
      console.log(
        "🚀 ~ file: AddAudit.js ~ line 354 ~ handleFormSubmit ~ data",
        data
      );
      CreateAudit(data, setAuditFormErrors, ChangeShowAddAuditModal);

      console.log(auditFormErrors);
    }

    console.log(data);
  };

  // useEffect(() => {
  //   console.log("called");
  //   auditTemplate.value && GetSingleAuditingTemplate(auditTemplate.value);
  // }, [auditTemplate.value]);

  useEffect(() => {
    // TODO: Debuging
    // const auditingTemplateSectionsId =
    //   singleAuditingTemplate?.data?.sections.map(
    //     (section) => section.section_id
    //   );
    // // setAuditingTemplateSectionsIds(auditingTemplateSectionsId);
    // const auditingTemplateRequirementsId =
    //   singleAuditingTemplate?.data?.requirements.map(
    //     (requirement) => requirement.requirement_id
    //   );
    // AddSectionsForCreatingAudit(auditingTemplateSectionsId);
    // AddRequirementsForCreatingAudit(auditingTemplateRequirementsId);
    // TODO: Debuging ends here
    // setAuditingTemplateRequirementsIds(auditingTemplateRequirementsId);
    // const auditingTemplateSections = allSections?.filter((section) =>
    //   auditingTemplateSectionsId?.includes(section.id)
    // );
    // setAuditingTemplateSectionsData(auditingTemplateSections);
    // const auditingTemplateRequirements = allRequirements?.filter(
    //   (requirement) => auditingTemplateRequirementsId?.includes(requirement.id)
    // );
    // setAuditingTemplateRequirementsData(auditingTemplateRequirements);
    // console.log(
    //   ":::::::::::::::::::::::::AUDITING TEMPLATE USE EFFECT::::::::::::::::::::::"
    // );
    // console.log(auditingTemplateSectionsId);
    // console.log(auditingTemplateRequirementsId);
    // console.log(auditingTemplateSections);
    // console.log(auditingTemplateRequirements);
  }, [singleAuditingTemplate]);

  // FIXME: Edit Audit (2nd method)
  // useEffect(() => {

  // }, [])

  // useEffect(() => {
  //   auditTemplate.value && setSelectedSectionsData(singleAuditingTemplate);
  // }, [auditTemplate.value]);

  console.log("::::::::::::::singleAuditingTemplate::::::::::::::::::");
  console.log(selectedSectionsData);
  console.log(singleAuditingTemplate);
  console.log(auditTemplate.value);

  // const auditingTemplateSectionsId = singleAuditingTemplate?.data?.sections.map(
  //   (section) => section.section_id
  // );
  // const auditingTemplateRequirementsId =
  //   singleAuditingTemplate?.data?.requirements.map(
  //     (requirement) => requirement.requirement_id
  //   );

  // console.log(auditingTemplateSectionsId);
  // console.log(auditingTemplateRequirementsId);

  // const auditingTemplateSections = allSections?.filter((section) =>
  //   auditingTemplateSectionsId?.includes(section.id)
  // );
  // console.log(auditingTemplateSections);

  return (
    <>
      {alert}
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => ChangeShowAddAuditModal(false)}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">
          {isEditing ? "Edit" : "New"} Assessment
        </h4>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>
                  Assessment Name <span className="text-danger">*</span>
                </Label>
                <Input
                  invalid={
                    auditFormErrors && auditFormErrors?.name ? true : false
                  }
                  type="text"
                  placeholder="Enter Assessment Name"
                  // value={isEditing ? "Audit 1" : ""}
                  value={auditName}
                  onChange={(e) => {
                    setAuditName(e.target.value);
                  }}
                />
                <FormFeedback>
                  {auditFormErrors && auditFormErrors?.name}
                </FormFeedback>
              </FormGroup>
            </Col>
            {!isEditing && (
              <Col md="6">
                <FormGroup>
                  <Label>
                    Version Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    invalid={
                      auditFormErrors && auditFormErrors?.version_name
                        ? true
                        : false
                    }
                    type="text"
                    placeholder="Enter Verion Name"
                    // value={isEditing ? "Audit 1" : ""}
                    value={auditVersionName}
                    onChange={(e) => {
                      setAuditVersionName(e.target.value);
                    }}
                  />
                  <FormFeedback>
                    {auditFormErrors && auditFormErrors?.version_name}
                  </FormFeedback>
                </FormGroup>
              </Col>
            )}

            <Col md="6">
              <FormGroup>
                <Label>Standard</Label>
                <Input
                  type="text"
                  placeholder="Enter Standard"
                  value={standardName}
                  onChange={(e) => {
                    setStandardName(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>

            {/* <Col md="6">
                            <FormGroup>
                                <Label>Audit Entity</Label>
                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    placeholder="Select Members"
                                    name="members"
                                    closeMenuOnSelect={false}
                                    isMulti={false}
                                    value={entity}
                                    onChange={(value) => ChangeEntity(value)}
                                    options={[
                                        {
                                            value: 0,
                                            label: "Select Members",
                                            isDisabled: true,
                                        },
                                        { value: 1, label: "Zeeshan " },
                                        { value: 2, label: "Ali" },
                                        { value: 3, label: "Akmal" },
                                        { value: 4, label: "Moon" },

                                    ]}
                                />
                            </FormGroup>
                        </Col> */}
            {/* <Col md="6">
              <FormGroup>
                <Label>Contributor Lead</Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Members"
                  name="members"
                  isDisabled={singleAudit?.status === 1 ? true : false}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  value={contributeLead}
                  onChange={(value) => setContributeLead(value)}
                

                  options={allContributeLead?.map((contributeLead) => ({
                    value: contributeLead.id,
                    label: contributeLead.name,
                  }))}
                />
                <small className="text-danger">
                  {auditFormErrors &&
                    auditFormErrors?.entity_id &&
                    "The contributor lead field is required"}
                </small>
              </FormGroup>
            </Col> */}
            <Col md="6">
              <FormGroup>
                <Label> Assessment Template</Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Assessment Template"
                  name="members"
                  isDisabled={singleAudit?.status === 1 ? true : false}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  value={auditTemplate}
                  onChange={(value) => {
                    setAuditTemplate(value);
                    GetSingleAuditingTemplate(value.value);
                  }}
                  // options={[
                  //   {
                  //     value: 0,
                  //     label: "Select Members",
                  //     isDisabled: true,
                  //   },
                  //   { value: 1, label: "Audit Template 1" },
                  //   { value: 2, label: "Audit Template 2" },
                  //   { value: 3, label: "Audit Template 3" },
                  //   { value: 4, label: "Audit Template 4" },
                  // ]}
                  options={allAuditingTemplate.map((template) => ({
                    value: template.id,
                    label: template.name,
                  }))}
                />
                <small className="text-danger">
                  {auditFormErrors &&
                    auditFormErrors?.template_id &&
                    "The audit template field is required"}
                </small>
              </FormGroup>
            </Col>

            {/* <Col md="6">
                            <FormGroup>
                                <Label>Members</Label>
                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    placeholder="Select Members"
                                    name="members"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={members}
                                    onChange={(value) => ChangeMembers(value)}
                                    options={[
                                        {
                                            value: 0,
                                            label: "Select Members",
                                            isDisabled: true,
                                        },
                                        { value: 1, label: "Zeeshan " },
                                        { value: 2, label: "Ali" },
                                        { value: 3, label: "Akmal" },
                                        { value: 4, label: "Moon" },

                                    ]}
                                />
                            </FormGroup>
                        </Col> */}
            {/* <Col md="6">
              <FormGroup>
                <Label>Support Team</Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Members"
                  name="members"
                  closeMenuOnSelect={false}
                  isMulti
                  value={supportTeam}
                  onChange={(value) => setSupportTeam(value)}
                  options={[
                    {
                      value: 0,
                      label: "Select Members",
                      isDisabled: true,
                    },
                    { value: 1, label: "Zeeshan " },
                    { value: 2, label: "Ali" },
                    { value: 3, label: "Akmal" },
                    { value: 4, label: "Moon" },
                  ]}
                />
              </FormGroup>
            </Col> */}
            <Col md="6">
              <FormGroup>
                <Label>Deadline</Label>
                <Input
                  type="date"
                  placeholder="Enter Date"
                  value={deadline}
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label>Description</Label>
                <textarea
                  value={description}
                  style={{
                    paddingLeft: 10,
                  }}
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder="Add description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </FormGroup>
            </Col>
          </Row>
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav id="tabs" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    aria-expanded={horizontalTabs === "sections"}
                    data-toggle="tab"
                    role="tab"
                    className={horizontalTabs === "sections" ? "active" : ""}
                    onClick={() => setHorizontalTabs("sections")}
                  >
                    Sections
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-expanded={horizontalTabs === "requirements"}
                    data-toggle="tab"
                    role="tab"
                    className={
                      horizontalTabs === "requirements" ? "active" : ""
                    }
                    onClick={() => setHorizontalTabs("requirements")}
                  >
                    Requirments
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent
            className="text-center"
            id="my-tab-content"
            activeTab={horizontalTabs}
          >
            <TabPane tabId="sections" role="tabpanel">
              <Sections
                isEditing={isEditing}
                selectedSectionsData={selectedSectionsData}
                status={singleAudit?.status}
              />
            </TabPane>
            <TabPane tabId="requirements" role="tabpanel">
              <Requirments
                isEditing={isEditing}
                // selectedSectionsRequirements={[
                //   ...auditingTemplateRequirementsData,
                //   ...selectedSectionsRequirements,
                // ]}
                status={singleAudit?.status}
                selectedSectionsRequirements={[
                  ...selectedRequirementsData,
                  ...selectedSectionsRequirements,
                ].flat()}
              />
            </TabPane>
          </TabContent>
          <div className="text-right">
            <Button
              color="success"
              onClick={() => {
                handleFormSubmit();
                // ChangeShowAddAuditModal(false);
              }}
            >
              {isEditing ? "Edit" : "Save"}
            </Button>
            <Button
              color="danger"
              onClick={() => ChangeShowAddAuditModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
  company,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
  company,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
  GetSingleAuditingTemplate,
  CreateAudit,
  EditAudit,
  GetSingleAudit,
  AddSectionsForCreatingAudit,
  AddRequirementsForCreatingAudit,
})(AddAudit);
