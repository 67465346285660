import { duration } from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";

export const messageHandler = (setAlert, message, title, status) => {
  const successErrorMessage = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success={status === "success" ? true : false}
        warning={status === "warning" ? true : false}
        default={status === "default" ? true : false}
        info={status === "info" ? true : false}
        danger={status === "danger" ? true : false}
        error={status === "error" ? true : false}
        input={status === "input" ? true : false}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  successErrorMessage();
  const hideAlert = () => {
    setAlert(null);
  };
};
