
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect, useBlockLayout, useResizeColumns } from 'react-table'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactFileReader from 'react-file-reader';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Input,
    Modal, Form, FormGroup, Label
} from "reactstrap";
import { CheckBox } from "../CheckBox";
import ExistingMappedRequirements from "../ExistingMappedRequirements";
import ExistingSubrequirements from "../ExistingSubRequirements";

// core components


// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
        // CSV example
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
        // XLSX example

        const header = columns.map((c) => c.exportValue);
        const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
                obj[col] = row[index];
            });
            return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        // Returning false as downloading of file is already taken care of
        return false;
    }
    //PDF example
    if (fileType === "pdf") {
        const headerNames = columns.map((column) => column.exportValue);
        const doc = new jsPDF();
        doc.autoTable({
            head: [headerNames],
            body: data,
            margin: { top: 20 },
            styles: {
                minCellHeight: 9,
                halign: "left",
                valign: "center",
                fontSize: 11,
            },
        });
        doc.save(`${fileName}.pdf`);

        return false;
    }

    // Other formats goes here
    return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------


const EditRequirement = () => {

    // ---------------- SETTING UP DATATABLE START ---------------------------

    const data = React.useMemo(
        () => [
            {
                name: 'ISO:8888',
                desc: 'World',
                authority: 'lorem',
                location: 'lorem',
                industry: 'lorem',
                information: 'lorem',
                effective_date: '12/03/2020',
                status: 'Active',
                requirement_file: 'lorem'
            },
            {
                name: 'ISO:9000',
                desc: 'World',
                authority: 'lorem',
                location: 'lorem',
                industry: 'lorem',
                information: 'lorem',
                effective_date: '12/03/2020',
                status: 'Active',
                requirement_file: 'lorem'
            },



        ],
        []
    )
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Desc',
                accessor: 'desc',
            },
            {
                Header: 'Authority',
                accessor: 'authority',
            },
            {
                Header: 'Location',
                accessor: 'location',
            },
            {
                Header: 'Industry',
                accessor: 'industry',
            },
            {
                Header: 'Information',
                accessor: 'information',
            },
            {
                Header: 'Date',
                accessor: 'effective_date',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Attachement',
                accessor: 'requirement_file',
            },

        ],
        []
    )


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        selectedFlatRows,
        resetResizing,
        exportData,
        rows


    } = useTable({ columns, data, getExportFileBlob }, useGlobalFilter,
        useResizeColumns, useSortBy, usePagination, useExportData, useRowSelect, (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <CheckBox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <CheckBox {...row.getToggleRowSelectedProps()} />
                        )
                    }, ...columns
                ]
            })
        })

    const { globalFilter, pageIndex, pageSize } = state;

    // ---------------- SETTING UP DATATABLE END ---------------------------

    // ---------------- SWEETALERRT START ---------------------------
    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);
    const successDelete = () => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
            >
                Requirement Deleted
            </ReactBSAlert>
        );
    };

    const successAlert = () => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Good job!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
            >
                Requirement Updated !
            </ReactBSAlert>
        );
    };
    const colMissmatchAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Columns mismatch!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
            >
                The header(1st row) in csv file should have same columns as in table header!
            </ReactBSAlert>
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    // ---------------- SWEETALERRT END ---------------------------

    // ------------------------ EDIT AND VIEW MODALS -------------------------


    // VIEW

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const setRow = (row) => {
        console.log(row)
        setRowDetails(row);
        toggleModalClassic1()

    }


    // EDIT

    const [modalClassic, setModalClassic] = React.useState(false);
    const [rowDetails, setRowDetails] = useState('')
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const setRowForEdit = (row) => {
        console.log(row)
        setRowDetails(row);
        toggleModalClassic()

    }

    const editSubmit = (e) => {
        e.preventDefault()
        toggleModalClassic()
        successAlert()
    }



    // ------------------------ EDIT AND VIEW MODALS END -------------------------


    const handleFiles = files => {
        var reader = new FileReader();
        reader.onload = function (e) {
            var rows = reader.result.split(`\r\n`)
            console.log("ROWS")
            console.log(rows)
            for (var i = 0; i < rows.length; i++) {
                var columns = rows[i].split(",")
                console.log("COLUMNS")
                console.log(columns)
                if (i == 0) {
                    if (columns[0] != 'Name' && columns[1] != 'Desc' && columns[2] != 'Authority' && columns[3] != 'Location' && columns[4] != 'Industry' && columns[5] != 'Information' && columns[6] != 'Date' && columns[7] != 'Status' && columns[8] != 'Attachement') {
                        colMissmatchAlert()
                        break;
                    }
                } else {
                    SetData(prev => {
                        return [...prev, {
                            name: columns[0],
                            desc: columns[1],
                            authority: columns[2],
                            location: columns[3],
                            industry: columns[4],
                            information: columns[5],
                            effective_date: columns[6],
                            status: columns[7],
                            requirement_file: columns[8],
                        }]
                    })
                }
            }
        }
        reader.readAsText(files[0]);
    }

    return (
        <>
            <div className="modal-header justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-left">{rowDetails?.original?.name}</h5>
            </div>
            <div className="modal-body">
                <h5>Requirement Details</h5>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label>Name</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.name} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.desc} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Authority</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.authority} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.location} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">

                        <FormGroup>
                            <Label>Industry</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.industry} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">

                        <FormGroup>
                            <Label>Information</Label>
                            <Input type="text" defaultValue={rowDetails?.original?.information} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Effective Date</Label>
                            <Input type="date" defaultValue={rowDetails?.original?.effective_date} onChange={e => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Status</Label>
                            <select name="" id="" className="form-control" onChange={e => console.log(e.target.value)} defaultValue={rowDetails?.original?.status}>
                                <option value="" disabled>Select</option>
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col md="6">

                        <FormGroup>
                            <Label>Requirement File</Label>
                            <Input type="file" onChange={e => console.log(e.target.files[0])} />
                        </FormGroup>
                    </Col>
                </Row>
                <h5>Sub Requirements</h5>
                <ExistingSubrequirements />

                <h5>Mapped Requirement</h5>
                <ExistingMappedRequirements />
                <div className="text-right">
                    <Button color="danger">Close</Button>
                </div>
            </div>
        </>
    );
}

export default EditRequirement;
