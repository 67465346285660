
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect, useBlockLayout, useResizeColumns } from 'react-table'
import PerfectScrollbar from "perfect-scrollbar";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Input, Collapse,
    Modal, Form, FormGroup, Label
} from "reactstrap";
import { CheckBox } from "../CheckBox";
import ReactFileReader from 'react-file-reader';

// core components


// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
        // CSV example
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
        // XLSX example

        const header = columns.map((c) => c.exportValue);
        const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
                obj[col] = row[index];
            });
            return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        // Returning false as downloading of file is already taken care of
        return false;
    }
    //PDF example
    if (fileType === "pdf") {
        const headerNames = columns.map((column) => column.exportValue);
        const doc = new jsPDF();
        doc.autoTable({
            head: [headerNames],
            body: data,
            margin: { top: 20 },
            styles: {
                minCellHeight: 9,
                halign: "left",
                valign: "center",
                fontSize: 11,
            },
        });
        doc.save(`${fileName}.pdf`);

        return false;
    }

    // Other formats goes here
    return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------


const RequirementsListView = () => {
    const history = useHistory();

    // ---------------- SETTING UP DATATABLE START ---------------------------

    var dataTable = React.useMemo(
        () => [
            {
                name: 'ISO:8888',
                desc: 'World',
                authority: 'lorem',
                location: 'lorem',
                industry: 'lorem',
                information: 'lorem',
                effective_date: '12/03/2020',
                status: 'Active',
                requirement_file: 'lorem'
            },
            {
                name: 'ISO:9000',
                desc: 'World',
                authority: 'lorem',
                location: 'lorem',
                industry: 'lorem',
                information: 'lorem',
                effective_date: '12/03/2020',
                status: 'InActive',
                requirement_file: 'lorem'
            },




        ],
        []
    )
    const [data, SetData] = useState(dataTable)

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Desc',
                accessor: 'desc',
            },
            {
                Header: 'Authority',
                accessor: 'authority',
            },
            {
                Header: 'Location',
                accessor: 'location',
            },
            {
                Header: 'Industry',
                accessor: 'industry',
            },
            {
                Header: 'Information',
                accessor: 'information',
            },
            {
                Header: 'Date',
                accessor: 'effective_date',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Attachement',
                accessor: 'requirement_file',
            },

        ],
        []
    )


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        selectedFlatRows,
        resetResizing,
        exportData,
        rows


    } = useTable({ columns, data, getExportFileBlob }, useGlobalFilter,
        useResizeColumns, useSortBy, usePagination, useExportData, useRowSelect, (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <CheckBox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <CheckBox {...row.getToggleRowSelectedProps()} />
                        )
                    }, ...columns
                ]
            })
        })

    // ---------------- SETTING UP DATATABLE END ---------------------------

    // ---------------- SWEETALERRT START ---------------------------
    const [alert, setAlert] = React.useState(null);
    var ps;
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    const successAlert = () => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Good job!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
            >
                Requirement Updated !
            </ReactBSAlert>
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    // ---------------- SWEETALERRT END ---------------------------

    // ------------------------ EDIT AND VIEW MODALS -------------------------


    // VIEW

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };


    // EDIT

    const [modalClassic, setModalClassic] = React.useState(false);
    const [rowDetails, setRowDetails] = useState('')
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const editSubmit = (e) => {
        e.preventDefault()
        toggleModalClassic()
        successAlert()
    }



    // ------------------------ EDIT AND VIEW MODALS END -------------------------

    //  ------------------------------------------------ ACCORDION FOR MODAL START ----------------------------------------------

    const [openedCollapses, setOpenedCollapses] = React.useState([
        "collapseOne",
        "collapse1",
        "sectionReq1",
        "subRequirement1"
    ]);

    return (
        <>

            <div className="modal-header justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic1}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-left">{rowDetails?.original?.name}</h5>
            </div>
            <div className="modal-body">
                <table className="table table-bordered table-striped table-hover">
                    <tbody>
                        <tr>
                            <td className="font-weight-bold">Name</td>
                            <td>{rowDetails?.original?.name}</td>
                            <td className="font-weight-bold">Description</td>
                            <td>{rowDetails?.original?.desc}</td>
                            <td className="font-weight-bold">Authority</td>
                            <td>{rowDetails?.original?.authority}</td>
                        </tr>
                        <tr>

                            <td className="font-weight-bold">Location</td>
                            <td>{rowDetails?.original?.location}</td>
                            <td className="font-weight-bold">Industry</td>
                            <td>{rowDetails?.original?.industry}</td>
                            <td className="font-weight-bold">Information</td>
                            <td>{rowDetails?.original?.information}</td>
                        </tr>

                        <tr>
                            <td className="font-weight-bold">Effective Date</td>
                            <td>{rowDetails?.original?.effective_date}</td>
                            <td className="font-weight-bold">Status</td>
                            <td>{rowDetails?.original?.status}</td>
                            <td className="font-weight-bold">Requirement File</td>
                            <td>{rowDetails?.original?.requirement_file}</td>
                        </tr>

                    </tbody>
                </table>

                <div style={{ height: '400px', overflowY: 'scroll' }} id="scroll-bar">
                    <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                    >
                        <Card className="card-plain">
                            <CardHeader role="tab">
                                <a
                                    aria-expanded={openedCollapses.includes("collapseOne")}
                                    className="text-danger font-weight-bold"

                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={() => collapsesToggle("collapseOne")}
                                >
                                    A Section : 14.1 : Section Description{" "}
                                    <i className="nc-icon nc-minimal-down" />
                                </a>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={openedCollapses.includes("collapseOne")}
                            >
                                <Card className="card-plain ml-3">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={openedCollapses.includes("sectionReq1")}
                                            className="text-info font-weight-bold"

                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={() => collapsesToggle("sectionReq1")}
                                        >
                                            Section Requirement : 14.1.1 : Requirement Description{" "}
                                            <i className="nc-icon nc-minimal-down text-info" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={openedCollapses.includes("sectionReq1")}
                                    >
                                        <Card className="card-plain ml-5">
                                            <CardHeader role="tab">
                                                <a
                                                    aria-expanded={openedCollapses.includes("subRequirement1")}

                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    className="text-success font-weight-bold"
                                                    onClick={() => collapsesToggle("subRequirement1")}
                                                >
                                                    Sub Requirement : 14.1.1.1 : Subrequirement Description{" "}
                                                    <i className="nc-icon nc-minimal-down text-success" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes("subRequirement1")}
                                            >
                                                <CardBody>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Standard Ref.</th>
                                                                <th>Description</th>
                                                                <th>References</th>
                                                                <th>Weight</th>
                                                                <th>Criticality</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>12.1</td>
                                                                <td>This is a description of the subrequirement.</td>
                                                                <td>A , B , C</td>
                                                                <td>0</td>
                                                                <td>Hight</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </Collapse>
                                </Card>

                            </Collapse>
                        </Card>


                        <Card className="card-plain">
                            <CardHeader role="tab">
                                <a
                                    aria-expanded={openedCollapses.includes("collapseTwo")}
                                    className="text-danger font-weight-bold"

                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={() => collapsesToggle("collapseTwo")}
                                >
                                    A Section : 14.2 : Section Description{" "}
                                    <i className="nc-icon nc-minimal-down" />
                                </a>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={openedCollapses.includes("collapseTwo")}
                            >
                                <Card className="card-plain ml-3">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={openedCollapses.includes("sectionReq2")}
                                            className="text-info font-weight-bold"

                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={() => collapsesToggle("sectionReq2")}
                                        >
                                            Section Requirement : 14.2.1 : Requirement Description{" "}
                                            <i className="nc-icon nc-minimal-down text-info" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={openedCollapses.includes("sectionReq2")}
                                    >
                                        <Card className="card-plain ml-5">
                                            <CardHeader role="tab">
                                                <a
                                                    aria-expanded={openedCollapses.includes("subRequirement2")}

                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    className="text-success font-weight-bold"
                                                    onClick={() => collapsesToggle("subRequirement2")}
                                                >
                                                    Sub Requirement : 14.2.1.1 : Subrequirement Description{" "}
                                                    <i className="nc-icon nc-minimal-down text-success" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes("subRequirement2")}
                                            >
                                                <CardBody>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Standard Ref.</th>
                                                                <th>Description</th>
                                                                <th>References</th>
                                                                <th>Weight</th>
                                                                <th>Criticality</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>12.1</td>
                                                                <td>This is a description of the subrequirement.</td>
                                                                <td>A , B , C</td>
                                                                <td>0</td>
                                                                <td>Hight</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </Collapse>
                                </Card>
                            </Collapse>
                        </Card>
                    </div>
                </div>
                <div className="text-right">
                    <Button color="info" onClick={() => { history.push('/user/requirements-list-detail') }}>Details</Button>
                </div>
            </div>
        </>

    );
}

export default RequirementsListView;
