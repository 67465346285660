export const CHANGE_SET_SIDEBAR_MINI = `CHANGE_SET_SIDEBAR_MINI`;
export const CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL = `CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL`;
export const CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL = `CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL`;
export const CHANGE_IS_EDITING = `CHANGE_IS_EDITING`;
export const CHANGE_UNDER_EDIT_TEMPLATE_TITLE = `CHANGE_UNDER_EDIT_TEMPLATE_TITLE`;
export const CHANGE_SHOW_ADD_SECTIONS_MODAL = `CHANGE_SHOW_ADD_SECTIONS_MODAL`;
export const CHANGE_SHOW_ADD_AUDIT_MODAL = `CHANGE_SHOW_ADD_AUDIT_MODAL`;
export const CHANGE_SHOW_ADD_REQUIRMENTS_MODAL = `CHANGE_SHOW_ADD_REQUIRMENTS_MODAL`;
export const CHANGE_SHOW_VIEW_SECTION_MODAL = `CHANGE_SHOW_VIEW_SECTION_MODAL`;
export const CHANGE_SHOW_EDIT_SECTION_MODAL = `CHANGE_SHOW_EDIT_SECTION_MODAL`;
export const CHANGE_SHOW_ADD_SECTION_MODAL = `CHANGE_SHOW_ADD_SECTION_MODAL`;
export const CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL = `CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL`;
export const CHANGE_SHOW_VIEW_REQUIREMENT_MODAL = `CHANGE_SHOW_VIEW_REQUIREMENT_MODAL`;
export const CHANGE_VIEWING_SUB_REQUIREMENTS = `CHANGE_VIEWING_SUB_REQUIREMENTS`;
export const CHANGE_IS_EDITING_SECTION = `CHANGE_IS_EDITING_SECTION`;
export const CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL = `CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL`;
export const CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL = `CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL`;
export const TOGGLE_COLLAPSED_SECTIONS = `TOGGLE_COLLAPSED_SECTIONS`;
export const MANAGE_CURRENT_LOGGED_IN_USER_ROLE = `MANAGE_CURRENT_LOGGED_IN_USER_ROLE`;
export const CHANGE_REQUIREMENT_LIST = `CHANGE_REQUIREMENT_LIST`;
export const CHANGE_GET_ALL_REQUIREMENT_LIST = `CHANGE_GET_ALL_REQUIREMENT_LIST`;
export const CHANGE_REQUIREMENT_SECTION = `CHANGE_REQUIREMENT_SECTION`;
export const MANAGE_USER = `MANAGE_USER`;
export const CHANGE_GET_SINGLE_REQUIREMENT_LIST = `CHANGE_GET_SINGLE_REQUIREMENT_LIST`;
export const CHANGE_CREATE_SECTION_REQUIREMENT = `CHANGE_CREATE_SECTION_REQUIREMENT`;
export const CHANGE_CREATE_SUB_REQUIREMENT = `CHANGE_CREATE_SUB_REQUIREMENT`;
export const CHANGE_GET_ALL_AUDITING_TEMPLATE = `CHANGE_GET_ALL_AUDITING_TEMPLATE`;
export const CHANGE_DELETE_AUDITING_TEMPLATE = `CHANGE_DELETE_AUDITING_TEMPLATE`;
export const CHANGE_GET_SINGLE_SECTION = `CHANGE_GET_SINGLE_SECTION`;
export const CHANGE_GET_SINGLE_REQUIREMENT = `CHANGE_GET_SINGLE_REQUIREMENT`;
export const CHANGE_GET_SINGLE_SUB_REQUIREMENT = `CHANGE_GET_SINGLE_SUB_REQUIREMENT`;
export const CHANGE_GET_REQUIREMENTS_BY_SECTION_ID = `CHANGE_GET_REQUIREMENTS_BY_SECTION_ID`;
export const CHANGE_CREATE_AUDITING_TEMPLATE = `CHANGE_CREATE_AUDITING_TEMPLATE`;
export const CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID = `CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID`;
export const CHANGE_GET_ALL_SECTIONS = `CHANGE_GET_ALL_SECTIONS`;
export const ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE = `ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE`;
export const CHANGE_GET_ALL_REQUIREMENTS = `CHANGE_GET_ALL_REQUIREMENTS`;
export const CHANGE_GLOBAL_LOADER = `CHANGE_GLOBAL_LOADER`;
export const CHANGE_GET_ALL_COMPANIES = `CHANGE_GET_ALL_COMPANIES`;
export const CHANGE_REQUIREMENT_EDITING = `CHANGE_REQUIREMENT_EDITING`;
export const CHANGE_EDIT_REQUIREMENT_LIST = `CHANGE_EDIT_REQUIREMENT_LIST`;
export const CHANGE_GET_ALL_SUB_REQUIREMENTS = `CHANGE_GET_ALL_SUB_REQUIREMENTS`;
export const ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE = `ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE`;
export const CHANGE_EDIT_AUDITING_TEMPLATE_DATA = `CHANGE_EDIT_AUDITING_TEMPLATE_DATA`;
export const CHANGE_EDIT_REQUIREMENT_SECTION = `CHANGE_EDIT_REQUIREMENT_SECTION`;
export const CHANGE_GET_SECTION_BY_ID = `CHANGE_GET_SECTION_BY_ID`;
export const CHANGE_IS_ADD_REQUIREMENT_LIST = `CHANGE_IS_ADD_REQUIREMENT_LIST`;
export const CHANGE_IS_ADD_SECTION = `CHANGE_IS_ADD_SECTION`;
export const CHANGE_IS_ADD_REQUIREMENT = `CHANGE_IS_ADD_REQUIREMENT`;
export const CHANGE_IS_ADD_SUB_REQUIREMENT = `CHANGE_IS_ADD_SUB_REQUIREMENT`;
export const CHANGE_EDIT_SUB_REQUIREMENT = `CHANGE_EDIT_SUB_REQUIREMENT`;
export const CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID = `CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID`;
export const CHANGE_EDIT_SECTION_REQUIREMENT = `CHANGE_EDIT_SECTION_REQUIREMENT`;
export const CHANGE_GET_REQUIREMENT_BY_ID = `CHANGE_GET_REQUIREMENT_BY_ID`;
export const CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID = `CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID`;
export const CHANGE_CREATE_COMPANY = `CHANGE_CREATE_COMPANY`;
export const CHANGE_EDIT_COMPANY = `CHANGE_EDIT_COMPANY`;
export const CHANGE_GET_SINGLE_COMPANY = `CHANGE_GET_SINGLE_COMPANY`;
export const CHANGE_IS_EDITING_COMPANY = `CHANGE_IS_EDITING_COMPANY`;
export const CHANGE_SINGLE_COMPANY_EMPTY = `CHANGE_SINGLE_COMPANY_EMPTY`;
export const CHANGE_GET_ALL_COUNTRIES = `CHANGE_GET_ALL_COUNTRIES`;
export const CHANGE_GET_STATES_BY_COUNTRY = `CHANGE_GET_STATES_BY_COUNTRY`;
export const CHANGE_GET_CITIES_BY_STATE = `CHANGE_GET_CITIES_BY_STATE`;
export const CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID = `CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID`;
export const CHANGE_GET_SUB_REQUIREMENT_BY_ID = `CHANGE_GET_SUB_REQUIREMENT_BY_ID`;
export const CHANGE_SHOW_ADD_NEW_USER_MODAL = `CHANGE_SHOW_ADD_NEW_USER_MODAL`;
export const CHANGE_CREATE_REFERENCE = `CHANGE_CREATE_REFERENCE`;
export const CHANGE_GET_ALL_REFERENCES = `CHANGE_GET_ALL_REFERENCES`;
export const CHANGE_EDIT_REFERENCE = `CHANGE_EDIT_REFERENCE`;
export const CHANGE_CREATE_USER_ROLE = `CHANGE_CREATE_USER_ROLE`;
export const CHANGE_GET_ALL_USER_ROLE = `CHANGE_GET_ALL_USER_ROLE`;
export const CHANGE_IS_ADD_NEW_AUDIT = `CHANGE_IS_ADD_NEW_AUDIT`;
export const ADD_SECTIONS_FOR_CREATING_AUDIT = `ADD_SECTIONS_FOR_CREATING_AUDIT`;
export const ADD_REQUIREMENTS_FOR_CREATING_AUDIT = `ADD_REQUIREMENTS_FOR_CREATING_AUDIT`;
export const CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT = `CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT`;
export const CLEAR_AUDIT_SECTION_AND_REQUIREMENT = `CLEAR_AUDIT_SECTION_AND_REQUIREMENT`;
export const CHANGE_CREATE_AUDIT = `CHANGE_CREATE_AUDIT`;
export const CHANGE_CLEAR_REQUIREMENT_DATA = `CHANGE_CLEAR_REQUIREMENT_DATA`;
export const CHANGE_GET_ALL_AUDIT = `CHANGE_GET_ALL_AUDIT`;
export const CHANGE_DELETE_REFERENCE = `CHANGE_DELETE_REFERENCE`;
export const CHANGE_CREATE_INDIVIDUAL = `CHANGE_CREATE_INDIVIDUAL`;
export const CHANGE_GET_INDIVIDUAL = `CHANGE_GET_INDIVIDUAL`;
export const CHANGE_EDIT_INDIVIDUAL = `CHANGE_EDIT_INDIVIDUAL`;
export const CHANGE_SINGLE_AUDITING_TEMPLATE = `CHANGE_SINGLE_AUDITING_TEMPLATE`;
export const CHANGE_CURRENT_CLICKED_COMPANY_DATA = `CHANGE_CURRENT_CLICKED_COMPANY_DATA`;
export const CHANGE_EDIT_AUDIT = `CHANGE_Edit_AUDIT`;
export const CHANGE_GET_SINGLE_AUDIT = "CHANGE_GET_SINGLE_AUDIT";
export const GET_ALL_DASHBOARD_INFO = "GET_ALL_DASHBOARD_INFO";
export const CHANGE_EDIT_AUDITING_TEMPLATE = "CHANGE_EDIT_AUDITING_TEMPLATE";
export const TOKEN_VIRIFICATION_USER_ROLE = "TOKEN_VIRIFICATION_USER_ROLE";
export const CREATE_USER_ROLE_REGISTER = "CREATE_USER_ROLE_REGISTER";
export const CHANGE_CREATE_TASK = "CHANGE_CREATE_TASK";
export const GET_ALL_TASK = "GET_ALL_TASK";
export const SET_AUDIT_NAME_FOR_SIDEBAR = "SET_AUDIT_NAME_FOR_SIDEBAR";
export const GET_ALL_CONTRIBUTE_LEAD = "GET_ALL_CONTRIBUTE_LEAD";
export const CHANGE_EDIT_AUDIT_DATA = "CHANGE_EDIT_AUDIT_DATA";
export const CHANGE_AUDITOR_LIFE_CYCLE = "CHANGE_AUDITOR_LIFE_CYCLE";
export const CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT =
  "CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT";
export const CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE =
  "CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE";

export const GET_ALL_SUPPORT_TEAM = "GET_ALL_SUPPORT_TEAM";

export const INVITE_SUPPORT_TEAM = "INVITE_SUPPORT_TEAM";
export const SUPPORT_VERIFY_MEMBER = "SUPPORT_VERIFY_MEMBER";
export const CREATE_AUDIT_MEMBER_SUPPORT = "CREATE_AUDIT_MEMBER_SUPPORT";

export const ASSIGN_SUPPORT_TEAM_RESPONSE = "ASSIGN_SUPPORT_TEAM_RESPONSE";
export const CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE =
  "CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE";
export const MAP_REQUIREMENT = "MAP_REQUIREMENT";
export const MAP_REQUIREMENT_IDS = "MAP_REQUIREMENT_IDS";
export const MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE =
  "MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE";
export const CHANGE_SAVE_ANSWER = "CHANGE_SAVE_ANSWER";
export const GET_ALL_MAP_REQUIREMENT = "GET_ALL_MAP_REQUIREMENT";
export const GET_SINGLE_MAP_REQUIREMENT = "GET_SINGLE_MAP_REQUIREMENT";
export const CHANGE_SHOW_AUDIT_ANSWER = "CHANGE_SHOW_AUDIT_ANSWER";
export const CHANGE_SHOW_AUDIT_ANSWER_TASK = "CHANGE_SHOW_AUDIT_ANSWER_TASK";
export const EMPTY_SINGLE_AUDIT_DATA = "EMPTY_SINGLE_AUDIT_DATA";
export const MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW =
  "MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW";
export const GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT =
  "GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT";
export const CLEAR_AUDIT_ANSWER_TASK_DATA = "CLEAR_AUDIT_ANSWER_TASK_DATA";
export const CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA =
  "CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA";

export const CREATE_VERSION_LIFE_CYCLE = " CREATE_VERSION_LIFE_CYCLE";
export const CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION =
  " CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION";
export const GET_ALL_VERSION_DETAILS = " GET_ALL_VERSION_DETAILS";
export const GET_ALL_VERSION_DETAILS_TABLE = "GET_ALL_VERSION_DETAILS_TABLE";
export const CHANGE_IS_REQUIREMENT_LIST_EDIT =
  "CHANGE_IS_REQUIREMENT_LIST_EDIT";

export const CLEAR_ALL_TASK_DATA = "CLEAR_ALL_TASK_DATA";
export const CLEAR_SINGLE_REQUIREMENT_LIST_DATA =
  "CLEAR_SINGLE_REQUIREMENT_LIST_DATA";
export const SHOW_VERSION_NAME = "SHOW_VERSION_NAME";
export const SHOW_VERSION_ITERATION = "SHOW_VERSION_ITERATION";
export const CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA =
  "CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA";

export const GET_VERSION_GRAPH = 'GET_VERSION_GRAPH'
export const GET_SUMMARY_DETAILS = 'GET_SUMMARY_DETAILS'
export const GET_AUDITOR_TASK_NOTIFICATION = 'GET_AUDITOR_TASK_NOTIFICATION'
export const GET_CONTRIBUTE_TASK_NOTIFICATION = 'GET_CONTRIBUTE_TASK_NOTIFICATION'
export const GET_VERSION_FILES = 'GET_VERSION_FILES'
export const GET_DISCUSSION_DATA = 'GET_DISCUSSION_DATA'
export const CREATE_DISCUSSION_DATA = " CREATE_DISCUSSION_DATA"
export const GET_TOTAL_COMMENT = 'GET_TOTAL_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const GET_ALL_MAIN_REQ_LIST = 'GET_ALL_MAIN_REQ_LIST'
export const ASSIGN_AUDIT_TO_CONTRIBUTE = 'ASSIGN_AUDIT_TO_CONTRIBUTE'
export const INVITE_CONTRIBUTE_TO_AUDIT = 'INVITE_CONTRIBUTE_TO_AUDIT'
export const INVITE_CONTRIBUTE_TO_AUDIT_TOKEN = 'INVITE_CONTRIBUTE_TO_AUDIT_TOKEN'
export const INVITE_MULTI_SUPPORT_TO_AUDIT = 'INVITE_MULTI_SUPPORT_TO_AUDIT'
export const GET_REPORT_FILES = 'GET_REPORT_FILES'
export const GET_RADAR_CHART = 'GET_RADAR_CHART'
export const SHOW_VERSION_NAME_REPORT = 'SHOW_VERSION_NAME_REPORT'
export const GET_SUPPORT_USER = 'GET_SUPPORT_USER'
export const GET_CONTRIBUTE_USER = 'GET_CONTRIBUTE_USER'
export const GET_USER_DATA = 'GET_USER_DATA'
export const EDIT_USER_DATA = 'EDIT_USER_DATA'
export const CLEAR_SUB_REQUIREMENT_BY_ID = 'CLEAR_SUB_REQUIREMENT_BY_ID'
export const SHOW_SUB_REQ_DECISION = 'SHOW_SUB_REQ_DECISION'
export const CLEAR_SUB_REQ_DECISION = 'CLEAR_SUB_REQ_DECISION'