import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";
import TagsInput from "react-tagsinput";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap";

import { ChangeShowAddCompanyOrIndividualModal } from "../../../store/actions/auditActions";
import { CreateCompany } from "store/actions/companyActions";
import { EditCompany } from "store/actions/companyActions";
import { ChangeIsEditingCompany } from "store/actions/companyActions";
import { GetSingleCompany } from "store/actions/companyActions";
import { ChangeSingleCompanyEmpty } from "store/actions/companyActions";
import { GetAllCountries } from "store/actions/sharedAction";
import { GetStatesByCountry } from "store/actions/sharedAction";
// import { GetCitiesByState } from "store/actions/sharedAction";
import { GetAllCompanies } from "store/actions/companyActions";

const AddCompanyORIndividual = (props) => {
  const { } = props.audit;
  const {
    ChangeShowAddCompanyOrIndividualModal,
    CreateCompany,
    company: companyReducer,
    EditCompany,
    ChangeIsEditingCompany,
    ChangeSingleCompanyEmpty,
    GetAllCountries,
    GetSingleCompany,
    GetStatesByCountry,

    GetAllCompanies,
  } = props;
  const { allCountries, statesByCountry, citiesByState } = props.shared;
  const { singleCompany, isEditCompany, allCompanies } = companyReducer;
  const { createCompany } = props.company;

  const [addingCompany, ChangeAddingCompany] = useState(true);

  //   const [companyData, setCompanyData] = useState(singleCompany);

  //   console.log("companyData");
  //   console.log(companyData);

  const [companyName, setCompanyName] = useState(
    singleCompany?.name ? singleCompany?.name : ""
  );
  const [address, setAddress] = useState(
    singleCompany?.address ? singleCompany?.address : ""
  );
  const [country, setCountry] = useState(
    singleCompany?.country ? singleCompany?.country : ""
  );
  const [companyState, setCompanyState] = useState(
    singleCompany?.state ? singleCompany?.state : ""
  );
  const [city, setCity] = useState(
    singleCompany?.city ? singleCompany?.city : ""
  );
  const [zipCode, setZipCode] = useState(
    singleCompany?.zip_code ? singleCompany?.zip_code : ""
  );
  const [taxId, setTaxId] = useState(
    singleCompany?.tax_id ? singleCompany?.tax_id : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    singleCompany?.phone_no ? singleCompany?.phone_no : ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    singleCompany?.mobile_no ? singleCompany?.mobile_no : ""
  );
  const [email, setEmail] = useState(
    singleCompany?.email ? singleCompany?.email : ""
  );
  const [website, setWebsite] = useState(
    singleCompany?.website ? singleCompany?.website : ""
  );
  const [file, setFile] = useState(
    singleCompany?.logo ? singleCompany?.logo : {}
  );
  const [tagsinput, setTagsinput] = React.useState(
    singleCompany?.tags ? singleCompany?.tags : []
  );

  const [individualMember, setIndividualMember] = useState("");

  console.log(file);

  // useEffect(() => {
  //   GetStatesByCountry(country.value);
  // }, [country]);

  // useEffect(() => {
  //   GetCitiesByState(companyState.value);
  // }, [companyState]);

  useEffect(() => {
    addingCompany === false && GetAllCompanies();
  }, [addingCompany]);

  console.log(allCompanies);

  console.log(statesByCountry);

  //   useEffect(() => {
  //     setCompanyName(singleCompany?.name ? singleCompany?.name : "");
  //     setAddress(singleCompany?.address ? singleCompany?.address : "");
  //     setCountry(singleCompany?.country ? singleCompany?.country : "");
  //     setCompanyState(singleCompany?.state ? singleCompany?.state : "");
  //     setCity(singleCompany?.city ? singleCompany?.city : "");
  //     setZipCode(singleCompany?.zip_code ? singleCompany?.zip_code : "");
  //     setTaxId(singleCompany?.tax_id ? singleCompany?.tax_id : "");
  //     setPhoneNumber(singleCompany?.phone_no ? singleCompany?.phone_no : "");
  //     setMobileNumber(singleCompany?.mobile_no ? singleCompany?.mobile_no : "");
  //     setEmail(singleCompany?.email ? singleCompany?.email : "");
  //     setWebsite(singleCompany?.website ? singleCompany?.website : "");
  //     setFile(singleCompany?.file ? singleCompany?.file : {});
  //     setTagsinput(singleCompany?.tags ? singleCompany?.tags : []);
  //   }, [singleCompany]);

  console.log(singleCompany);

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };
  var dataTable = useMemo(
    () => [
      {
        section: "Section 1",
        standardRef: "Requirment 1",
        desc: "Requirment 1 desc",
        requirmentList: "Req List 1",
        references: RenderTags(["ref#1", "ref#2"]),
        requirementMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Standard Ref.",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirment List",
        accessor: "requirmentList",
      },
      {
        Header: "Requirement Mapping",
        accessor: "requirementMapping",
      },
    ],
    []
  );
  const [data, SetData] = useState(dataTable);
  const [members, ChangeMembers] = useState(null);
  const [template, ChangeTemplate] = useState(null);
  const [entity, ChangeEntity] = useState(null);
  const [horizontalTabs, setHorizontalTabs] = React.useState("sections");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = useState(null);
  const [company, ChangeCompany] = useState(null);
  //   const [country, ChangeCountry] = useState(null);
  //   const [city, ChangeCity] = useState(null);
  //   const [state_, ChangeState_] = useState(null);
  const successDelete = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const getUploadImageFileData = (file) => {
    setFile(file);
  };
  const [formErrorState, setFormErrorState] = useState(null);

  const handleCompanySubmit = () => {
    const data = {
      name: companyName,
      address: address,
      country: country,
      city: city,
      state: companyState,
      zip_code: zipCode,
      tax_id: taxId,
      phone_no: phoneNumber,
      mobile_no: mobileNumber,
      email: email,
      website: website,
      tags: tagsinput,
      status: "",
      file: file,
    };

    console.log("dataComanpy", data)
    !isEditCompany
      ? CreateCompany(
        data,
        setFormErrorState,
        ChangeShowAddCompanyOrIndividualModal,
        // setAlert
      )
      : EditCompany(
        { ...data, id: singleCompany?.id },
        setFormErrorState,
        ChangeShowAddCompanyOrIndividualModal,
        ChangeIsEditingCompany,
        ChangeSingleCompanyEmpty,
        // setAlert
      );

    // ChangeShowAddCompanyOrIndividualModal(false)
    // ChangeIsEditingCompany(false),
    // ChangeSingleCompanyEmpty(null)

    // if (formErrorState === null) {
    //   ChangeShowAddCompanyOrIndividualModal(false);
    // }

    // if (!createCompany?.success) {
    //   if (createCompany?.message?.email) {
    //     setEmailError(createCompany?.message?.email);
    //   }
    //   if (mobileNumber.length !== 11) {
    //     setMobileNumberError("Mobile Number must be 11 digits");
    //   }

    //   if (phoneNumber.length !== 10) {
    //     setPhoneNumberError("Phone Number must be 10 digits");
    //   }

    //   if (zipCode.length < 5 || zipCode.length > 9) {
    //     setZipCodeError("Zip Code must be 5 to 9 digits");
    //   }
    // }

    // if (
    //   mobileNumberError === "" &&
    //   phoneNumberError === "" &&
    //   zipCodeError === ""
    // ) {
    //   ChangeShowAddCompanyOrIndividualModal(false);
    // }

    // const individualData = {
    //   member: individualMember,
    //   name: company.value,
    //   address: address,
    //   country: country.value,
    //   city: city.value,
    //   state: companyState.value,
    //   zip_code: zipCode,
    //   tax_id: taxId,
    //   phone_no: phoneNumber,
    //   mobile_no: mobileNumber,
    //   email: email,
    //   website: website,
    //   // tags: tagsinput,
    //   // status: "",
    //   // file: file,
    // };

    console.log(data);

    // TODO: UNCOMMENT IT LATER

    console.log(data);
  };

  return (
    <>
      {alert}
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            ChangeShowAddCompanyOrIndividualModal(false);
            ChangeIsEditingCompany(false);
            ChangeSingleCompanyEmpty(null);
          }}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">
          {/* {addingCompany ? "New" : "Edit"} Company */}
          {isEditCompany ? "Edit" : "New"} Company
        </h4>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col md="12" className="text-center">
              <ImageUpload
                getUploadImageFileData={getUploadImageFileData}
                filePath={file}
              />
            </Col>
            <Col md="6">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>
                      {addingCompany ? "Company" : "Individual"} Name
                    </Label>
                  </Col>
                  {/* <Col md="6" className="text-right">
                    <Switch
                      defaultValue={addingCompany}
                      onColor="info"
                      offColor="info"
                      offText="Ind"
                      onText="Com"
                      // labelWidth={50}
                      // handleWidth="100"
                      onChange={(elemant, state) => {
                        ChangeAddingCompany(state);
                        console.log(state);
                      }}
                    />
                  </Col> */}
                </Row>

                {addingCompany ? (
                  <Input
                    type="text"
                    placeholder="Enter Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                ) : (
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Select Member"
                    name="members"
                    closeMenuOnSelect={true}
                    isMulti={false}
                    value={individualMember}
                    onChange={(value) => setIndividualMember(value)}
                    options={[
                      {
                        value: 0,
                        label: "Select Company",
                        isDisabled: true,
                      },
                      { value: "1", label: "Osama" },
                      { value: "2", label: "Javed" },
                      { value: "3", label: "Khan" },
                      { value: "4", label: "Naveed" },
                    ]}
                  />
                )}
              </FormGroup>
            </Col>
            {!addingCompany && (
              <Col md="6">
                <FormGroup>
                  <Label> Company</Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Select Company"
                    name="members"
                    closeMenuOnSelect={false}
                    isMulti={false}
                    value={company}
                    onChange={(value) => ChangeCompany(value)}
                    // options={[
                    //   {
                    //     value: 0,
                    //     label: "Select Company",
                    //     isDisabled: true,
                    //   },
                    //   { value: "1", label: "NASA " },
                    //   { value: "2", label: "SPACE X" },
                    //   { value: "3", label: "TESLA" },
                    //   { value: "4", label: "APPLE" },
                    // ]}
                    options={allCompanies.map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="6">
              <FormGroup>
                <Label> Address</Label>
                <Input
                  type="text"
                  placeholder="House 69 ,Street No 2"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label> Country</Label>
                <Input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />
                {/* <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Country"
                  name="members"
                  closeMenuOnSelect={false}
                  isMulti={false}
                  value={country}
                  onChange={(value) => setCountry(value)}
                  // options={[
                  //   {
                  //     value: "0",
                  //     label: "Select Country",
                  //     isDisabled: true,
                  //   },
                  //   { value: "1", label: "USA " },
                  //   { value: "2", label: "Pakistan" },
                  //   { value: "3", label: "Japan" },
                  //   { value: "4", label: "Russia" },
                  // ]}
                  options={allCountries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                /> */}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label> State</Label>
                <Input
                  type="text"
                  placeholder="State"
                  value={companyState}
                  onChange={(e) => {
                    setCompanyState(e.target.value);
                  }}
                />
                {/* <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select State"
                  name="State"
                  closeMenuOnSelect={false}
                  isMulti={false}
                  value={companyState}
                  onChange={(value) => setCompanyState(value)}
                  // options={[
                  //   {
                  //     value: 0,
                  //     label: "Select Company",
                  //     isDisabled: true,
                  //   },
                  //   { value: "1", label: "NY " },
                  //   { value: "2", label: "MN" },
                  //   { value: "3", label: "LA" },
                  // ]}
                  options={statesByCountry.map((state) => ({
                    value: state.id,
                    label: state.name,
                  }))}
                /> */}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
                {/* <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select City"
                  name="City"
                  closeMenuOnSelect={true}
                  isMulti={false}
                  value={city}
                  onChange={(value) => setCity(value)}
                  // options={[
                  //   {
                  //     value: 0,
                  //     label: "Select City",
                  //     isDisabled: true,
                  //   },
                  //   { value: "1", label: "New York" },
                  //   { value: "2", label: "Albany" },
                  //   { value: "3", label: "Buffalo" },
                  // ]}
                  options={citiesByState.map((city) => ({
                    value: city.id,
                    label: city.name,
                  }))}
                /> */}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Zip Code</Label>
                <Input
                  invalid={
                    formErrorState && formErrorState?.zip_code ? true : false
                  }
                  type="text"
                  placeholder="42069"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                />
                <FormFeedback>
                  {formErrorState && formErrorState?.zip_code}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Tax ID</Label>
                <Input
                  type="text"
                  placeholder="DC2867148"
                  value={taxId}
                  onChange={(e) => {
                    setTaxId(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  invalid={
                    formErrorState && formErrorState?.phone_no ? true : false
                  }
                  type="text"
                  placeholder="057-1234567"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
                <FormFeedback>
                  {formErrorState && formErrorState?.phone_no}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Mobile</Label>
                <Input
                  invalid={
                    formErrorState && formErrorState?.mobile_no ? true : false
                  }
                  type="text"
                  placeholder="0300-1234567"
                  value={mobileNumber}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
                <FormFeedback>
                  {formErrorState && formErrorState?.mobile_no}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Email</Label>
                <Input
                  invalid={
                    formErrorState && formErrorState?.email ? true : false
                  }
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />

                <FormFeedback>
                  {formErrorState && formErrorState?.email}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Website</Label>
                <Input
                  type="text"
                  placeholder="https://esquall.com/"
                  value={website}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label> Tags</Label>
                <div>
                  <TagsInput
                    value={tagsinput}
                    onChange={(value) => setTagsinput(value)}
                    tagProps={{ className: "react-tagsinput-tag success" }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <div className="text-right">
          {!isEditCompany ? (
            <Button
              color="success"
              onClick={() => {
                handleCompanySubmit();
                // ChangeShowAddCompanyOrIndividualModal(false);
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              color="success"
              onClick={() => {
                handleCompanySubmit();
                // ChangeShowAddCompanyOrIndividualModal(false);
              }}
            >
              Edit
            </Button>
          )}
          <Button
            color="danger"
            onClick={() => {
              ChangeShowAddCompanyOrIndividualModal(false);
              ChangeIsEditingCompany(false);
              ChangeSingleCompanyEmpty(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit, company }) => ({
  shared,
  styling,
  audit,
  company,
});

export default connect(mapStateToProps, {
  ChangeShowAddCompanyOrIndividualModal,
  CreateCompany,
  EditCompany,
  ChangeIsEditingCompany,
  GetSingleCompany,
  ChangeSingleCompanyEmpty,
  GetAllCountries,

  GetAllCompanies,
})(AddCompanyORIndividual);
