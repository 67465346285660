import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "../GlobalFilter";
import { useExportData } from "react-table-plugins";
import { CheckBox } from "../CheckBox";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
} from "reactstrap";
import ExistingSectionRequirements from "views/tables/ExistingSectionRequirements";

import {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
} from "../../../store/actions/requirmentActions";

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

const AddSection = (props) => {
  // ---------------- SETTING UP DATATABLE START ---------------------------

  // Sections Table
  var data = React.useMemo(
    () => [
      {
        name: "ISO:8888",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "Active",
        requirement_file: "lorem",
      },
      {
        name: "ISO:9000",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "InActive",
        requirement_file: "lorem",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Standard Ref.",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Clarification",
        accessor: "authority",
      },
      {
        Header: "Requirement List",
        accessor: "location",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Added !
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ----------- Add Requirement Submit //

  const onSubmit = (e) => {
    e.preventDefault();
    successAlert();
  };

  const { } = props.requirment;

  const {
    ChangeShowAddSectionModal,
    ChangeShowEditSectionModal,
    ChangeShowViewSectionModal,
  } = props;

  return (
    <>
      {alert}
      <CardBody>
        <div className="row p-3 align-items-center">
          <div className="col-md-2">
            <div className="d-flex align-items-center justify-content-between">
              Show
              <select
                value={pageSize}
                className="form-control pagination-select"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              Entries
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3 text-right">
            <FormGroup className="custom-form-group">
              <select
                name=""
                id=""
                className="form-control custom-input"
                onChange={(e) => filterDataTable(e)}
              >
                <option value="" defaultValue disabled>
                  Filters
                </option>
                <option value={0}>All</option>
                <option value={1}>Mapped</option>
                <option value={2}>Not Mapped</option>
              </select>
            </FormGroup>
          </div>
          <div className="col-md-3 text-right">
            <div className="form-group custom-form-group">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </div>
        <div className="tableWrap">
          <table {...getTableProps()} className="table-striped table-hover ">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-info text-white"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border border-left-0 border-right "
                      style={{
                        borderBottom: "1px solid #cacaca",
                        background: "rgba(0, 0, 0, 0.05)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down  text-secondary"></i>
                          ) : (
                            <i className="fas fa-arrow-up text-secondary"></i>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                      {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td
                            {...cell.getCellProps()}

                          // style={{
                          //     padding: '10px',
                          //     // border: 'solid 1px gray',
                          //     // background: 'papayawhip',
                          // }}
                          >
                            {cell.render("Cell")}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <div className="">
            <button
              className="btn btn-info btn-sm"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <span className="pagination-button">
              {pageOptions.map((page) => (
                <button
                  className={
                    pageIndex === page
                      ? "btn border-info border btn-info  bg-transparent text-info "
                      : "btn border-info border btn-info btn-sm bg-transparent text-info"
                  }
                  onClick={() => {
                    const pageNumber = page;
                    gotoPage(pageNumber);
                  }}
                >
                  {page + 1}
                </button>
              ))}
            </span>
            <button
              className="btn btn-info  btn-sm"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
        <div className="text-right">
          <Button
            color="success"
            onClick={() => ChangeShowAddSectionModal(false)}
          >
            Add
          </Button>
          <Button
            color="danger"
            onClick={() => ChangeShowAddSectionModal(false)}
          >
            Cancel
          </Button>
        </div>
      </CardBody>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit, requirment }) => ({
  shared,
  styling,
  audit,
  requirment,
});

export default connect(mapStateToProps, {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
})(AddSection);
