
import { data, support } from "jquery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";
import { SupportTeamVerifyToken, CreateAuditSupportMember } from "store/actions/userAction";

function SupportInviteAudit(props) {
    //   const history = useHistory();
    const { supportVerifyToken } =
        props.user;
    const { SupportTeamVerifyToken, CreateAuditSupportMember } = props;


    // get error and show to user
    const [errorSupport, setErrorSupport] = useState()


    // fetching token from url
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token')

    console.log("token ", token)


    useEffect(() => {
        SupportTeamVerifyToken(token, setErrorSupport)
    }, [])


    console.log(supportVerifyToken?.data);
    console.log(errorSupport)

    // show pop up for user base action
    const [alert, setAlert] = React.useState(null);


    const successfullyRegisterMessage = () => {
        console.log("SUCCESSDELETE");
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
            >
                Successfully Register.
            </ReactBSAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };


    return (
        <>
            {alert}
            <div className="content">

                <div className="d-flex justify-content-between align-items-center">
                    <h5>Support Team Invitation</h5>

                </div>
                <div className="login-page">
                    <Container>

                        <Row>
                            <Col className="ml-auto mr-auto" lg="6" md="8">
                                <Card className="card-login">
                                    <CardHeader>
                                        <CardHeader>
                                            <h3 className="header text-center">Welcome in New Audit</h3>
                                        </CardHeader>
                                    </CardHeader>
                                    <CardBody>


                                        {supportVerifyToken?.data?.token ? (
                                            <h4 className="text-success mb-0 mt-2 text-center" style={{ height: "40px" }}>
                                                {errorSupport}

                                            </h4>
                                        ) : (
                                            <h4 className="text-danger mb-0 mt-2 text-center" style={{ height: "40px" }}>
                                                {errorSupport}

                                            </h4>
                                        )}


                                        {/* <br /> */}
                                        {/* <FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input defaultChecked defaultValue="" type="checkbox" />
                      <span className="form-check-sign" />
                      Subscribe to newsletter   
                    </Label>
                  </FormGroup>
                </FormGroup> */}
                                    </CardBody>
                                    <CardFooter>

                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg").default
                                })`,
                        }}
                    />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ user }) => ({
    user,
});

export default connect(mapStateToProps, { SupportTeamVerifyToken, CreateAuditSupportMember })(
    SupportInviteAudit,

);
