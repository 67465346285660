import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import { GlobalFilter } from "../GlobalFilter";
import { CheckBox } from "../CheckBox";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import ExistingSectionRequirements from "views/tables/ExistingSectionRequirements";

import {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
} from "../../../store/actions/requirmentActions";

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns?.map((col) => col?.exportValue);
    const csvString = Papa?.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns?.map((c) => c.exportValue);
    const compatibleData = data?.map((row) => {
      const obj = {};
      header?.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX?.utils?.book_new();
    let ws1 = XLSX?.utils?.json_to_sheet(compatibleData, {
      header,
    });
    XLSX?.utils?.book_append_sheet(wb, ws1, "React Table Data");
    XLSX?.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns?.map((column) => column?.exportValue);
    const doc = new jsPDF();
    doc?.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc?.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

const ViewSection = (props) => {
  const { singleSection, requirementsBySectionId } = props?.requirment;

  const {
    ChangeShowAddSectionModal,
    ChangeShowEditSectionModal,
    ChangeShowViewSectionModal,
  } = props;
  // ---------------- SETTING UP DATATABLE START ---------------------------

  // Sections Table

  let dataMap = requirementsBySectionId?.map((data) => ({
    standardRef:
      data?.standard_ref && data?.standard_ref !== undefined
        ? data?.standard_ref
        : "-",
    desc:
      data?.description && data?.description !== undefined
        ? data?.description
        : "-",
    reference:
      data?.references && data?.references !== undefined
        ? data?.references
        : "-",
    evidence:
      data?.evidence && data?.evidence !== undefined ? data?.evidence : "-",
    questionType:
      data?.question_type && data?.question_type !== undefined
        ? data?.question_type
        : "-",
  }));
  let data = React?.useMemo(() => dataMap, [requirementsBySectionId]);
  console?.log(dataMap);

  // var data = React.useMemo(
  //   () => [
  //     {
  //       standardRef: "ISO:88883",
  //       desc: "This is the description of the section requirement",
  //       reference: "lorem",
  //       // weight: '0',
  //       // criticality: 'High',
  //       evidence: "Yes",
  //       questionType: "Yes/No",
  //     },
  //     {
  //       standardRef: "ISO:9000",
  //       desc: "This is the description of the section requirement",
  //       reference: "lorem",
  //       // weight: '0',
  //       // criticality: 'Low',
  //       evidence: "No",
  //       questionType: "Yes/No/Partial",
  //     },
  //   ],
  //   []
  // );
  const columns = React?.useMemo(
    () => [
      {
        Header: "Standard Ref.",
        accessor: "standardRef", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "References",
        accessor: "reference",
      },
      // {
      //     Header: 'Weight',
      //     accessor: 'weight',
      // },
      // {
      //     Header: 'Criticality',
      //     accessor: 'criticality',
      // },
      {
        Header: "Evidence Required",
        accessor: "evidence",
      },
      {
        Header: "Question Type",
        accessor: "questionType",
      },
    ],
    []
  );
  // Reason of foreach undefine confilit in react table.
  //  exportData 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
    // (hooks) => {
    //     hooks.visibleColumns.push((columns) => {
    //         return [
    //             {
    //                 id: 'selection',
    //                 Header: ({ getToggleAllRowsSelectedProps }) => (
    //                     <CheckBox {...getToggleAllRowsSelectedProps()} />
    //                 ),
    //                 Cell: ({ row }) => (
    //                     <CheckBox {...row.getToggleRowSelectedProps()} />
    //                 )
    //             }, ...columns
    //         ]
    //     })
    // }
  );

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React?.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window?.setTimeout(null, 0);
      while (id--) {
        window?.clearTimeout(id);
      }
    };
  }, []);

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Added !
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ----------- Add Requirement Submit //

  const onSubmit = (e) => {
    e.preventDefault();
    successAlert();
  };

  const { standard_ref, clarifications, description } = singleSection?.data;
  const { name } = singleSection?.data?.req_list;

  return (
    <>
      {alert}
      <Form onSubmit={(e) => onSubmit(e)}>
        <CardBody>
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                {singleSection?.data?.req_list?.name}
              </BreadcrumbItem>
              <BreadcrumbItem className="text-info" active>
                {singleSection?.data?.standard_ref}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <table className="table table-bordered table-striped table-hover">
            <tbody>
              <tr>
                <td className="font-weight-bold">Requirement List</td>
                <td>{name}</td>
                <td className="font-weight-bold">Standard Ref.</td>
                <td>{singleSection?.data?.standard_ref}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Clarification</td>
                <td>{singleSection?.data?.clarifications}</td>
                <td className="font-weight-bold">Description</td>
                <td>{singleSection?.data?.description}</td>
              </tr>
              <tr>
                {singleSection?.data?.extra_fields?.map((field) => (
                  <>
                    <td className="font-weight-bold">{field?.field_name}</td>
                    <td>{field?.field_value}</td>
                  </>
                ))}
              </tr>
            </tbody>
          </table>

          {requirementsBySectionId && requirementsBySectionId?.length > 0 && (
            <div className="my-4">
              <div className="form-group flex-row">
                <div className="flex-row-left">
                  <h4>Requirments</h4>
                </div>
                {/* <div className="flex-row-right">
                                <Button color="info" onClick={() => { }}>
                                    <i className="fas fa-plus"></i>
                                    {"Add Requirment"}
                                </Button>
                            </div> */}
              </div>
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups?.map((headerGroup) => (
                      <tr
                        {...headerGroup?.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers?.map((column) => (
                          <th
                            {...column?.getHeaderProps(
                              column?.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column?.render("Header")}
                            <span>
                              {column?.isSorted ? (
                                column?.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page?.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row?.getRowProps()}>
                          {row?.cells?.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell?.getCellProps()}

                                // style={{
                                //     padding: '10px',
                                //     // border: 'solid 1px gray',
                                //     // background: 'papayawhip',
                                // }}
                                >
                                  {cell?.render("Cell")}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </CardBody>
      </Form>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit, requirment }) => ({
  shared,
  styling,
  audit,
  requirment,
});

export default connect(mapStateToProps, {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
})(ViewSection);
