import {
} from '../actions/types'

const initialState = {
    mainColor: "#51bcda",
    // mainLighterColor: "#58507A",
    // mainDarkerColor: "#373054",
    // backgroundDarkerColor: "#e0e0e0",
    // backgroundColor: "#F7F8F9",
    // textLighterColor: "#fff",
    // textLightColor: "silver",
    // textColor: "#000",
    // textOffColor: "#818181",
    // textMainishColor: "#575E71",
    // modalColor: "#fff",
    // modalBorderColor: "silver",
    // successColor: "#12C719",
    // dangerColor: "#D20000",
    // dangerLightColor: "#FF4B6A",
    // warningColor: "#FCD438",
    // hitPinkColor: "#FFA26B",
    // slateBlue: "#A7B437",
    // yellowColor: "#FCD438",
    // yellowRaitingColor: "#F5CE6C",
    // purpleColor: "#B391FF",
    // skyBlueColor: "#79D1E8",
    // darkBlueColor: "#3763BB",
    // orangeColor: "#FFA41B",
}

export default (state = initialState, { type, payload }) => {
    // console.log('TYPE::')
    // console.log(type)
    // console.log('PAYLOAD::')
    // console.log(payload)
    switch (type) {
        default:
            return state;
    }
}