
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Collapse } from 'react-collapse';
import { Link, useHistory } from 'react-router-dom'
import {
    FcAcceptDatabase,
    FcApproval,
    FcBarChart,
    FcCheckmark,
    FcDataRecovery,
    FcHighPriority,
    FcRadarPlot,
} from 'react-icons/fc'

import { styles } from '../../../styling/custom'

import img from '../../../assets/img/faces/default-avatar.png';
import { GetAllTask, ShowAuditRequirementAnswer } from "store/actions/lifeCycleAction"
import { GetDiscussionData } from 'store/actions/auditActions.js'

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Input, Form,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container, Modal, ModalBody, ModalHeader, ModalFooter, DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import {
    ToggleCollapsedSections,
} from '../../../store/actions/auditActions'
import moment from "moment";
import { GetVersionFiles } from "store/actions/lifeCycleAction";

import { CheckSubReqDecision } from '../../../store/actions/versionAction'


const VersionRequirements = (props) => {
    console.log("propRequirement", props)
    const { getCurrentRequirementData, GetAllTask, ShowAuditRequirementAnswer, GetDiscussionData, GetVersionFiles, CheckSubReqDecision } = props
    const {
        versionRequirementSections,

    } = props.audit

    const {
        getCommentCount,
        discussion,

    } = props.audit

    const { getSubRequirementById } = props.requirment;


    const { allTask, showAuditAnswer, versionFiles } = props.lifeCycle
    const { showSubReqDec } = props.versionAudit
    const [auditAnswer, setAuditAnswer] = useState('No')
    const [requirementTabs, ChangeRequirementTabs] = useState("details")
    const [attachmentTabs, ChangeAttachmentTabs] = useState("table")
    const [assessorComment, ChangeAssessorComment] = useState("The tilt of photons is not detected by the sensors sometimes in quantum chip")


    const [contributeComment, SetContributeComment] = useState();
    const [evidenceDetail, setEvidenceDetail] = useState();
    const [partialPercent, setPartialPercent] = useState();
    const [partialDescription, setPartialDescription] = useState();

    const [generalDescription, setGeneralDescription] = useState("");
    const [generalClarification, setGeneralClarification] = useState("");

    console.log("version files", versionFiles)
    console.log("showSubReqDec", showSubReqDec)


    //show requirement answer
    useEffect(() => {
        setAuditAnswer(showAuditAnswer && showAuditAnswer?.answer_status ? {
            label: showAuditAnswer?.answer_status,
            value: showAuditAnswer?.id

        } : "No")
        setPartialDescription(showAuditAnswer && showAuditAnswer?.partial_description ? showAuditAnswer?.partial_description : "")
        setPartialPercent(showAuditAnswer && showAuditAnswer?.partial_percentage ? showAuditAnswer?.partial_percentage : ""),
            setEvidenceDetail(showAuditAnswer && showAuditAnswer?.evidence_details ? showAuditAnswer?.evidence_details : ""),
            SetContributeComment(showAuditAnswer && showAuditAnswer?.contribute_comments ? showAuditAnswer?.contribute_comments : "")
        // setAssessorDecision(
        //     showAuditAnswer?.status === 1
        //         ? { label: "Accepted", value: showAuditAnswer?.status }
        //         : showAuditAnswer?.status === 2
        //             ? { label: "Rejected", value: showAuditAnswer?.status }
        //             : showAuditAnswer?.status === 3
        //                 ? { label: "Minor", value: showAuditAnswer?.status }
        //                 : showAuditAnswer?.status === 4 && {
        //                     label: "Major",
        //                     value: showAuditAnswer?.status,
        //                 }
        // );
        ChangeAssessorComment(
            showAuditAnswer?.auditor_comments ? showAuditAnswer?.auditor_comments : ""
        );
    }, [showAuditAnswer])

    console.log("getSubRequirementById", getSubRequirementById)
    useEffect(() => {
        setGeneralDescription(
            getSubRequirementById?.data?.description
                ? getSubRequirementById?.data?.description
                : getCurrentRequirementData?.description
        );
        setGeneralClarification(
            getSubRequirementById?.data?.clarification
                ? getSubRequirementById?.data?.clarification
                : getCurrentRequirementData?.clarification
        );
    }, [getSubRequirementById, getCurrentRequirementData]);




    function RenderDiscussion() {
        return discussion.map((item, key) => {
            const { image, name, role, date, comment } = item
            return (
                <div key={key}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            border: '0px solid red',
                            // alignItems: 'center'
                        }}
                    >
                        <img src={image} className="rounded-circle" width="40" height="40" alt="" />
                        <div
                            style={{
                                border: '0px solid red',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    border: '0px solid red',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        border: '0px solid red',
                                    }}
                                >
                                    <div
                                        className="single-line-text"
                                        style={{
                                            marginLeft: 10,
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            color: '#595974',
                                        }}
                                    >
                                        {name}
                                    </div>
                                    <div
                                        style={{
                                            background: '#F4F5F7',
                                            marginLeft: 10,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            borderRadius: 5,
                                        }}
                                    >
                                        {role}
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        {moment(date).format("MMM Do, YYYY")}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        border: '0px solid red',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                    }}
                                >
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    border: '0px solid red',
                                    padding: 10,
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    {comment}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            )
        })
    }

    return (
        <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
                <div class="d-flex justify-content-start align-items-center">
                    <div>
                        {/* {getCurrentRequirementData?.supportAssignMemberData?.map(mem => (
                            <>
                                <i className="fas fa-user-circle mr-1"></i> <a href="javascript:void(0)" className="text-dark">Assigned to:

                                    {mem?.supportAssignMemberName} </a>
                            </>
                        ))} */}

                        {
                            getCurrentRequirementData?.supportAssignMemberData && !getCurrentRequirementData?.checkContributeLead ?
                                getCurrentRequirementData?.supportAssignMemberData?.map((mem) => (
                                    <>
                                        <i className="fas fa-user-circle mr-1"></i> <a href="javascript:void(0)" className="text-dark">Assigned to: {" "}

                                            {mem?.supportAssignMemberName} </a>
                                    </>
                                )) :
                                (<>
                                    <i className="fas fa-user-circle mr-1"></i> <a href="javascript:void(0)" className="text-dark">Assigned to: {" "}

                                        {getCurrentRequirementData?.contributeLeadName} </a>
                                </>)
                        }

                    </div>
                    <div className="ml-4">
                        <i className="fas fa-comment text-info mr-1"></i> {getCommentCount} Comments
                    </div>
                </div>

                <div className="ml-4">
                    {/* {auditAnswer == 'No' ?
                        <span className="text-danger"> <i className="fas fa-circle text-danger"></i> {"Not Compliant"}</span>
                        :
                        <span className="text-success"> <i className="fas fa-circle text-success"></i> {"Compliant"}</span>
                    } */}
                    {
                        !showSubReqDec?.final_percentage && getCurrentRequirementData?.isCompliant == "partial-compliant" && (
                            <span className="text-warning"> <i className="fas fa-circle text-warning"></i> {"Partial Compliant"}</span>
                        )

                    }
                    {
                        !showSubReqDec?.final_percentage && getCurrentRequirementData?.isCompliant == "compliant" && (
                            <span className="text-success"> <i className="fas fa-circle text-success"></i> {"Compliant"}</span>
                        )
                    }

                    {
                        !showSubReqDec?.final_percentage && getCurrentRequirementData?.isCompliant == "non-compliant" && (
                            <span className="text-danger"> <i className="fas fa-circle text-danger"></i> {"Not Compliant"}</span>
                        )
                    }
                    {
                        showSubReqDec?.final_percentage && showSubReqDec?.final_percentage == "Compliant" && (
                            <span className="text-success"> <i className="fas fa-circle text-success"></i> {"Compliant"}</span>
                        )
                    }
                    {
                        showSubReqDec?.final_percentage && showSubReqDec?.final_percentage == "Non-compliant" && (
                            <span className="text-danger"> <i className="fas fa-circle text-danger"></i> {"Not Compliant"}</span>
                        )
                    }
                    {
                        showSubReqDec?.final_percentage && showSubReqDec?.final_percentage == "Partially Compliant" && (
                            <span className="text-warning"> <i className="fas fa-circle text-warning"></i> {"Partial Compliant"}</span>
                        )
                    }
                </div>
            </div>
            <div className="bg-light">
                <Row style={{ padding: 10 }}>
                    <Col md="3" className="text-left">
                        <span style={{ fontSize: 18, fontWeight: 'bold', display: 'block' }}>  Requirment List </span>
                        <span style={{ fontSize: 12, display: 'block' }}> {getCurrentRequirementData?.req_list?.name}</span>
                    </Col>
                    <Col md="3" className="text-left">
                        <span style={{ fontSize: 18, fontWeight: 'bold', display: 'block' }}> Section</span>
                        <span style={{ fontSize: 12, display: 'block' }}>{getCurrentRequirementData?.standard_ref}</span>
                    </Col>
                </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
                <Row>
                    <Col md="7">
                        <table className="table ">
                            <thead className="bg-transparent border-0">
                                <tr className="border-0">
                                    <th className="border-0">Ref.</th>
                                    <th>Weight</th>
                                    {/* <th>Area</th> */}
                                    <th>Criticality</th>
                                    <th>Reference</th>
                                </tr>
                            </thead>
                            <tbody className="border-0">

                                <tr className="border-0">
                                    <td className="border-0"> {getSubRequirementById?.data?.standard_ref || getCurrentRequirementData?.standard_ref}</td>
                                    <td className="border-0"> {getSubRequirementById?.data?.weight || getCurrentRequirementData?.weight}</td>
                                    {/* <td className="border-0"> {getCurrentRequirementData?.area}</td> */}
                                    <td className="border-0"> {getSubRequirementById?.data?.criticality || getCurrentRequirementData?.criticality}</td>
                                    <td className="border-0"> {getSubRequirementById?.data?.reference?.name || getCurrentRequirementData?.reference?.name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md="5" className="text-right">
                        {/* <p className="p-2">Updated By Zeesha Ahmad.</p> */}
                        <div className="nav-tabs-navigation border-0">
                            <div className="nav-tabs-wrapper border-0">
                                <div id="tabs" role="tablist" tabs className="justify-content-end border-0 horizontal-tabs">
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={requirementTabs === "details"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={requirementTabs === "details" ? "active" : ""}
                                            onClick={() => ChangeRequirementTabs("details")}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={requirementTabs === "tasks"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={requirementTabs === "tasks" ? "active" : ""}
                                            onClick={() => {
                                                ChangeRequirementTabs("tasks")
                                                const showTask = {
                                                    audit_requirement_id: getCurrentRequirementData?.auditRequirementId
                                                }
                                                GetAllTask(showTask)
                                            }}
                                        >
                                            Tasks
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={requirementTabs === "discussion"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={
                                                requirementTabs === "discussion" ? "active" : ""
                                            }
                                            onClick={() => {
                                                ChangeRequirementTabs("discussion");
                                                const data = {
                                                    audit_requirement_id: getCurrentRequirementData?.auditRequirementId,
                                                    audit_id: window.localStorage.getItem("audit_life_cycle"),
                                                }
                                                GetDiscussionData(data)
                                            }}
                                        >
                                            <i class="far fa-comments"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={requirementTabs === "attachments"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={
                                                requirementTabs === "attachments" ? "active" : ""
                                            }
                                            onClick={() => {

                                                ChangeRequirementTabs("attachments");
                                                GetVersionFiles(window.localStorage.getItem("audit_life_cycle"))
                                            }}
                                        >
                                            <i class="fas fa-paperclip"></i>
                                        </NavLink>
                                    </NavItem>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <CardBody id="modal_scroll" style={{ height: '500px', position: 'relative', overflowY: 'auto' }}>

                <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={requirementTabs}
                >
                    <TabPane tabId="details" role="tabpanel">
                        <Row>
                            <Col md="6">
                                <h6 className="p-2 border-bottom text-left">Description</h6>
                                <textarea
                                    // value={getCurrentRequirementData?.description}
                                    value={generalDescription}
                                    style={{
                                        paddingLeft: 10
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    className="form-control"
                                    disabled
                                />
                                <Row style={{ marginBottom: 0, marginTop: 10 }}>
                                    <Col className="text-left" style={{}}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }} >Contributor Lead Section</span>
                                    </Col>
                                    <Col className="text-right">
                                        {/* <span style={{ fontSize: 14, }} >Answered by: Zeeshan</span> */}
                                        {
                                            getCurrentRequirementData?.supportAssignMemberData && !getCurrentRequirementData?.checkContributeLead ?
                                                getCurrentRequirementData?.supportAssignMemberData?.map((mem) => (
                                                    <>

                                                        <span style={{ fontSize: 14, }} >Answered by:   {mem?.supportAssignMemberName}</span>
                                                    </>
                                                )) :
                                                (<>

                                                    <span style={{ fontSize: 14, }} >Answered by:  {getCurrentRequirementData?.contributeLeadName}</span>

                                                </>)
                                        }
                                    </Col>
                                </Row>
                                <hr style={{ marginTop: 5, marginBottom: 5 }} />
                                <div className="d-flex align-items-center justify-content-start">
                                    <Row>
                                        <Col className="contribute-lead-section-left-col" md="3">
                                            {/* 40% Partial */}
                                            {partialPercent}

                                        </Col>
                                        <Col className="contribute-lead-section-right-col" style={{ paddingLeft: 0, paddingRight: 8 }} md="9">
                                            {/* The electron sensors are ready, but we're working on tilt of photons */}
                                            {partialDescription}
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="evidence-row">
                                    <Col md="3" className="contribute-lead-section-left-col">
                                        <Button style={{ marginTop: 0 }} color="info" onClick={() => { }}>
                                            <i class="fas fa-eye"></i>
                                        </Button>
                                    </Col>
                                    <Col className="contribute-lead-section-right-col" md="9" style={{ paddingLeft: 0, }}>
                                        {/* The file shows our research that how we can tilt photons using beta-beams
                                         */}
                                        {evidenceDetail}
                                    </Col>
                                </Row>
                                <textarea
                                    value={contributeComment}
                                    disabled
                                    placeholder="Contributor Comment"
                                    style={{
                                        paddingLeft: 10,
                                        marginTop: 10
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    className="form-control"
                                />
                                <Row style={{ marginBottom: 0, marginTop: 10 }}>
                                    <Col className="text-left" style={{}}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }} >Assessor Section</span>
                                    </Col>
                                </Row>
                                <hr style={{ marginTop: 5, marginBottom: 5 }} />
                                <textarea
                                    disabled
                                    value={assessorComment}
                                    onChange={(e) => ChangeAssessorComment(e.target.value)}
                                    placeholder="Assessor Comment"
                                    style={{
                                        paddingLeft: 10
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    className="form-control"
                                />
                            </Col>
                            <Col md="6">
                                <h6 className="p-2 border-bottom text-left">Clarification</h6>
                                <textarea
                                    disabled
                                    // value={getCurrentRequirementData?.clarification}
                                    value={generalClarification}
                                    placeholder="Enter clarification"
                                    style={{
                                        paddingLeft: 10
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    className="form-control"
                                />
                                {getCurrentRequirementData?.sub_requirements.length > 0 && (
                                    <>
                                        <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10 }} className="text-left">Sub Requirements</div>
                                        <hr style={{ marginTop: 5, marginBottom: 5 }} />
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Ref.</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getCurrentRequirementData?.sub_requirements.map((subRequirement) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <Button style={{ marginTop: 0 }} color="info" onClick={() => {

                                                                        const answerShowData = {
                                                                            audit_requirement_id: getCurrentRequirementData?.auditRequirementId,
                                                                            requirement_id: getCurrentRequirementData?.id,
                                                                            sub_requirement_id: subRequirement?.id
                                                                        }
                                                                        const data = {
                                                                            audit_requirement_id: getCurrentRequirementData?.auditRequirementId,

                                                                            sub_requirement_id: subRequirement?.id
                                                                        }
                                                                        console.log(answerShowData)
                                                                        ShowAuditRequirementAnswer(answerShowData)
                                                                        CheckSubReqDecision(data)
                                                                    }}>Answer</Button>
                                                                </td>
                                                                <td>{subRequirement?.standard_ref}</td>
                                                                <td>{subRequirement?.description}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                                {/* // : (<p>No Record Found</p>) */}



                                            </tbody>
                                        </table>

                                    </>)
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="attachments" role="tabpanel">
                        <div className="nav-tabs-navigation p-0">
                            <div className="nav-tabs-wrapper p-0">
                                <Nav id="tabs" role="tablist" tabs className="justify-content-end p-0">
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={attachmentTabs === "table"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={attachmentTabs === "table" ? "active" : ""}
                                            onClick={() => ChangeAttachmentTabs("table")}
                                        >
                                            <i class="fas fa-table"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-expanded={attachmentTabs === "grid"}
                                            data-toggle="tab"

                                            role="tab"
                                            className={
                                                attachmentTabs === "grid" ? "active" : ""
                                            }
                                            onClick={() => ChangeAttachmentTabs("grid")}
                                        >
                                            <i class="fas fa-th"></i>
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                            </div>
                        </div>
                        <TabContent
                            className="text-center"
                            id="my-tab-content"
                            activeTab={attachmentTabs}
                        >
                            <TabPane tabId="table" role="tabpanel">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Size</th>
                                            <th>Date Attached</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            versionFiles?.data?.map(file => (
                                                <>
                                                    <tr>
                                                        <td>{file?.evidence_name}</td>
                                                        <td>{file?.evidence_size + 'KB'}</td>
                                                        <td>{moment(file?.evidence_date).format("MMM Do, YYYY")}</td>
                                                        <td>{file?.evidence_details}</td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </TabPane>
                            <TabPane tabId="grid" role="tabpanel">
                                <Row>
                                    {
                                        versionFiles?.data?.map((file, key) => (
                                            <Col md="3">
                                                <Card>
                                                    <CardBody>
                                                        <i class="fas fa-file-pdf text-danger fa-3x"></i>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p>{file?.evidence_name}</p>
                                                            <Button color="info" style={{ borderRadius: "5px", padding: "3px 10px" }}
                                                                onClick={() => {

                                                                    window.open(`${process.env.REACT_APP_BASE_URL}/public/${file?.evidence_attachment}`)
                                                                }}
                                                            >
                                                                <i class="fas fa-download"></i>
                                                            </Button>
                                                            {/* <UncontrolledDropdown>
                                      <DropdownToggle
                                        aria-expanded={false}
                                        aria-haspopup={true}
                                        caret
                                        className="btn-round btn-block"
                                        color="primary"
                                        data-toggle="dropdown"
                                        id={key}
                                        type="button"
                                      >
                                        <i class="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        persist
                                        aria-labelledby={key}
                                        right
                                      >
                                        <DropdownItem
                                          download
                                          href="./Untitled.pdf"
                                          target="_blank"
                                        onClick={() => {

                                          downloadFile(`${process.env.REACT_APP_BASE_URL}/public/${file?.evidence_attachment}`)
                                        }}
                                        >
                                          Download
                                        </DropdownItem>
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          View
                                        </DropdownItem>
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                                        </div>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                    {/* <Col md="3">
                                        <Card>
                                            <CardBody>
                                                <i class="fas fa-file-pdf text-danger fa-3x"></i>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p>Document Name</p>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            aria-expanded={false}
                                                            aria-haspopup={true}
                                                            caret
                                                            className="btn-round btn-block"
                                                            color="primary"
                                                            data-toggle="dropdown"
                                                            id="dropdownMenuButton"
                                                            type="button"
                                                        >
                                                            <i class="fas fa-ellipsis-v"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            persist
                                                            aria-labelledby="dropdownMenuButton"
                                                            right
                                                        >

                                                            <DropdownItem
                                                                download href="./Untitled.pdf"
                                                                target="_blank"


                                                            >
                                                                Download
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                View
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                Delete
                                                            </DropdownItem>

                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col> */}
                                </Row>
                            </TabPane>

                        </TabContent>
                    </TabPane>
                    <TabPane tabId="tasks" role="tabpanel">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Stand Ref.</th>
                                    <th>Description</th>
                                    <th>Created On</th>
                                    <th>Status</th>
                                    <th>Iteration</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allTask?.map((task) => (
                                        <tr>
                                            <td>{task?.name}</td>
                                            <td>{task?.standard_ref}</td>
                                            <td> {task?.description}t</td>
                                            <td>{moment(task?.created_at).format("MMM Do, YYYY")}</td>
                                            <td>{task?.status}</td>
                                            <td>{task?.answer?.answer_iteration}</td>
                                            {/* <td>
                                        <i className="fas fa-eye text-info" style={{ cursor: 'pointer' }} onClick={() => { }}></i>
                                    </td> */}
                                        </tr>
                                    ))
                                }



                            </tbody>
                        </table>
                    </TabPane>
                    <TabPane tabId="discussion" role="tabpanel">
                        {RenderDiscussion()}
                    </TabPane>
                </TabContent>
            </CardBody>
        </div>
    );
}

const mapStateToProps = ({ shared, styling, audit, lifeCycle, requirment, versionAudit }) => ({
    shared, styling, audit, lifeCycle, requirment, versionAudit
})

export default connect(mapStateToProps, {
    ToggleCollapsedSections,
    GetAllTask,
    ShowAuditRequirementAnswer,
    GetDiscussionData,
    GetVersionFiles,
    CheckSubReqDecision,

})(VersionRequirements)