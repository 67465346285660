import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import img from "../../assets/img/faces/default-avatar.png";
import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import PerfectScrollbar from "perfect-scrollbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { v4 } from "uuid";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Form,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import "../../styling/life-cycle.css";
import { styles } from "styling/custom";
import moment from "moment";

const data = {
  labels: ["Yes", "No", "Partial"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const barchartdata = {
  labels: [
    "Req 1",
    "Req 2",
    "Req 3",
    "Req 4",
    "Req 5",
    "Req 6",
    "Req 7",
    "Req 8",
    "Req 9",
    "Req 10",
    "Req 11",
    "Req 12",
  ],
  datasets: [
    {
      label: "Yes",
      data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "No",
      data: [2, 3, 20, 5, 1, 4, 2, 3, 20, 5, 1, 4],
      backgroundColor: "rgb(54, 162, 235)",
    },
    {
      label: "Partial",
      data: [3, 10, 13, 15, 22, 30, 3, 10, 13, 15, 22, 30],
      backgroundColor: "rgb(75, 192, 192)",
    },
  ],
};
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
var ps;
// var ps1;
function LifeCycle(props) {
  useEffect(() => {
    const container = document.querySelector("#scroll-bar");
    // // const chatContainer = document.querySelector('#chat-parent-tab');
    ps = new PerfectScrollbar(container, {
      // useBothWheelAxes: true,
      suppressScrollX: true,
    });
    // // ps1 = new PerfectScrollbar(chatContainer, {
    // //     // useBothWheelAxes: true,
    // //     suppressScrollX: true,
    // // });

    // const modal_scroll = document.getElementById('#modal-scroll');
    // // console.log(modal_scroll)
    // // var ps = new PerfectScrollbar(modal_scroll);
  }, []);
  const [modalClassic, setModalClassic] = useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };
  const [multipleSelect, setMultipleSelect] = useState(null);
  const [repeatFrequency, setRepeatFrequency] = useState("once");
  const [singleSelect, setSingleSelect] = useState(null);
  const [auditStatus, setAuditStatus] = useState(false);
  const [auditAnswer, setAuditAnswer] = useState("No");
  const [assessorDecision, setAssessorDecision] = useState("");
  const [showingRequirments, ChangeShowingRequirments] = useState(true);
  const [selectedItem, ChangeSelectedItem] = useState(null);
  const [taskModal, ChangeTaskModal] = useState(false);
  const [lifeCycleTabs, ChangeLifeCycleTabs] = useState("lifecycle");
  const [requirementTabs, ChangeRequirementTabs] = useState("details");
  const [attachmentTabs, ChangeAttachmentTabs] = useState("table");
  const [showCreateTaskModal, ChangeShowCreateTaskModal] = useState(false);
  const [AssessorComment, ChangeAssessorComment] = useState(
    "The tilt of photons is not detected by the sensors sometimes in quantum chip"
  );

  const { discussion } = props.audit;
  const { allRequirements } = props.requirment;

  // ------------------------ DND Stuff ----------------------------

  const [text, setText] = useState("");

  const item = {
    id: v4(),
    name: "Requirment 1",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Ready",
    section: "Section 1",
  };
  const item2 = {
    id: v4(),
    name: "Requirment 2",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "In Process",
    section: "Section 1",
  };
  const item3 = {
    id: v4(),
    name: "Requirment 3",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "In Process",
    section: "Section 2",
  };
  const item4 = {
    id: v4(),
    name: "Requirment 4",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Ready",
    section: "Section 2",
  };
  const item5 = {
    id: v4(),
    name: "Requirment 5",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Ready",
    section: "Section 2",
  };
  const item6 = {
    id: v4(),
    name: "Requirment 6",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Compliant",
    section: "Section 2",
  };
  const item7 = {
    id: v4(),
    name: "Requirment 7",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Compliant",
    section: "Section 3",
  };
  const item8 = {
    id: v4(),
    name: "Requirment 8",
    desc: "The encryption technique should be sha-2 instead of sha-1",
    task_state: "Compliant",
    section: "Section 3",
  };

  //   const originalItems = allRequirements.map((requirement) => ({
  //     id: requirement.id,
  //     name: requirement.standard_ref,
  //     desc: requirement.description,
  //     task_state: "Compliant",
  //     section: requirement.section.standard_ref,
  //   }));

  //   console.log("JKHKJHKJHKJHJKHJKHKJHKJHKJHKJHKJHKJHKJHKJ");
  //   console.log(originalItems);
  const [state, setState] = useState([
    {
      title: "Requirements",
      items: [item, item2, item3],
    },
    {
      title: "In Response",
      items: [item4],
    },
    {
      title: "In Review",
      items: [item5, item6, item7],
    },
    {
      title: "Compliance",
      items: [item8],
    },
  ]);
  const [checkedItemsState, setCheckedItemsState] = useState([
    {
      title: "Requirements",
      checkedItems: [],
    },
    {
      title: "In Response",
      checkedItems: [],
    },
    {
      title: "In Review",
      checkedItems: [],
    },
    {
      title: "Compliance",
      checkedItems: [],
    },
  ]);
  const [justToRender, ChangeJustToRender] = useState(false);
  const [wholeAuditStatus, ChangeWholeAuditStatus] = useState("In Process");
  const AddItem = () => {
    setState((prev) => {
      return {
        ...prev,
        todo: {
          title: "Requirements",
          items: [
            {
              id: v4(),
              name: text,
              task_state: "Requirements",
            },
            ...prev.todo.items,
          ],
        },
      };
    });
    setText("");
  };
  function IsSectionChecked(parentKey, section) {
    var itemIdsTemp = state[parentKey].items
      .filter((obj) => {
        if (obj.section == section) return obj;
      })
      .map((item, key) => {
        return item.id;
      });
    let checkedItems = checkedItemsState[parentKey].checkedItems;
    return itemIdsTemp.every((v) => checkedItems.includes(v));
  }
  const HandleDragEnd = ({ destination, source }) => {
    // console.log('from', source)
    // console.log('to', destination)
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // setCheckedItemsState(prev => {
    //     prev[parseInt(source.droppableId)].checkedItems = []
    //     prev[parseInt(destination.droppableId)].checkedItems = []
    //     return prev
    // })

    var itemCopy = state[source.droppableId].items[source.index];
    state[source.droppableId].items.splice(source.index, 1);
    state[destination.droppableId].items.splice(destination.index, 0, itemCopy);

    state[destination.droppableId].items.sort((item1, item2) => {
      if (item1.section < item2.section) return -1;
      if (item1.section > item2.section) return 1;
      return 0;
    });

    var itemCopy =
      checkedItemsState[source.droppableId].checkedItems[source.index];
    console.log("ITEMCOPY");
    console.log(itemCopy);
    if (itemCopy != undefined) {
      checkedItemsState[source.droppableId].checkedItems.splice(
        source.index,
        1
      );
      checkedItemsState[destination.droppableId].checkedItems.splice(
        destination.index,
        0,
        itemCopy
      );
    }
  };
  const RenderDiscussion = () => {
    return discussion.map((item, key) => {
      const { image, name, role, date, comment } = item;
      return (
        <div key={key}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "0px solid red",
              // alignItems: 'center'
            }}
          >
            <img
              src={image}
              className="rounded-circle"
              width="40"
              height="40"
              alt=""
            />
            <div
              style={{
                border: "0px solid red",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  border: "0px solid red",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    border: "0px solid red",
                  }}
                >
                  <div
                    className="single-line-text"
                    style={{
                      marginLeft: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#595974",
                    }}
                  >
                    {name}
                  </div>
                  <div
                    style={{
                      background: "#F4F5F7",
                      marginLeft: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 5,
                    }}
                  >
                    {role}
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    {moment(date).format("MMM Do, YYYY")}
                  </div>
                </div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <i
                    style={{ fontSize: 16, cursor: "pointer" }}
                    class="fas fa-trash-alt"
                  ></i>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  border: "0px solid red",
                  padding: 10,
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                  }}
                >
                  {comment}
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };
  const RenderItems = (items, parentKey) => {
    let previousSection = "";
    return items.map((item, key) => {
      let showSectionTile = false;
      const { id, name, desc, task_state, section } = item;
      if (previousSection != section) {
        previousSection = section;
        showSectionTile = true;
      }
      return (
        <>
          {showSectionTile && (
            <div className={"life-cycle-req-section"}>
              <Input
                checked={IsSectionChecked(parentKey, section) ? "checked" : ""}
                defaultChecked={""}
                onChange={(e) => {
                  var itemIdsTemp = [];
                  console.log("E.TARGET.CHECKED");
                  console.log(e.target.checked);
                  itemIdsTemp = state[parentKey].items
                    .filter((obj) => {
                      if (obj.section == section) return obj;
                    })
                    .map((item, key) => {
                      return item.id;
                    });
                  var checkedItemsStateTemp = checkedItemsState;
                  var isAdding = e.target.checked;
                  itemIdsTemp.map((item, key) => {
                    // if (key == 0) {
                    //     if (!checkedItemsStateTemp[parentKey].checkedItems.includes(item))
                    //         isAdding = true
                    //     else
                    //         isAdding = false
                    // }
                    if (isAdding) {
                      // console.log("ADDING")
                      if (
                        !checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      )
                        checkedItemsStateTemp[parentKey].checkedItems.push(
                          item
                        );
                    } else {
                      // console.log("REMOVING")
                      if (
                        checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      ) {
                        var index =
                          checkedItemsStateTemp[parentKey].checkedItems.indexOf(
                            item
                          );
                        checkedItemsStateTemp[parentKey].checkedItems.splice(
                          index,
                          1
                        );
                      }
                    }
                  });
                  // setCheckedItemsState(checkedItemsStateTemp)
                  console.log(checkedItemsState);
                  ChangeJustToRender(!justToRender);
                }}
                onDoubleClick={() => {
                  console.log("DOUBLE CLICKED");
                }}
                type="checkbox"
              />
              <span className={"section-span"}>{section}</span>
            </div>
          )}
          <Draggable
            onClick={(e) => {
              // console.log("Clicked")
            }}
            draggableId={parentKey + "" + key}
            index={key}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={"item text-left"}
                id="insertBefore"
                onKeyDown={(event) => {
                  // ! Multi drag not working, will implement it later
                  // this.onKeyDown(event, snapshot)
                  if (snapshot.isDragging) return;
                  // // console.log("EVENT.KEYCODE")
                  // // console.log(event.keyCode)
                  if (event.keyCode !== 17) return;
                  // // we are using the event for selection
                  event.preventDefault();
                  // console.log(props)
                  // const {
                  //     task,
                  //     toggleSelection,
                  //     toggleSelectionInGroup,
                  //     multiSelectTo,
                  // } = props
                  // // console.log("TASK.ID")
                  // // console.log(task.id)
                  // if (this.wasToggleInSelectionGroupKeyUsed(event)) {
                  //     toggleSelectionInGroup(task.id);
                  //     return;
                  // }

                  // if (this.wasMultiSelectKeyUsed(event)) {
                  //     multiSelectTo(task.id);
                  //     return;
                  // }

                  // toggleSelection(task.id);
                }}
              >
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "0px solid red", paddingLeft: 35 }}
                  >
                    <Input
                      checked={
                        checkedItemsState[parentKey]?.checkedItems?.includes(id)
                          ? "checked"
                          : ""
                      }
                      onChange={(e) => {
                        var itemIdsTemp = [];
                        if (window.event.ctrlKey) {
                          itemIdsTemp = state[parentKey].items
                            .filter((obj) => {
                              if (obj.section == section) return obj;
                            })
                            .map((item, key) => {
                              return item.id;
                            });
                        } else {
                          itemIdsTemp.push(id);
                        }
                        // console.log("ITEMIDSTEMP")
                        // console.log(itemIdsTemp)
                        var checkedItemsStateTemp = checkedItemsState;
                        var isAdding;
                        itemIdsTemp.map((item, key) => {
                          if (key == 0) {
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              isAdding = true;
                            else isAdding = false;
                          }
                          if (isAdding) {
                            // console.log("ADDING")
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.push(item);
                          } else {
                            // console.log("REMOVING")
                            if (
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            ) {
                              var index =
                                checkedItemsStateTemp[
                                  parentKey
                                ].checkedItems.indexOf(item);
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.splice(index, 1);
                            }
                          }
                        });
                        // setCheckedItemsState(checkedItemsStateTemp)
                        console.log(checkedItemsState);
                        ChangeJustToRender(!justToRender);
                      }}
                      onDoubleClick={() => {
                        console.log("DOUBLE CLICKED");
                      }}
                      type="checkbox"
                    />
                    <p>{" " + name}</p>
                  </div>
                  <div className="col text-right">
                    <span
                      onClick={toggleModalClassic}
                      style={{
                        border: "0px solid red",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <i class="fas fa-eye"></i>
                    </span>
                    <span
                      {...provided.dragHandleProps}
                      style={{ border: "0px solid red" }}
                    >
                      <i class="fas fa-bars"></i>
                    </span>
                  </div>
                </div>
                <div>{desc}</div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                    }}
                  >
                    {/* {section} */}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {task_state === "Ready" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {task_state === "In Process" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-warning"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {task_state === "Compliant" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        </>
      );
    });
  };
  const RenderColumns = () => {
    // console.log(checkedItemsState)
    return state.map((item, key) => {
      const { title, items } = item;
      if (title == "Compliance" && showingRequirments) return null;
      if (title == "Requirements" && !showingRequirments) return null;
      return (
        <Droppable droppableId={key.toString()}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps} // TODO: these props can be changed later
              style={{
                border: "0px solid red",
                paddingLeft: 5,
                paddingRight: 5,
              }}
              className="col"
            >
              <div
                style={{
                  backgroundColor: "#F8F9FA",
                  paddingBottom: 10,
                  padding: 10,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="text-left" style={{ fontWeight: "bold" }}>
                      {title}
                    </div>
                  </div>
                  <div className="col text-right" style={{ minHeight: 25 }}>
                    {checkedItemsState[key].checkedItems.length > 0 && (
                      <Button
                        className="btn btn-sm"
                        color="info"
                        style={{
                          marginRight: 10,
                          height: 20,
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        onClick={() => {}}
                      >
                        <i className="fas fa-user-circle"></i>
                        {"Assign"}
                      </Button>
                    )}
                    {title != "Requirements" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: 14,
                            marginRight: 10,
                          }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key - 1).toString();
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                          <i class="fas fa-hand-point-left"></i>
                        </span>
                      )}
                    {title != "Compliance" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key + 1).toString();
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      )}
                  </div>
                </div>
                <hr style={{ ...styles.smartHr, marginTop: 2 }} />
                {RenderItems(items, key)}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col style={{ border: "0px solid red" }}>
            <p>
              {"Assessment Summary"}
              <i className="fas fa-star text-warning mx-2"></i>
              <i className="fas fa-users" id="tooltip366246651"></i>
            </p>
          </Col>
          <Col style={{ border: "0px solid red" }} className="text-right">
            <span style={{ border: "0px solid red" }}>
              {wholeAuditStatus === "Ready" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-info"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "In Process" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-warning"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "Compliant" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-success"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
            </span>
          </Col>
        </Row>
        <UncontrolledTooltip delay={0} target="tooltip366246651">
          Harry , Meghan , Ryan
        </UncontrolledTooltip>

        <div className="nav-tabs-navigation">
          <div className="nav-tabs-wrapper">
            <Nav
              id="tabs"
              role="tablist"
              tabs
              className="justify-content-start"
            >
              <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "lifecycle"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "lifecycle" ? "active" : ""}
                  onClick={() => ChangeLifeCycleTabs("lifecycle")}
                >
                  Life Cycle
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "radarchart"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "radarchart" ? "active" : ""}
                  onClick={() => ChangeLifeCycleTabs("radarchart")}
                >
                  Radar Chart
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <TabContent
          className="text-center"
          id="my-tab-content"
          activeTab={lifeCycleTabs}
        >
          <TabPane tabId="lifecycle" role="tabpanel" className="boards-flex">
            <Card className="p-3">
              <Row
                id="scroll-bar"
                style={{
                  height: "450px",
                  position: "relative",
                }}
              >
                {!showingRequirments ? (
                  <div
                    onClick={() => {
                      ChangeShowingRequirments(true);
                    }}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 10,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-right"></i> */}
                    {"Requirements"}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      // setCheckedItemsState(prev => {
                      //     prev[0].checkedItems = []
                      //     return prev
                      // })
                      ChangeShowingRequirments(false);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 10,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                    {"Compliance"}
                  </div>
                )}
                <DragDropContext onDragEnd={HandleDragEnd}>
                  {RenderColumns()}
                </DragDropContext>
              </Row>
            </Card>
          </TabPane>
          <TabPane tabId="radarchart" role="tabpanel">
            <Card className="p-2">
              <Row className="w-100 h-100">
                <Col md="10" className="ml-auto mr-auto">
                  <Radar data={barchartdata} options={options} />
                </Col>
              </Row>
            </Card>
          </TabPane>
        </TabContent>
        {/* ----------------------------------------- Modal ------------------------- */}
        <Modal
          Modal
          isOpen={modalClassic}
          size="xl"
          toggle={toggleModalClassic}
        >
          <div className="modal-header">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalClassic}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                Requirement Title
              </h4>
            </div>
          </div>
          <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  <a href="javascript:void(0)" className="text-dark">
                    Assigned to: Zero Two
                  </a>
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i> 69 Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    EE Requirments
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    Requirment List
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    ISO12658
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    Section
                  </span>
                </Col>
                <Col className="text-right" md="6">
                  {(assessorDecision.value == 2 ||
                    assessorDecision.value == 3) && (
                    <Button
                      className="btn btn-sm"
                      color="info"
                      onClick={() => {
                        ChangeShowCreateTaskModal(true);
                      }}
                    >
                      {" "}
                      <i className="fas fa-plus"></i> Create Task
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="7">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        <th>Area</th>
                        <th>Criticality</th>
                        <th>Version</th>
                        <th>Reference</th>
                        <th>Decision</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">500.b</td>
                        <td className="border-0">6</td>
                        <td className="border-0">Low</td>
                        <td className="border-0">2nd</td>
                        <td className="border-0">3.0</td>
                        <td className="border-0">Zeeshan</td>
                        {assessorDecision.value == 1 ? (
                          <td className="border-0 text-success">
                            {"Compliant"}
                          </td>
                        ) : (
                          <td className="border-0 text-danger">
                            {"Not Compliant"}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="5" className="text-right">
                  {/* <p className="p-2">Updated By Zeesha Ahmad.</p> */}
                  <div className="nav-tabs-navigation border-0">
                    <div className="nav-tabs-wrapper border-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end border-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "details"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "details" ? "active" : ""
                            }
                            onClick={() => ChangeRequirementTabs("details")}
                          >
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "tasks"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "tasks" ? "active" : ""
                            }
                            onClick={() => ChangeRequirementTabs("tasks")}
                          >
                            Tasks
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "discussion"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "discussion" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("discussion");
                              // var elem = document.getElementById('modal_scroll'); //! scroll to bottom not working
                              // var elem2 = document.getElementById('my-tab-content');
                              // console.log("ELEM.SCROLLHEIGHT")
                              // console.log(elem.scrollHeight)
                              // console.log("ELEM")
                              // console.log(elem)
                              // console.log("ELEM2")
                              // console.log(elem2)
                              // elem.scrollTop = elem2.scrollHeight;
                            }}
                          >
                            <i class="far fa-comments"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "attachments"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "attachments" ? "active" : ""
                            }
                            onClick={() => ChangeRequirementTabs("attachments")}
                          >
                            <i class="fas fa-paperclip"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "500px",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <TabContent
                className="text-center"
                id="my-tab-content"
                activeTab={requirementTabs}
              >
                <TabPane tabId="details" role="tabpanel">
                  <Row>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                        Description
                      </h6>
                      <textarea
                        value={
                          "The encryption technique should be sha-2 instead of sha-1"
                        }
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                        disabled
                      />
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                            Contributor Answer
                          </span>
                        </Col>
                        <Col className="text-right">
                          {/* <span style={{ fontSize: 14 }}>Answered by: Zeeshan</span> */}
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <div className="d-flex align-items-center justify-content-start">
                        <Row>
                          <Col
                            style={{ paddingRight: 0, minWidth: 130 }}
                            md="3"
                          >
                            <FormGroup className="mr-2">
                              <Select
                                styles={{
                                  container: () => ({}),
                                }}
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Ans"
                                name="answer"
                                closeMenuOnSelect={false}
                                isMulti={false}
                                value={auditAnswer}
                                onChange={(value) => {
                                  // console.log("SELECTED ANSWER")
                                  // console.log(value)
                                  setAuditAnswer(value);
                                }}
                                options={[
                                  {
                                    value: 0,
                                    label: "Select Answer",
                                    isDisabled: true,
                                  },
                                  { value: 1, label: "No " },
                                  { value: 2, label: "Yes" },
                                  { value: 3, label: "Partial" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          {auditAnswer.value == 3 && (
                            <>
                              <Col
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                md="3"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Partial %"
                                    value={""}
                                    onChange={(e) => {
                                      // console.log(e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                style={{ paddingLeft: 0, paddingRight: 8 }}
                                md="6"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Partial Description"
                                    value={""}
                                    onChange={(e) => {
                                      // console.log(e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                      <Row>
                        
                        <Col md="3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <Button
                            style={{ marginTop: 0 }}
                            color="info"
                            onClick={() => {}}
                          >
                            Evidence
                          </Button>
                        </Col>
                        <Col md="9" style={{ paddingLeft: 0 }}>
                          <FormGroup>
                            <Input placeholder="Evidence Detail" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <textarea
                        value={""}
                        placeholder="Contributor Comment"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                            Assessor Section
                          </span>
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <FormGroup className="mr-2">
                        <Select
                          styles={{
                            container: () => ({}),
                          }}
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Ans"
                          name="answer"
                          closeMenuOnSelect={false}
                          isMulti={false}
                          value={assessorDecision}
                          onChange={(value) => {
                            setAssessorDecision(value);
                          }}
                          options={[
                            {
                              value: 0,
                              label: "Select Decision",
                              isDisabled: true,
                            },
                            { value: 1, label: "Accepted " },
                            { value: 2, label: "Minor R" },
                            { value: 3, label: "Major R" },
                            { value: 4, label: "Rejected" },
                          ]}
                        />
                      </FormGroup>
                      <textarea
                        value={AssessorComment}
                        onChange={(e) => ChangeAssessorComment(e.target.value)}
                        placeholder="Assessor Comment"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                    </Col>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                        Clarification
                      </h6>
                      <textarea
                        value={""}
                        placeholder="Enter clarification"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                        className="text-left"
                      >
                        Sub Requirements
                      </div>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Ref.</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="attachments" role="tabpanel">
                  <div
                    className="text-left font-small"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      style={{ marginRight: 5 }}
                      className="fas fa-plus text-success"
                    ></i>
                    Add Attachement
                  </div>

                  <div className="nav-tabs-navigation p-0">
                    <div className="nav-tabs-wrapper p-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end p-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "table"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "table" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("table")}
                          >
                            <i class="fas fa-table"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "grid"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "grid" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("grid")}
                          >
                            <i class="fas fa-th"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={attachmentTabs}
                  >
                    <TabPane tabId="table" role="tabpanel">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Attached</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>lorem</td>
                            <td>4KB</td>
                            <td>22/02/202</td>
                            <td>Lorem, ipsum.</td>
                          </tr>
                        </tbody>
                      </table>
                    </TabPane>
                    <TabPane tabId="grid" role="tabpanel">
                      <Row>
                        <Col md="3">
                          <Card>
                            <CardBody>
                              <i class="fas fa-file-pdf text-danger fa-3x"></i>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Document Name</p>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-expanded={false}
                                    aria-haspopup={true}
                                    caret
                                    className="btn-round btn-block"
                                    color="primary"
                                    data-toggle="dropdown"
                                    id="dropdownMenuButton"
                                    type="button"
                                  >
                                    <i class="fas fa-ellipsis-v"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    persist
                                    aria-labelledby="dropdownMenuButton"
                                    right
                                  >
                                    <DropdownItem
                                      download
                                      href="./Untitled.pdf"
                                      target="_blank"
                                    >
                                      Download
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      View
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </TabPane>
                <TabPane tabId="tasks" role="tabpanel">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Stand Ref.</th>
                        <th>Description</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Iteration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Task 1</td>
                        <td>420.6.9</td>
                        <td>
                          Photons should represent 2 bits instead of a single
                          bit
                        </td>
                        <td>14/01/2021</td>
                        <td>Ready</td>
                        <td>1</td>
                        <td>
                          <i
                            className="fas fa-eye text-info"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ChangeTaskModal(true);
                            }}
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Task 2</td>
                        <td>420.6.9</td>
                        <td>
                          Photons should represent 2 bits instead of a single
                          bit
                        </td>
                        <td>14/01/2021</td>
                        <td>Ready</td>
                        <td>2</td>
                        <td>
                          <i
                            className="fas fa-eye text-info"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ChangeTaskModal(true);
                            }}
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Task 3</td>
                        <td>420.6.9</td>
                        <td>
                          Photons should represent 2 bits instead of a single
                          bit
                        </td>
                        <td>14/01/2021</td>
                        <td>In Process</td>
                        <td>3</td>
                        <td>
                          <i
                            className="fas fa-eye text-info"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ChangeTaskModal(true);
                            }}
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TabPane>
                <TabPane
                  tabId="discussion"
                  role="tabpanel"
                  id="chat-parent-tab"
                >
                  {RenderDiscussion()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      border: "0px solid red",
                      // alignItems: 'center'
                    }}
                  >
                    <img
                      src={img}
                      className="rounded-circle"
                      width="40"
                      height="40"
                      alt=""
                    />
                    <div
                      style={{
                        border: "0px solid red",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          border: "0px solid red",
                        }}
                      >
                        <div
                          className="single-line-text"
                          style={{
                            marginLeft: 10,
                            fontSize: 16,
                            fontWeight: "bold",
                            textAlign: "left",
                            color: "#595974",
                          }}
                        >
                          {"Dani Charles"}
                        </div>
                        <div
                          style={{
                            background: "#F4F5F7",
                            marginLeft: 10,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 5,
                          }}
                        >
                          {"Assessor"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          border: "0px solid red",
                          padding: 10,
                        }}
                      >
                        <textarea
                          placeholder="type your comment here . . ."
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          className="form-control"
                          style={{
                            padding: 5,
                          }}
                        >
                          {
                            "Photons should represent 2 bits instead of a single bit"
                          }
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      className="btn-blue "
                      data-dismiss="modal"
                      type="button"
                      onClick={toggleModalClassic}
                    >
                      Add
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button
                  color="success"
                  onClick={() => toggleModalClassic(false)}
                >
                  Publish
                </Button>
                <Button
                  color="danger"
                  onClick={() => toggleModalClassic(false)}
                >
                  Draft
                </Button>
              </div>
            </CardFooter>
          </div>
        </Modal>
        <Modal
          isOpen={taskModal}
          size="xl"
          toggle={() => ChangeTaskModal(false)}
        >
          <div className="modal-header">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => ChangeTaskModal(false)}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                Task Title
              </h4>
            </div>
          </div>
          <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  <a href="javascript:void(0)" className="text-dark">
                    Assigned to: Zero Two
                  </a>
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i> 69 Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    EE Requirments
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    Requirment List
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    ISO12658
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    Section
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    EE Requirement 1
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    Requirment
                  </span>
                </Col>
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="7">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        <th>Area</th>
                        <th>Criticality</th>
                        <th>Reference</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">500.b</td>
                        <td className="border-0">6</td>
                        <td className="border-0">Low</td>
                        <td className="border-0">2nd</td>
                        <td className="border-0">Zeeshan</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="5" className="text-right">
                  <p className="p-2">Created By: Moon.</p>
                </Col>
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "500px",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <Row>
                <Col md="12">
                  <h6 className="p-2 border-bottom text-left">Description</h6>
                  <textarea
                    value={
                      "The encryption technique should be sha-2 instead of sha-1"
                    }
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                        Contributor Answer Section
                      </span>
                    </Col>
                    <Col className="text-right">
                      {/* <span style={{ fontSize: 14 }}>Answered by: Zeeshan</span> */}
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                  <div className="d-flex align-items-center justify-content-start">
                    <Row>
                      <Col style={{ paddingRight: 0, minWidth: 130 }} md="3">
                        <FormGroup className="mr-2">
                          <Select
                            styles={{
                              container: () => ({}),
                            }}
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Ans"
                            name="answer"
                            closeMenuOnSelect={false}
                            isMulti={false}
                            value={auditAnswer}
                            onChange={(value) => {
                              // console.log("SELECTED ANSWER")
                              // console.log(value)
                              setAuditAnswer(value);
                            }}
                            options={[
                              {
                                value: 0,
                                label: "Select Answer",
                                isDisabled: true,
                              },
                              { value: 1, label: "No " },
                              { value: 2, label: "Yes" },
                              { value: 3, label: "Partial" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                      {auditAnswer.value == 3 && (
                        <>
                          <Col
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            md="3"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                type="text"
                                placeholder="Partial %"
                                value={""}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            style={{ paddingLeft: 0, paddingRight: 8 }}
                            md="6"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                type="text"
                                placeholder="Partial Description"
                                value={""}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                  <Row>
                    <Col md="2" style={{ paddingLeft: 15, paddingRight: 0 }}>
                      <Button
                        style={{ marginTop: 0 }}
                        color="info"
                        onClick={() => {}}
                      >
                        Evidence
                      </Button>
                    </Col>
                    <Col md="10" style={{ paddingLeft: 0 }}>
                      <FormGroup>
                        <Input placeholder="Evidence Detail" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <textarea
                    value={""}
                    placeholder="Contributor Comment"
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                        Assessor Section
                      </span>
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                  <textarea
                    value={""}
                    placeholder="Assessor Comment"
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="text-right">
                <Button color="success" onClick={() => ChangeTaskModal(false)}>
                  Publish
                </Button>
                <Button color="danger" onClick={() => ChangeTaskModal(false)}>
                  Draft
                </Button>
              </div>
            </CardBody>
          </div>
        </Modal>
        <Modal
          isOpen={showCreateTaskModal}
          size="xl"
          toggle={() => {
            ChangeShowCreateTaskModal(false);
          }}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                ChangeShowCreateTaskModal(false);
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">New Task</h4>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Rask Name</Label>
                    <Input
                      type="text"
                      placeholder="Task Name"
                      value={""}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Stand Ref.</Label>
                    <Input
                      type="text"
                      placeholder="Stand Ref."
                      value={""}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Description</Label>
                    <textarea
                      value={AssessorComment}
                      style={{
                        paddingLeft: 10,
                      }}
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  color="success"
                  onClick={() => {
                    ChangeShowCreateTaskModal(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    ChangeShowCreateTaskModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}
const mapStateToProps = ({ shared, styling, audit, requirment }) => ({
  shared,
  styling,
  audit,
  requirment,
});

export default connect(mapStateToProps, {})(LifeCycle);
