import {
    GET_ALL_VERSION_DETAILS,
    GET_ALL_VERSION_DETAILS_TABLE,
    SHOW_VERSION_ITERATION,
    SHOW_VERSION_NAME,
    SHOW_SUB_REQ_DECISION,
    CLEAR_SUB_REQ_DECISION

} from "../actions/types";

const initialState = {
    auditVersionsRequirementSectionsAudit: [],
    auditVersionTable: [],
    showVersionName: [],
    showVersionIteration: null,
    showSubReqDec: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ALL_VERSION_DETAILS:
            return {
                ...state,
                auditVersionsRequirementSectionsAudit: payload,
            };

        case GET_ALL_VERSION_DETAILS_TABLE:
            return {
                ...state,
                auditVersionTable: payload
            }
        case SHOW_VERSION_NAME:
            return {
                ...state,
                showVersionName: payload,
            };

        case SHOW_VERSION_ITERATION:
            return {
                ...state,
                showVersionIteration: payload,
            };

        case SHOW_SUB_REQ_DECISION:
            return {
                ...state,
                showSubReqDec: payload,
            };
        case CLEAR_SUB_REQ_DECISION:
            return {
                ...state,
                showSubReqDec: null,
            };
        default:
            return state;
    }
};
