
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import avatar from "../../../assets/img/faces/default-avatar.png";
import { Link, useHistory } from 'react-router-dom'
import {
    FcAcceptDatabase,
    FcApproval,
    FcBarChart,
    FcCheckmark,
    FcDataRecovery,
    FcHighPriority,
    FcRadarPlot,
} from 'react-icons/fc'

import { styles } from '../../../styling/custom'

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    UncontrolledPopover,
    PopoverBody,
    Modal,
    Form,
} from "reactstrap";

import {
} from '../../../store/actions/auditActions'
import moment from "moment";

const CustomBreadCrumbs = (props) => {
    const history = useHistory()
    const {
        crumbs
    } = props
    function RenderCrumbs() {
        return crumbs.map((item, key) => {
            const { name, path } = item
            return (
                <>
                    {key != 0 &&
                        <div
                            className="crumb-separator"
                        >
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    }
                    <div
                        className="single-crumb"
                        onClick={() => {
                            if (path != null)
                                history.push(path)
                        }}
                    >
                        <span style={{ fontSize: 18, fontWeight: 'bold' }}>{name}</span>
                    </div>

                </>
            )
        })
    }
    return (
        <div
            style={{
                justifyContent: 'flex-start',
                display: 'flex'
            }}
        >
            <Card>
                <div
                    className="breadcrumbs"
                >
                    {RenderCrumbs()}
                </div>
            </Card>
        </div>
    );
}

const mapStateToProps = ({ shared, styling, audit }) => ({
    shared, styling, audit
})

export default connect(mapStateToProps, {
})(CustomBreadCrumbs)