import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "views/tables/GlobalFilter";
import { useExportData } from "react-table-plugins";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "../../tables/CheckBox";

import {
  AddRequirementsForCreatingAudit,
  AddSectionsForCreatingAudit,
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
} from "../../../store/actions/auditActions";
import { AddSectionsForCreatingAuditTemplate } from "store/actions/auditingTemplateActions";
import { GetAllSections } from "store/actions/requirmentActions";
import { AddRequirementsForCreatingAuditTemplate } from "store/actions/auditingTemplateActions";

const AddSections = (props) => {
  // const [tableData, SetTableData] = useState([]);
  const [data, SetData] = useState([]);
  const [showMore, setShowMore] = useState(false)

  const { addSectionsForCreatingAudit, addRequirementsForCreatingAudit } =
    props.audit;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddSectionsModal,
    requirment,
    AddSectionsForCreatingAuditTemplate,
    AddSectionsForCreatingAudit,
    AddRequirementsForCreatingAuditTemplate,
    AddRequirementsForCreatingAudit,
  } = props;
  const {
    addSectionsForCreatingAuditTemplate,
    addRequirementsForCreatingAuditTemplate,
  } = props.auditingTemplate;
  const { allSections } = requirment;
  const { isAddNewAudit } = props.shared;

  console.log(":::::::::::::::::IS ADD NEW AUDIT");

  console.log(isAddNewAudit);

  // const [tableData, setTableData] = useState([]);

  let dataMap = allSections.map((data) => ({
    ...data,
    id: data.id,
    requirementList: data.req_list.name,
    desc: data.description,
    standardRef: data.standard_ref,
    clarification: data.clarifications,

    mapped: data?.requirements.map((data) => data.mapped_requirements?.length > 0),
  }));
  let dataTable = React.useMemo(() => dataMap, [allSections]);

  useEffect(() => {
    SetData(dataTable);
  }, [allSections]);

  console.log(allSections);

  // var dataTable = React.useMemo(
  //   () => [
  //     {
  //       name: "ISO:88880000",
  //       desc: "Section 1 Desc",
  //       authority: "ISO",
  //       location: "USA",
  //       industry: "ISO",
  //       information: "International Organization for Standardization",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "CNIC Card",
  //     },
  //     {
  //       name: "ISO:8888",
  //       desc: "Section 2 Desc",
  //       authority: "ISO",
  //       location: "USA",
  //       industry: "ISO",
  //       information: "International Organization for Standardization",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "CNIC Card",
  //     },
  //   ],
  //   []
  // );
  const columns = React.useMemo(
    () => [
      {
        Header: "Sec: Standard Ref",
        accessor: "standardRef",
      },
      // {
      //   Header: "Section Name",
      //   accessor: "name",
      // },
      {
        Header: "Desc",
        accessor: "desc",
      },
      {
        Header: "Requirement List",
        accessor: "requirementList",
      },
      {
        Header: "Clarification",
        accessor: "clarification",
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const filterDataTable = (e) => {
    console.log("E.TARGET.VALUE");
    console.log(e.target.value);
    var elements;
    if (e.target.value == 1) {
      elements = dataTable.filter((el) => {
        return el.mapped.includes(true)
      });
    } else if (e.target.value == 2) {
      elements = dataTable.filter((el) => {

        return !el.mapped.includes(true);
      });
    } else {
      console.log(dataTable)
      elements = dataTable;
    }
    console.log("ELEMENTS");
    console.log(elements);
    SetData(elements);
  };

  // useEffect(() => {
  //   AddSectionsForCreatingAuditTemplate(selectedFlatRows);
  // }, [selectedFlatRows]);

  // console.log(selectedFlatRows.original.map((row) => row.id));
  console.log(selectedFlatRows.map((row) => row.original.id));
  console.log(state);

  const handleSectionsSubmit = () => {
    let sections = selectedFlatRows.map((row) => row.original.id);
    let sectionsRequirements = selectedFlatRows
      .map((row) =>
        row.original.requirements.map((requirement) => requirement.id)
      )
      .flat();
    console.log("----ADD SECTIONS COMPONENT----");
    console.log(selectedFlatRows);
    console.log(sections);
    console.log(sectionsRequirements);

    isAddNewAudit
      ? (AddSectionsForCreatingAudit([
        ...addSectionsForCreatingAudit,
        ...sections,
      ]),
        AddRequirementsForCreatingAudit([
          ...addRequirementsForCreatingAudit,
          ...sectionsRequirements,
        ]))
      : (AddSectionsForCreatingAuditTemplate([
        ...addSectionsForCreatingAuditTemplate,
        ...sections,
      ]),
        AddRequirementsForCreatingAuditTemplate([
          ...addRequirementsForCreatingAuditTemplate,
          ...sectionsRequirements,
        ]));
  };

  return (
    <>
      <div className="modal-header justify-content-center" >
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            ChangeShowAddSectionsModal(false);
          }}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">Add Sections </h4>
      </div>
      <div className="modal-body" style={{ height: "80vh", overflow: "scroll" }}>
        <div className="row p-3 align-items-center">
          <div className="col-md-2">
            <div className="d-flex align-items-center justify-content-between">
              Show
              <select
                value={pageSize}
                className="form-control pagination-select"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              Entries
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3 text-right">
            <FormGroup className="custom-form-group">
              <select
                name=""
                id=""
                className="form-control custom-input"
                onChange={(e) => filterDataTable(e)}
              >
                <option value="" defaultValue disabled>
                  Filters
                </option>
                <option value={0}>All</option>
                <option value={1}>Mapped</option>
                <option value={2}>Not Mapped</option>
              </select>
            </FormGroup>
          </div>
          <div className="col-md-3 text-right">
            <div className="form-group custom-form-group">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </div>
        <div className="tableWrap">
          <table {...getTableProps()} className="table-striped table-hover ">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-info text-white"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border border-left-0 border-right "
                      style={{
                        borderBottom: "1px solid #cacaca",
                        background: "rgba(0, 0, 0, 0.05)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down  text-secondary"></i>
                          ) : (
                            <i className="fas fa-arrow-up text-secondary"></i>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td {...cell.getCellProps()} style={{

                            wordBreak: "break-word",
                            minWidth: "25%",
                            width: "20%",
                          }}>
                            <div style={{
                              height: "150px",
                              overflow: "auto"

                            }}>
                              {cell.render("Cell")}
                            </div>



                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <div className="">
            <button
              className="btn btn-info btn-sm"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <span className="pagination-button">
              {pageOptions.map((page) => (
                <button
                  className={
                    pageIndex === page
                      ? "btn border-info border btn-info  bg-transparent text-info "
                      : "btn border-info border btn-info btn-sm bg-transparent text-info"
                  }
                  onClick={() => {
                    const pageNumber = page;
                    gotoPage(pageNumber);
                  }}
                >
                  {page + 1}
                </button>
              ))}
            </span>
            <button
              className="btn btn-info  btn-sm"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
        <div className="text-right">
          <Button
            color="success"
            onClick={() => {
              ChangeShowAddSectionsModal(false);
              handleSectionsSubmit();
            }}
          >
            Add
          </Button>
          <Button
            color="danger"
            onClick={() => {
              ChangeShowAddSectionsModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
  AddSectionsForCreatingAuditTemplate,
  AddSectionsForCreatingAudit,
  GetAllSections,
  AddRequirementsForCreatingAuditTemplate,
  AddRequirementsForCreatingAudit,
})(AddSections);
