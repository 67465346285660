import {
  CHANGE_GLOBAL_LOADER,
  CHANGE_GET_ALL_COMPANIES,
  CHANGE_CREATE_COMPANY,
  CHANGE_EDIT_COMPANY,
  CHANGE_GET_SINGLE_COMPANY,
  CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL,
  CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
  CHANGE_IS_EDITING_COMPANY,
  CHANGE_SINGLE_COMPANY_EMPTY,
  CHANGE_CREATE_INDIVIDUAL,
  CHANGE_GET_INDIVIDUAL,
  CHANGE_EDIT_INDIVIDUAL,
  CHANGE_CURRENT_CLICKED_COMPANY_DATA,
} from "./types";
import { baseUrl } from "config/baseUrl";
import { data } from "jquery";
import { messageHandler } from "utils/popupMessage";

export const ChangeIsEditingCompany = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_EDITING_COMPANY, payload: val });
  };
};

export const ChangeSingleCompanyEmpty = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SINGLE_COMPANY_EMPTY, payload: null });
  };
};

export const ChangeCurrentClickedCompanyData = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_CURRENT_CLICKED_COMPANY_DATA, payload: val });
  };
};

// Get All Companies
export const GetAllCompanies = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allCompanies = await fetch(
        `${baseUrl}/api/auditor/all-companies`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allCompanies.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_COMPANIES,
          payload: allCompanies.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Single Company
export const GetSingleCompany = (val, isModal) => {
  console.log(val);
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let company = await fetch(
        `${baseUrl}/api/auditor/show-company`,
        requestOptions
      ).then((response) => {
        console.log("response1");
        console.log(response);
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (company.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_COMPANY,
          payload: company.data.data,
        });
        isModal &&
          dispatch({
            type: CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL,
            payload: true,
          });
        isModal &&
          dispatch({
            type: CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
            payload: true,
          });
        console.log(company);
      } else {
        console.log("ERROR");
        console.log(company);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Create Company
export const CreateCompany = (val, setErrorState, modal) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("name", val.name);
      formdata.append("address", val.address);
      formdata.append("country", val.country);
      formdata.append("city", val.city);
      formdata.append("state", val.state);
      formdata.append("zip_code", val.zip_code);
      formdata.append("tax_id", val.tax_id);
      formdata.append("phone_no", val.phone_no);
      formdata.append("mobile_no", val.mobile_no);
      formdata.append("email", val.email);
      formdata.append("website", val.website);
      formdata.append("tags", val.tags);
      formdata.append("status", val.status);
      formdata.append("file", val.file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let company = await fetch(
        `${baseUrl}/api/auditor/create-company`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (company.status == 200 && company.data.success === true) {
        dispatch({
          type: CHANGE_CREATE_COMPANY,
          payload: company.data.data,
        });
        dispatch(GetAllCompanies());
        // messageHandler(setAlert, "Successfully Created", "Company created successfully", "success")
        modal(false)


        console.log(company);
      } else {
        if (company.data.success === false) {
          console.log(company);
          setErrorState(company.data.message);
          modal(true);
        }
        console.log("ERROR");
        // messageHandler(setAlert, "Failed to Create", "Unable to create company try again", "error")
        console.log(company);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Edit Company
export const EditCompany = (
  val,
  setErrorState,
  modal,
  ChangeIsEditingCompany,
  ChangeSingleCompanyEmpty,

) => {
  return async (dispatch) => {
    try {
      console.log("::::::::::::::::::FILE IMAGE PROBLEM::::::::::::::::::");
      console.log(val);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val.id);
      formdata.append("name", val.name);
      formdata.append("address", val.address);
      formdata.append("country", val.country);
      formdata.append("city", val.city);
      formdata.append("state", val.state);
      formdata.append("zip_code", val.zip_code);
      formdata.append("tax_id", val.tax_id);
      formdata.append("phone_no", val.phone_no);
      formdata.append("mobile_no", val.mobile_no);
      formdata.append("email", val.email);
      formdata.append("website", val.website);
      formdata.append("tags", val.tags);
      formdata.append("status", val.status);
      formdata.append("file", val.file);

      console.log(formdata);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let company = await fetch(
        `${baseUrl}/api/auditor/edit-company`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (company.status == 200) {
        dispatch({
          type: CHANGE_EDIT_COMPANY,
          payload: company.data.data,
        });
        dispatch(GetAllCompanies());
        modal(false);
        ChangeIsEditingCompany(false);
        ChangeSingleCompanyEmpty(null);

        // messageHandler(setAlert, "Edit Company Successfully", "Successfully Edit Company data", "success")
        console.log(company);
      } else {
        if (company.data.success === false) {
          console.log(company);
          setErrorState(company.data.message);
          modal(true);
        }
        console.log("ERROR");
        console.log(company);
        // messageHandler(setAlert, "Edit Failed", "Unable to Edit", "error")
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// FIXME: Change URL or other stuff

// Create Individual
export const CreateIndividual = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("name", val.name);
      formdata.append("company-id", val.company_id);
      formdata.append("address", val.address);
      formdata.append("country", val.country);
      formdata.append("city", val.city);
      formdata.append("state", val.state);
      formdata.append("zip_code", val.zip_code);
      formdata.append("tax_id", val.tax_id);
      formdata.append("phone_no", val.phone_no);
      formdata.append("mobile_no", val.mobile_no);
      formdata.append("email", val.email);
      formdata.append("website", val.website);
      formdata.append("tags", val.tags);
      formdata.append("status", val.status);
      formdata.append("file", val.file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let individual = await fetch(
        `${baseUrl}/api/auditor/create-individual`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (individual.status == 200) {
        dispatch({
          type: CHANGE_CREATE_INDIVIDUAL,
          payload: individual.data.data,
        });
        console.log(individual);
      } else {
        console.log("ERROR");
        console.log(individual);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// FIXME: Change URL or other stuff

// Edit Individual
export const EditIndividual = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val.id);
      formdata.append("name", val.name);
      formdata.append("company-id", val.company_id);
      formdata.append("address", val.address);
      formdata.append("country", val.country);
      formdata.append("city", val.city);
      formdata.append("state", val.state);
      formdata.append("zip_code", val.zip_code);
      formdata.append("tax_id", val.tax_id);
      formdata.append("phone_no", val.phone_no);
      formdata.append("mobile_no", val.mobile_no);
      formdata.append("email", val.email);
      formdata.append("website", val.website);
      formdata.append("tags", val.tags);
      formdata.append("status", val.status);
      formdata.append("file", val.file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let individual = await fetch(
        `${baseUrl}/api/auditor/edit-individual`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (individual.status == 200) {
        dispatch({
          type: CHANGE_EDIT_INDIVIDUAL,
          payload: individual.data.data,
        });
        console.log(individual);
      } else {
        console.log("ERROR");
        console.log(individual);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// FIXME: Change URL or other stuff

// Show Individual
export const GetIndividual = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let individual = await fetch(
        `${baseUrl}/api/auditor/show-individual`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (individual.status == 200) {
        dispatch({
          type: CHANGE_GET_INDIVIDUAL,
          payload: individual.data.data,
        });
        console.log(individual);
      } else {
        console.log("ERROR");
        console.log(individual);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};
