import { GET_ALL_DASHBOARD_INFO } from "./types";
import { baseUrl } from "config/baseUrl";

// Get all dashboard
export const GetAllDashboardInfo = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      // dispatch({
      //     type: CHANGE_GLOBAL_LOADER,
      //     payload: true,
      // });

      let allDashboardInfo = await fetch(
        `${baseUrl}/api/auditor/dashboard`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allDashboardInfo.status == 200) {
        dispatch({
          type: GET_ALL_DASHBOARD_INFO,
          payload: allDashboardInfo.data.data,
        });

        console.log("dashboard data ", allDashboardInfo.data.data);
      } else {
        console.log("ERROR");
        console.log(allDashboardInfo);
      }
      // dispatch({
      //     type: CHANGE_GLOBAL_LOADER,
      //     payload: false,
      // });
    } catch (error) {
      console.log("failed get data", error);

      // dispatch({
      //     type: CHANGE_GLOBAL_LOADER,
      //     payload: false,
      // });
    }
  };
};
