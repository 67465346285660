import {
  CHANGE_CREATE_TASK,
  GET_ALL_TASK,
  CHANGE_AUDITOR_LIFE_CYCLE,
  CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT,
  CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE,
  CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE,
  ASSIGN_SUPPORT_TEAM_RESPONSE,
  MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE,
  CHANGE_SAVE_ANSWER,
  CHANGE_SHOW_AUDIT_ANSWER,
  MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW,
  GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT,
  CHANGE_SHOW_AUDIT_ANSWER_TASK,
  CLEAR_AUDIT_ANSWER_TASK_DATA,
  CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA,
  CREATE_VERSION_LIFE_CYCLE,
  CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION,
  CLEAR_ALL_TASK_DATA,
  GET_VERSION_FILES,
  GET_REPORT_FILES,
  GET_RADAR_CHART,
  SHOW_VERSION_NAME_REPORT

} from "store/actions/types";
import { createNull } from "typescript";

const initialState = {
  createTask: null,
  allTask: [],
  getAuditorLifeCycle: null,
  deleteAuditorLifeCycleRequirement: null,
  moveAuditorLifeCycleRequirementsToResponse: null,
  getContributeLifeCycle: null,
  assginSupportTeamAudit: null,
  moveContributeLeadReqToRes: null,
  saveAnswerAudit: null,
  showAuditAnswer: null,
  showAuditAnswerTask: null,
  moveContributeLeadLifeCycleRequirementsToReview: null,
  answerAgainstRequirementOrSubRequirement: null,
  createVersion: null,
  versionFiles: [],
  getReportFile: null,
  getRadarChart: null,
  showVersionNameReport: []


};

export default (state = initialState, { type, payload }) => {
  console.log("LIFE CYCLE TYPE");
  console.log(type);
  console.log("LIFE CYCLE PAYLOAD");
  console.log(payload);
  switch (type) {
    case CHANGE_AUDITOR_LIFE_CYCLE:
      return {
        ...state,
        getAuditorLifeCycle: payload,
      };
    case CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT:
      return {
        ...state,
        deleteAuditorLifeCycleRequirement: payload,
      };
    case CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE:
      return {
        ...state,
        moveAuditorLifeCycleRequirementsToResponse: payload,
      };
    case CHANGE_CREATE_TASK:
      return {
        ...state,
        createTask: payload,
      };

    case GET_ALL_TASK:
      return {
        ...state,
        allTask: payload,
      };

    case CLEAR_ALL_TASK_DATA:
      return {
        ...state,
        allTask: null,
      };
    case CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE:
      return {
        ...state,
        getContributeLifeCycle: payload,
      };

    case ASSIGN_SUPPORT_TEAM_RESPONSE:
      return {
        ...state,
        assginSupportTeamAudit: payload,
      };
    case CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA:
      return {
        ...state,
        showAuditAnswer: null,
      };

    case MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE:
      return {
        ...state,
        moveContributeLeadReqToRes: payload,
      };

    case CHANGE_SAVE_ANSWER:
      return {
        ...state,
        saveAnswerAudit: payload,
      };

    case CHANGE_SHOW_AUDIT_ANSWER:
      return {
        ...state,
        showAuditAnswer: payload,
      };
    case CHANGE_SHOW_AUDIT_ANSWER_TASK:
      return {
        ...state,
        showAuditAnswerTask: payload,
      };
    case CLEAR_AUDIT_ANSWER_TASK_DATA:
      return {
        ...state,
        showAuditAnswerTask: null,
      };

    case CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION:

      return {
        ...state,
        showAuditAnswerTask: {
          ...state.showAuditAnswerTask,
          answer_iteration: null
        }
      };

    case MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW:
      return {
        ...state,
        moveContributeLeadLifeCycleRequirementsToReview: payload,
      };
    case GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT:
      return {
        ...state,
        answerAgainstRequirementOrSubRequirement: payload,
      };

    // version reducer
    case CREATE_VERSION_LIFE_CYCLE:
      return {
        ...state,
        createVersion: payload,
      };

    // version reducer
    case CREATE_VERSION_LIFE_CYCLE:
      return {
        ...state,
        createVersion: payload,
      };
    // get version files
    case GET_VERSION_FILES:
      return {
        ...state,
        versionFiles: payload,
      };

    // get version files
    case GET_REPORT_FILES:
      return {
        ...state,
        getReportFile: payload,
      };

    // get radar chart
    case GET_RADAR_CHART:
      const barchartdata = {
        labels: [

        ],
        datasets: [
          {
            label: "Requirements",
            data: [],
            backgroundColor: "rgb(255, 99, 132)",
          },


        ],
      };
      return {
        ...state,
        getRadarChartData: payload.data.map((item) => {
          barchartdata?.labels.push(item?.requirement_name)
          barchartdata?.datasets[0]?.data.push(item?.final_percentage)


        }),
        getRadarChart: barchartdata

      }

    // get version files
    case SHOW_VERSION_NAME_REPORT:
      return {
        ...state,
        showVersionNameReport: payload,
      };

    default:
      return state;
  }
};
