
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import {
    FcAcceptDatabase,
    FcApproval,
    FcBarChart,
    FcCheckmark,
    FcDataRecovery,
    FcHighPriority,
    FcRadarPlot,
} from 'react-icons/fc'

import { styles } from '../../../styling/custom'

import img from '../../../assets/img/faces/default-avatar.png';

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Input, Form,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container, Modal, ModalBody, ModalHeader, ModalFooter, DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import {
} from '../../../store/actions/auditActions'
import moment from "moment";
import { Radar } from "react-chartjs-2";
import CustomBreadCrumbs from "../Shared/CustomBreadCrumbs";





const barchartdata = {
    labels: ['Req 1', 'Req 2', 'Req 3', 'Req 4', 'Req 5', 'Req 6', 'Req 7', 'Req 8', 'Req 9', 'Req 10', 'Req 11', 'Req 12'],
    datasets: [
        {
            label: 'Yes',
            data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
            backgroundColor: 'rgb(255, 99, 132)',
        },
        {
            label: 'No',
            data: [2, 3, 20, 5, 1, 4, 2, 3, 20, 5, 1, 4],
            backgroundColor: 'rgb(54, 162, 235)',
        },
        {
            label: 'Partial',
            data: [3, 10, 13, 15, 22, 30, 3, 10, 13, 15, 22, 30],
            backgroundColor: 'rgb(75, 192, 192)',
        },
    ],
};
const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

const VersionRadarChart = (props) => {


    const { getRadarChart } = props.lifeCycle;


    return (
        <div className="content">
            <Card className="p-2" >
                <Row className="w-100 h-100">
                    <Col md="10" className="ml-auto mr-auto">
                        <Radar data={getRadarChart} options={options} />
                    </Col>
                </Row>
            </Card>
        </div >
    );
}

const mapStateToProps = ({ shared, styling, audit, lifeCycle }) => ({
    shared, styling, audit, lifeCycle
})

export default connect(mapStateToProps, {

})(VersionRadarChart)