import {
  CHANGE_GET_ALL_COMPANIES,
  CHANGE_CREATE_COMPANY,
  CHANGE_EDIT_COMPANY,
  CHANGE_GET_SINGLE_COMPANY,
  CHANGE_IS_EDITING_COMPANY,
  CHANGE_SINGLE_COMPANY_EMPTY,
  CHANGE_CREATE_INDIVIDUAL,
  CHANGE_GET_INDIVIDUAL,
  CHANGE_EDIT_INDIVIDUAL,
  CHANGE_CURRENT_CLICKED_COMPANY_DATA,
} from "../actions/types";

const initialState = {
  createCompany: null,
  allCompanies: [],
  editCompany: null,
  singleCompany: null,
  isEditCompany: false,
  createIndividual: null,
  singleIndividual: null,
  editIndividual: null,
  currentClickedCompanyData: null,
};

export default (state = initialState, { type, payload }) => {
  console.log("TYPE::");
  console.log(type);
  console.log("PAYLOAD::");
  console.log(payload);
  switch (type) {
    case CHANGE_CREATE_COMPANY:
      return {
        ...state,
        createCompany: payload,
      };
    case CHANGE_GET_ALL_COMPANIES:
      return {
        ...state,
        allCompanies: payload,
      };
    case CHANGE_GET_SINGLE_COMPANY:
      // const country = {
      //   value: payload?.country?.id,
      //   label: payload?.country?.name,
      // };
      // const city = { value: payload?.city?.id, label: payload?.city?.name };
      // const companyState = {
      //   value: payload?.state?.id,
      //   label: payload?.state?.name,
      // };
      const tags = payload?.tags?.split(",");
      payload = { ...payload, tags };
      return {
        ...state,
        singleCompany: payload,
      };
    case CHANGE_EDIT_COMPANY:
      return {
        ...state,
        editCompany: payload,
      };
    case CHANGE_CREATE_INDIVIDUAL:
      return {
        ...state,
        createIndividual: payload,
      };
    case CHANGE_GET_INDIVIDUAL:
      return {
        ...state,
        singleIndividual: payload,
      };
    case CHANGE_EDIT_INDIVIDUAL:
      return {
        ...state,
        editIndividual: payload,
      };
    case CHANGE_IS_EDITING_COMPANY:
      return {
        ...state,
        isEditCompany: payload,
      };
    case CHANGE_SINGLE_COMPANY_EMPTY:
      return {
        ...state,
        singleCompany: payload,
      };
    case CHANGE_CURRENT_CLICKED_COMPANY_DATA:
      return {
        ...state,
        currentClickedCompanyData: payload,
      };
    default:
      return state;
  }
};
