import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import ReactFileReader from "react-file-reader";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "./CheckBox";
import { GetAllReferences } from "store/actions/requirmentActions";
import { connect } from "react-redux";
import { DeleteReference } from "store/actions/requirmentActions";
import { EditReference } from "store/actions/requirmentActions";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}
// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const References = (props) => {
  const { GetAllReferences, requirment, DeleteReference, EditReference } =
    props;
  const { allReferences } = requirment;

  const [data, SetData] = useState([]);
  const [currentEditReferenceId, SetCurrentEditReferenceId] = useState([]);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [internetResource, setInternetResource] = useState("");
  const [internalLinks, setInternalLinks] = useState("");
  const [complianceStandard, setComplianceStandard] = useState("");
  // const [title, setTitle] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: currentEditReferenceId,
      name: title,
      type: type,
      description: description,
      internet_resource: internetResource,
      internal_links: internalLinks,
      compliance_standard: complianceStandard,
    };

    EditReference(data);

    setTitle("");
    setType("");
    setDescription("");
    setInternetResource("");
    setInternalLinks("");
    setComplianceStandard("");
    toggleModalClassic();

    console.log(data);
  };

  // ---------------- SETTING UP DATATABLE START ---------------------------

  let dataMap = allReferences.map((reference) => ({
    id: reference.id,
    name: reference.name,
    desc: reference.description,
    compliance_standard: reference.compliance_standard,
    type: reference.type,
    internet_resource: reference.internet_resource,
    internal_links: reference.internal_links,
  }));
  let dataMemo = React.useMemo(() => dataMap, [allReferences]);

  useEffect(() => {
    GetAllReferences();
    SetData(dataMemo);
  }, []);

  useEffect(() => {
    SetData(dataMemo);
  }, [dataMemo]);

  // const dataTable = React.useMemo(
  //   () => [
  //     {
  //       name: "ISO:88880000000",
  //       desc: "World",
  //       compliance_started: "lorem",
  //       type: "lorem",
  //       internet_resource: "lorem",
  //       internal_links: "12/03/2020",
  //     },
  //     {
  //       name: "ISO:9000",
  //       desc: "World",
  //       compliance_started: "lorem",
  //       type: "lorem",
  //       internet_resource: "lorem",
  //       internal_links: "12/03/2020",
  //     },
  //   ],
  //   []
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Compliance Standard",
        accessor: "compliance_standard",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Internet Resource",
        accessor: "internet_resource",
      },
      {
        Header: "Internal Links",
        accessor: "internal_links",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  //  TODO: Confirmation on deleting reference
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Reference Deleted Successfully
      </ReactBSAlert>
    );
  };

  const confirmDelete = (actionData) => {
    setAlert(
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Confirmation"
        onConfirm={() => {
          DeleteReference(actionData);
          hideAlert();
          // successDelete();
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Do you really want to delete reference?
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };

  const colMissmatchAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Columns mismatch!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        The header(1st row) in csv file should have same columns as in table
        header!
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    // console.log(row)
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = React.useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const setRowForEdit = (row) => {
    // console.log(row)
    setRowDetails(row);
    toggleModalClassic();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var rows = reader.result.split(`\r\n`);
      // console.log("ROWS")
      // console.log(rows)
      for (var i = 0; i < rows.length; i++) {
        // console.log("ITERATION ", i)
        var columns = rows[i].split(",");
        // console.log("COLUMNS")
        // console.log(columns)
        if (i == 0) {
          if (
            columns[0] != "Title" &&
            columns[1] != "Description" &&
            columns[2] != "Compliance Standard" &&
            columns[3] != "Type" &&
            columns[4] != "Internet Resource" &&
            columns[5] != "Internal Links"
          ) {
            colMissmatchAlert();
            break;
          }
        } else {
          SetData((prev) => {
            return [
              ...prev,
              {
                name: columns[0],
                desc: columns[1],
                compliance_started: columns[2],
                type: columns[3],
                internet_resource: columns[4],
                internal_links: columns[5],
              },
            ];
          });
        }
      }
    };
    reader.readAsText(files[0]);
  };

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              <div className="bg-info rounded d-flex flex-wrap align-items-center justify-content-between ">
                <div className="text-left">
                  <Link
                    className="btn btn-info btn-sm"
                    to="/user/create-reference"
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    {"Create New Reference"}
                  </Link>
                </div>
                <div className="text-right table-top-right-buttons">
                  {selectedFlatRows.length > 0
                    ? // <Button
                      //   color="danger"
                      //   size="sm"
                      //   onClick={() => successDelete()}
                      // >
                      //   Delete
                      // </Button>
                      null
                    : null}
                  <ReactFileReader
                    fileTypes={[".csv"]}
                    handleFiles={handleFiles}
                  >
                    <button className="btn btn-info btn-sm">Import CSV</button>
                  </ReactFileReader>
                  <button
                    onClick={() => {
                      exportData("csv", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export CSV
                  </button>
                  <button
                    onClick={() => {
                      exportData("pdf", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export PDF
                  </button>{" "}
                </div>
              </div>

              <div className="row p-3 align-items-center">
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-between">
                    Show
                    <select
                      value={pageSize}
                      className="form-control"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    Entries
                  </div>
                </div>
                <div className="col-md-7"></div>
                <div className="col-md-3 text-right">
                  <div className="form-group">
                    {/* <label htmlFor="">Search</label> */}

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div>
              {/* <button onClick={resetResizing}>Reset Resizing</button> */}
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                        <th
                          className="border border-left-0 border-right "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}

                                  // style={{
                                  //     padding: '10px',
                                  //     // border: 'solid 1px gray',
                                  //     // background: 'papayawhip',
                                  // }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}

                          <td>
                            <div className="d-flex flex-wrap justify-content-around">
                              <i
                                className="fas fa-eye text-info"
                                onClick={() => setRow(row)}
                                style={{ cursor: "pointer" }}
                              ></i>
                              <i
                                className="fas fa-edit text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  SetCurrentEditReferenceId(row.original.id);
                                  setTitle(row.original.name);
                                  setType(row.original.type);
                                  setComplianceStandard(
                                    row.original.compliance_standard
                                  );
                                  setInternetResource(
                                    row.original.internet_resource
                                  );
                                  setDescription(row.original.desc);
                                  setInternalLinks(row.original.internal_links);
                                  setRowForEdit(row);
                                }}
                              ></i>
                              <i
                                className="fas fa-times text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  confirmDelete(row.original.id);
                                  // DeleteReference(row.original.id)
                                }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>

                <div className="">
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  {/* <button className="btn btn-info  btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button> */}
                  <span>
                    {pageOptions.map((page) => (
                      <button
                        className={
                          pageIndex === page
                            ? "btn border-info border btn-info  bg-transparent text-info "
                            : "btn border-info border btn-info btn-sm bg-transparent text-info"
                        }
                        onClick={() => {
                          const pageNumber = page;
                          gotoPage(pageNumber);
                        }}
                      >
                        {page + 1}
                      </button>
                    ))}

                    {/* <input type="number" className="" defaultValue={pageIndex + 1} onChange={(e) => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }} style={{ width: '50px' }} /> */}
                  </span>
                  {/* <button className="btn btn-info  btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>Next</button> */}
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        {/* ------------------------------- EDIT MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          {modalClassic ? (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">{rowDetails.original.name}</h4>
              </div>
              <div className="modal-body">
                <Form onSubmit={(e) => editSubmit(e)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          // defaultValue={rowDetails.original.name}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Type</Label>
                        <select
                          name=""
                          id=""
                          className="form-control w-100"
                          // defaultValue={type}
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="internal">Internal</option>
                          <option value="external">External</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          // defaultValue={rowDetails.original.desc}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Internal Resource</Label>
                        <Input
                          type="text"
                          // defaultValue={rowDetails.original.internet_resource}
                          value={internetResource}
                          onChange={(e) => setInternetResource(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Compliance Standard</Label>
                        <Input
                          type="text"
                          // defaultValue={rowDetails.original.compliance_standard}
                          value={complianceStandard}
                          onChange={(e) =>
                            setComplianceStandard(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      {/* <FormGroup>
                        <Label>Internal Links</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.internal_links}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup> */}
                      <FormGroup className="text-left">
                        <label>Internal Links</label>
                        <select
                          id=""
                          className="form-control"
                          // defaultValue={rowDetails.original.internal_links}
                          value={internalLinks}
                          onChange={(e) => setInternalLinks(e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="lorem">lorem</option>
                          <option value="demo">Demo</option>
                          <option value="demoList">Demo List</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button type="submit" color="info" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          ) : null}
        </Modal>
        {/* ---------------------------- End EDIT  Modal --------------------------- */}

        {/* ------------------------------- VIEW MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic1} size="lg" toggle={toggleModalClassic1}>
          {modalClassic1 ? (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic1}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-left">{rowDetails.original.name}</h5>
              </div>
              <div className="modal-body">
                <table className="table table-bordered table-striped table-hover">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Name</td>
                      <td>{rowDetails.original.name}</td>
                      <td className="font-weight-bold">Type</td>
                      <td>{rowDetails.original.type}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Description</td>
                      <td>{rowDetails.original.desc}</td>
                      <td className="font-weight-bold">Internet Resource</td>
                      <td>{rowDetails.original.internet_resource}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Internal Links</td>
                      <td>{rowDetails.original.internal_links}</td>
                      <td className="font-weight-bold">Compliance Standard</td>
                      <td>{rowDetails.original.compliance_standard}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-right">
                  <Button
                    color="danger"
                    type="button"
                    onClick={toggleModalClassic1}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          ) : null}
        </Modal>
        {/* ---------------------------- End VIEW  Modal --------------------------- */}
      </div>
    </>
  );
};

const mapStateToProps = ({ requirment }) => ({
  requirment,
});
export default connect(mapStateToProps, {
  GetAllReferences,
  DeleteReference,
  EditReference,
})(References);
