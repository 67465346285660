

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import { manageUserRole, ManageUser, CreateUserRoleToken, CreateUserRoleRegistration } from "store/actions/userAction";
import { InviteContributeToAuditToken } from "store/actions/auditActions";

function Register(props) {
  //   const history = useHistory();
  const { assessorCredentials, contributeCredentials, supportCredentials, userRoleToken } =
    props.user;

  const { inviteContributeToAuditToken } = props.audit;
  const { manageUserRole, ManageUser, user, CreateUserRoleToken, CreateUserRoleRegistration, InviteContributeToAuditToken } = props;


  console.log(inviteContributeToAuditToken?.email)

  // fetching token from url
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token')


  // call user role token when user join with link
  useEffect(() => {
    CreateUserRoleToken(token)
  }, [])

  // call user role token when user join with link
  useEffect(() => {
    InviteContributeToAuditToken(token)
  }, [])


  const history = useHistory();

  console.log('tokendata', userRoleToken)
  console.log('inviteContributeToAuditToken', inviteContributeToAuditToken)


  // set field from register new user role
  const [name, setName] = useState("");
  const [email, setEmail] = useState(userRoleToken?.data?.email || inviteContributeToAuditToken?.email);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(userRoleToken?.data?.role || inviteContributeToAuditToken?.role);
  const [getError, setGetError] = useState(null)


  // show pop up for user base action
  const [alert, setAlert] = React.useState(null);
  const successfullyRegisterMessage = () => {

    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Successfully Register.
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };




  // handel form from new user role and send to api "Suppport Team" : "Contributor Lead"
  const handleFormSubmit = () => {

    const data = {
      name,
      email: userRoleToken?.data?.email || inviteContributeToAuditToken?.email,
      password,
      role: userRoleToken?.data?.role || inviteContributeToAuditToken?.role,
      type: inviteContributeToAuditToken?.type || '',
      audit_id: inviteContributeToAuditToken?.audit_id || ''
      // role: userRoleToken?.data?.role == "Suppport Team" ? 2 : 1,

    }

    CreateUserRoleRegistration(data, successfullyRegisterMessage, setGetError, history)
    console.log("data is ", data)

  }
  console.log(getError)

  //   const { userData } = user;
  //   const [email, ChangeEmail] = useState("");
  //   const [password, ChangePassword] = useState("");
  //   const [invalidCrendentials, ChangeInvalidCrendentials] = useState(false);
  //   const ValidateDummyCredentials = () => {
  //     console.log("DUMMYCREDENTIALS");
  //     const data = { email: email, password: password };
  //     console.log(data);
  //     ManageUser(data, history);
  //     console.log(user);

  //   };
  //   useEffect(() => {
  //     console.log(userData);
  //     if (userData?.error) {
  //       ChangeInvalidCrendentials(true);
  //     }
  // if (userData?.data?.user?.role == 0) {
  //   window.localStorage.setItem("token", userData?.data?.token?.accessToken);
  //   window.localStorage.setItem(
  //     "currentLoginUserRole",
  //     userData?.data?.user?.role
  //   );
  //   history.push("/user/dashboard");
  // } else {
  //   console.log("NON");
  // }
  //   }, [user]);
  //   useEffect(() => {
  //     document.body.classList.toggle("login-page");
  //     return function cleanup() {
  //       document.body.classList.toggle("login-page");
  //     };
  //   });
  return (
    <>
      {alert}
      <div className="login-page">
        <Container>

          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Register</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={
                        getError && getError?.name ? true : false
                      }
                      placeholder="Name"
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);

                      }}
                      value={name}
                    />
                    <FormFeedback>
                      {getError && getError?.name}
                    </FormFeedback>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ background: "#E3E3E3" }}>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={
                        getError && getError?.email ? true : false
                      }
                      placeholder="Email..."
                      type="email"

                      // onChange={(e) => {
                      //   ChangeEmail(e.target.value);
                      //   ChangeInvalidCrendentials(false);
                      // }}
                      value={userRoleToken?.data?.email || inviteContributeToAuditToken?.email}
                      readOnly

                    />
                    <FormFeedback>
                      {getError && getError?.email}
                    </FormFeedback>
                  </InputGroup>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={
                        getError && getError?.password ? true : false
                      }
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                    <FormFeedback>
                      {getError && getError?.password}
                    </FormFeedback>
                  </InputGroup>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ background: "#E3E3E3" }}>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Role"
                      type="text"
                      readOnly
                      // onChange={(e) => {
                      //   ChangeEmail(e.target.value);
                      //   ChangeInvalidCrendentials(false);
                      // }}
                      // 
                      value={(userRoleToken?.data?.role ? userRoleToken?.data.role == 2 ? "Suppport Team" : "Contributor Lead" : '') || (inviteContributeToAuditToken?.role ? inviteContributeToAuditToken?.role == 2 ? "Suppport Team" : "Contributor Lead" : '')}
                    // value={role}
                    />
                  </InputGroup>


                  <p className="text-danger mb-0 mt-4" style={{ height: "20px" }}>
                    {/* {invalidCrendentials && "Invalid email or password!"} */}
                  </p>

                  {/* <br /> */}
                  {/* <FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input defaultChecked defaultValue="" type="checkbox" />
                      <span className="form-check-sign" />
                      Subscribe to newsletter
                    </Label>
                  </FormGroup>
                </FormGroup> */}
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    onClick={() => handleFormSubmit()}
                  >
                    Register
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg").default
              })`,
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ user, audit }) => ({
  user, audit
});

export default connect(mapStateToProps, { manageUserRole, ManageUser, CreateUserRoleToken, InviteContributeToAuditToken, CreateUserRoleRegistration })(
  Register,

);
