import {
    GET_ALL_VERSION_DETAILS,
    CHANGE_GLOBAL_LOADER,
    GET_ALL_VERSION_DETAILS_TABLE,
    SHOW_VERSION_NAME,
    SHOW_VERSION_ITERATION,
    SHOW_SUB_REQ_DECISION,
    CLEAR_SUB_REQ_DECISION


} from "./types";

import { baseUrl } from "config/baseUrl";

// version api
//get all detail list
export const GetVersionDetails = (val) => {
    return async (dispatch) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "Application/json");
            myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
            );

            var formdata = new FormData();
            formdata.append("audit_id", val);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: true,
            });

            let getVersion = await fetch(
                `${baseUrl}/api/auditor/show-audit-version`,
                requestOptions
            ).then((response) => {
                return response.json().then((data) => ({
                    status: response.status,
                    data,
                }));
            });
            if (getVersion.status == 200) {
                dispatch({
                    type: GET_ALL_VERSION_DETAILS,
                    payload: getVersion.data.data,
                });
                console.log(getVersion);
            } else {
                console.log("ERROR");
                console.log(getVersion);
            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        }
    };
};



// get all detail table
export const GetVersionDetailsTable = (val) => {
    return async (dispatch) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "Application/json");
            myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
            );

            var formdata = new FormData();
            formdata.append("audit_id", val);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: true,
            });

            let getVersion = await fetch(
                `${baseUrl}/api/auditor/show-version-per`,
                requestOptions
            ).then((response) => {
                return response.json().then((data) => ({
                    status: response.status,
                    data,
                }));
            });
            if (getVersion.status == 200) {
                dispatch({
                    type: GET_ALL_VERSION_DETAILS_TABLE,
                    payload: getVersion.data.data,
                });
                console.log(getVersion);
            } else {
                console.log("ERROR");
                console.log(getVersion);
            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        }
    };
};


// Show version name
export const ShowVersionName = (val) => {

    return async (dispatch) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Accept", "Application/json");
            myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
            );

            var formdata = new FormData();
            formdata.append("audit_id", val);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: true,
            });
            let version = await fetch(
                `${baseUrl}/api/auditor/show-version-names`,
                requestOptions
            ).then((response) => {
                return response.json().then((data) => ({
                    status: response.status,
                    data,
                }));
            });
            if (version.status == 200) {
                dispatch({
                    type: SHOW_VERSION_NAME,
                    payload: version.data.data,
                });

                console.log(version);
            } else {
                console.log("ERROR");

            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });


        } catch (error) {
            console.log(error)
            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        }
    }
}


// Show version iteration
export const ShowVersionIteration = (val) => {

    return async (dispatch) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Accept", "Application/json");
            myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
            );

            var formdata = new FormData();
            formdata.append("audit_requirement_id", val);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: true,
            });
            let version = await fetch(
                `${baseUrl}/api/auditor/show-version-iteration`,
                requestOptions
            ).then((response) => {
                return response.json().then((data) => ({
                    status: response.status,
                    data,
                }));
            });
            if (version.status == 200) {
                dispatch({
                    type: SHOW_VERSION_ITERATION,
                    payload: version.data.data,
                });

                console.log(version);
            } else {
                console.log("ERROR");

            }

            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });


        } catch (error) {
            console.log(error)
            dispatch({
                type: CHANGE_GLOBAL_LOADER,
                payload: false,
            });
        }
    }
}


// Show version req decition
export const CheckSubReqDecision = (val) => {

    return async (dispatch) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Accept", "Application/json");
            myHeaders.append(
                "Authorization",
                `Bearer ${window.localStorage.getItem("token")}`
            );

            var formdata = new FormData();
            formdata.append("audit_requirement_id", val.audit_requirement_id);
            formdata.append("sub_requirement_id", val.sub_requirement_id);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            }
            let version = await fetch(
                `${baseUrl}/api/contribute_lead/show-sub-requirement-status`,
                requestOptions
            ).then((response) => {
                return response.json().then((data) => ({
                    status: response.status,
                    data,
                }));
            });
            if (version.status == 200) {
                dispatch({
                    type: SHOW_SUB_REQ_DECISION,
                    payload: version.data.data,
                });


                console.log(version);
            } else {
                console.log("ERROR");


            }


        } catch (error) {
            console.log(error)

        }
    }
}

export const ClearShowVersionDec = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_SUB_REQ_DECISION });
    };
};