import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import avatar from "../../assets/img/faces/default-avatar.png";
import companyAvatar from "../../assets/img/company-default.jpg";
import { Link, useHistory } from "react-router-dom";

import { styles } from "../../styling/custom";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  Form,
} from "reactstrap";
import AddAudit from "./AllAudits/AddAudit";

import {
  ChangeShowAddCompanyOrIndividualModal,
  ChangeIsEditingCompanyOrIndividual,
} from "../../store/actions/auditActions";
import AddCompanyORIndividual from "./AllCompanies/AddCompanyORIndividual";
import {
  GetAllCompanies,
  GetSingleCompany,
} from "store/actions/companyActions";
import { ChangeIsEditingCompany } from "store/actions/companyActions";
import { GetAllCountries } from "store/actions/sharedAction";
import { ChangeCurrentClickedCompanyData } from "store/actions/companyActions";
import { GetAllAuditingTemplate } from "store/actions/auditingTemplateActions";
import { GetAllSections } from "store/actions/requirmentActions";
import { GetAllRequirements } from "store/actions/requirmentActions";
import { baseUrl } from "config/baseUrl";
import moment from "moment";

const AllCompanies = (props) => {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  var month = new Array();
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  var d = new Date();
  var n = month[d.getMonth()];
  var newdate = n + "-" + year;
  const [goalsSelectValue, ChangeGoalsSelectValue] = useState("Audit");
  const [goalTitle, ChangeGoalTitle] = useState("");
  const [goalAmount, ChangeGoalAmount] = useState("");
  const [isEditing, ChangeIsEditing] = useState("");
  const [popoverOpen, ChangePopoverOpen] = useState(false);
  const [goalsEnvelopeSelectValue, ChangeGoalsEnvelopeSelectValue] =
    useState("0");
  const [addGoalModal, ChangeAddGoalModal] = useState(false);
  const [auditName, ChangeAuditName] = useState(false);
  const history = useHistory();
  const {
    showAddSectionsModal,
    showAddAuditModal,
    showAddRequirmentsModal,
    showAddCompanyOrIndividualModal,
  } = props.audit;
  const {
    ChangeShowAddCompanyOrIndividualModal,
    ChangeIsEditingCompanyOrIndividual,
    GetAllCompanies,
    ChangeIsEditingCompany,
    GetSingleCompany,
    company,
    user,
    GetAllCountries,
    ChangeCurrentClickedCompanyData,
    GetAllAuditingTemplate,
    GetAllSections,
    GetAllRequirements,
  } = props;
  const { allCompanies } = company;
  const [allCompaniesMap, setAllCompaniesMap] = useState([]);

  useEffect(() => {
    GetAllCompanies();
    GetAllCountries();
  }, []);

  useEffect(() => {
    setAllCompaniesMap(allCompanies);
  }, [allCompanies]);

  const [companiesFilter, setCompaniesFilter] = useState("");

  useEffect(() => {
    const filteredCompanies = allCompanies?.filter((company) => {
      return company?.name
        ?.toLowerCase()
        .includes(companiesFilter?.toLowerCase());
    });
    setAllCompaniesMap(filteredCompanies);
  }, [companiesFilter]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ width: "32%", marginBottom: "50px" }}>
              <FormGroup className="text-left">
                <Input
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={companiesFilter}
                  onChange={(e) => setCompaniesFilter(e.target.value)}
                />
              </FormGroup>
            </div>
            <Row>
              {window.localStorage.getItem("currentLoginUserRole") == 0 && (
                <Col lg="4" style={{ marginBottom: 10 }}>
                  <Card
                    onClick={() => {
                      ChangeShowAddCompanyOrIndividualModal(true);
                      ChangeIsEditingCompanyOrIndividual(false);
                    }}
                    className="card-pricing all-budget-cards clickable-card"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "475px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <CardHeader>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {/* <i style={{ color: "#B53326", fontSize: 16 }} class="fas fa-balance-scale"></i> */}
                            <p
                              style={{
                                marginBottom: 0,
                                marginLeft: 10,
                                fontSize: 16,
                              }}
                              className="font-weight-bold"
                            >
                              Add New
                            </p>
                          </div>
                          <hr style={{ marginBottom: 0, ...styles.smartHr }} />
                        </CardHeader>
                        <CardBody style={{ marginTop: 0 }}>
                          <div>
                            <img
                              style={{ height: 200, width: "100%" }}
                              src={companyAvatar}
                              alt="company"
                            />
                          </div>

                          <div
                            style={{
                              height: 180,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                height: 80,
                                width: 80,
                                borderRadius: 80,
                                backgroundColor: "#51BCDA",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i
                                style={{ color: "white", fontSize: 30 }}
                                class="fas fa-plus"
                              ></i>
                            </div>
                            {/* image  */}
                          </div>

                          <hr
                            style={{
                              margin: 10,
                              width: "100%",
                              ...styles.smartHr,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: 23,
                            }}
                          ></div>
                        </CardBody>
                      </div>
                    </div>
                  </Card>
                </Col>
              )}
              {allCompaniesMap?.map((company) => {
                const {
                  id,
                  name,
                  email,
                  tax_id,
                  phone_no,
                  address,
                  website,
                  logo,
                  updated_at,
                } = company;
                return (
                  <Col lg="4" style={{ marginBottom: 10 }} key={id}>
                    <Card
                      className="card-pricing all-budget-cards clickable-card"
                      style={{ cursor: "default", overflow: "hidden" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <CardHeader>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginLeft: 10,
                                  fontSize: 16,
                                }}
                                className="font-weight-bold"
                              >
                                {name}
                              </p>
                            </div>
                            <hr style={styles.smartHr} />
                          </CardHeader>
                          <CardBody
                            style={{
                              marginBottom: 0,
                              cursor: "pointer",
                              overflow: "hidden",
                            }}
                            onClick={() => {
                              GetAllAuditingTemplate();
                              history.push("/user/audits");
                              ChangeCurrentClickedCompanyData(id);
                              GetAllSections();
                              GetAllRequirements();
                            }}
                          >
                            <div>
                              {logo === null ? (
                                <>
                                  <img
                                    style={{ height: 200, width: "100%" }}
                                    src={companyAvatar}
                                    alt="company"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    style={{ height: 200, width: "100%" }}
                                    src={`${baseUrl}/public/${logo}`}
                                    alt="company"
                                  />
                                  {/* <img
                                      style={{ height: 250, width: "100%" }}
                                      src="https://assets.entrepreneur.com/content/3x2/2000/20150805204041-google-company-building-corporate.jpeg" alt="" /> */}
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                height: 30,
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Tax ID
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {tax_id}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 30,
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Phone
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {phone_no}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 30,
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Email
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                  wordWrap: "break-word",
                                }}
                              >
                                {email}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 30,
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Website
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {website?.substring(0, 25) + "..."}
                              </div>
                            </div>
                            {/* <div
                              style={{
                                height: 30,
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Members
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {"4"}
                              </div>
                            </div> */}
                            {/* <div className="left-to-right-flex">
                              <img
                                className="avatar-image-small"
                                src={avatar}
                                alt="Avatar"
                              />
                              <img
                                className="avatar-image-small"
                                src={avatar}
                                alt="Avatar"
                              />
                              <img
                                className="avatar-image-small"
                                src={avatar}
                                alt="Avatar"
                              />
                              <img
                                className="avatar-image-small"
                                src={avatar}
                                alt="Avatar"
                              />
                            </div> */}
                            <hr style={styles.smartHr} />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {/* <i class="fas fa-redo"></i> */}
                              {/* <span style={{ marginLeft: 5 }}>Updated Now</span> */}
                              <span style={{ marginLeft: 5 }}>
                                Last Update : {moment(updated_at)?.fromNow()}
                              </span>
                            </div>
                          </CardBody>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          ChangeIsEditingCompany(true);
                          GetSingleCompany(id, true);
                          // ChangeShowAddCompanyOrIndividualModal(true);
                          // ChangeIsEditingCompanyOrIndividual(true);
                        }}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 10,
                          right: 10,
                        }}
                      >
                        <i class="fas fa-edit"></i>
                      </div>
                    </Card>
                  </Col>
                );
              })}
              {/* <Col lg="4" style={{ marginBottom: 10 }}>
                <Card
                  className="card-pricing all-budget-cards clickable-card"
                  style={{ cursor: "default" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                      <CardHeader>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: 0,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                            className="font-weight-bold"
                          >
                            {"NASA"}
                          </p>
                        </div>
                        <hr style={styles.smartHr} />
                      </CardHeader>
                      <CardBody
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/audits");
                        }}
                      >
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Tax ID
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"BD18748541284"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Phone
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"03379912175"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Email
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"mk1411997@gmail.com"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Members
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"4"}
                          </div>
                        </div>
                        <div className="left-to-right-flex">
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                        </div>
                        <hr style={styles.smartHr} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <i class="fas fa-redo"></i>
                          <span style={{ marginLeft: 5 }}>Updated Now</span>
                        </div>
                      </CardBody>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      ChangeShowAddCompanyOrIndividualModal(true);
                      ChangeIsEditingCompanyOrIndividual(true);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </div>
                </Card>
              </Col>
              <Col lg="4" style={{ marginBottom: 10 }}>
                <Card
                  className="card-pricing all-budget-cards clickable-card"
                  style={{ cursor: "default" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                      <CardHeader>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: 0,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                            className="font-weight-bold"
                          >
                            {"Space X"}
                          </p>
                        </div>
                        <hr style={styles.smartHr} />
                      </CardHeader>
                      <CardBody
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/audits");
                        }}
                      >
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Tax ID
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"BD18748541284"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Phone
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"03379912175"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Email
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"mk1411997@gmail.com"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Members
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"4"}
                          </div>
                        </div>
                        <div className="left-to-right-flex">
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                        </div>
                        <hr style={styles.smartHr} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <i class="fas fa-redo"></i>
                          <span style={{ marginLeft: 5 }}>Updated Now</span>
                        </div>
                      </CardBody>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      ChangeShowAddCompanyOrIndividualModal(true);
                      ChangeIsEditingCompanyOrIndividual(true);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </div>
                </Card>
              </Col>
              <Col lg="4" style={{ marginBottom: 10 }}>
                <Card
                  className="card-pricing all-budget-cards clickable-card"
                  style={{ cursor: "default" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                      <CardHeader>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: 0,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                            className="font-weight-bold"
                          >
                            {"Marvel"}
                          </p>
                        </div>
                        <hr style={styles.smartHr} />
                      </CardHeader>
                      <CardBody
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/audits");
                        }}
                      >
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Tax ID
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"BD18748541284"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Phone
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"03379912175"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Email
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"mk1411997@gmail.com"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Members
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"4"}
                          </div>
                        </div>
                        <div className="left-to-right-flex">
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                        </div>
                        <hr style={styles.smartHr} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <i class="fas fa-redo"></i>
                          <span style={{ marginLeft: 5 }}>Updated Now</span>
                        </div>
                      </CardBody>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      ChangeShowAddCompanyOrIndividualModal(true);
                      ChangeIsEditingCompanyOrIndividual(true);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </div>
                </Card>
              </Col>
              <Col lg="4" style={{ marginBottom: 10 }}>
                <Card
                  className="card-pricing all-budget-cards clickable-card"
                  style={{ cursor: "default" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                      <CardHeader>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: 0,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                            className="font-weight-bold"
                          >
                            {"TESLA"}
                          </p>
                        </div>
                        <hr style={styles.smartHr} />
                      </CardHeader>
                      <CardBody
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/audits");
                        }}
                      >
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Tax ID
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"BD18748541284"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Phone
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"03379912175"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Email
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"mk1411997@gmail.com"}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 30,
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            Members
                          </div>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {"4"}
                          </div>
                        </div>
                        <div className="left-to-right-flex">
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                          <img
                            className="avatar-image-small"
                            src={avatar}
                            alt="Avatar"
                          />
                        </div>
                        <hr style={styles.smartHr} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <i class="fas fa-redo"></i>
                          <span style={{ marginLeft: 5 }}>Updated Now</span>
                        </div>
                      </CardBody>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      ChangeShowAddCompanyOrIndividualModal(true);
                      ChangeIsEditingCompanyOrIndividual(true);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Modal
          isOpen={showAddCompanyOrIndividualModal}
          size="xl"
          toggle={() => {
            ChangeShowAddCompanyOrIndividualModal(false);
          }}
        >
          <AddCompanyORIndividual />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit, user, company }) => ({
  shared,
  styling,
  audit,
  user,
  company,
});

export default connect(mapStateToProps, {
  ChangeShowAddCompanyOrIndividualModal,
  ChangeIsEditingCompanyOrIndividual,
  GetAllCompanies,
  GetSingleCompany,
  ChangeIsEditingCompany,
  ChangeCurrentClickedCompanyData,
  GetAllCountries,
  GetAllAuditingTemplate,
  GetAllSections,
  GetAllRequirements,
})(AllCompanies);
