import { InviteContributeToAudit } from "store/actions/auditActions";
import { v4 } from "uuid";
import {
  CHANGE_SHOW_ADD_SECTIONS_MODAL,
  CHANGE_SHOW_ADD_AUDIT_MODAL,
  CHANGE_SHOW_ADD_REQUIRMENTS_MODAL,
  CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
  CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL,
  TOGGLE_COLLAPSED_SECTIONS,
  ADD_SECTIONS_FOR_CREATING_AUDIT,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT,
  CLEAR_AUDIT_SECTION_AND_REQUIREMENT,
  CHANGE_CREATE_AUDIT,
  CHANGE_EDIT_AUDIT,
  CHANGE_GET_ALL_AUDIT,
  CHANGE_GET_SINGLE_AUDIT,
  SET_AUDIT_NAME_FOR_SIDEBAR,
  GET_ALL_CONTRIBUTE_LEAD,
  CHANGE_EDIT_AUDIT_DATA,
  EMPTY_SINGLE_AUDIT_DATA,
  GET_VERSION_GRAPH,
  GET_SUMMARY_DETAILS,
  GET_CONTRIBUTE_TASK_NOTIFICATION,
  GET_AUDITOR_TASK_NOTIFICATION,
  GET_DISCUSSION_DATA,
  GET_TOTAL_COMMENT,
  CREATE_DISCUSSION_DATA,
  DELETE_COMMENT,
  GET_ALL_MAIN_REQ_LIST,
  ASSIGN_AUDIT_TO_CONTRIBUTE,
  INVITE_CONTRIBUTE_TO_AUDIT,
  INVITE_CONTRIBUTE_TO_AUDIT_TOKEN,
  INVITE_MULTI_SUPPORT_TO_AUDIT


} from "../actions/types";

const initialState = {
  showAddSectionsModal: false,
  showAddAuditModal: false,
  showAddRequirmentsModal: false,
  showAddCompanyOrIndividualModal: false,
  isEditingCompanyOrIndividual: false,
  addSectionsForCreatingAudit: [],
  addRequirementsForCreatingAudit: [],
  allAudit: [],
  singleAudit: null,
  createAudit: null,
  editAudit: null,
  allContributeLead: null,
  auditNameForSidebar: "",
  editAuditData: null,
  versionGraph: null,
  summaryDetails: null,
  getAuditorTaskNotification: null,
  getContributeTaskNotification: null,
  getCommentCount: null,
  deleteComment: null,
  getAllMainReqList: [],
  assignAuditToContribute: null,
  inviteContributeToAuditToken: null,
  assignMultiSupport: null,
  auditVersions: [
    {
      version: "1.0",
      compliance: "15%",
      startDate: new Date(1995, 1, 1),
      endDate: new Date(1997, 1, 1),
    },
    {
      version: "2.0",
      compliance: "25%",
      startDate: new Date(1997, 1, 1),
      endDate: new Date(2005, 1, 1),
    },
    {
      version: "3.0",
      compliance: "56%",
      startDate: new Date(2005, 1, 1),
      endDate: new Date(2012, 1, 1),
    },
    {
      version: "4.0",
      compliance: "72%",
      startDate: new Date(2012, 1, 1),
      endDate: new Date(2015, 1, 1),
    },
    {
      version: "5.0",
      compliance: "78%",
      startDate: new Date(2015, 1, 1),
      endDate: new Date(2018, 1, 1),
    },
    {
      version: "6.0",
      compliance: "82%",
      startDate: new Date(2019, 1, 1),
      endDate: new Date(2021, 1, 1),
    },
  ],

  auditVersionsWithRequirementSections: [
    {
      label: "1.0",
      compliance: "15%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
    {
      label: "2.0",
      compliance: "25%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
    {
      label: "3.0",
      compliance: "45%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
    {
      label: "4.0",
      compliance: "55%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
    {
      label: "5.0",
      compliance: "75%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
    {
      label: "6.0",
      compliance: "85%",
      versionRequirementSections: [
        {
          id: v4(),
          name: "Section 1",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 2",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
        {
          id: v4(),
          name: "Section 3",
          requirements: [
            {
              name: "Requirement 1",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 2",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 3",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 4",
              id: v4(),
              isCompliant: false,
            },
            {
              name: "Requirement 5",
              id: v4(),
              isCompliant: true,
            },
            {
              name: "Requirement 6",
              id: v4(),
              isCompliant: true,
            },
          ],
        },
      ],
    },
  ],

  collapsedSections: [],
  versionRequirementSections: [
    {
      id: v4(),
      name: "Section 1",
      requirements: [
        {
          name: "Requirement 1",
          id: v4(),
          isCompliant: true,
        },
        {
          name: "Requirement 2",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 3",
          id: v4(),
          isCompliant: true,
        },
      ],
    },
    {
      id: v4(),
      name: "Section 2",
      requirements: [
        {
          name: "Requirement 1",
          id: v4(),
          isCompliant: true,
        },
        {
          name: "Requirement 2",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 3",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 4",
          id: v4(),
          isCompliant: true,
        },
      ],
    },
    {
      id: v4(),
      name: "Section 3",
      requirements: [
        {
          name: "Requirement 1",
          id: v4(),
          isCompliant: true,
        },
        {
          name: "Requirement 2",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 3",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 4",
          id: v4(),
          isCompliant: false,
        },
        {
          name: "Requirement 5",
          id: v4(),
          isCompliant: true,
        },
        {
          name: "Requirement 6",
          id: v4(),
          isCompliant: true,
        },
      ],
    },
  ],

  discussion: [
    // {
    //   image: require("../../assets/img/animePics/saitama.png").default,
    //   name: "Saitama",
    //   role: "Assessor",
    //   date: new Date(2020, 1, 1),
    //   comment:
    //     "Quantum mechanics is a fundamental theory in physics that provides a description of the physical properties of nature at the scale of atoms and subatomic particles. It is the foundation of all quantum physics including quantum chemistry, quantum field theory, quantum technology, and quantum information science.",
    // },
    // {
    //   image: require("../../assets/img/animePics/anime2.jpg").default,
    //   name: "Genos",
    //   role: "Contribute Lead",
    //   date: new Date(2020, 1, 1),
    //   comment:
    //     "Quantum mechanics is a fundamental theory in physics that provides a description of the physical properties of nature at the scale of atoms and subatomic particles. It is the foundation of all quantum physics including quantum chemistry, quantum field theory, quantum technology, and quantum information science.",
    // },
    // {
    //   image: require("../../assets/img/animePics/anime4.jpg").default,
    //   name: "Zero Two",
    //   role: "Support Team",
    //   date: new Date(2020, 1, 1),
    //   comment:
    //     "Quantum mechanics is a fundamental theory in physics that provides a description of the physical properties of nature at the scale of atoms and subatomic particles. It is the foundation of all quantum physics including quantum chemistry, quantum field theory, quantum technology, and quantum information science.",
    // },
  ],
  createDiscussion: []
};

export default (state = initialState, { type, payload }) => {
  console.log("TYPE::");
  console.log(type);
  console.log("PAYLOAD::");
  console.log(payload);
  switch (type) {
    case TOGGLE_COLLAPSED_SECTIONS:
      return {
        ...state,
        collapsedSections: state.collapsedSections.includes(payload)
          ? state.collapsedSections.filter((id) => id != payload)
          : [payload, ...state.collapsedSections],
      };
    case CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL:
      return {
        ...state,
        showAddCompanyOrIndividualModal: payload,
      };

    case CHANGE_GET_SINGLE_AUDIT:
      return {
        ...state,
        singleAudit: payload,
      };
    case CHANGE_CREATE_AUDIT:
      return {
        ...state,
        createAudit: payload,
      };
    case CHANGE_EDIT_AUDIT:
      return {
        ...state,
        editAudit: payload,
      };
    case ADD_SECTIONS_FOR_CREATING_AUDIT:
      return {
        ...state,
        addSectionsForCreatingAudit: payload,
      };
    case ADD_REQUIREMENTS_FOR_CREATING_AUDIT:
      return {
        ...state,
        addRequirementsForCreatingAudit: payload,
      };
    case CLEAR_AUDIT_SECTION_AND_REQUIREMENT:
      return {
        ...state,
        addRequirementsForCreatingAudit: [],
        addSectionsForCreatingAudit: [],
      };
    case CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL:
      return {
        ...state,
        isEditingCompanyOrIndividual: payload,
      };
    case CHANGE_GET_ALL_AUDIT:
      return {
        ...state,
        allAudit: payload,
      };
    case CHANGE_SHOW_ADD_REQUIRMENTS_MODAL:
      return {
        ...state,
        showAddRequirmentsModal: payload,
      };
    case EMPTY_SINGLE_AUDIT_DATA:
      return {
        ...state,
        singleAudit: null,
      };
    case CHANGE_SHOW_ADD_REQUIRMENTS_MODAL:
      return {
        ...state,
        showAddRequirmentsModal: payload,
      };
    case CHANGE_SHOW_ADD_AUDIT_MODAL:
      return payload === false
        ? {
          ...state,
          showAddAuditModal: payload,
          addSectionsForCreatingAudit: [],
          addRequirementsForCreatingAudit: [],
        }
        : {
          ...state,
          showAddAuditModal: payload,
        };
    case CHANGE_SHOW_ADD_SECTIONS_MODAL:
      return {
        ...state,
        showAddSectionsModal: payload,
      };
    case SET_AUDIT_NAME_FOR_SIDEBAR:
      return {
        ...state,
        auditNameForSidebar: payload,
      };

    case GET_ALL_CONTRIBUTE_LEAD:
      // const contributeLead = payload.filter((user) => user.role === "1");
      return {
        ...state,
        allContributeLead: payload,
      };
    // FIXME: Edit Audit (2nd method)
    case CHANGE_EDIT_AUDIT_DATA:
      const sectionsForEditAudit = payload?.sections.map(
        (section) => section.section_id
      );
      const requirementsForEditAudit = payload?.requirements.map(
        (requirement) => requirement.requirement_id
      );
      return {
        ...state,
        addSectionsForCreatingAudit: sectionsForEditAudit,
        addRequirementsForCreatingAudit: requirementsForEditAudit,
        editAuditData: payload,
      };

    case GET_VERSION_GRAPH:
      return {
        ...state,
        versionGraph: payload,
      }
    case GET_SUMMARY_DETAILS:
      return {
        ...state,
        summaryDetails: payload,
      }
    case GET_AUDITOR_TASK_NOTIFICATION:
      return {
        ...state,
        getAuditorTaskNotification: payload,
      }
    case GET_CONTRIBUTE_TASK_NOTIFICATION:
      return {
        ...state,
        getContributeTaskNotification: payload,
      }


    case CREATE_DISCUSSION_DATA:

      return {
        ...state,
        createDiscussion: payload,
      }
    case GET_DISCUSSION_DATA:
      const data = payload?.map((data) => ({
        id: data.id,
        comment: data?.message,
        date: data?.created_at,
        name: data?.user?.name,
        image: data?.user?.image,
        role: data?.user?.role === "0" ? "Assessor" : data?.user?.role === "1" ? "Contribute Lead" : "Support Team",
      }))
      return {
        ...state,
        discussion: data,
      }
    case GET_TOTAL_COMMENT:
      return {
        ...state,
        getCommentCount: payload,
      }

    case DELETE_COMMENT:
      return {
        ...state,
        deleteComment: payload,
      }

    case GET_ALL_MAIN_REQ_LIST:

      return {
        ...state,
        getAllMainReqList: payload,
      }

    case ASSIGN_AUDIT_TO_CONTRIBUTE:

      return {
        ...state,
        assignAuditToContribute: payload,
      }

    case INVITE_CONTRIBUTE_TO_AUDIT:

      return {
        ...state,
        InviteContributeToAudit: payload,
      }

    case INVITE_CONTRIBUTE_TO_AUDIT_TOKEN:

      return {
        ...state,
        inviteContributeToAuditToken: payload,
      }

    case INVITE_MULTI_SUPPORT_TO_AUDIT:

      return {
        ...state,
        assignMultiSupport: payload,
      }



    default:
      return state;
  }
};
