import {
  MANAGE_CURRENT_LOGGED_IN_USER_ROLE,
  MANAGE_USER,
  CHANGE_SHOW_ADD_NEW_USER_MODAL,
  CHANGE_CREATE_USER_ROLE,
  CHANGE_GET_ALL_USER_ROLE,
  CHANGE_GLOBAL_LOADER,
  TOKEN_VIRIFICATION_USER_ROLE,
  CREATE_USER_ROLE_REGISTER,
  GET_ALL_SUPPORT_TEAM,
  INVITE_SUPPORT_TEAM,
  SUPPORT_VERIFY_MEMBER,
  CREATE_AUDIT_MEMBER_SUPPORT,
  GET_SUPPORT_USER,
  GET_CONTRIBUTE_USER,
  GET_USER_DATA,
  EDIT_USER_DATA
} from "./types";
import { baseUrl } from "config/baseUrl";
import { messageHandler } from "utils/popupMessage";
import { toast } from "react-toastify";

export const ChangeShowAddNewUserModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_NEW_USER_MODAL, payload: val });
  };
};

export const manageUserRole = (role) => {
  return async (dispatch) => {
    dispatch({ type: MANAGE_CURRENT_LOGGED_IN_USER_ROLE, payload: role });
  };
};

export const ManageUser = (val, history) => {
  console.log(val);
  return async (dispatch) => {
    if (val == null) {
      return dispatch({ type: MANAGE_USER, payload: null });
    }
    try {
      var myHeaders = new Headers()
      myHeaders.append('Access-Control-Allow-Origin', true)

      var formdata = new FormData();
      formdata.append("email", val.email);
      formdata.append("password", val.password);

      var requestOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let user = await fetch(`${baseUrl}/api/login`, requestOptions).then(
        (response) => {
          return response.json().then((data) => ({
            status: response.status,
            data,
          }));
        }
      );
      if (user.status == 200) {
        dispatch({ type: MANAGE_USER, payload: user.data });
        window.localStorage.setItem("user", JSON.stringify(user.data.data.user));
        window.localStorage.setItem(
          "token",
          user.data?.data?.token?.accessToken
        );
        window.localStorage.setItem(
          "currentLoginUserRole",
          user.data?.data?.user?.role
        );
        // if (user.data?.data?.user?.role == 0) {
        // localStorage.setItem('user_data', JSON.stringify(user.data))
        if (user.data?.data?.user?.role == 2) {
          history.push("/user/audits");
        } else {
          history.push("/user/dashboard")
        }
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
        // } else if (user.data?.data?.user?.role == 1) {
        // history.push("/user/audits");
        // } else {
        //   console.log("NONONNNNNNNN");
        // }
      } else {
        dispatch({
          type: MANAGE_USER,
          payload: { error: "Invalid Credentials" },
        });
        console.log("ERROR");
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// FIXME: Change URL or other Stuff

// CREATE USER ROLE

export const CreateUserRole = (val, pop) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("email", val.email);
      formdata.append("role", val.role);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let user = await fetch(
        `${baseUrl}/api/user-invitation`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {
        dispatch({
          type: CHANGE_CREATE_USER_ROLE,
          payload: user.data,
        });
        console.log(user);

        pop();
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

//user token verification

export const CreateUserRoleToken = (token) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("token", token);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let user = await fetch(`${baseUrl}/api/get-token`, requestOptions).then(
        (response) => {
          return response.json().then((data) => ({
            status: response.status,
            data,
          }));
        }
      );
      if (user.status == 200) {
        dispatch({
          type: TOKEN_VIRIFICATION_USER_ROLE,
          payload: user.data,
        });
        console.log(user);
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

//create new user role register

export const CreateUserRoleRegistration = (val, fnMsg, setGetError, history) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      // myHeaders.append("Accept", "Application/json");
      // myHeaders.append(
      //   "Authorization",
      //   `Bearer ${window.localStorage.getItem("token")}`
      // );
      myHeaders.append('Access-Control-Allow-Origin', true)

      var formdata = new FormData();
      formdata.append("name", val.name);
      formdata.append("email", val.email);
      formdata.append("password", val.password);
      formdata.append("role", val.role);
      formdata.append("audit_id", val.audit_id);
      formdata.append("type", val.type);


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let user = await fetch(`${baseUrl}/api/sign-up`, requestOptions).then(
        (response) => {
          return response.json().then((data) => ({
            status: response.status,
            data,
          }));
        }
      );
      if (user.status == 200) {
        dispatch({
          type: CREATE_USER_ROLE_REGISTER,
          payload: user.data,
        });
        window.localStorage.setItem("user", JSON.stringify(user.data.data.user));
        window.localStorage.setItem(
          "token",
          user.data?.data?.token
        );
        window.localStorage.setItem(
          "currentLoginUserRole",
          user.data?.data?.user?.role
        );
        // console.log(user);
        // fnMsg();
        history.push("/user/dashboard")
      } else {
        console.log("ERROR");
        if (user?.data?.success == false) {
          setGetError(user?.data?.data)
        }
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// FIXME: Change URL or other Stuff

// GET ALL USER ROLES

export const GetAllUserRole = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let userRoles = await fetch(
        `${baseUrl}/api/auditor/get-all-users`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (userRoles.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_USER_ROLE,
          payload: userRoles.data.data,
        });
        console.log(userRoles);
      } else {
        console.log("ERROR");
        console.log(userRoles);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// GET ALL USER ROLES

export const GetAlLSupportTeam = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allSupportTeam = await fetch(
        `${baseUrl}/api/auditor/get-all-users`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allSupportTeam.status == 200) {
        dispatch({
          type: GET_ALL_SUPPORT_TEAM,
          payload: allSupportTeam.data.data,
        });

        console.log(allSupportTeam);
      } else {
        console.log("ERROR");
        console.log(allSupportTeam);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};


// CREATE USER ROLE

export const SupportTeamInvite = (val, setAlert) => {

  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("user_ids", JSON.stringify(val.user_ids));
      formdata.append("audit_id", val.audit_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let supportInvite = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/contribute_lead/audit-invitation`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });

      if (supportInvite.status == 200) {

        dispatch({
          type: INVITE_SUPPORT_TEAM,
          payload: supportInvite.data,
        });
        messageHandler(setAlert, "Invitation is successfully send", "send Invitation", "success")
        console.log(supportInvite);


      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};


//verify support team verification

export const SupportTeamVerifyToken = (token, getError) => {

  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      // var formdata = new FormData();
      // formdata.append("token", token);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        // body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });


      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/verify-member?token=${token}`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });

      if (user.data.message) {
        getError(user.data.message)
      }


      if (user.status == 200) {

        dispatch({
          type: SUPPORT_VERIFY_MEMBER,
          payload: user.data,
        });
        console.log(user.data.data)
        if (user.data.data.token) {
          dispatch(CreateAuditSupportMember(user.data.data))
        }
        console.log(user);
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};


export const CreateAuditSupportMember = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );


      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("member_id", val.member_id);
      formdata.append("token", val.token);


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/create-audit-member`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {

        dispatch({
          type: CREATE_AUDIT_MEMBER_SUPPORT,
          payload: user.data.data,
        });
        console.log(user);
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};


//support user
export const GetSupportUser = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );


      var formdata = new FormData();
      formdata.append("user_id", val);



      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };



      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auditor/users-agianst-support`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {

        dispatch({
          type: GET_SUPPORT_USER,
          payload: user.data,
        });
        console.log(user);
      } else {
        console.log("ERROR");
      }


    } catch (error) {

      console.log(error);
    }
  };
};

//contribue user
export const GetContributeUser = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );


      var formdata = new FormData();
      formdata.append("user_id", val);



      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };



      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auditor/users-agianst-contribute`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {

        dispatch({
          type: GET_CONTRIBUTE_USER,
          payload: user.data.data,
        });
        console.log(user);
      } else {
        console.log("ERROR");
      }


    } catch (error) {

      console.log(error);
    }
  };
};


// Get User data for edit
export const GetUserData = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };


      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auditor/get-user`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {

        dispatch({
          type: GET_USER_DATA,
          payload: user.data.data,
        });
        console.log(user);
      } else {
        console.log("ERROR");
      }


    } catch (error) {

      console.log(error);
    }
  };
};

//update user Data
export const EditUserData = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );


      var formdata = new FormData();
      formdata.append("name", val.name);
      formdata.append("email", val.email);
      formdata.append("password", val.password);
      formdata.append('image', val.image);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };



      let user = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auditor/edit-user`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (user.status == 200) {

        dispatch({
          type: EDIT_USER_DATA,
          payload: user.data.data,
        });
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
        window.localStorage.setItem("user", JSON.stringify(user.data.data));
        toast("Edit Successfully")
        console.log(user);
      } else {
        console.log("ERROR");
      }


    } catch (error) {

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};
