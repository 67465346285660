
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import { manageUserRole, ManageUser } from "store/actions/userAction";

function Login(props) {
  const history = useHistory();
  const { assessorCredentials, contributeCredentials, supportCredentials } =
    props.user;
  const { manageUserRole, ManageUser, user } = props;
  const { userData } = user;
  const [email, ChangeEmail] = useState("");
  const [password, ChangePassword] = useState("");
  const [invalidCrendentials, ChangeInvalidCrendentials] = useState(false);
  const ValidateDummyCredentials = () => {
    console.log("DUMMYCREDENTIALS");
    const data = { email: email, password: password };
    console.log(data);
    ManageUser(data, history);
    console.log(user);
    // if (
    //   email === assessorCredentials.email &&
    //   password === assessorCredentials.password
    // ) {
    //   ChangeInvalidCrendentials(false);
    //   window.localStorage.setItem(
    //     "currentLoginUserRole",
    //     assessorCredentials.role
    //   );
    //   manageUserRole(assessorCredentials.role);
    //   history.push("/user/dashboard");
    // } else if (
    //   email === contributeCredentials.email &&
    //   password === contributeCredentials.password
    // ) {
    //   ChangeInvalidCrendentials(false);
    //   window.localStorage.setItem(
    //     "currentLoginUserRole",
    //     contributeCredentials.role
    //   );
    //   manageUserRole(contributeCredentials.role);
    //   history.push("/user/audits");
    // } else if (
    //   email === supportCredentials.email &&
    //   password === supportCredentials.password
    // ) {
    //   ChangeInvalidCrendentials(false);
    //   window.localStorage.setItem(
    //     "currentLoginUserRole",
    //     supportCredentials.role
    //   );
    //   manageUserRole(supportCredentials.role);
    //   history.push("/user/audits");
    // } else {
    //   ChangeInvalidCrendentials(true);
    // }
  };
  useEffect(() => {
    console.log(userData);
    if (userData?.error) {
      ChangeInvalidCrendentials(true);
    }
    // if (userData?.data?.user?.role == 0) {
    //   window.localStorage.setItem("token", userData?.data?.token?.accessToken);
    //   window.localStorage.setItem(
    //     "currentLoginUserRole",
    //     userData?.data?.user?.role
    //   );
    //   history.push("/user/dashboard");
    // } else {
    //   console.log("NON");
    // }
  }, [user]);
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Card className="card-login">
              <CardHeader>
                <CardHeader>
                  <h3 className="header text-center">Login</h3>
                </CardHeader>
              </CardHeader>
              <CardBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onChange={(e) => {
                      ChangeEmail(e.target.value);
                      ChangeInvalidCrendentials(false);
                    }}
                    value={email}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-key-25" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onChange={(e) => {
                      ChangePassword(e.target.value);
                      ChangeInvalidCrendentials(false);
                    }}
                    value={password}
                  />
                </InputGroup>

                <p className="text-danger mb-0 mt-4" style={{ height: "20px" }}>
                  {invalidCrendentials && "Invalid email or password!"}
                </p>

                {/* <br /> */}
                {/* <FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input defaultChecked defaultValue="" type="checkbox" />
                      <span className="form-check-sign" />
                      Subscribe to newsletter
                    </Label>
                  </FormGroup>
                </FormGroup> */}
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className="btn-round mb-3"
                  color="warning"
                  onClick={() => ValidateDummyCredentials()}
                >
                  Login
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg").default
            })`,
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { manageUserRole, ManageUser })(Login);
