import { combineReducers } from "redux";
import sharedReducer from "./sharedReducer";
import stylingReducer from "./stylingReducer";
import requirmentReducer from "./requirmentReducer";
import auditReducer from "./auditReducer";
import auditingTemplateReducer from "./auditingTemplateReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import dashboardReducer from "./dashboardReducer";
import lifeCycle from "./lifeCycleReducer";
import version from './versionReducers'

export default combineReducers({
  shared: sharedReducer,
  styling: stylingReducer,
  requirment: requirmentReducer,
  audit: auditReducer,
  auditingTemplate: auditingTemplateReducer,
  user: userReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  lifeCycle: lifeCycle,
  versionAudit: version
});
