
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import avatar from "../../assets/img/faces/default-avatar.png";
import { Link, useHistory } from 'react-router-dom'
import Switch from "react-bootstrap-switch";
import { Collapse } from "react-collapse";
import Select from "react-select";
import {
    FcAcceptDatabase,
    FcApproval,
    FcBarChart,
    FcCheckmark,
    FcDataRecovery,
    FcHighPriority,
    FcRadarPlot,
} from 'react-icons/fc'

import { styles } from '../../styling/custom';
import { GetRadarChart } from "store/actions/lifeCycleAction";
import { ClearShowVersionDec } from "../../store/actions/versionAction";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    UncontrolledPopover,
    PopoverBody,
    Modal,
    Form,
} from "reactstrap";

import {
    GetTotalComment,
    ToggleCollapsedSections,
} from '../../store/actions/auditActions'
import { GetVersionDetails, GetVersionDetailsTable, ShowVersionName, ShowVersionIteration } from '../../store/actions/versionAction'
import moment from "moment";
import VersionRequirementView from "./Versions/VersionRequirementView";
import { ShowAuditRequirementAnswer, } from "store/actions/lifeCycleAction";

const Versions = (props) => {
    const history = useHistory();
    const [versionsView, ChangeversionsView] = useState(true)
    const [versionsToExclude, ChangeVersionsToExclude] = useState(null)
    const [showRequirementModal, ChangeShowRequirementModal] = useState(false)

    const {
        auditVersions,

        // auditVersionsWithRequirementSections,
    } = props.audit

    const {
        showVersionName, showVersionIteration
        // auditVersionsWithRequirementSections,
    } = props.versionAudit
    // version with requiremnt auditVersionsRequirementSectionsAudit
    const {

        auditVersionsRequirementSectionsAudit,
        auditVersionTable
    } = props.versionAudit
    const {
        ToggleCollapsedSections,
        GetVersionDetails,
        GetVersionDetailsTable,
        ShowAuditRequirementAnswer,
        ShowVersionName,
        GetTotalComment,
        ShowVersionIteration,
        GetRadarChart,
        ClearShowVersionDec


    } = props

    // data 
    const [getCurrentRequirementData, setGetCurrentRequirementData] = useState(null);
    //console.log
    console.log("versionDetails", auditVersionsRequirementSectionsAudit)
    console.log("versionDetailsTable", auditVersionTable)
    console.log("CurrentReqDetail", getCurrentRequirementData)
    console.log(showVersionName)
    console.log(showVersionIteration)

    useEffect(() => {
        GetVersionDetails(window.localStorage.getItem("audit_life_cycle"))
        GetVersionDetailsTable(window.localStorage.getItem("audit_life_cycle"));
        ShowVersionName(window.localStorage.getItem("audit_life_cycle"));

    }, [])



    // support team data for popolate 
    const supportTeamAssignMember = auditVersionsRequirementSectionsAudit?.audit?.members?.map(
        (member) => ({
            supportAssignMemberId: member?.member_data?.id,
            supportAssignMemberName: member?.member_data?.name,
            supportAssignMemberEmail: member?.member_data?.email,
            // ...requirement.requirements
        }));

    // get array mappig to api data and render
    var auditVersionsWithRequirementSections = [];



    // auditVersionsRequirementSectionsAudit[0]?.audit?.versions?.map(
    //     (version) => auditVersionsWithRequirementSections.push({

    //         label: version?.name,
    //         compliance: version?.compliance_percentage + "%",

    //         versionRequirementSections: [{

    //             id: 1,
    //             name: "Section 1",

    //             requirements: version?.requirements?.map((req) => ({
    //                 ...req.requirement_data,
    //                 name: req?.requirement_data?.standard_ref,
    //                 auditRequirementId: req?.id,
    //                 id: req?.requirement_id,
    //                 isCompliant: req?.final_status,
    //                 checkContributeLead: req?.entity_id,
    //                 supportAssignMemberData: supportTeamAssignMember?.filter((mem) => mem.supportAssignMemberId === requirement?.support_id),
    //                 contributeLeadName: auditVersionsRequirementSectionsAudit[0]?.audit?.contribute_lead?.name,
    //                 versionId: auditVersionsRequirementSectionsAudit[0]?.version_id,
    //                 minorIteration: req?.minor_iteration,
    //                 majorIteration: req?.major_iteration,
    //             }))
    //         }]

    //     }));

    //********************************* */ testing start (passed) **************************************//
    let arr = [];

    auditVersionsRequirementSectionsAudit[0]?.audit?.versions?.map(

        (version) => {
            var a = []
            var b = []

            version?.requirements.forEach(function (req) {
                var section = req.requirement_data.section.standard_ref;
                if (!this[section]) {
                    this[section] = { section: section, id: req?.requirement_data?.id, requirements: [] };
                    a.push(this[section]);
                }
                this[section].requirements.push({
                    ...req.requirement_data,
                    name: req?.requirement_data?.standard_ref,
                    auditRequirementId: req?.id,
                    id: req?.requirement_id,
                    isCompliant: req?.final_status,
                    checkContributeLead: req?.entity_id,
                    supportAssignMemberData: supportTeamAssignMember?.filter((mem) => mem.supportAssignMemberId === requirement?.support_id),
                    contributeLeadName: auditVersionsRequirementSectionsAudit[0]?.audit?.contribute_lead?.name,
                    versionId: auditVersionsRequirementSectionsAudit[0]?.version_id,
                    minorIteration: req?.minor_iteration,
                    majorIteration: req?.major_iteration,
                });
            }, {})

            b.push({
                label: version?.name,
                compliance: version?.compliance_percentage + "%",
                versionRequirementSections: [...a]
            })

            auditVersionsWithRequirementSections.push(b)

        }

    )
    console.log(arr)


    const res = auditVersionsWithRequirementSections.flat(Infinity)
    console.log("🚀 ~ file: Versions.js:200 ~ res:", res)

    //********************************* */ testing start (End) *****************************************//


    // let make data mapping
    console.log("dataversionData", auditVersionsWithRequirementSections)


    function RenderAuditVersionsTable() {

        // return auditVersions.map((item, key) => {
        return auditVersionTable?.map((item, key) => {

            const { version_name, compliance, start_date, end_date, version_id } = item
            let complianceInt = parseInt(compliance)
            let color
            if (complianceInt >= 0 && complianceInt <= 33)
                color = "#F24234"
            else if (complianceInt > 33 && complianceInt <= 66)
                color = "#FFC261"
            else
                color = "#46A048"
            return (
                <tr key={key}>
                    <td className="text-center">{version_name}</td>
                    <td>{moment(start_date).format("Do MMM, YYYY")}</td>
                    <td>{moment(end_date).format("Do MMM, YYYY")}</td>
                    <td className="text-center">
                        <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <div id={"tooltip69420" + key} style={{ display: "flex", width: '100%', flexDirection: "row", backgroundColor: "#4CB7D955", borderRadius: 5, cursor: 'pointer' }} >
                                <div
                                    style={{
                                        display: "flex",
                                        width: `${compliance}%`,
                                        backgroundColor: color,
                                        height: 10,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                    }}
                                ></div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip69420" + key}
                                >
                                    {compliance}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </td>
                    <td className="text-right">
                        {/* <Button
                            className="btn-icon audio-verion-action-btn btn-success-border"
                            color="link"
                            id="tooltip264453216"
                            size="sm"
                            type="button"
                            onClick={() => history.push('/user/version-requirements')}
                        >
                            <FcAcceptDatabase style={{ fontSize: 20, marginRight: 5 }} />
                        </Button>{" "}
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip264453216"
                        >
                            All Requirements
                        </UncontrolledTooltip> */}
                        <Button
                            className="btn-icon audio-verion-action-btn btn-info-border"
                            color="link"
                            id="tooltip476609793"
                            size="sm"
                            type="button"
                            onClick={() => {
                                const data = {
                                    version_id: version_id,
                                    audit_id: localStorage.getItem("audit_life_cycle")
                                }
                                GetRadarChart(data)
                                history.push('/user/version-radar-chart')
                            }}
                        >
                            <FcBarChart style={{ fontSize: 20, marginRight: 5 }} />
                        </Button>{" "}
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip476609793"
                        >
                            Radar Chart
                        </UncontrolledTooltip>
                    </td>
                </tr>
            )
        })
    }
    function RenderRequirements(requirements, parentKey) {
        return requirements.map((item, key) => {
            const { name, id, isCompliant, minorIteration, majorIteration } = item
            return (
                <div
                    key={id}
                    className={`version-req-left-tab`}
                    onClick={() => {
                        ChangeShowRequirementModal(true);
                        setGetCurrentRequirementData(item);

                        const answerShowData = {
                            audit_requirement_id: item?.auditRequirementId,
                            requirement_id: item?.id,
                            sub_requirement_id: ""
                        }
                        console.log(answerShowData)
                        ShowAuditRequirementAnswer(answerShowData)
                        // ShowVersionIteration(item?.auditRequirementId);
                        const comentcount = {
                            audit_requirement_id: item?.auditRequirementId,
                            audit_id: window.localStorage.getItem("audit_life_cycle"),
                        }
                        GetTotalComment(comentcount)

                    }}
                >
                    <div className="left-flex">
                        {name}
                    </div>
                    <div className="right-flex">
                        {isCompliant == "compliant" ?
                            <>
                                <div
                                    id={"tooltip264453216-top-min" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#dd4132aa',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-sync " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-top-min" + key + parentKey}
                                >

                                    {/* Maj in Ite:1 */}
                                    Maj in Ite: {majorIteration}


                                </UncontrolledTooltip>
                                <div
                                    id={"tooltip264453216-top-maj" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#FFC261',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-redo-alt " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-top-maj" + key + parentKey}
                                >
                                    {/* Min in Ite:3 */}
                                    Min in Ite: {minorIteration}
                                </UncontrolledTooltip>
                            </>
                            :
                            <>
                                <div
                                    id={"tooltip264453216-bottom-maj" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#FFC261',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-redo-alt " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-bottom-maj" + key + parentKey}
                                >
                                    Min in Ite: {minorIteration}
                                </UncontrolledTooltip>
                                <div
                                    id={"tooltip264453216-bottom-min" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#dd4132aa',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-sync " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-bottom-min" + key + parentKey}
                                >
                                    Maj in Ite:{majorIteration}
                                </UncontrolledTooltip>
                            </>
                        }
                        {isCompliant == "compliant" &&
                            <>
                                <i id={"tooltip264453216-acc" + key + parentKey} class="fas fa-check-circle compliant-req-icon" style={{ marginLeft: 5 }}></i>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-acc" + key + parentKey}
                                >
                                    {/* Acc in Ite:4 */}
                                    {isCompliant}
                                </UncontrolledTooltip>
                            </>
                        }
                        {isCompliant == "non-compliant" &&
                            <>
                                <i id={"tooltip264453216-rej" + key + parentKey} class="fas fa-times-circle non-compliant-req-icon" style={{ marginLeft: 5 }}></i>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-rej" + key + parentKey}
                                >
                                    {/* Acc in Ite:4
                                     */}
                                    {isCompliant == "non-compliant" ? "Not Compliant" : ""}
                                </UncontrolledTooltip>
                            </>
                        }
                        {isCompliant == "partial-compliant" &&
                            <>
                                <i id={"tooltip264453216-rej" + key + parentKey} class="fa fa-adjust partial-req-icon" style={{ marginLeft: 5 }}></i>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-rej" + key + parentKey}
                                >
                                    {/* Acc in Ite:4
                                     */}
                                    {isCompliant}
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                </div>
            )
        })
    }
    function RenderVersionRequirementSections(versionRequirementSections) {
        return versionRequirementSections?.map((item, key) => {
            console.log(item)
            const { section, requirements, id } = item
            return (

                <div key={key}>
                    <div
                        className="version-left-tab-section"
                        onClick={() => ToggleCollapsedSections(id)}
                    >
                        <div className="left-flex">
                            <p style={{ marginBottom: 0, marginLeft: 10, fontSize: 16 }} className="font-weight-bold">{section}</p>
                        </div>
                        <div className="right-flex">
                            {!props.audit.collapsedSections.includes(id) ?
                                <i class="fas fa-caret-up"></i>
                                :
                                <i class="fas fa-caret-down"></i>
                            }
                        </div>
                    </div>
                    <hr style={styles.smartHr} />

                    <Collapse isOpened={!props.audit.collapsedSections.includes(id)}>
                        {RenderRequirements(requirements, key)}
                    </Collapse>
                </div>
            )
        })
    }
    function RenderAuditVersionsWithRequirementSections() {

        //   auditVersionsRequirementSectionsAudit
        return res?.filter((_, index) => index !== res.length - 1)?.map((item, key) => {
            console.log("🚀 ~ file: Versions.js:496 ~ returnres?.splice ~ item:", item)
            console.log(item)
            const { label, compliance, versionRequirementSections } = item
            if (versionsToExclude?.find(obj => obj.label == label))
                return null
            let complianceInt = parseInt(compliance)
            let color
            if (complianceInt >= 0 && complianceInt <= 33)
                color = "#F24234"
            else if (complianceInt > 33 && complianceInt <= 66)
                color = "#FFC261"
            else
                color = "#46A048"
            return (
                <Card key={key} className="audit-version-list card-pricing all-budget-cards clickable-card" >
                    <CardHeader>
                        <span className="version-heading">
                            {label}
                        </span>
                        <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <div id={"tooltip69420" + key} style={{ display: "flex", width: '100%', flexDirection: "row", backgroundColor: "#4CB7D955", borderRadius: 5, cursor: 'pointer' }} >
                                <div
                                    style={{
                                        display: "flex",
                                        width: compliance,
                                        backgroundColor: color,
                                        height: 5,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                    }}
                                ></div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip69420" + key}
                                >
                                    {compliance}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody style={{ marginBottom: 0 }}>
                        {RenderVersionRequirementSections(versionRequirementSections)}
                    </CardBody>
                </Card>
            )
        })
        console.log("🚀 ~ file: Versions.js:542 ~ returnres?.splice ~ res:", res)
        console.log("🚀 ~ file: Versions.js:542 ~ returnres?.splice ~ res:", res)
    }
    return (
        <div className="content">
            <div className="versions-switch-flex">
                {versionsView &&
                    <div className="select">
                        <Select
                            styles={{
                            }}
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Select Version To Exclude"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={versionsToExclude}
                            onChange={(value) => ChangeVersionsToExclude(value)}
                            // options={[
                            //     {
                            //         value: "",
                            //         label: "Audit Versions",
                            //         isDisabled: true,
                            //     },
                            //     { value: "1", label: "1.0" },
                            //     { value: "2", label: "2.0" },
                            //     { value: "3", label: "3.0" },
                            //     { value: "4", label: "4.0" },
                            //     { value: "5", label: "5.0" },
                            //     { value: "6", label: "6.0" },

                            // ]}

                            options={showVersionName?.map((verName, key) => ({
                                value: verName?.id,
                                label: verName?.name,
                            }))}
                        />
                    </div>
                }
                <div className="switch">
                    <Switch
                        defaultValue={versionsView}
                        onColor="info"
                        offColor="info"
                        offText="Table"
                        onText="List"
                        // labelWidth={50}
                        // handleWidth="100"
                        onChange={(elemant, state) => {
                            ChangeversionsView(state)
                        }}
                    />
                </div>
            </div>
            {!versionsView ?
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"> {auditVersionsRequirementSectionsAudit[0]?.audit?.name}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">Version</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th className="text-center">Compliance</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RenderAuditVersionsTable()}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                :
                <Card className="audit-version-lists-container">
                    {
                        auditVersionTable?.length > 0 ? (<>
                            <div style={{}}>
                                <div className="audit-version-lists-parent">
                                    {RenderAuditVersionsWithRequirementSections()}
                                </div>
                            </div>
                        </>) : (<>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: "center ", height: 'calc(100vh - 280px)', color: "gray" }}>

                                <i className="fa fa-ban" aria-hidden="true" style={{ fontSize: '40px' }}></i>
                                <h4 >No Version data found</h4>
                            </div>
                        </>)
                    }

                </Card>
            }
            <Modal Modal isOpen={showRequirementModal} size="xl" toggle={() => { ChangeShowRequirementModal(false) }} >
                <div className="modal-header">
                    <button
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                        }}
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            ChangeShowRequirementModal(false)
                            ClearShowVersionDec()
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <div>
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} className="text-left"> {getCurrentRequirementData?.standard_ref}</h4>
                    </div>
                </div>
                <VersionRequirementView getCurrentRequirementData={getCurrentRequirementData} />
            </Modal>
        </div >
    );
}

const mapStateToProps = ({ shared, styling, audit, versionAudit, lifeCycle }) => ({
    shared, styling, audit, versionAudit, lifeCycle
})

export default connect(mapStateToProps, {
    ToggleCollapsedSections, GetVersionDetails, GetVersionDetailsTable, ShowAuditRequirementAnswer, ShowVersionName, ShowVersionIteration, GetTotalComment, GetRadarChart, ClearShowVersionDec
})(Versions)