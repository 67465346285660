import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { GlobalFilterDropdown } from "./GlobalFilterDropdown";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "./CheckBox";
import { GetSectionById } from "store/actions/requirmentActions";
import { connect } from "react-redux";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const ExistingSections = (props) => {
  // ---------------- SETTING UP DATATABLE START ---------------------------

  let { data: dataArray, GetSectionById } = props;
  console.log("data is is ", dataArray)
  //   let { data } = props;
  let dataMap = dataArray.map((data) => ({
    id: data.id,
    name: data.standard_ref,
    desc: data.description,
    clarification: data.clarifications,
    requirement_list: data?.req_list?.name,
  }));
  let data = React.useMemo(() => dataMap, [dataArray]);
  console.log(data);
  //   var data = React.useMemo(
  //     () => [
  //       {
  //         name: "ISO:8888",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "Active",
  //         requirement_file: "lorem",
  //       },
  //       {
  //         name: "ISO:9000",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "InActive",
  //         requirement_file: "lorem",
  //       },
  //     ],
  //     []
  //   );
  const columns = React.useMemo(
    () => [
      {
        Header: "Standard Ref.",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Clarification",
        accessor: "clarification",
      },
      {
        Header: "Requirement List",
        accessor: "requirement_list",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
    // (hooks) => {
    //     hooks.visibleColumns.push((columns) => {
    //         return [
    //             {
    //                 id: 'selection',
    //                 Header: ({ getToggleAllRowsSelectedProps }) => (
    //                     <CheckBox {...getToggleAllRowsSelectedProps()} />
    //                 ),
    //                 Cell: ({ row }) => (
    //                     <CheckBox {...row.getToggleRowSelectedProps()} />
    //                 )
    //             }, ...columns
    //         ]
    //     })
    // }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Section Deleted
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = React.useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const setRowForEdit = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  const { viewActions } = props;

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                        <th
                          className="border border-left-0 border-right "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}

                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px"
                                  }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}
                          <td>
                            {/* {console.log(row)} */}
                            <div className="d-flex flex-wrap justify-content-around">
                              <i
                                className="fas fa-edit text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  GetSectionById(row.original.id);
                                }}
                              ></i>
                            </div>
                          </td>
                          {viewActions && (
                            <td>
                              {/* {console.log(row)} */}
                              <div className="d-flex flex-wrap justify-content-around">
                                <i
                                  className="fas fa-eye text-info"
                                  onClick={() => { }}
                                  style={{ cursor: "pointer" }}
                                ></i>
                                <i
                                  className="fas fa-edit text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { }}
                                ></i>
                                <i
                                  className="fas fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    successDelete();
                                  }}
                                ></i>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                                <span>
                                    Page {' '}
                                    <strong>{pageIndex + 1} of {pageOptions.length}</strong>
                                </span>

                                <div className="">


                                    <button className="btn btn-info  btn-sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                    {/* <button className="btn btn-info  btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button> *
                                    <span>
                                        {
                                            pageOptions.map(page => (
                                                <button
                                                    className={
                                                        pageIndex === page ? ('btn border-info border btn-info  bg-transparent text-info ') : ('btn border-info border btn-info btn-sm bg-transparent text-info')
                                                    }


                                                    onClick={
                                                        () => {
                                                            const pageNumber = page
                                                            gotoPage(pageNumber)
                                                        }
                                                    }
                                                >{page + 1}</button>
                                            ))
                                        }

                                        {/* <input type="number" className="" defaultValue={pageIndex + 1} onChange={(e) => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }} style={{ width: '50px' }} /> *
                                    </span>
                                    {/* <button className="btn btn-info  btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>Next</button> *
                                    <button className="btn btn-info  btn-sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>



                            </div> */}

              {console.log(
                JSON.stringify(
                  {
                    selectedFlatRows: selectedFlatRows.map(
                      (row) => row.original
                    ),
                  },
                  null,
                  2
                )
              )}

              {/* <pre>
                                <code>
                                    {
                                        JSON.stringify(
                                            {
                                                selectedFlatRows: selectedFlatRows.map((row) => row.original),
                                            }, null, 2
                                        )
                                    }
                                </code>
                            </pre> */}
            </Card>
          </Col>
        </Row>

        {/* ------------------------------- EDIT MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          {modalClassic ? (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">{rowDetails.original.name}</h4>
              </div>
              <div className="modal-body">
                <Form onSubmit={(e) => editSubmit(e)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.name}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.desc}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Authority</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.authority}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Location</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.location}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Industry</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.industry}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Information</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails.original.information}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Effective Date</Label>
                        <Input
                          type="date"
                          defaultValue={rowDetails.original.effective_date}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Status</Label>
                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={(e) => console.log(e.target.value)}
                          defaultValue={rowDetails.original.status}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Requirement File</Label>
                        <Input
                          type="file"
                          onChange={(e) => console.log(e.target.files[0])}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button type="submit" color="info">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          ) : null}
        </Modal>
        {/* ---------------------------- End EDIT  Modal --------------------------- */}

        {/* ------------------------------- VIEW MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic1} size="lg" toggle={toggleModalClassic1}>
          {modalClassic1 ? (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic1}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-left">{rowDetails.original.name}</h5>
              </div>
              <div className="modal-body">
                <table className="table table-bordered table-striped table-hover">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Name</td>
                      <td>{rowDetails.original.name}</td>
                      <td className="font-weight-bold">Description</td>
                      <td>{rowDetails.original.desc}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Authority</td>
                      <td>{rowDetails.original.authority}</td>
                      <td className="font-weight-bold">Location</td>
                      <td>{rowDetails.original.location}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Industry</td>
                      <td>{rowDetails.original.industry}</td>
                      <td className="font-weight-bold">Information</td>
                      <td>{rowDetails.original.information}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Effective Date</td>
                      <td>{rowDetails.original.effective_date}</td>
                      <td className="font-weight-bold">Status</td>
                      <td>{rowDetails.original.status}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Requirement File</td>
                      <td>{rowDetails.original.requirement_file}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <div className="right-side">
                  <Button className="btn-link" color="info" type="button">
                    Close
                  </Button>
                </div>
              </div>
            </>
          ) : null}
        </Modal>
        {/* ---------------------------- End VIEW  Modal --------------------------- */}
      </div>
    </>
  );
};

const mapStateToProps = ({ requirment }) => ({
  requirment,
});

export default connect(mapStateToProps, { GetSectionById })(ExistingSections);
