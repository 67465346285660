import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import { GlobalFilter } from "../../tables/GlobalFilter";
import { CheckBox } from "../../tables/CheckBox";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
} from "reactstrap";

import ViewSection from "./../RequirementsListDetail/ViewSection";
import {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
  ChangeIsEditingSection,
} from "../../../store/actions/requirmentActions";
import { ChangeShowRequirementListDeailsModal } from "../../../store/actions/sharedAction";
import { ChangeShowAddAuditingTemplateModal } from "../../../store/actions/auditingTemplateActions";
import EditSection from "./../RequirementsListDetail/EditSection";
import AddSection from "./../RequirementsListDetail/AddSection";

const RequirementsListDetail = (props) => {
  const {
    showAddSectionModal,
    showEditSectionModal,
    showViewSectionModal,
    isEditingSection,
    singleRequirementList,
  } = props.requirment;
  const { showAddOriginalRequirmentModal } = props.auditingTemplate;
  const { showRequirementListDeailsModal } = props.shared;
  const {
    ChangeShowAddSectionModal,
    ChangeShowEditSectionModal,
    ChangeShowViewSectionModal,
    ChangeShowAddAuditingTemplateModal,
    ChangeIsEditingSection,
    ChangeShowRequirementListDeailsModal,
  } = props;

  const [horizontalTabs, setHorizontalTabs] =
    React.useState("requirement-list");
  const [questionType, setQuestionType] = useState("0");

  // ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      // XLSX example

      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new jsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }

  // ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

  // ---------------- SETTING UP DATATABLE START ---------------------------

  let dataMap =
    singleRequirementList &&
    singleRequirementList?.data?.sections?.map((section) => ({
      standardRef: section.standard_ref,
      desc: section.description,
      clarifications: section.clarifications,
    }));
  let data = React.useMemo(() => dataMap, [singleRequirementList]);

  // Sections Table
  // var data = React.useMemo(
  //   () => [
  //     {
  //       name: "ISO:8888",
  //       desc: "World",
  //       authority: "lorem",
  //       location: "lorem",
  //       industry: "lorem",
  //       information: "lorem",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "lorem",
  //     },
  //     {
  //       name: "ISO:9000",
  //       desc: "World",
  //       authority: "lorem",
  //       location: "lorem",
  //       industry: "lorem",
  //       information: "lorem",
  //       effective_date: "12/03/2020",
  //       status: "InActive",
  //       requirement_file: "lorem",
  //     },
  //   ],
  //   []
  // );
  const columns = React.useMemo(
    () => [
      {
        Header: "Standard Ref.",
        accessor: "standardRef", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Clarifications",
        accessor: "clarifications",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Added !
      </ReactBSAlert>
    );
  };
  const successMapped = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Mapped !
      </ReactBSAlert>
    );
  };

  const successRemoved = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Removed!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Section is removed from Requirement List
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ----------- Add Requirement Submit //

  const onSubmit = (e) => {
    e.preventDefault();
    successAlert();
  };

  const {
    name,
    location,
    authority,
    industry,
    information,
    effective_date,
    description,
    status,
  } = singleRequirementList?.data;
  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="modal-header justify-content-center">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      ChangeShowRequirementListDeailsModal(false);
                    }}
                  >
                    <i className="nc-icon nc-simple-remove" />
                  </button>
                  <h5 className="text-left">{"Requirement List Detail"}</h5>
                </div>
              </CardHeader>
              <Form onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <table className="table table-bordered table-striped table-hover">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Name</td>
                        <td>{name}</td>
                        <td className="font-weight-bold">Location</td>
                        <td>{location}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Description</td>
                        <td>{description}</td>
                        <td className="font-weight-bold">Industry</td>
                        <td>{industry}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Authority</td>
                        <td>{authority}</td>
                        <td className="font-weight-bold">Information</td>
                        <td>{information}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Effective Date</td>
                        <td>{effective_date}</td>
                        <td className="font-weight-bold">Status</td>
                        <td>{status === 1 ? "Active" : "In Active"}</td>
                      </tr>
                      <tr>
                        {singleRequirementList?.data?.extra_fields?.map(
                          (field) => (
                            <>
                              <td className="font-weight-bold">
                                {field?.field_name}
                              </td>
                              <td>{field?.field_value}</td>
                            </>
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <div className="my-4">
                    <div className="form-group flex-row">
                      <div className="flex-row-left">
                        <h4>Sections</h4>
                      </div>
                      {/* <div className="flex-row-right">
                        <Button
                          color="info"
                          onClick={() => {
                            ChangeShowEditSectionModal(true);
                            ChangeIsEditingSection(false);
                          }}
                        >
                          <i className="fas fa-plus"></i>
                          {"Add Section"}
                        </Button>
                      </div> */}
                    </div>
                    <div className="tableWrap">
                      <table
                        {...getTableProps()}
                        className="table-striped table-hover "
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr
                              {...headerGroup.getHeaderGroupProps()}
                              className="table-info text-white"
                            >
                              {headerGroup.headers.map((column) => (
                                <th
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  className="border border-left-0 border-right "
                                  style={{
                                    borderBottom: "1px solid #cacaca",
                                    background: "rgba(0, 0, 0, 0.05)",
                                    color: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {column.render("Header")}
                                  <span>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="fas fa-arrow-down  text-secondary"></i>
                                      ) : (
                                        <i className="fas fa-arrow-up text-secondary"></i>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                                </th>
                              ))}
                              {/* <th
                                className="border border-left-0 border-right "
                                style={{
                                  borderBottom: "1px solid #cacaca",
                                  background: "rgba(0, 0, 0, 0.05)",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              ></th> */}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <>
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    </>
                                  );
                                })}
                                {/* <td>
                                  <div className="d-flex flex-wrap justify-content-around">
                                    <i
                                      className="fas fa-eye text-info"
                                      onClick={() => {
                                        ChangeShowViewSectionModal(true);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                    <i
                                      className="fas fa-edit text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        ChangeShowEditSectionModal(true);
                                        ChangeIsEditingSection(true);
                                      }}
                                    ></i>
                                    <i
                                      className="fas fa-times text-danger"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        successRemoved();
                                      }}
                                    ></i>
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      color="danger"
                      onClick={() => {
                        ChangeShowRequirementListDeailsModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={showViewSectionModal}
        size="xl"
        toggle={() => {
          ChangeShowViewSectionModal(false);
        }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              ChangeShowViewSectionModal(false);
            }}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Section Detail</h4>
        </div>
        <ViewSection />
      </Modal>
      <Modal
        isOpen={showEditSectionModal}
        size="xl"
        toggle={() => {
          ChangeShowEditSectionModal(false);
        }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              ChangeShowEditSectionModal(false);
            }}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">
            {isEditingSection ? "Edit" : "Add"} Section
          </h4>
        </div>
        <EditSection />
      </Modal>
      <Modal
        isOpen={showAddSectionModal}
        size="xl"
        toggle={() => {
          ChangeShowAddSectionModal(false);
        }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              ChangeShowAddSectionModal(false);
            }}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Add Section </h4>
        </div>
        <AddSection />
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowAddSectionModal,
  ChangeShowEditSectionModal,
  ChangeShowViewSectionModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditingSection,
  ChangeShowRequirementListDeailsModal,
})(RequirementsListDetail);
