import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import img from "../../assets/img/faces/default-avatar.png";
import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import PerfectScrollbar from "perfect-scrollbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Form,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import "../../styling/life-cycle.css";
import { styles } from "styling/custom";
import moment from "moment";
import { GetSingleAudit } from "store/actions/auditActions";

import {
  SaveAnswerAudit,
  ShowAuditRequirementAnswer,
  MoveContributeLeadRequirementToReview,
  GetContributeLifeCycle,
  GetAllTask,
  ShowAuditRequirementAnswerTask,
  ClearAuditRequirementAnswerData,
  ClearAuditAnswerTaskData,
  ClearAllTaskData,
  GetVersionFiles,
  GetRadarChart,
} from "store/actions/lifeCycleAction";

import {
  CreateDiscussionData,
  GetDiscussionData,
  GetTotalComment,
  DeleteComment,
} from "store/actions/auditActions.js";
const data = {
  labels: ["Yes", "No", "Partial"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
var ps;
// var ps1;
function SupportLifeCycle(props) {
  useEffect(() => {
    const container = document.querySelector("#scroll-bar");
    // // const chatContainer = document.querySelector('#chat-parent-tab');
    ps = new PerfectScrollbar(container, {
      // useBothWheelAxes: true,
      suppressScrollX: true,
    });
    // // ps1 = new PerfectScrollbar(chatContainer, {
    // //     // useBothWheelAxes: true,
    // //     suppressScrollX: true,
    // // });

    // const modal_scroll = document.getElementById('#modal-scroll');
    // // console.log(modal_scroll)
    // // var ps = new PerfectScrollbar(modal_scroll);
  }, []);
  const [modalClassic, setModalClassic] = useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const [getSubRequirementId, setGetSubRequirementId] = useState();

  console.log("getSubReq", getSubRequirementId);

  const [getCurrentRequirementId, setGetCurrentRequirementId] = useState(null);
  const [multipleSelect, setMultipleSelect] = useState(null);
  const [repeatFrequency, setRepeatFrequency] = useState("once");
  const [singleSelect, setSingleSelect] = useState(null);
  const [auditStatus, setAuditStatus] = useState(false);
  const [assessorDecision, setAssessorDecision] = useState("");
  const [showingRequirments, ChangeShowingRequirments] = useState(true);
  const [selectedItem, ChangeSelectedItem] = useState(null);
  const [taskModal, ChangeTaskModal] = useState(false);
  const [lifeCycleTabs, ChangeLifeCycleTabs] = useState("lifecycle");
  const [requirementTabs, ChangeRequirementTabs] = useState("details");
  const [attachmentTabs, ChangeAttachmentTabs] = useState("table");
  const [showCreateTaskModal, ChangeShowCreateTaskModal] = useState(false);
  const [AssessorComment, ChangeAssessorComment] = useState(
    "The tilt of photons is not detected by the sensors sometimes in quantum chip"
  );
  const [chatComment, setChatComment] = useState("");

  const {
    SaveAnswerAudit,
    GetContributeLifeCycle,
    ShowAuditRequirementAnswer,
    MoveContributeLeadRequirementToReview,
    GetAllTask,
    ClearAllTaskData,
    ShowAuditRequirementAnswerTask,
    ClearAuditRequirementAnswerData,
    GetSingleAudit,
    GetVersionFiles,
    GetTotalComment,
    CreateDiscussionData,
    GetDiscussionData,
    DeleteComment,
    GetRadarChart,
    ClearAuditAnswerTaskData,
  } = props;
  const { discussion, getCommentCount, createDiscussion, deleteComment } =
    props.audit;
  const {
    getContributeLifeCycle,
    showAuditAnswer,
    allTask,
    showAuditAnswerTask,
    versionFiles,
    getRadarChart,
  } = props.lifeCycle;
  const { getSubRequirementById, singleSubRequirement } = props.requirment;

  console.log("One audit data", getContributeLifeCycle);
  console.log("getRequirement", getCurrentRequirementId);
  console.log("singleSubRequirement", singleSubRequirement);
  console.log("getSubRequirementById", getSubRequirementById);

  const [generalDescription, setGeneralDescription] = useState("");
  const [generalClarification, setGeneralClarification] = useState("");
  const [answered, setanswered] = useState(false)

  const [evidenceError, setEvidenceError] = useState("");

  // ------------------------ DND Stuff ----------------------------

  const [text, setText] = useState("");

  const [auditAnswer, setAuditAnswer] = useState("No");
  const [partialPercent, setPartialPercent] = useState("");
  const [partialDescription, setPartialDescription] = useState("");
  const [evidenceDetail, setEvidenceDetail] = useState("");
  const [evidenceAttachment, setEvidenceAttachment] = useState(null);
  const [contributeComment, SetContributeComment] = useState("");

  //state which is use for task status
  const [auditAnswerTask, setAuditAnswerTask] = useState("No");
  const [partialPercentTask, setPartialPercentTask] = useState();
  const [partialDescriptionTask, setPartialDescriptionTask] = useState();
  const [evidenceDetailTask, setEvidenceDetailTask] = useState();
  const [contributeCommentTask, SetContributeCommentTask] = useState();
  const [AssessorCommentTask, ChangeAssessorCommentTask] = useState(
    "The tilt of photons is not detected by the sensors sometimes in quantum chip"
  );

  const [taskId, setTaskId] = useState();
  const [taskSubRequirementId, setTaskSubRequirementId] = useState();
  const [evidenceAttachmentTask, setEvidenceAttachmentTask] = useState(null);
  const [evidenceErrorTask, setEvidenceErrorTask] = useState("");

  const [checkallrequirements, setCheckallrequirements] = useState(false);

  //pop message alert
  const [alert, setAlert] = useState(null);

  const history = useHistory();



  const supportTeamAssignMember = getContributeLifeCycle?.audit?.members?.map(
    (member) => ({
      supportAssignMemberId: member?.member_data?.id,
      supportAssignMemberName: member?.member_data?.name,
      supportAssignMemberEmail: member?.member_data?.email,
      // ...requirement.requirements
    })
  );

  const supportTeamItems = getContributeLifeCycle?.audit?.requirements?.map(
    (requirement) => ({
      ...requirement.requirement_data,
      // ...requirement.requirements,
      id: requirement.requirement_data.id,
      name: requirement.requirement_data.standard_ref,
      desc: requirement.requirement_data.description,
      task_state: "Compliant",
      section: requirement.requirement_data.section.standard_ref,
      sectionDec: requirement.requirement_data.section.description,
      auditRequirementId: requirement.id,
      auditId: getContributeLifeCycle.audit_id,
      subStatus: requirement.sub_status,
      mainStatus: getContributeLifeCycle?.main_status,
      checkContributeLead: requirement?.entity_id,
      checKSupportTeam: requirement?.support_id,
      answerId: requirement?.answer_id,
      supportAssignMemberData: supportTeamAssignMember?.filter(
        (mem) => mem.supportAssignMemberId === requirement?.support_id
      ),
      taskId: taskId,
      versionId: getContributeLifeCycle?.version_id,
      versionName: getContributeLifeCycle?.versions?.name,
      evidenceRequired: requirement?.requirement_data?.evidence,
      overallPercentage: requirement?.final_percentage,
    })
  );

  const [state, setState] = useState([]);

  useEffect(() => {
    setState([
      {
        title: "Initialization",
        items: supportTeamItems?.filter(
          (item) =>
            (item?.subStatus === 4 &&
              item?.checKSupportTeam > 0 &&
              item?.mainStatus === 3) ||
            (item?.subStatus === 5 &&
              item?.checKSupportTeam > 0 &&
              item?.mainStatus === 3) ||
            (item?.subStatus === 4 &&
              item?.checKSupportTeam > 0 &&
              item?.mainStatus === 4) ||
            (item?.subStatus === 5 && item?.checKSupportTeam > 0)
        ),
      },
      // {
      //   title: "In Response",
      //   items: [item4],
      // },
      {
        title: "In Review",
        items: supportTeamItems?.filter(
          (item) =>
            (item?.subStatus === 6 && item?.checKSupportTeam > 0) ||
            (item?.subStatus === 9 &&
              item?.mainStatus === 4 &&
              item?.checKSupportTeam > 0) ||
            (item?.subStatus === 10 &&
              item?.mainStatus === 4 &&
              item?.checKSupportTeam > 0) ||
            (item?.subStatus === 11 &&
              item?.mainStatus === 4 &&
              item?.checKSupportTeam > 0)
        ),
      },
      // {
      //   title: "Compliant",
      //   items: [item8],
      // },
    ]);

    // Minor or Major

    ChangeWholeAuditStatus(
      getContributeLifeCycle?.main_status === 3
        ? "In Response"
        : getContributeLifeCycle?.main_status === 4
        ? "Review in pending"
        : // : getContributeLifeCycle?.main_status === 4 && itemCopy?.subStatus === 4
          //   ? "Minor or Major"

          "DUMMY STATUS"
    );
  }, [getContributeLifeCycle]);

  const [checkedItemsState, setCheckedItemsState] = useState([
    {
      title: "Initialization",
      checkedItems: [],
    },
    // {
    //   title: "In Response",
    //   checkedItems: [],
    // },
    {
      title: "In Review",
      checkedItems: [],
    },
    // {
    //   title: "Compliant",
    //   checkedItems: [],
    // },
  ]);

  // useEffect for dynamic render chat
  useEffect(() => {
    const data = {
      audit_requirement_id: getCurrentRequirementId?.auditRequirementId,
      audit_id: window.localStorage.getItem("audit_life_cycle"),
    };
    GetDiscussionData(data);
  }, [createDiscussion, deleteComment]);
  //useEffect for dynamic comment count
  useEffect(() => {
    const data = {
      audit_requirement_id: getCurrentRequirementId?.auditRequirementId,
      audit_id: window.localStorage.getItem("audit_life_cycle"),
    };
    GetTotalComment(data);
  }, [createDiscussion, deleteComment]);

  useEffect(()=>{},[checkallrequirements])

  let checkReqType =
    getSubRequirementId && getCurrentRequirementId.sub_requirements.length > 0
      ? "sub_requirement"
      : "main_requirement";
  let checkReqTypeTask =
    taskSubRequirementId && getCurrentRequirementId.sub_requirements.length > 0
      ? "sub_requirement"
      : "main_requirement";

  const handelAnswerForm = () => {
    const checkDataforCreateAnswer = {
      type: checkReqType,
      version_id: getContributeLifeCycle?.version_id,
      audit_id: getContributeLifeCycle?.audit?.id,
      requirement_id: getCurrentRequirementId?.id,
      answer_status: auditAnswer?.label.toLowerCase(),
      partial_percentage: partialPercent,
      partial_description: partialDescription,
      evidence_details: evidenceDetail,
      contribute_comments: contributeComment,
      audit_requirement_id: getCurrentRequirementId?.auditRequirementId,
      sub_requirement_id: getSubRequirementId || "",
      evidence_attachment: evidenceAttachment,
      sub_status: 1,
      status: 0,
    };

    console.log("answer field", checkDataforCreateAnswer);

     SaveAnswerAudit(checkDataforCreateAnswer, setAlert);
    setGetSubRequirementId("");
    setTaskSubRequirementId("");
    // setanswered(true)
    
    setAuditAnswer("No")
    setPartialPercent("")
    setEvidenceAttachment(null)
    setEvidenceDetail("")
    SetContributeComment("")
    setanswered(false)
    
    
  };


  // handel requirement answer for task
  const handelTaskAnswerForm = () => {
    const checkDataforCreateAnswerTask = {
      type: checkReqTypeTask,
      version_id: getContributeLifeCycle?.version_id,
      audit_id: getContributeLifeCycle?.audit?.id,
      requirement_id: getCurrentRequirementId?.id,
      answer_status: auditAnswerTask?.label.toLowerCase(),
      partial_percentage: partialPercentTask,
      partial_description: partialDescriptionTask,
      evidence_details: evidenceDetailTask,
      contribute_comments: contributeCommentTask,
      audit_requirement_id: getCurrentRequirementId?.auditRequirementId,
      sub_requirement_id: taskSubRequirementId || "",
      sub_status: 1,
      evidence_attachment: evidenceAttachmentTask,
      status: 0,
      task_id: taskId,
    };

    console.log("task", checkDataforCreateAnswerTask);

    SaveAnswerAudit(checkDataforCreateAnswerTask, setAlert);
    setTaskSubRequirementId("");
    setGetSubRequirementId("");
  };

  useEffect(() => {
    GetContributeLifeCycle(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  useEffect(() => {
    setAuditAnswer(
      showAuditAnswer && showAuditAnswer?.answer_status
        ? {
            label: showAuditAnswer?.answer_status,
            value: showAuditAnswer?.id,
          }
        : "No"
    );
    setPartialDescription(
      showAuditAnswer && showAuditAnswer?.partial_description
        ? showAuditAnswer?.partial_description
        : ""
    );
    setPartialPercent(
      showAuditAnswer && showAuditAnswer?.partial_percentage
        ? showAuditAnswer?.partial_percentage
        : ""
    ),
      setEvidenceDetail(
        showAuditAnswer && showAuditAnswer?.evidence_details
          ? showAuditAnswer?.evidence_details
          : ""
      ),
      SetContributeComment(
        showAuditAnswer && showAuditAnswer?.contribute_comments && !answered
          ? showAuditAnswer?.contribute_comments
          : ""
      );
    setAssessorDecision(
      showAuditAnswer?.status === 1
        ? { label: "Accepted", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 2
        ? { label: "Rejected", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 3
        ? { label: "Minor Revision", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 4 && {
            label: "Major Revision",
            value: showAuditAnswer?.status,
          }
    );
    ChangeAssessorComment(
      showAuditAnswer?.auditor_comments ? showAuditAnswer?.auditor_comments : ""
    );
  }, [showAuditAnswer]);

  // show answer againt task
  useEffect(() => {
    setAuditAnswerTask(
      showAuditAnswerTask && showAuditAnswerTask?.answer_status
        ? {
            label: showAuditAnswerTask?.answer_status,
            value: showAuditAnswerTask?.id,
          }
        : "No"
    );
    setPartialDescriptionTask(
      showAuditAnswerTask && showAuditAnswerTask?.partial_description
        ? showAuditAnswerTask?.partial_description
        : ""
    );
    setPartialPercentTask(
      showAuditAnswerTask && showAuditAnswerTask?.partial_percentage
        ? showAuditAnswerTask?.partial_percentage
        : ""
    ),
      setEvidenceDetailTask(
        showAuditAnswerTask && showAuditAnswerTask?.evidence_details
          ? showAuditAnswerTask?.evidence_details
          : ""
      ),
      SetContributeCommentTask(
        showAuditAnswerTask && showAuditAnswerTask?.contribute_comments
          ? showAuditAnswerTask?.contribute_comments
          : ""
      );
    // setAssessorDecision(
    //   showAuditAnswerTask?.status === 1
    //     ? { label: "Accepted", value: showAuditAnswerTask?.status }
    //     : showAuditAnswerTask?.status === 2
    //       ? { label: "Rejected", value: showAuditAnswerTask?.status }
    //       : showAuditAnswerTask?.status === 3
    //         ? { label: "Minor", value: showAuditAnswerTask?.status }
    //         : showAuditAnswerTask?.status === 4 && {
    //           label: "Major",
    //           value: showAuditAnswerTask?.status,
    //         }
    // );
    ChangeAssessorCommentTask(
      showAuditAnswerTask?.auditor_comments
        ? showAuditAnswerTask?.auditor_comments
        : ""
    );
  }, [showAuditAnswerTask]);

  useEffect(() => {
    setGeneralDescription(
      getSubRequirementById?.data?.description
        ? getSubRequirementById?.data?.description
        : getCurrentRequirementId?.description
    );
    setGeneralClarification(
      getSubRequirementById?.data?.clarification
        ? getSubRequirementById?.data?.clarification
        : getCurrentRequirementId?.clarification
    );
  }, [getSubRequirementById, getSubRequirementId]);

  //get radar chart

  useEffect(() => {
    GetRadarChart(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  const [justToRender, ChangeJustToRender] = useState(false);
  const [wholeAuditStatus, ChangeWholeAuditStatus] = useState("");
  const AddItem = () => {
    setState((prev) => {
      return {
        ...prev,
        todo: {
          title: "Initialization",
          items: [
            {
              id: v4(),
              name: text,
              task_state: "Initialization",
            },
            ...prev.todo.items,
          ],
        },
      };
    });
    setText("");
  };
  function IsSectionChecked(parentKey, section) {
    var itemIdsTemp = state[parentKey].items
      .filter((obj) => {
        if (obj.section == section) return obj;
      })
      .map((item, key) => {
        return item.auditRequirementId;
      });
    let checkedItems = checkedItemsState[parentKey].checkedItems;
    return itemIdsTemp.every((v) => checkedItems.includes(v));
  }
  const HandleDragEnd = ({ destination, source }) => {
    // console.log('from', source)
    // console.log('to', destination)
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // setCheckedItemsState(prev => {
    //     prev[parseInt(source.droppableId)].checkedItems = []
    //     prev[parseInt(destination.droppableId)].checkedItems = []
    //     return prev
    // })

    var itemCopy = state[source.droppableId].items[source.index];
    state[source.droppableId].items.splice(source.index, 1);
    state[destination.droppableId].items.splice(destination.index, 0, itemCopy);

    state[destination.droppableId].items.sort((item1, item2) => {
      if (item1.section < item2.section) return -1;
      if (item1.section > item2.section) return 1;
      return 0;
    });

    if (source.droppableId === "0" && destination.droppableId === "1") {
      const data = {
        auditId: itemCopy.auditId,
        auditRequirementIds: [itemCopy.auditRequirementId],
        version_id: itemCopy?.versionId,
      };

      if (itemCopy.subStatus === 4) {
        console.log("subStatus is 2 and unable to push");
        window.location.reload();
      } else {
        MoveContributeLeadRequirementToReview(data);
      }
    }

    var itemCopy =
      checkedItemsState[source.droppableId].checkedItems[source.index];
    console.log("ITEMCOPY");
    console.log(itemCopy);
    if (itemCopy != undefined) {
      checkedItemsState[source.droppableId].checkedItems.splice(
        source.index,
        1
      );
      checkedItemsState[destination.droppableId].checkedItems.splice(
        destination.index,
        0,
        itemCopy
      );
    }
  };
  const RenderDiscussion = () => {
    return discussion.map((item, key) => {
      const { image, name, role, date, comment, id } = item;
      return (
        <div key={key}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "0px solid red",
              // alignItems: 'center'
            }}
          >
            <img
              src={image}
              className="rounded-circle"
              width="40"
              height="40"
              alt=""
            />
            <div
              style={{
                border: "0px solid red",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  border: "0px solid red",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    border: "0px solid red",
                  }}
                >
                  <div
                    className="single-line-text"
                    style={{
                      marginLeft: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#595974",
                    }}
                  >
                    {name}
                  </div>
                  <div
                    style={{
                      background: "#F4F5F7",
                      marginLeft: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 5,
                    }}
                  >
                    {role}
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    {moment(date).format("MMM Do, YYYY")}
                  </div>
                </div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  onClick={() => {
                    DeleteComment(id);
                  }}
                >
                  <i
                    style={{ fontSize: 16, cursor: "pointer" }}
                    class="fas fa-trash-alt"
                  ></i>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  border: "0px solid red",
                  padding: 10,
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                  }}
                >
                  {comment}
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };
  const RenderItems = (items, parentKey) => {
    console.log("🚀 ~ file: SupportLifeCycle.js:754 ~ RenderItems ~ items:", items)
    let previousSection = "";
    return items?.filter(f => f?.checKSupportTeam == JSON.parse(localStorage.getItem("user"))?.id)?.map((item, key) => {
      let showSectionTile = false;
      const {
        id,
        name,
        desc,
        task_state,
        section,
        auditRequirementId,
        auditId,
        sectionDec,
        subStatus,
        checKSupportTeam,
      } = item;
      if (previousSection != section) {
        previousSection = section;
        showSectionTile = true;
      }
      return (
        <>
          {alert}
          {showSectionTile && (
            <div className={"life-cycle-req-section"}>
              <Input
                checked={IsSectionChecked(parentKey, section) ? "checked" : ""}
                defaultChecked={""}
                onChange={(e) => {
                  var itemIdsTemp = [];
                  console.log("E.TARGET.CHECKED");
                  console.log(e.target.checked);
                  itemIdsTemp = state[parentKey].items
                    .filter((obj) => {
                      if (obj.section == section) return obj;
                    })
                    .map((item, key) => {
                      return item.auditRequirementId;
                    });
                  var checkedItemsStateTemp = checkedItemsState;
                  var isAdding = e.target.checked;
                  itemIdsTemp.map((item, key) => {
                    // if (key == 0) {
                    //     if (!checkedItemsStateTemp[parentKey].checkedItems.includes(item))
                    //         isAdding = true
                    //     else
                    //         isAdding = false
                    // }
                    if (isAdding) {
                      // console.log("ADDING")
                      if (
                        !checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      )
                        checkedItemsStateTemp[parentKey].checkedItems.push(
                          item
                        );
                    } else {
                      // console.log("REMOVING")
                      if (
                        checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      ) {
                        var index =
                          checkedItemsStateTemp[parentKey].checkedItems.indexOf(
                            item
                          );
                        checkedItemsStateTemp[parentKey].checkedItems.splice(
                          index,
                          1
                        );
                      }
                    }
                  });
                  // setCheckedItemsState(checkedItemsStateTemp)
                  console.log(checkedItemsState);
                  ChangeJustToRender(!justToRender);
                }}
                onDoubleClick={() => {
                  console.log("DOUBLE CLICKED");
                }}
                type="checkbox"
              />
              <span className={"section-span"}>{section}</span>
              <span>{sectionDec}</span>
            </div>
          )}
          <Draggable
            onClick={(e) => {
              // console.log("Clicked")
            }}
            draggableId={parentKey + "" + key}
            index={key}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={"item text-left"}
                // style={{ boxShadow: "none" }}
                id="insertBefore"
                onKeyDown={(event) => {
                  // ! Multi drag not working, will implement it later
                  // this.onKeyDown(event, snapshot)
                  if (snapshot.isDragging) return;
                  // // console.log("EVENT.KEYCODE")
                  // // console.log(event.keyCode)
                  if (event.keyCode !== 17) return;
                  // // we are using the event for selection
                  event.preventDefault();
                  // console.log(props)
                  // const {
                  //     task,
                  //     toggleSelection,
                  //     toggleSelectionInGroup,
                  //     multiSelectTo,
                  // } = props
                  // // console.log("TASK.ID")
                  // // console.log(task.id)
                  // if (this.wasToggleInSelectionGroupKeyUsed(event)) {
                  //     toggleSelectionInGroup(task.id);
                  //     return;
                  // }

                  // if (this.wasMultiSelectKeyUsed(event)) {
                  //     multiSelectTo(task.id);
                  //     return;
                  // }

                  // toggleSelection(task.id);
                }}
              >
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "0px solid red", paddingLeft: 35 }}
                  >
                    <Input
                      checked={
                        checkedItemsState[parentKey]?.checkedItems?.includes(
                          auditRequirementId
                        )
                          ? "checked"
                          : ""
                      }
                      onChange={(e) => {
                        var itemIdsTemp = [];
                        if (window.event.ctrlKey) {
                          itemIdsTemp = state[parentKey].items
                            .filter((obj) => {
                              if (obj.section == section) return obj;
                            })
                            .map((item, key) => {
                              return item.auditRequirementId;
                            });
                        } else {
                          itemIdsTemp.push(auditRequirementId);
                        }
                        // console.log("ITEMIDSTEMP")
                        // console.log(itemIdsTemp)
                        var checkedItemsStateTemp = checkedItemsState;
                        var isAdding;
                        itemIdsTemp.map((item, key) => {
                          if (key == 0) {
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              isAdding = true;
                            else isAdding = false;
                          }
                          if (isAdding) {
                            // console.log("ADDING")
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.push(item);
                          } else {
                            // console.log("REMOVING")
                            if (
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            ) {
                              var index =
                                checkedItemsStateTemp[
                                  parentKey
                                ].checkedItems.indexOf(item);
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.splice(index, 1);
                            }
                          }
                        });
                        // setCheckedItemsState(checkedItemsStateTemp)
                        console.log(checkedItemsState);
                        ChangeJustToRender(!justToRender);
                      }}
                      onDoubleClick={() => {
                        console.log("DOUBLE CLICKED");
                      }}
                      type="checkbox"
                    />
                    <p>{" " + name }</p>
                  </div>
                  <div className="col text-right">
                    <span
                      onClick={() => {
                        toggleModalClassic();
                        setGetCurrentRequirementId(item);
                        ClearAuditRequirementAnswerData();

                        const answerShowData = {
                          audit_requirement_id: item?.auditRequirementId,
                          requirement_id: item.id,
                          sub_requirement_id: "",
                        };
                        console.log(answerShowData);
                        ShowAuditRequirementAnswer(answerShowData);

                        const comentcount = {
                          audit_requirement_id: item?.auditRequirementId,
                          audit_id:
                            window.localStorage.getItem("audit_life_cycle"),
                        };
                        GetTotalComment(comentcount);
                      }}
                      style={{
                        border: "0px solid red",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <i class="fas fa-eye"></i>
                    </span>
                    <span
                      {...provided.dragHandleProps}
                      style={{ border: "0px solid red" }}
                    >
                      <i class="fas fa-bars"></i>
                    </span>
                  </div>
                </div>
                <div>{desc}</div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                    }}
                  >
                    {/* {section} */}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {/* {task_state === "Ready" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {task_state === "In Process" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-warning"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {task_state === "Compliant" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {task_state}
                      </>
                    )} */}

                    {subStatus === 4 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-danger"
                        ></i>{" "}
                        {/* {task_state} */}
                        UnAnswered
                      </>
                    )}

                    {subStatus === 5 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {/* {task_state} */}
                        Answered
                      </>
                    )}

                    {subStatus === 6 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {/* {task_state} */}
                        Review in Pending
                      </>
                    )}

                    {subStatus === 9 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {/* {task_state} */}
                        Compliant
                      </>
                    )}

                    {subStatus === 10 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-danger"
                        ></i>{" "}
                        {/* {task_state} */}
                        Not Compliant
                      </>
                    )}

                    {subStatus === 11 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-warning"
                        ></i>{" "}
                        {/* {task_state} */}
                        Partial Compliant
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        </>
      );
    });
  };
  const RenderColumns = () => {
    // console.log(checkedItemsState)
    return state?.map((item, key) => {
      const { title, items } = item;
      if (title == "Compliant" && showingRequirments) return null;
      if (title == "Initialization" && !showingRequirments) return null;
      return (
        <Droppable droppableId={key.toString()}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps} // TODO: these props can be changed later
              style={{
                border: "0px solid red",
                paddingLeft: 5,
                paddingRight: 5,
              }}
              className="col"
            >
              <div
                style={{
                  backgroundColor: "#F8F9FA",
                  // backgroundColor: "#f4f3ef",
                  borderRadius: "0px",
                  // border: "1px solid #f4f3ef",
                  borderRight: "3px solid #f4f3ef",
                  height: "100%",
                  paddingBottom: 10,
                  padding: 10,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div
                      className="text-left"
                      style={{ fontWeight: "bold" }}
                      contentEditable="true"
                    >
                      {title}
                    </div>
                  </div>
                  <div className="col text-right" style={{ minHeight: 25 }}>
                    {title === "Initialization" && (
                      // checkedItemsState[key].checkedItems.length > 0 && (
                      <>
                        <Input
                          // className="btn btn-sm"
                          // color="info"

                          type="checkbox"
                          value={checkallrequirements}
                          checked={checkallrequirements}
                          onChange={(e) => {
                            setCheckallrequirements(e.target.checked);
                          }}
                          onClick={() => {}}
                        >
                          {/* <i className="fas fa-user-circle"></i> */}
                          {/* {"All"} */}
                        </Input>
                      </>
                    )}

                    {/* {title === "Initialization" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <Button
                          className="btn btn-sm"
                          color="info"
                          style={{
                            marginRight: 10,
                            height: 20,
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                          onClick={() => { }}
                        >
                          <i className="fas fa-user-circle"></i>
                          {"Assign"}
                        </Button>
                      )} */}
                    {/* {title != "Initialization" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: 14,
                            marginRight: 10,
                          }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key - 1).toString();
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                          <i class="fas fa-hand-point-left"></i>
                        </span>
                      )} */}
                    {title != "Compliant" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key + 1).toString();
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                          <i
                            class="fas fa-hand-point-right"
                            onClick={() => {
                              if (
                                checkedItemsState[key].checkedItems.length > 0
                              ) {
                                var srcDroppableId = key.toString();
                                var destDroppableId = (key + 1).toString();

                                const data = {
                                  auditId: items[0].auditId,
                                  // auditRequirementIds: items.map((reqId) => (reqId?.auditRequirementId)),
                                  auditRequirementIds:
                                    checkedItemsState[key].checkedItems,
                                  version_id: items[0].versionId,
                                };

                                console.log(
                                  "itemsDDDD",
                                  checkedItemsState[key]
                                );

                                console.log(data);

                                // req to res
                                if (
                                  srcDroppableId == 0 &&
                                  destDroppableId == 1
                                ) {
                                  if (
                                    items.filter((i) => i?.subStatus === 4)
                                      .length > 0
                                  ) {
                                    window.location.reload();
                                  } else {
                                    MoveContributeLeadRequirementToReview(data);
                                  }
                                }
                              }
                            }}
                          ></i>
                        </span>
                      )}

                 {title === "Initialization" &&
                      checkallrequirements &&
                      items.length > 0 &&
                      checkedItemsState[key].checkedItems.length == 0 && (
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key + 1).toString();
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                          <i
                            class="fas fa-hand-point-right"
                            onClick={() => {
                              if (checkallrequirements) {
                                var srcDroppableId = key.toString();
                                var destDroppableId = (key + 1).toString();

                                const data = {
                                  auditId: items[0].auditId,
                                  auditRequirementIds: items.map(
                                    (reqId) => reqId?.auditRequirementId
                                  ),
                                  // auditRequirementIds: checkedItemsState[key].checkedItems,
                                  version_id: items[0].versionId,
                                };

                                console.log(
                                  "itemsDDDD",
                                  checkedItemsState[key]
                                );

                                console.log(data);

                                // req to res
                                if (
                                  srcDroppableId == 0 &&
                                  destDroppableId == 1
                                ) {
                                  if (
                                    items.filter((i) => i?.subStatus === 4)
                                      .length > 0
                                  ) {
                                    window.location.reload();
                                  } else {
                                    MoveContributeLeadRequirementToReview(data);
                                  }
                                }
                              }
                            }}
                          >
                 Push All
                      </i>
                        </span>
                      )}
                  </div>
                </div>
                <hr style={{ ...styles.smartHr, marginTop: 2 }} />
                {RenderItems(items, key)}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col style={{ border: "0px solid red", display: "flex" }}>
            <p
              onClick={() => {
                history.push("/user/assessment-summary");
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {"Assessment Summary"}{" "}
            </p>
            <p style={{ paddingLeft: "6px" }}>
              {/* <i className="fas fa-star text-warning mx-2"></i> */}
              <i className="fas fa-users" id="tooltip366246651"></i>
            </p>
          </Col>
          <Col style={{ border: "0px solid red" }} className="text-right">
            <span style={{ border: "0px solid red" }}>
              {wholeAuditStatus === "In Response" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-warning"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "Review in pending" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-info"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {/* {wholeAuditStatus === "Minor or Major" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-danger"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )} */}
              {/* {wholeAuditStatus === "Compliant" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-success"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )} */}
            </span>
          </Col>
        </Row>
        <UncontrolledTooltip delay={0} target="tooltip366246651">
          {/* Harry , Meghan , Ryan */}
          {getContributeLifeCycle?.audit?.members &&
            getContributeLifeCycle?.audit?.members?.map((member) => (
              <>
                {" "}
                {member?.member_data?.name} {","}
              </>
            ))}

          {getContributeLifeCycle?.audit?.contribute_lead &&
            getContributeLifeCycle?.audit?.contribute_lead?.name}
        </UncontrolledTooltip>

        <div className="nav-tabs-navigation">
          <div className="nav-tabs-wrapper">
            <Nav
              id="tabs"
              role="tablist"
              tabs
              className="justify-content-start"
            >
              <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "lifecycle"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "lifecycle" ? "active" : ""}
                  onClick={() => ChangeLifeCycleTabs("lifecycle")}
                >
                  Life Cycle
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "radarchart"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "radarchart" ? "active" : ""}
                  onClick={() => ChangeLifeCycleTabs("radarchart")}
                >
                  Radar Chart
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </div>
        <TabContent
          className="text-center"
          id="my-tab-content"
          activeTab={lifeCycleTabs}
        >
          <TabPane tabId="lifecycle" role="tabpanel" className="boards-flex">
            <Card className="p-3" style={{ backgroundColor: "#F8F9FA" }}>
              <Row
                id="scroll-bar"
                style={{
                  // height: "450px",
                  height: "calc(100vh - 280px)",
                  position: "relative",
                }}
              >
                {
                  !showingRequirments && (
                    <div
                      onClick={() => {
                        ChangeShowingRequirments(true);
                      }}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 10,
                        zIndex: 99,
                        writingMode: "vertical-lr",
                        backgroundColor: "#61B9DA88",
                        // padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {/* <i style={{ fontSize: 18 }} class="fas fa-caret-right"></i> */}
                      {"Initialization"}
                    </div>
                  )
                  // : (
                  //   <div
                  //     onClick={() => {
                  //       // setCheckedItemsState(prev => {
                  //       //     prev[0].checkedItems = []
                  //       //     return prev
                  //       // })
                  //       ChangeShowingRequirments(false);
                  //     }}
                  //     style={{
                  //       position: "absolute",
                  //       right: 0,
                  //       top: 10,
                  //       zIndex: 99,
                  //       writingMode: "vertical-lr",
                  //       backgroundColor: "#61B9DA88",
                  //       // padding: 5,
                  //       paddingTop: 10,
                  //       paddingBottom: 10,
                  //       borderTopLeftRadius: 10,
                  //       borderBottomLeftRadius: 10,
                  //       cursor: "pointer",
                  //       fontWeight: "bold",
                  //       fontSize: 12,
                  //     }}
                  //   >
                  //     {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                  //     {"Compliant"}
                  //   </div>
                  // )
                }
                <DragDropContext onDragEnd={HandleDragEnd}>
                  {RenderColumns()}
                </DragDropContext>
              </Row>
            </Card>
          </TabPane>
          <TabPane tabId="radarchart" role="tabpanel">
            <Card className="p-2">
              <Row className="w-100 h-100">
                <Col md="10" className="ml-auto mr-auto">
                  {getRadarChart?.datasets?.length > 0 ? (
                    <Radar data={getRadarChart} options={options} />
                  ) : (
                    <div
                      style={{
                        height: "calc(100vh - 280px)",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        color: "gray",
                      }}
                    >
                      <i
                        className="fa fa-line-chart"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <h5> No Chart to show</h5>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </TabPane>
        </TabContent>
        {/* ----------------------------------------- Modal ------------------------- */}
        <Modal
          Modal
          isOpen={modalClassic}
          size="xl"
          toggle={toggleModalClassic}
        >
          <div className="modal-header">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassic();
                setGetSubRequirementId("");
                setTaskSubRequirementId("");
                ClearAllTaskData();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                {getCurrentRequirementId?.standard_ref}
                <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                  {getCurrentRequirementId?.desc}
                </span>
                {/* Requirement Title */}
              </h4>
            </div>
          </div>
          <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  {/* 
                  {
                    getCurrentRequirementId?.supportAssignMemberData && !getCurrentRequirementId?.checkContributeLead ?
                      getCurrentRequirementId?.supportAssignMemberData?.map((mem) => (

                        <a href="javascript:void(0)" className="text-dark" >
                          Assigned to {mem?.supportAssignMemberName}

                        </a>

                      )) : (
                        <a href="javascript:void(0)" className="text-dark" >
                          Not Assigned Yet
                        </a>
                      )
                  } */}
                  {getCurrentRequirementId?.supportAssignMemberData &&
                    getCurrentRequirementId?.supportAssignMemberData?.map(
                      (mem) => (
                        <a href="javascript:void(0)" className="text-dark">
                          Assigned to {mem?.supportAssignMemberName}
                        </a>
                      )
                    )}
                  {!getCurrentRequirementId?.supportAssignMemberData &&
                    !getCurrentRequirementId?.supportAssignMemberData?.map(
                      (mem) => (
                        <a href="javascript:void(0)" className="text-dark">
                          Not Assign Yet
                        </a>
                      )
                    )}
                  {/* // <a href="javascript:void(0)" className="text-dark">
                  //   Assigned to: Zero Two
                  // </a> */}
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i>{" "}
                  {getCommentCount} Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                 Requirement List
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getCurrentRequirementId?.req_list?.name}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Sections
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getCurrentRequirementId?.sectionDec}
                  </span>
                </Col>
                {/* <Col className="text-right" md="6">
                  {(assessorDecision.value == 2 ||
                    assessorDecision.value == 3) && (
                      <Button
                        className="btn btn-sm"
                        color="info"
                        onClick={() => {
                          ChangeShowCreateTaskModal(true);
                        }}
                      >
                        {" "}
                        <i className="fas fa-plus"></i> Create Task
                      </Button>
                    )}
                </Col> */}
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="8">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        {/* <th>Area</th> */}
                        <th>Criticality</th>
                        <th>Version</th>
                        <th>Reference</th>
                        <th>Decision</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">
                          {getCurrentRequirementId?.name}
                        </td>
                        <td className="border-0">
                          {getCurrentRequirementId?.weight}
                        </td>
                        {/* <td className="border-0">{getCurrentRequirementId?.area}</td> */}
                        <td className="border-0">
                          {getCurrentRequirementId?.criticality}
                        </td>
                        <td className="border-0">
                          {getCurrentRequirementId?.versionName}
                        </td>
                        <td className="border-0">
                          {getCurrentRequirementId?.reference?.name}
                        </td>
                        {/* {assessorDecision.value == 1 || showAuditAnswer?.answer_status == "yes" ? (
                          <td className="border-0 text-success">
                            {"Compliant"}
                          </td>
                        ) : (
                          <td className="border-0 text-danger">
                            {"Not Compliant"}
                          </td>
                        )} */}
                        {showAuditAnswer?.final_percentage == 100 && (
                          <td className="border-0 text-success">
                            {"Compliant"}
                          </td>
                        )}
                        {showAuditAnswer?.final_percentage == 0 && (
                          <td className="border-0 text-danger">
                            {"Not Compliant "}
                          </td>
                        )}
                        {showAuditAnswer?.final_percentage > 0 &&
                          showAuditAnswer?.final_percentage < 100 && (
                            <td className="border-0 text-warning">
                              {"Partial Compliant "}
                            </td>
                          )}
                        {!showAuditAnswer?.final_percentage &&
                          getCurrentRequirementId?.overallPercentage && (
                            <td className="border-0 ">
                              {getCurrentRequirementId?.overallPercentage > 0 &&
                                getCurrentRequirementId?.overallPercentage <
                                  100 && (
                                  <td className="border-0 text-warning">
                                    {"Partial Compliant "}
                                  </td>
                                )}
                              {getCurrentRequirementId?.overallPercentage ==
                                0 && (
                                <td className="border-0 text-success">
                                  {"Compliant"}
                                </td>
                              )}
                              {getCurrentRequirementId?.overallPercentage ==
                                100 && (
                                <td className="border-0 text-danger">
                                  {"Not Compliant "}
                                </td>
                              )}
                            </td>
                          )}
                        {showAuditAnswer?.final_percentage == null &&
                          getCurrentRequirementId?.overallPercentage ==
                            null && (
                            <>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip366"
                              >
                                {" "}
                                Decision has not yet been made by reviewer{" "}
                              </UncontrolledTooltip>
                              <td className="border-0 ">
                                <p
                                  id="tooltip366"
                                  style={{ cursor: "pointer" }}
                                >
                                  {"N/A "}
                                </p>
                              </td>
                            </>
                          )}
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="4" className="text-right">
                  {/* <p className="p-2">Updated By Arman Ali.</p> */}
                  <div className="nav-tabs-navigation border-0">
                    <div className="nav-tabs-wrapper border-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end border-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "details"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "details" ? "active" : ""
                            }
                            onClick={() => ChangeRequirementTabs("details")}
                          >
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "tasks"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "tasks" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("tasks");
                              const showTask = {
                                audit_requirement_id:
                                  getCurrentRequirementId?.auditRequirementId,
                              };
                              GetAllTask(showTask);
                            }}
                          >
                            Tasks
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "discussion"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "discussion" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("discussion");
                              const data = {
                                audit_requirement_id:
                                  getCurrentRequirementId?.auditRequirementId,
                                audit_id:
                                  window.localStorage.getItem(
                                    "audit_life_cycle"
                                  ),
                              };
                              GetDiscussionData(data);
                              // var elem = document.getElementById('modal_scroll'); //! scroll to bottom not working
                              // var elem2 = document.getElementById('my-tab-content');
                              // console.log("ELEM.SCROLLHEIGHT")
                              // console.log(elem.scrollHeight)
                              // console.log("ELEM")
                              // console.log(elem)
                              // console.log("ELEM2")
                              // console.log(elem2)
                              // elem.scrollTop = elem2.scrollHeight;
                            }}
                          >
                            <i class="far fa-comments"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "attachments"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "attachments" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("attachments");
                              GetVersionFiles(
                                window.localStorage.getItem("audit_life_cycle")
                              );
                            }}
                          >
                            <i class="fas fa-paperclip"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "500px",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <TabContent
                className="text-center"
                id="my-tab-content"
                activeTab={requirementTabs}
              >
                <TabPane tabId="details" role="tabpanel">
                  <Row>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                        Description
                      </h6>
                      <textarea
                        value={generalDescription}
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                        disabled
                      />
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                       Answer
                          </span>
                        </Col>
                        <Col className="text-right">
                          {/* <span style={{ fontSize: 14 }}>Answered by: Arman Ali</span> */}
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <div className="d-flex align-items-center justify-content-start">
                        <Row>
                          <Col
                            style={{ paddingRight: 0, minWidth: 130 }}
                            md="3"
                          >
                            {/* <FormGroup className="mr-2">
                              <Select
                                styles={{
                                  container: () => ({}),
                                }}
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                name="answer"
                                closeMenuOnSelect={true}
                                isMulti={false}
                                value={auditAnswer}
                                onChange={(value) => {
                                  // console.log("SELECTED ANSWER")
                                  // console.log(value)
                                  setAuditAnswer(value);
                                }}
                                options={getCurrentRequirementId?.question_type == 'Yes/No' ? [
                                  {
                                    value: 0,
                                    label: "Select Answer",
                                    isDisabled: true,
                                  },
                                  { value: 1, label: "No " },
                                  { value: 2, label: "Yes" },
                                  // { value: 3, label: "Partial" },
                                ] :
                                  [
                                    {
                                      value: 0,
                                      label: "Select Answer",
                                      isDisabled: true,
                                    },
                                    { value: 1, label: "No " },
                                    { value: 2, label: "Yes" },
                                    { value: 3, label: "Partial" },

                                  ]

                                }
                              />
                            </FormGroup> */}
                            {getCurrentRequirementId?.sub_requirements?.length >
                              0 && (
                              <FormGroup className="mr-2">
                                <Select
                                  styles={{
                                    container: () => ({}),
                                  }}
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Select"
                                  name="answer"
                                  closeMenuOnSelect={true}
                                  isMulti={false}
                                  value={auditAnswer}
                                  onChange={(value) => {
                                    setAuditAnswer(value);
                                  }}
                                  options={
                                    getSubRequirementById?.data
                                      ?.question_type == "Yes/No"
                                      ? [
                                          {
                                            value: 0,
                                            label: "Select Answer",
                                            isDisabled: true,
                                          },
                                          { value: 1, label: "No " },
                                          { value: 2, label: "Yes" },
                                        ]
                                      : [
                                          {
                                            value: 0,
                                            label: "Select Answer",
                                            isDisabled: true,
                                          },
                                          { value: 1, label: "No " },
                                          { value: 2, label: "Yes" },
                                          { value: 3, label: "Partial" },
                                        ]
                                  }
                                />
                              </FormGroup>
                            )}
                            {getCurrentRequirementId?.sub_requirements
                              ?.length == 0 && (
                              <FormGroup className="mr-2">
                                <Select
                                  styles={{
                                    container: () => ({}),
                                  }}
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Select"
                                  name="answer"
                                  closeMenuOnSelect={true}
                                  isMulti={false}
                                  value={auditAnswer}
                                  onChange={(value) => {
                                    setAuditAnswer(value);
                                  }}
                                  options={
                                    getCurrentRequirementId?.question_type ==
                                    "Yes/No"
                                      ? [
                                          {
                                            value: 0,
                                            label: "Select Answer",
                                            isDisabled: true,
                                          },
                                          { value: 1, label: "No " },
                                          { value: 2, label: "Yes" },
                                        ]
                                      : [
                                          {
                                            value: 0,
                                            label: "Select Answer",
                                            isDisabled: true,
                                          },
                                          { value: 1, label: "No " },
                                          { value: 2, label: "Yes" },
                                          { value: 3, label: "Partial" },
                                        ]
                                  }
                                />
                              </FormGroup>
                            )}
                          </Col>
                          {auditAnswer.value == 3 && (
                            <>
                              <Col
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                md="6"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Input percentage %"
                                    value={partialPercent}
                                    onChange={(e) => {
                                      setPartialPercent(e.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              {/* <Col
                                style={{ paddingLeft: 0, paddingRight: 8 }}
                                md="6"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Partial Description"
                                    value={partialDescription}
                                    onChange={(e) => {
                                      // console.log(e.target.value)
                                      setPartialDescription(e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col> */}
                            </>
                          )}
                        </Row>
                      </div>
                      <Row>
                        {getCurrentRequirementId?.evidenceRequired == "Yes" && (
                          <>
                            <Col
                              md="3"
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <input
                                type="file"
                                id="actual-btn"
                                onChange={(e) => {
                                  setEvidenceAttachment(e.target.files[0]);
                                }}
                                hidden
                              />
                              <label
                                className="btn btn-info"
                                style={{
                                  marginTop: 0,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                for="actual-btn"
                                onClick={() => {
                                  setEvidenceError("");
                                }}
                              >
                                <i class="fa fa-upload" aria-hidden="true"></i>{" "}
                                Evidence
                              </label>
                            </Col>
                            <Col md="9" style={{ paddingLeft: 0 }}>
                              <FormGroup>
                                <Input
                                  placeholder="Evidence Detail"
                                  value={evidenceDetail}
                                  onChange={(e) => {
                                    setEvidenceDetail(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            {evidenceError && (
                              <div
                                style={{
                                  color: "red",
                                  height: "30px",
                                  marginLeft: "15px",
                                }}
                              >
                                {evidenceError}
                              </div>
                            )}
                          </>
                        )}
                      </Row>
                 <textarea
                        value={contributeComment}
                        onChange={(e) => {
                          SetContributeComment(e.target.value);
                        }}
                        placeholder="Contributor Comment"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                       <Button
                          style={{ marginTop: 10 }}
                          color="info"
                          // disabled={item?.subStatus === 4 ? 'false' : 'true'}

                          // for now it will not be disable

                          // disabled={
                          //   (getCurrentRequirementId?.subStatus === 5 && getCurrentRequirementId?.mainStatus === 3) ||
                          //     (getCurrentRequirementId?.subStatus === 4 && getCurrentRequirementId?.mainStatus === 3) ||
                          //     getCurrentRequirementId?.subStatus === 6 && getCurrentRequirementId?.mainStatus === 4 ? true : false}

                          //m
                          // disabled={
                          //   getCurrentRequirementId?.subStatus === 5 ||
                          //   getCurrentRequirementId?.subStatus === 6 ||
                          //   getCurrentRequirementId?.subStatus === 9 ||
                          //   getCurrentRequirementId?.subStatus === 10 ||
                          //   // form sub requirement case
                          //   (getCurrentRequirementId?.subStatus === 4 &&
                          //     getSubRequirementId === undefined &&
                          //     getCurrentRequirementId?.sub_requirements.length >
                          //       0) ||
                          //   (getCurrentRequirementId?.subStatus === 4 &&
                          //     getSubRequirementId ===
                          //       showAuditAnswer?.sub_requirement_id &&
                          //     getCurrentRequirementId?.sub_requirements.length >
                          //       0) ||
                          //   //for main requirement case
                          //   (getCurrentRequirementId?.subStatus === 4 &&
                          //     getCurrentRequirementId?.sub_requirements
                          //       ?.length === 0 &&
                          //     showAuditAnswer?.status)
                          //     ? true
                          //     : false
                          // }
                          onClick={() => {
                            if (
                              getCurrentRequirementId?.evidenceRequired ==
                                "No" ||
                              getCurrentRequirementId?.evidenceRequired == null
                            ) {
                              handelAnswerForm();
                              
                            } else {
                              if (evidenceAttachment == undefined) {
                                setEvidenceError("please upload evidence");
                              } else {
                                handelAnswerForm();
                              }
                            }
                          }}
                        >
                     Answer
                        </Button>
                      </div>
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                         Assessor Section
                          </span>
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <FormGroup className="mr-2">
                        <Select
                          disabled={true}
                          styles={{
                            container: () => ({}),
                          }}
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          name="answer"
                          closeMenuOnSelect={true}
                          isMulti={false}
                          value={assessorDecision}
                          onChange={(value) => {
                            setAssessorDecision(value);
                          }}
                          options={[
                            {
                              value: 0,
                              label: "Select Decision",
                              isDisabled: true,
                            },
                            { value: 1, label: "Accepted " },
                            { value: 2, label: "Minor Revision " },
                            { value: 3, label: "Major Revision" },
                            { value: 4, label: "Rejected" },
                          ]}
                        />
                      </FormGroup>
                      <textarea
                        disabled={true}
                        value={AssessorComment}
                        onChange={(e) => ChangeAssessorComment(e.target.value)}
                        placeholder="Assessor Comment"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                    </Col>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                  Clarification
                      </h6>
                      <textarea
                        value={generalClarification}
                        onChange={(e)=> setGeneralClarification(e.target.value)}
                        placeholder="Enter clarification"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                      {getCurrentRequirementId?.sub_requirements?.length >
                        0 && (
                        <>
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                            className="text-left"
                          >
                            Sub Requirements
                          </div>
                          <hr style={{ marginTop: 5, marginBottom: 5 }} />
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Ref.</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getCurrentRequirementId?.sub_requirements.map(
                                (subRequirement) => (
                                  <>
                                    <tr>
                                      <td>
                                        <Button
                                          style={{ marginTop: 0 }}
                                          outline
                                          color="info"
                                          // disabled={getCurrentRequirementId?.subStatus === 4 ? false : true}

                                          onClick={() => {
                                            setGetSubRequirementId(
                                              subRequirement?.id
                                            );

                                            const answerShowData = {
                                              audit_requirement_id:
                                                getCurrentRequirementId?.auditRequirementId,
                                              requirement_id:
                                                getCurrentRequirementId?.id,
                                              sub_requirement_id:
                                                subRequirement?.id,
                                            };
                                            console.log(answerShowData);
                                            ShowAuditRequirementAnswer(
                                              answerShowData
                                            );
                                          }}
                                        >
                                          Select to Answer
                                        </Button>
                                      </td>
                                      <td>{subRequirement?.references}</td>
                                      <td>{subRequirement?.description}</td>
                                    </tr>
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        </>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="attachments" role="tabpanel">
                  {/* <div
                    className="text-left font-small"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      style={{ marginRight: 5 }}
                      className="fas fa-plus text-success"
                    ></i>
                    Add Attachement
                  </div> */}

                  <div className="nav-tabs-navigation p-0">
                    <div className="nav-tabs-wrapper p-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end p-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "table"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "table" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("table")}
                          >
                            <i class="fas fa-table"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "grid"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "grid" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("grid")}
                          >
                            <i class="fas fa-th"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={attachmentTabs}
                  >
                    <TabPane tabId="table" role="tabpanel">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Attached</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {versionFiles?.data?.map((file) => (
                            <>
                              <tr>
                                <td>{file?.evidence_name}</td>
                                <td>{file?.evidence_size + "KB"}</td>
                                <td>
                                  {moment(file?.evidence_date).format(
                                    "MMM Do, YYYY"
                                  )}
                                </td>
                                <td>{file?.evidence_details}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </TabPane>
                    <TabPane tabId="grid" role="tabpanel">
                      <Row>
                        {versionFiles?.data?.map((file) => (
                          <Col md="3">
                            <Card>
                              <CardBody>
                                <i class="fas fa-file-pdf text-danger fa-3x"></i>
                              </CardBody>
                              <CardFooter>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>{file?.evidence_name}</p>
                                  <Button
                                    color="info"
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px 10px",
                                    }}
                                    onClick={() => {
                                      window.open(
                                        `${process.env.REACT_APP_BASE_URL}/public/${file?.evidence_attachment}`
                                      );
                                    }}
                                  >
                                    <i class="fas fa-download"></i>
                                  </Button>
                                  {/* <UncontrolledDropdown>
                                      <DropdownToggle
                                        aria-expanded={false}
                                        aria-haspopup={true}
                                        caret
                                        className="btn-round btn-block"
                                        color="primary"
                                        data-toggle="dropdown"
                                        id="dropdownMenuButton"
                                        type="button"
                                      >
                                        <i class="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        persist
                                        aria-labelledby="dropdownMenuButton"
                                        right
                                      >
                                        <DropdownItem
                                          download
                                          href="./Untitled.pdf"
                                          target="_blank"
                                        >
                                          Download
                                        </DropdownItem>
                                        <DropdownItem
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        View
                                      </DropdownItem>
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </TabPane>
                <TabPane tabId="tasks" role="tabpanel">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Stand Ref.</th>
                        <th>Description</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Iteration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTask?.map((task) => (
                        <tr>
                          <td>{task?.name}</td>
                          <td>{task?.standard_ref}</td>
                          <td>{task?.description}</td>
                          <td>
                            {moment(task?.created_at).format("MMM Do, YYYY")}
                          </td>
                          <td>{task?.status}</td>
                          <td>{task?.Iteration}</td>
                          <td>
                            <i
                              className="fas fa-eye text-info"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                ChangeTaskModal(true);
                                setTaskId(task?.id);
                                ShowAuditRequirementAnswerTask(task?.id);
                                setTaskSubRequirementId(
                                  task?.answer?.sub_requirement_id
                                );

                                console.log("task id is", task?.id);

                                const answerShowData = {
                                  audit_requirement_id:
                                    getCurrentRequirementId?.auditRequirementId,
                                  requirement_id: getCurrentRequirementId?.id,
                                  sub_requirement_id:
                                    task?.answer?.sub_requirement_id,
                                };
                                console.log(answerShowData);
                                ShowAuditRequirementAnswer(answerShowData);
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabPane>
                <TabPane
                  tabId="discussion"
                  role="tabpanel"
                  id="chat-parent-tab"
                >
                  {RenderDiscussion()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      border: "0px solid red",
                      // alignItems: 'center'
                    }}
                  >
                    <img
                      src={img}
                      className="rounded-circle"
                      width="40"
                      height="40"
                      alt=""
                    />
                    <div
                      style={{
                        border: "0px solid red",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          border: "0px solid red",
                        }}
                      >
                        <div
                          className="single-line-text"
                          style={{
                            marginLeft: 10,
                            fontSize: 16,
                            fontWeight: "bold",
                            textAlign: "left",
                            color: "#595974",
                          }}
                        >
                          {/* {"Dani Charles"} */}
                          {
                            JSON.parse(window.localStorage.getItem("user"))
                              ?.name
                          }
                        </div>
                        <div
                          style={{
                            background: "#F4F5F7",
                            marginLeft: 10,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 5,
                          }}
                        >
                          {/* {"Assessor"} */}
                          {JSON.parse(window.localStorage.getItem("user"))
                            ?.role == "2" && "Support Team"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          border: "0px solid red",
                          padding: 10,
                        }}
                      >
                        <textarea
                          placeholder="type your comment here . . ."
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          className="form-control"
                          style={{
                            padding: 5,
                          }}
                          value={chatComment}
                          onChange={(e) => {
                            setChatComment(e.target.value);
                          }}
                        >
                          {
                            "Photons should represent 2 bits instead of a single bit"
                          }
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      className="btn-blue "
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {
                        const data = {
                          // image:img
                          audit_id:
                            window.localStorage.getItem("audit_life_cycle"),
                          audit_requirement_id:
                            getCurrentRequirementId?.auditRequirementId,
                          message: chatComment,
                        };
                        CreateDiscussionData(data);
                        setChatComment("");
                        // toggleModalClassic()
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {/* <Button
                  color="success"
                  onClick={() => toggleModalClassic(false)}
                >
                  Publish
                </Button> */}
                <Button
                  color="danger"
                  onClick={() => {
                    toggleModalClassic(false);
                    setGetSubRequirementId("");
                    setTaskSubRequirementId("");
                    ClearAllTaskData();
                  }}
                >
                  Close
                </Button>
              </div>
            </CardFooter>
          </div>
        </Modal>
        <Modal
          isOpen={taskModal}
          size="xl"
          toggle={() => ChangeTaskModal(false)}
        >
          <div className="modal-header">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                ChangeTaskModal(false);
                ClearAuditAnswerTaskData();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                {/* Task Title */}
                {getSubRequirementById?.standard_ref}
              </h4>
            </div>
          </div>
          <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  {getCurrentRequirementId?.supportAssignMemberData &&
                  !getCurrentRequirementId?.checkContributeLead ? (
                    getCurrentRequirementId?.supportAssignMemberData?.map(
                      (mem) => (
                        <a href="javascript:void(0)" className="text-dark">
                          Assigned to {mem?.supportAssignMemberName}
                        </a>
                      )
                    )
                  ) : (
                    <a href="javascript:void(0)" className="text-dark">
                      Assigned to {getCurrentRequirementId?.contributeLeadName}
                    </a>
                  )}
                  {/* <a href="javascript:void(0)" className="text-dark">
                    Assigned to: Zero Two
                  </a> */}
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i>
                  {getCommentCount} Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Requirment List
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getSubRequirementById?.data?.req_list?.name}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Section
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getSubRequirementById?.data?.requirement?.standard_ref}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Requirment
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {/* EE Requirement 1 */}
                    {getSubRequirementById?.message}
                  </span>
                </Col>
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="7">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        {/* <th>Area</th> */}
                        <th>Criticality</th>
                        <th>Reference</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">
                          {getSubRequirementById?.data?.standard_ref ||
                            getCurrentRequirementId?.standard_ref}
                        </td>
                        <td className="border-0">
                          {getSubRequirementById?.data?.requirement?.weight ||
                            getCurrentRequirementId?.weight}
                        </td>
                        {/* <td className="border-0"></td> */}
                        <td className="border-0">
                          {getSubRequirementById?.data?.requirement
                            ?.criticality ||
                            getCurrentRequirementId?.criticality}
                        </td>
                        <td className="border-0">
                          {getSubRequirementById?.data?.requirement
                            ?.references ||
                            getCurrentRequirementId?.reference?.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                {/* <Col md="5" className="text-right">
                  <p className="p-2">Created By: Moon.</p>
                </Col> */}
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "500px",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <Row>
                <Col md="12">
                  <h6 className="p-2 border-bottom text-left">Description</h6>
                  <textarea
                    value={getSubRequirementById?.data?.description}
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                        Support Team Answer Section
                      </span>
                    </Col>
                    <Col className="text-right">
                      {getCurrentRequirementId?.supportAssignMemberData &&
                      !getCurrentRequirementId?.checkContributeLead ? (
                        getCurrentRequirementId?.supportAssignMemberData?.map(
                          (mem) => (
                            // <a href="javascript:void(0)" className="text-dark" >
                            //   Assigned to {mem?.supportAssignMemberName}

                            // </a>
                            <span style={{ fontSize: 14 }}>
                              Answered by: {mem?.supportAssignMemberName}
                            </span>
                          )
                        )
                      ) : (
                        <span style={{ fontSize: 14 }}>
                          Answered by:{" "}
                          {getCurrentRequirementId?.contributeLeadName}
                        </span>
                        // <a href="javascript:void(0)" className="text-dark" >
                        //   Assigned to {getCurrentRequirementId?.contributeLeadName}
                        // </a>
                      )}
                      {/* <span style={{ fontSize: 14 }}>Answered by: {"Arman"}</span> */}
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                  <div className="d-flex align-items-center justify-content-start">
                    <Row>
                      <Col style={{ paddingRight: 0, minWidth: 130 }} md="3">
                        {getCurrentRequirementId?.sub_requirements?.length >
                          0 && (
                          <FormGroup className="mr-2">
                            <Select
                              styles={{
                                container: () => ({}),
                              }}
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              name="answer"
                              closeMenuOnSelect={false}
                              isMulti={false}
                              value={auditAnswerTask}
                              onChange={(value) => {
                                setAuditAnswerTask(value);
                              }}
                              // options={[
                              //   {
                              //     value: 0,
                              //     label: "Select Answer",
                              //     isDisabled: true,
                              //   },
                              //   { value: 1, label: "No " },
                              //   { value: 2, label: "Yes" },
                              //   { value: 3, label: "Partial" },
                              // ]}
                              options={
                                getSubRequirementById?.data?.question_type ==
                                "Yes/No"
                                  ? [
                                      {
                                        value: 0,
                                        label: "Select Answer",
                                        isDisabled: true,
                                      },
                                      { value: 1, label: "No " },
                                      { value: 2, label: "Yes" },
                                    ]
                                  : [
                                      {
                                        value: 0,
                                        label: "Select Answer",
                                        isDisabled: true,
                                      },
                                      { value: 1, label: "No " },
                                      { value: 2, label: "Yes" },
                                      { value: 3, label: "Partial" },
                                    ]
                              }
                            />
                          </FormGroup>
                        )}

                        {getCurrentRequirementId?.sub_requirements?.length ==
                          0 && (
                          <FormGroup className="mr-2">
                            <Select
                              styles={{
                                container: () => ({}),
                              }}
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              name="answer"
                              closeMenuOnSelect={false}
                              isMulti={false}
                              value={auditAnswerTask}
                              onChange={(value) => {
                                setAuditAnswerTask(value);
                              }}
                              // options={[
                              //   {
                              //     value: 0,
                              //     label: "Select Answer",
                              //     isDisabled: true,
                              //   },
                              //   { value: 1, label: "No " },
                              //   { value: 2, label: "Yes" },
                              //   { value: 3, label: "Partial" },
                              // ]}
                              options={
                                getCurrentRequirementId?.question_type ==
                                "Yes/No"
                                  ? [
                                      {
                                        value: 0,
                                        label: "Select Answer",
                                        isDisabled: true,
                                      },
                                      { value: 1, label: "No " },
                                      { value: 2, label: "Yes" },
                                    ]
                                  : [
                                      {
                                        value: 0,
                                        label: "Select Answer",
                                        isDisabled: true,
                                      },
                                      { value: 1, label: "No " },
                                      { value: 2, label: "Yes" },
                                      { value: 3, label: "Partial" },
                                    ]
                              }
                            />
                          </FormGroup>
                        )}
                      </Col>
                      {auditAnswerTask.value == 3 && (
                        <>
                          <Col
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            md="3"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                type="text"
                                placeholder="Input percentage %"
                                value={partialPercentTask}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setPartialPercentTask(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col
                            style={{ paddingLeft: 0, paddingRight: 8 }}
                            md="6"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                type="text"
                                placeholder="Partial Description"
                                value={partialDescriptionTask}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  setPartialDescriptionTask(e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col> */}
                        </>
                      )}
                    </Row>
                  </div>
                  <Row>
                    {getCurrentRequirementId?.evidenceRequired == "Yes" && (
                      <>
                        <Col md="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                         <input
                            type="file"
                            id="actual-btn-task"
                            required
                            onChange={(e) => {
                              setEvidenceAttachmentTask(e.target.files[0]);
                            }}
                            hidden
                          />

                          <label
                            className="btn btn-info"
                            style={{
                              marginTop: 0,
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              marginLeft: "15px",
                            }}
                            for="actual-btn-task"
                            onClick={() => {
                              setEvidenceErrorTask("");
                            }}
                          >
                            <i class="fa fa-upload" aria-hidden="true"></i>{" "}
                       Evidence
                          </label>
                        </Col>

                        <Col md="10" style={{ paddingLeft: 0 }}>
                          <FormGroup>
                            <Input
                              placeholder="Evidence Detail"
                              value={evidenceDetailTask}
                              onChange={(e) => {
                                setEvidenceDetailTask(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {evidenceErrorTask && (
                          <div
                            style={{
                              color: "red",
                              height: "30px",
                              marginLeft: "15px",
                            }}
                          >
                            {evidenceErrorTask}
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                  <textarea
                    value={contributeCommentTask}
                    onChange={(e) => {
                      SetContributeCommentTask(e.target.value);
                    }}
                    placeholder="Support Lead Comment"
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ marginTop: 10 }}
                      color="info"
                      onClick={() => {
                        if (
                          getCurrentRequirementId?.evidenceRequired == "No" ||
                          getCurrentRequirementId?.evidenceRequired == null
                        ) {
                          handelTaskAnswerForm();
                        } else {
                          if (evidenceAttachmentTask == undefined) {
                            setEvidenceErrorTask("please upload evidence");
                          } else {
                            handelTaskAnswerForm();
                          }
                        }
                      }}
                      //for now it will not disable
                      disabled={
                        getCurrentRequirementId?.subStatus === 5 ||
                        getCurrentRequirementId?.subStatus === 6 ||
                        getCurrentRequirementId?.subStatus === 9 ||
                        getCurrentRequirementId?.subStatus === 10
                          ? // getCurrentRequirementId?.subStatus === 4 && getSubRequirementId === undefined ||
                            // getCurrentRequirementId?.subStatus === 4 && getSubRequirementId === showAuditAnswer?.sub_requirement_id
                            // getCurrentRequirementId?.subStatus === 4
                            true
                          : false
                      }
                    >
                      Answer
                    </Button>
                  </div>
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                    Assessor Section
                      </span>
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                  <textarea
                    disabled={true}
                    value={""}
                    placeholder="Assessor Comment"
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="text-right">
                {/* <Button color="success" onClick={() => ChangeTaskModal(false)}>
                  Publish
                </Button> */}
                <Button color="danger" onClick={() => ChangeTaskModal(false)}>
                  Close
                </Button>
              </div>
            </CardBody>
          </div>
        </Modal>
        <Modal
          isOpen={showCreateTaskModal}
          size="xl"
          toggle={() => {
            ChangeShowCreateTaskModal(false);
          }}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                ChangeShowCreateTaskModal(false);
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">New Task</h4>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Task Name</Label>
                    <Input
                      type="text"
                      placeholder="Task Name"
                      value={""}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Stand Ref.</Label>
                    <Input
                      type="text"
                      placeholder="Stand Ref."
                      value={""}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Description</Label>
                    <textarea
                      value={AssessorComment}
                      style={{
                        paddingLeft: 10,
                      }}
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  color="success"
                  onClick={() => {
                    ChangeShowCreateTaskModal(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    ChangeShowCreateTaskModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}
const mapStateToProps = ({
  shared,
  styling,
  audit,
  lifeCycle,
  requirment,
}) => ({
  shared,
  styling,
  audit,
  lifeCycle,
  requirment,
});

export default connect(mapStateToProps, {
  SaveAnswerAudit,
  ShowAuditRequirementAnswer,
  MoveContributeLeadRequirementToReview,
  GetContributeLifeCycle,
  GetAllTask,
  ShowAuditRequirementAnswerTask,
  ClearAuditRequirementAnswerData,
  GetSingleAudit,
  ClearAuditAnswerTaskData,
  ClearAllTaskData,
  GetVersionFiles,
  GetTotalComment,
  CreateDiscussionData,
  GetDiscussionData,
  DeleteComment,
  GetRadarChart,
})(SupportLifeCycle);
