import {
  CHANGE_SHOW_ADD_SECTION_MODAL,
  CHANGE_SHOW_EDIT_SECTION_MODAL,
  CHANGE_SHOW_VIEW_SECTION_MODAL,
  CHANGE_IS_EDITING_SECTION,
  CHANGE_REQUIREMENT_LIST,
  CHANGE_GET_ALL_REQUIREMENT_LIST,
  CHANGE_REQUIREMENT_SECTION,
  CHANGE_GET_SINGLE_REQUIREMENT_LIST,
  CHANGE_CREATE_SECTION_REQUIREMENT,
  CHANGE_CREATE_SUB_REQUIREMENT,
  CHANGE_GET_SINGLE_SECTION,
  CHANGE_GET_SINGLE_REQUIREMENT,
  CHANGE_GET_SINGLE_SUB_REQUIREMENT,
  CHANGE_GET_REQUIREMENTS_BY_SECTION_ID,
  CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID,
  CHANGE_GET_ALL_SECTIONS,
  ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_GET_ALL_REQUIREMENTS,
  CHANGE_REQUIREMENT_EDITING,
  CHANGE_EDIT_REQUIREMENT_LIST,
  CHANGE_GET_ALL_SUB_REQUIREMENTS,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_EDIT_REQUIREMENT_SECTION,
  CHANGE_GET_SECTION_BY_ID,
  CHANGE_IS_ADD_REQUIREMENT_LIST,
  CHANGE_IS_ADD_SECTION,
  CHANGE_IS_ADD_REQUIREMENT,
  CHANGE_IS_ADD_SUB_REQUIREMENT,
  CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID,
  CHANGE_EDIT_SECTION_REQUIREMENT,
  CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
  CHANGE_GET_REQUIREMENT_BY_ID,
  CHANGE_EDIT_SUB_REQUIREMENT,
  CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
  CHANGE_GET_SUB_REQUIREMENT_BY_ID,
  CHANGE_CREATE_REFERENCE,
  CHANGE_GET_ALL_REFERENCES,
  CHANGE_EDIT_REFERENCE,
  CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT,
  CHANGE_CLEAR_REQUIREMENT_DATA,
  CHANGE_DELETE_REFERENCE,
  MAP_REQUIREMENT,
  MAP_REQUIREMENT_IDS,
  GET_ALL_MAP_REQUIREMENT,
  GET_SINGLE_MAP_REQUIREMENT,
  CHANGE_IS_REQUIREMENT_LIST_EDIT,
  CLEAR_SINGLE_REQUIREMENT_LIST_DATA,
  CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA,
  CLEAR_SUB_REQUIREMENT_BY_ID
} from "../actions/types";

const initialState = {
  showAddSectionModal: false,
  showEditSectionModal: false,
  showViewSectionModal: false,
  isEditingSection: false,
  requirementList: null,
  singleRequirementList: null,
  allRequirementList: [],
  requirementSection: null,
  sectionRequirement: null,
  subRequirement: null,
  singleSection: null,
  singleRequirement: null,
  singleSubRequirement: null,
  getSubRequirementById: null,
  requirementsBySectionId: null,
  subRequirementsByRequirementId: [],
  allSections: [],
  addSectionsForCreatingAuditTemplate: [],
  addRequirementsForCreatingAuditTemplate: [],
  allRequirements: [],
  requirementEditing: { isEdit: false, requirementListId: null },
  editRequirementList: null,
  mapRequirement: null,
  mapRequirementIds: [],
  allSubRequirements: [],
  editRequirementSection: null,
  editSectionRequirement: null,
  editSubRequirement: null,
  singleSectionById: null,
  isAddRequirementList: false,
  isAddSection: false,
  isAddRequirement: false,
  isAddSubRequirement: false,
  getCurrentAddedRequirementListId: null,
  allRequirementsByRequirementListId: null,
  allSubRequirementsByRequirementListId: null,
  singleRequirementById: null,
  createReference: null,
  editReference: null,
  allReferences: [],
  allMapRequirement: [],
  singleMapRequirement: null,
  deleteReference: null,
  isRequirementListEdit: false,
};

export default (state = initialState, { type, payload }) => {
  console.log("TYPE::");
  console.log(type);
  console.log("PAYLOAD::");
  console.log(payload);
  switch (type) {
    case CHANGE_IS_REQUIREMENT_LIST_EDIT:
      return {
        ...state,
        isRequirementListEdit: payload,
      };
    case CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA:
      return {
        ...state,
        requirementsBySectionId: null,
        subRequirementsByRequirementId: [],
      };
    case CHANGE_IS_ADD_REQUIREMENT_LIST:
      return {
        ...state,
        isAddRequirementList: payload,
      };
    case CHANGE_IS_ADD_SECTION:
      return {
        ...state,
        isAddSection: payload,
      };
    case CHANGE_IS_ADD_REQUIREMENT:
      return {
        ...state,
        isAddRequirement: payload,
      };
    case CHANGE_IS_ADD_SUB_REQUIREMENT:
      return {
        ...state,
        isAddSubRequirement: payload,
      };
    case CHANGE_REQUIREMENT_LIST:
      return {
        ...state,
        requirementList: payload,
      };
    case CHANGE_EDIT_REQUIREMENT_LIST:
      return {
        ...state,
        editRequirementList: payload,
      };
    case CHANGE_EDIT_REQUIREMENT_SECTION:
      return {
        ...state,
        editRequirementSection: payload,
      };
    case CHANGE_REQUIREMENT_SECTION:
      return {
        ...state,
        requirementSection: payload,
      };
    case CHANGE_GET_SUB_REQUIREMENT_BY_ID:
      return {
        ...state,
        getSubRequirementById: payload,
      };
    case CHANGE_GET_ALL_REQUIREMENT_LIST:
      return {
        ...state,
        allRequirementList: payload,
      };
    case CHANGE_GET_SINGLE_REQUIREMENT_LIST:
      return {
        ...state,
        singleRequirementList: payload,
      };
    case CHANGE_GET_SECTION_BY_ID:
      return {
        ...state,
        singleSectionById: payload,
      };
    case CHANGE_GET_REQUIREMENT_BY_ID:
      return {
        ...state,
        singleRequirementById: payload,
      };
    case CHANGE_GET_SINGLE_REQUIREMENT:
      return {
        ...state,
        singleRequirement: payload,
        singleMapRequirement: null,
        singleSubRequirement: null,
      };
    case CHANGE_GET_SINGLE_SUB_REQUIREMENT:
      return {
        ...state,
        singleSubRequirement: payload,
        singleRequirement: null,
        singleMapRequirement: null,
      };
    case CHANGE_CREATE_SECTION_REQUIREMENT:
      return {
        ...state,
        sectionRequirement: payload,
      };
    case CHANGE_EDIT_SECTION_REQUIREMENT:
      return {
        ...state,
        editSectionRequirement: payload,
      };
    case CHANGE_EDIT_SUB_REQUIREMENT:
      return {
        ...state,
        editSubRequirement: payload,
      };
    case CHANGE_CREATE_SUB_REQUIREMENT:
      return {
        ...state,
        subRequirement: payload,
      };
    case CHANGE_GET_ALL_SECTIONS:
      return {
        ...state,
        allSections: payload,
      };
    case CHANGE_GET_ALL_REQUIREMENTS:
      return {
        ...state,
        allRequirements: payload,
      };
    case CHANGE_GET_SINGLE_SECTION:
      return {
        ...state,
        singleSection: payload,
      };
    case CHANGE_GET_ALL_SUB_REQUIREMENTS:
      return {
        ...state,
        allSubRequirements: payload,
      };
    case CHANGE_CREATE_REFERENCE:
      return {
        ...state,
        createReference: payload,
      };
    case CHANGE_GET_ALL_REFERENCES:
      return {
        ...state,
        allReferences: payload,
      };
    case CHANGE_EDIT_REFERENCE:
      return {
        ...state,
        editReference: payload,
      };
    case CHANGE_DELETE_REFERENCE:
      return {
        ...state,
        deleteReference: payload,
      };
    case CHANGE_GET_REQUIREMENTS_BY_SECTION_ID:
      return {
        ...state,
        requirementsBySectionId: payload,
      };
    case CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID:
      return {
        ...state,
        allRequirementsByRequirementListId: payload,
      };
    case CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID:
      return {
        ...state,
        allSubRequirementsByRequirementListId: payload,
      };
    case CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID:
      return {
        ...state,
        getCurrentAddedRequirementListId: payload,
      };
    case CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID:
      return {
        ...state,
        subRequirementsByRequirementId: payload,
      };
    case CHANGE_CLEAR_REQUIREMENT_DATA:
      return {
        ...state,
        singleRequirementList: null,
        singleSectionById: null,
        singleRequirementById: null,
        getSubRequirementById: null,
      };
    case CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT:
      return {
        ...state,
        addRequirementsForCreatingAuditTemplate: [],
        addSectionsForCreatingAuditTemplate: [],
      };
    case CHANGE_IS_EDITING_SECTION:
      return {
        ...state,
        isEditingSection: payload,
      };
    case CHANGE_SHOW_EDIT_SECTION_MODAL:
      return {
        ...state,
        showEditSectionModal: payload,
      };
    case CHANGE_SHOW_VIEW_SECTION_MODAL:
      return {
        ...state,
        showViewSectionModal: payload,
      };
    case MAP_REQUIREMENT:
      return {
        ...state,
        mapRequirement: payload,
      };
    case GET_ALL_MAP_REQUIREMENT:
      return {
        ...state,
        allMapRequirement: payload,
      };
    case GET_SINGLE_MAP_REQUIREMENT:
      return {
        ...state,
        singleMapRequirement: payload,
        singleRequirement: null,
      };
    case MAP_REQUIREMENT_IDS:
      return {
        ...state,
        mapRequirementIds: payload,
      };
    case CHANGE_SHOW_ADD_SECTION_MODAL:
      return {
        ...state,
        showAddSectionModal: payload,
      };
    case CHANGE_REQUIREMENT_EDITING:
      return {
        ...state,
        requirementEditing: payload,
      };
    case CLEAR_SINGLE_REQUIREMENT_LIST_DATA:
      return {
        ...state,
        singleRequirementList: null,
      };

    case CLEAR_SUB_REQUIREMENT_BY_ID:
      return {
        ...state,
        getSubRequirementById: null,
      };
    default:
      return state;
  }
};
