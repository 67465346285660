import React, { useState } from "react";
import { batch, connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import avatar from "assets/img/faces/default-avatar.png";
// import logo from "assets/img/react-logo.png";
import logo from "assets/img/logo.png";
import { ManageUser } from "store/actions/userAction";
import { messageHandler } from "utils/popupMessage";
import { ChangeIsRequirementListEdit } from "store/actions/requirmentActions";
import { ClearSingleRequirementListData } from "store/actions/requirmentActions";
import { ChangeIsAddRequirementList } from "store/actions/requirmentActions";
import { ChangeIsAddRequirement } from "store/actions/requirmentActions";
import { ChangeIsAddSection } from "store/actions/requirmentActions";
import { ChangeIsAddSubRequirement } from "store/actions/requirmentActions";
import { ClearPreviousRequirementsAndSubRequirementsData } from "store/actions/requirmentActions";
import { baseUrl } from "config/baseUrl";

var ps;

function Sidebar(props) {
  const userJson = JSON.parse(localStorage.getItem("user"));
  const [alert, setAlert] = useState(null);
  const { setSidebarMini } = props.shared;
  const {
    ManageUser,
    audit,
    ChangeIsRequirementListEdit,
    ClearSingleRequirementListData,
    ChangeIsAddRequirementList,
    ChangeIsAddRequirement,
    ChangeIsAddSection,
    ChangeIsAddSubRequirement,
    ClearPreviousRequirementsAndSubRequirementsData,
  } = props;
  const { isRequirementListEdit } = props.requirment;
  const { auditNameForSidebar } = audit;
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  const history = useHistory();

  // check if name legth of audit is greater then 6

  var auditNameShow = window.localStorage.getItem("audit_name");

  if (auditNameShow?.length > 6) {
    var matches = auditNameShow.match(/\b(\w)/g);
    var acronym = matches.join("");
    auditNameShow = acronym;
  }

  console.log("nameA", auditNameShow);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /user/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    console.log("routes", routes);
    return routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
              className={prop.classes}
            >
              {prop.icon !== undefined ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: "0px solid red",
                  }}
                >
                  <i>{prop.icon}</i>
                  <p className={prop.classes}>{prop.name}</p>
                  <div
                    style={{
                      border: "0px solid red",
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      style={{ position: "inherit" }}
                      className="caret"
                    ></span>
                  </div>
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className={`sidebar-normal ${prop.classes}`}>
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          {window.localStorage.getItem("audit_life_cycle") ? (
            <NavLink
              to={prop.layout + prop.path}
              activeClassName=""
              className={prop.classes}
            >
              {prop.icon !== undefined ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <i>{prop.icon}</i>
                    <p className={prop.classes}>{prop.name}</p>
                  </div>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span
                    className="sidebar-normal"
                    onClick={() => {
                      prop.name === "Create Requirement" &&
                        ChangeIsRequirementListEdit(false);
                      ChangeIsAddRequirementList(false);
                      ChangeIsAddRequirement(false);
                      ChangeIsAddSection(false);
                      ChangeIsAddSubRequirement(false);
                      ClearSingleRequirementListData(false);
                      ClearPreviousRequirementsAndSubRequirementsData();
                    }}
                  >
                    {(window.localStorage.getItem("audit_name") !== "" &&
                      prop.name === "ASSESSMENT NAME") ||
                      prop.name === "AUDIT TITLE"
                      ? // ? auditNameForSidebar
                      auditNameShow
                      : prop.name}
                  </span>
                </>
              )}
            </NavLink>
          ) : (
            prop.name !== "Life Cycle" &&
            prop.name !== "Assessment  Summary" &&
            prop.name !== "Versions" && (
              <NavLink
                to={prop.layout + prop.path}
                activeClassName=""
                className={prop.classes}
              >
                {prop.icon !== undefined ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <i>{prop.icon}</i>
                      <p className={prop.classes}>{prop.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span
                      className="sidebar-normal"
                      onClick={() => {
                        prop.name === "Create Requirement" &&
                          ChangeIsRequirementListEdit(false);
                        ChangeIsAddRequirementList(false);
                        ChangeIsAddRequirement(false);
                        ChangeIsAddSection(false);
                        ChangeIsAddSubRequirement(false);
                        ClearSingleRequirementListData(false);
                        ClearPreviousRequirementsAndSubRequirementsData();
                      }}
                    >
                      {(window.localStorage.getItem("audit_name") !== "" &&
                        prop.name === "ASSESSMENT NAME") ||
                        prop.name === "AUDIT TITLE"
                        ? // ? auditNameForSidebar
                        auditNameShow
                        : prop.name}
                    </span>
                  </>
                )}
              </NavLink>
            )
          )}
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);
  return (
    <>
      {alert}
      <div
        className="sidebar"
        data-color={props.bgColor}
        data-active-color={props.activeColor}
      >
        <div className="logo">
          <a
            href="#"
            className="simple-text logo-mini"
            style={{ display: setSidebarMini ? "block" : "none" }}
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="#"
            className="simple-text logo-normal"
            style={{ textAlign: "center" }}
          >
            Compliance Solution
          </a>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo">
              <img
                src={
                  userJson?.image
                    ? `${baseUrl}/public${userJson?.image}`
                    : avatar
                }
                alt="Avatar"
              />
            </div>
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={(e) => {
                  // console.log(e)
                  e.preventDefault();
                  setOpenAvatar(!openAvatar);
                }}
              >
                <span>
                  {JSON.parse(window.localStorage.getItem("user"))?.name}
                  {/* Chet Faker */}
                  <b className="caret" />
                </span>
              </a>
              {/* </div> */}
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  <>
                    <li>
                      <NavLink to="/user/user-profile" activeClassName="">
                        <span className="sidebar-mini-icon">MP</span>
                        <span className="sidebar-normal">My Profile</span>
                      </NavLink>
                    </li>
                    {/* <li>
                          <NavLink to="/user/user-profile" activeClassName="">
                            <span className="sidebar-mini-icon">EP</span>
                            <span className="sidebar-normal">Edit Profile</span>
                          </NavLink>
                        </li> */}
                    {/* <li>
                          <NavLink to="/user/user-profile" activeClassName="">
                            <span className="sidebar-mini-icon">S</span>
                            <span className="sidebar-normal">Settings</span>
                          </NavLink>
                        </li> */}
                  </>

                  <li>
                    <NavLink
                      to=""
                      activeClassName=""
                      onClick={() => {
                        ManageUser(null);
                        window.localStorage.removeItem("currentLoginUserRole");
                        window.localStorage.removeItem("token");
                        window.localStorage.removeItem("user");
                        window.localStorage.removeItem("audit_life_cycle");
                        window.localStorage.removeItem("audit_name");
                        history.push("/auth/login");
                      }}
                    >
                      <span className="sidebar-mini-icon">L</span>
                      <span className="sidebar-normal">Logout</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ shared, user, audit, requirment }) => ({
  shared,
  user,
  audit,
  requirment,
});

export default connect(mapStateToProps, {
  ManageUser,
  ChangeIsRequirementListEdit,
  ClearSingleRequirementListData,
  ChangeIsRequirementListEdit,
  ChangeIsAddRequirementList,
  ChangeIsAddRequirement,
  ChangeIsAddSection,
  ChangeIsAddSubRequirement,
  ClearPreviousRequirementsAndSubRequirementsData,
})(Sidebar);
