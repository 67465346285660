import {
  CHANGE_SHOW_ADD_SECTIONS_MODAL,
  CHANGE_SHOW_ADD_AUDIT_MODAL,
  CHANGE_SHOW_ADD_REQUIRMENTS_MODAL,
  CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
  CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL,
  ADD_SECTIONS_FOR_CREATING_AUDIT,
  TOGGLE_COLLAPSED_SECTIONS,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT,
  CLEAR_AUDIT_SECTION_AND_REQUIREMENT,
  CHANGE_CREATE_AUDIT,
  CHANGE_GET_ALL_AUDIT,
  CHANGE_EDIT_AUDIT,
  CHANGE_GLOBAL_LOADER,
  CHANGE_GET_SINGLE_AUDIT,
  SET_AUDIT_NAME_FOR_SIDEBAR,
  GET_ALL_CONTRIBUTE_LEAD,
  CHANGE_EDIT_AUDIT_DATA,
  EMPTY_SINGLE_AUDIT_DATA,
  GET_VERSION_GRAPH,
  GET_SUMMARY_DETAILS,
  GET_AUDITOR_TASK_NOTIFICATION,
  GET_CONTRIBUTE_TASK_NOTIFICATION,
  GET_DISCUSSION_DATA,
  CREATE_DISCUSSION_DATA,
  GET_TOTAL_COMMENT,
  DELETE_COMMENT,
  GET_ALL_MAIN_REQ_LIST,
  ASSIGN_AUDIT_TO_CONTRIBUTE,
  INVITE_CONTRIBUTE_TO_AUDIT,
  INVITE_CONTRIBUTE_TO_AUDIT_TOKEN,
  INVITE_MULTI_SUPPORT_TO_AUDIT,
} from "./types";

import { baseUrl } from "config/baseUrl";
import { messageHandler } from "utils/popupMessage";

export const ToggleCollapsedSections = (val) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_COLLAPSED_SECTIONS, payload: val });
  };
};
export const ChangeIsEditingCompanyOrIndividual = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL, payload: val });
  };
};
export const ChangeShowAddCompanyOrIndividualModal = (val) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
      payload: val,
    });
  };
};
export const ChangeShowAddSectionsModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_SECTIONS_MODAL, payload: val });
  };
};
export const ChangeShowAddAuditModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_AUDIT_MODAL, payload: val });
  };
};
export const ChangeShowAddRequirmentsModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_REQUIRMENTS_MODAL, payload: val });
  };
};

export const EmptySingleAuditData = () => {
  return async (dispatch) => {
    dispatch({ type: EMPTY_SINGLE_AUDIT_DATA });
  };
};

// FIXME: For editing audit (2nd method)

export const EditAuditData = (val, model) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_EDIT_AUDIT_DATA, payload: val });
    // dispatch(ChangeShowAddAuditModal(true));
    // dispatch(GetSingleAudit(val.id, model));
    // model && model(true);
  };
};

export const AddSectionsForCreatingAudit = (val) => {
  return async (dispatch) => {
    dispatch({ type: ADD_SECTIONS_FOR_CREATING_AUDIT, payload: val });
  };
};

export const AddRequirementsForCreatingAudit = (val) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_REQUIREMENTS_FOR_CREATING_AUDIT,
      payload: val,
    });
  };
};

export const SetAuditNameForSidebar = (val) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUDIT_NAME_FOR_SIDEBAR,
      payload: val,
    });
  };
};

// FIXME: use it with createaudit action
export const ClearAuditSectionAndRequirement = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_AUDIT_SECTION_AND_REQUIREMENT,
    });
  };
};

// Create Audit
export const CreateAudit = (val, setAuditFormErrors, modal) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("company_id", JSON.stringify(val.company_id));
      formdata.append("user_id", JSON.stringify(val.user_id));
      formdata.append("status", JSON.stringify(val.status));
      formdata.append("name", val.audit_name);
      // formdata.append("entity_id", val.entity_id);
      formdata.append("template_id", val.audit_template);
      formdata.append("deadline", val.deadline);
      formdata.append("description", val.description);
      formdata.append("section_id", JSON.stringify(val.section_id));
      formdata.append("requirement_id", JSON.stringify(val.requirement_id));
      formdata.append("standard", val.standard);
      formdata.append("version_name", val.version_name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let audit = await fetch(
        `${baseUrl}/api/auditor/create-audit`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      console.log("audit", audit);
      if (audit.status == 200 && audit.data.success === true) {
        dispatch({
          type: CHANGE_CREATE_AUDIT,
          payload: audit.data,
        });
        console.log("audit added", audit.data);
        dispatch(GetAllAudit());
        dispatch(ClearAuditSectionAndRequirement());
        modal(false);
      } else {
        if (audit.data.success === false) {
          console.log("AUDIT VALIDATION CHECKING");
          console.log(audit);
          console.log(audit.data.message);
          setAuditFormErrors(audit.data.message);
          modal(true);
        }
        console.log("ERROR");
        console.log(audit);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Audits
export const GetAllAudit = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allAudit = await fetch(
        `${baseUrl}/api/auditor/all-audits`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allAudit.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_AUDIT,
          payload: allAudit.data.data,
        });
      } else {
        console.log("ERROR");
        // console.log(allAudit);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      // console.log(allAudit);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

//Get Single Audit
export const GetSingleAudit = (val, model) => {
  console.log(val);
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let audit = await fetch(
        `${baseUrl}/api/auditor/show-audit`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });

      if (audit.status == 200) {
        console.log("audit data", audit.data.data[0]);

        dispatch({
          type: CHANGE_GET_SINGLE_AUDIT,
          payload: audit.data.data[0],
        });
        dispatch(EditAuditData(audit.data.data[0]));
        // window.localStorage.setItem("auditName", audit.data.data[0].name);
        dispatch(SetAuditNameForSidebar(audit.data.data[0].name));
        model && model(true);
        // isModal &&
        //   dispatch({
        //     type: CHANGE_IS_EDITING_COMPANY_OR_INDIVIDUAL,
        //     payload: true,
        //   });
        // isModal &&
        //   dispatch({
        //     type: CHANGE_SHOW_ADD_COMPANY_OR_INDIVIDUAL_MODAL,
        //     payload: true,
        //   });
      } else {
        console.log("ERROR");
        console.log(audit);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Edit Audit
export const EditAudit = (val, setAuditFormErrors, modal) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", JSON.stringify(val.id));
      formdata.append("company_id", JSON.stringify(val.company_id));
      formdata.append("user_id", JSON.stringify(val.user_id));
      // formdata.append("status", JSON.stringify(val.status));
      formdata.append("name", val.audit_name);
      formdata.append("entity_id", val.entity_id);
      formdata.append("template_id", val.audit_template);
      formdata.append("deadline", val.deadline);
      formdata.append("description", val.description);
      formdata.append("section_id", JSON.stringify(val.section_id));
      formdata.append("requirement_id", JSON.stringify(val.requirement_id));
      formdata.append("standard", val.standard);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let audit = await fetch(
        `${baseUrl}/api/auditor/edit-audit`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      console.log("audit update", audit);
      if (audit.status == 200) {
        dispatch({
          type: CHANGE_EDIT_AUDIT,
          payload: audit.data,
        });
        console.log("audit update", audit.data);
        dispatch(GetAllAudit());
        dispatch(ClearAuditSectionAndRequirement());
        modal(false);
      } else {
        if (audit.data.success === false) {
          console.log("AUDIT VALIDATION CHECKING");
          console.log(audit);
          console.log(audit.data.message);
          setAuditFormErrors(audit.data.message);
          modal(true);
        }
        console.log("ERROR");
        console.log(audit);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      console.log(audit);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// remove val and history form parameter for now
export const GetSingleAuditAndPushToAuditSummary = () => {
  return async (dispatch) => {
    dispatch(GetSingleAudit(window.localStorage.getItem("audit_life_cycle")));
    // history.push("/user/assessment-summary");
  };
};

export const GetAllContributeLead = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let contributeLead = await fetch(
        `${baseUrl}/api/auditor/get-contribute-lead`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (contributeLead.status == 200) {
        dispatch({
          type: GET_ALL_CONTRIBUTE_LEAD,
          payload: contributeLead.data.data,
        });
        console.log(contributeLead.data.data);
      } else {
        console.log("ERROR");
        console.log(contributeLead);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get version graph
export const GetCompliantVsNoCompliant = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let versionGraph = await fetch(
        `${baseUrl}/api/auditor/version-graph`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (versionGraph.status == 200) {
        dispatch({
          type: GET_VERSION_GRAPH,
          payload: versionGraph.data.data,
        });
        // console.log("versionGraph", versionGraph);
      } else {
        console.log("ERROR");
      }

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Get version graph
export const SummaryDetails = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let summaryDetails = await fetch(
        `${baseUrl}/api/auditor/summary-details`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (summaryDetails.status == 200) {
        dispatch({
          type: GET_SUMMARY_DETAILS,
          payload: summaryDetails.data.data,
        });
        // console.log("versionGraph", versionGraph);
      } else {
        console.log("ERROR");
      }

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Get auditor notification
export const GetAuditorTaskNotification = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let taskNotification = await fetch(
        `${baseUrl}/api/auditor/tasks-notification`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (taskNotification.status == 200) {
        dispatch({
          type: GET_AUDITOR_TASK_NOTIFICATION,
          payload: taskNotification.data.data,
        });
        // console.log("versionGraph", versionGraph);
      } else {
        console.log("ERROR");
      }

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Get auditor notification
export const GetContributeTaskNotification = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let taskNotification = await fetch(
        `${baseUrl}/api/contribute_lead/tasks-notification`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (taskNotification.status == 200) {
        dispatch({
          type: GET_CONTRIBUTE_TASK_NOTIFICATION,
          payload: taskNotification.data.data,
        });
        // console.log("versionGraph", versionGraph);
      } else {
        console.log("ERROR");
      }

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Create Discussion Data
export const CreateDiscussionData = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("message", val.message);
      formdata.append("audit_requirement_id", val.audit_requirement_id);
      // formdata.append("image", val.image);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let comments = await fetch(
        `${baseUrl}/api/auditor/add-comment`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (comments.status == 200) {
        dispatch({
          type: CREATE_DISCUSSION_DATA,
          payload: comments.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Get Discussion Data
export const GetDiscussionData = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("audit_requirement_id", val.audit_requirement_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let comments = await fetch(
        `${baseUrl}/api/auditor/show-comments`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (comments.status == 200) {
        dispatch({
          type: GET_DISCUSSION_DATA,
          payload: comments.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// Get Total comment
export const GetTotalComment = (val) => {
  console.log("hell1" + JSON.stringify(val));
  return async (dispatch) => {
    try {
      var headers = new Headers();
      headers.append("Accept", "Application/json");
      headers.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("audit_requirement_id", val.audit_requirement_id);

      var requestOptions = {
        method: "POST",
        headers,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      // console.log("request : " + JSON.stringify(requestOptions));
      console.log("val.audit_requirement_id===>", val.audit_requirement_id);
      let comments = await fetch(
        `${baseUrl}/api/auditor/comment-count`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (comments.status == 200) {
        dispatch({
          type: GET_TOTAL_COMMENT,
          payload: comments.data.data,
        });
      } else {
        console.log("ERROR");
      }

      console.log("response" + JSON.stringify(comments));

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      console.log("404");
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

export const DeleteComment = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("comment_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let comments = await fetch(
        `${baseUrl}/api/auditor/delete-comment`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (comments.status == 200) {
        dispatch({
          type: DELETE_COMMENT,
          payload: comments.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};
// get all main requirement list
export const GetAllMainReqList = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let reqList = await fetch(
        `${baseUrl}/api/auditor/audit-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (reqList.status == 200) {
        dispatch({
          type: GET_ALL_MAIN_REQ_LIST,
          payload: reqList.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// get all main requirement list
export const AssignAuditToContribute = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("entity_id", val.entity_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let assignContribute = await fetch(
        `${baseUrl}/api/auditor/contribute-lead-to-audit`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (assignContribute.status == 200) {
        dispatch({
          type: ASSIGN_AUDIT_TO_CONTRIBUTE,
          payload: assignContribute.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// invitation contribute to audit
export const InviteContributeToAudit = (val, setAlert) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("role", val.role);
      formdata.append("email", val.email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let assignContribute = await fetch(
        `${baseUrl}/api/auditor/multi-invitation`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (assignContribute.status == 200) {
        dispatch({
          type: INVITE_CONTRIBUTE_TO_AUDIT,
          payload: assignContribute.data.data,
        });
        messageHandler(
          setAlert,
          "Email send successfully.",
          "for invite and assign",
          "success"
        );
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// invitation and verify token
export const InviteContributeToAuditToken = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("token", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let assignContribute = await fetch(
        `${baseUrl}/api/auth-token`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (assignContribute.status == 200) {
        dispatch({
          type: INVITE_CONTRIBUTE_TO_AUDIT_TOKEN,
          payload: assignContribute.data.data,
        });
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// invitation contribute to audit
export const InviteMultiSupportToAudit = (val, setAlert) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("role", val.role);
      formdata.append("emails", JSON.stringify(val.emails));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let assignMultiSupport = await fetch(
        `${baseUrl}/api/auditor/multi-invitation-support`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (assignMultiSupport.status == 200) {
        dispatch({
          type: INVITE_MULTI_SUPPORT_TO_AUDIT,
          payload: assignMultiSupport.data.data,
        });
        messageHandler(
          setAlert,
          "Email send successfully.",
          "for invite and assign",
          "success"
        );
      } else {
        console.log("ERROR");
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};
