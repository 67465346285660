import {
  FcPieChart,
  FcAreaChart,
  FcElectricalThreshold,
  FcStatistics,
  FcAcceptDatabase,
  FcDatabase,
  FcViewDetails,
  FcFilingCabinet,
  FcKindle,
  FcAnswers,
  FcDocument,
  FcRules,
  FcTemplate,
  FcWorkflow,
  FcProcess,
  FcRefresh,
  FcAssistant,
  FcCollaboration,
  FcBusinessman,
  FcTimeline,
} from "react-icons/fc";

import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
// import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";
import Login from "../src/views/User/Login.js";
import RequirementsList from "views/tables/RequirementsList";
import RequirementMapping from "views/tables/RequirementMapping";
import CreateRequirement from "views/forms/CreateRequirement";
import EditRequirement from "views/forms/EditRequirement";
import References from "views/tables/References";
import SectionDashboard from "views/tables/SectionDashboard";
import AuditingTemplates from "views/tables/AuditingTemplates";
import AllAudits from "views/Audit/AllAudits";
import AuditSummary from "views/Audit/AuditSummary";
import LifeCycle from "views/Audit/LifeCycle";
import CreateReference from "views/forms/CreateReference";
import Dashboard1 from "views/Dashboard1";
import RequirementsListDetail from "views/tables/RequirementsListDetail";
import RequirementsSubRequirementsTable from "views/tables/RequirementsSubRequirementsTable";
import RequirementMappingTable from "views/tables/RequirementMappingTable";
import AllCompanies from "views/Audit/AllCompanies";
import Versions from "views/Audit/Versions.js";
import VersionRequirements from "views/Audit/Versions/VersionRequirements.js";
import VersionRadarChart from "views/Audit/Versions/VersionRadarChart.js";
import AssessorLifeCycle from "views/Audit/AssessorLifeCycle.js";
import Register from "views/User/Register.js";
import SupportInviteAudit from "views/User/SupportInviteAudit.js";

const loginRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    layout: "/auth",
    invisible: true,
  },
  // {
  //   path: "/supportInviteAudit",
  //   name: "SupportInviteAudit",
  //   component: SupportInviteAudit,
  //   layout: "/auth",
  //   invisible: true,
  // },
];

export default loginRoutes;
