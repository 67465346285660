import React, { useState } from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { CreateNewReference } from "store/actions/requirmentActions";

function CreateReference(props) {
  const { CreateNewReference } = props;
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [internetResource, setInternetResource] = useState("");
  const [internalLinks, setInternalLinks] = useState("");
  const [complianceStandard, setComplianceStandard] = useState("");
  // const [title, setTitle] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: title,
      type: type,
      description: description,
      internet_resource: internetResource,
      internal_links: internalLinks,
      compliance_standard: complianceStandard,
    };

    CreateNewReference(data);

    setTitle("");
    setType("");
    setDescription("");
    setInternetResource("");
    setInternalLinks("");
    setComplianceStandard("");

    console.log(data);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Create Reference</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <label>Title</label>
                      <FormGroup>
                        <Input
                          placeholder="Enter title"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Type</label>
                      <FormGroup>
                        <select
                          name=""
                          id=""
                          className="form-control w-100"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="internal">Internal</option>
                          <option value="external">External</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Description</label>
                      <FormGroup>
                        <Input
                          placeholder="Enter description"
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Internet Resource</label>
                      <FormGroup>
                        <Input
                          placeholder="Enter internet Resource"
                          type="text"
                          value={internetResource}
                          onChange={(e) => setInternetResource(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="6">
                      <label>Description</label>
                      <FormGroup>
                        <Input placeholder="Enter description" type="text" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col md="6">
                      <label>Internal Links</label>
                      <FormGroup>
                        <input
                          className="form-control"
                          list="datalistOptions"
                          id="exampleDataList"
                          placeholder="Type to search..."
                        />
                        <datalist id="datalistOptions">
                          <option value="ISO 900" />
                          <option value="lorem" />
                          <option value="Demo" />
                          <option value="Requirement List" />
                          <option value="Demo List" />
                        </datalist>
                      </FormGroup>
                    </Col> */}
                    <Col md="6">
                      <FormGroup className="text-left">
                        <label>Internal Links</label>
                        <select
                          id=""
                          className="form-control"
                          value={internalLinks}
                          onChange={(e) => setInternalLinks(e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="lorem">lorem</option>
                          <option value="demo">Demo</option>
                          <option value="demoList">Demo List</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Compliance Standard</label>
                      <FormGroup>
                        <Input
                          placeholder=""
                          type="text"
                          value={complianceStandard}
                          onChange={(e) =>
                            setComplianceStandard(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = ({ requirment }) => ({
  requirment,
});
export default connect(mapStateToProps, {
  CreateNewReference,
})(CreateReference);
