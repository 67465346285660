import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";

// core components
// import PanelHeader from "components/PanelHeader/PanelHeader.js";

// import avatar from "assets/img/ryan.jpg";

function Inbox() {
  return (
    <>
      {/* <PanelHeader size="sm" /> */}
      <div className="content">
        <Card>
          <CardHeader className="">
            <div className="d-flex align-items-center  border-bottom">
              <p className="font-weight-bold align-middle align-items-center ml-3 mt-3">
                Lisa Thomposon
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <div className="text-left my-4">
              <span className=" bg-light rounded p-2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint .
              </span>{" "}
              <br />
              <small className="m-2 mt-5 text-secondary">Sent By Client</small>
            </div>

            <div className="text-right my-4">
              <span className=" border  rounded p-2">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod,
                nulla?
              </span>
            </div>
            <div className="text-left my-4">
              <span className=" bg-light rounded p-2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint .
              </span>{" "}
              <br />
              <small className="m-2 mt-5 text-secondary">Sent By Staff</small>
            </div>

            <div className="text-right my-4">
              <span className=" border  rounded p-2">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod,
                nulla?
              </span>
            </div>
          </CardBody>
          <CardFooter className="bg-light mt-5">
            <div className="d-flex align-items-center p-1">
              <i className="fas fa-plus-circle m-2 text-info"></i>
              <Input placeholder="Message" className="m-2" />
              <Button size="sm" className="m-2" color="info">
                Send
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default Inbox;
