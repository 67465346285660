import {
  CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL,
  CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL,
  CHANGE_IS_EDITING,
  CHANGE_UNDER_EDIT_TEMPLATE_TITLE,
  CHANGE_GET_ALL_AUDITING_TEMPLATE,
  CHANGE_DELETE_AUDITING_TEMPLATE,
  CHANGE_CREATE_AUDITING_TEMPLATE,
  CHANGE_EDIT_AUDITING_TEMPLATE_DATA,
  CHANGE_SINGLE_AUDITING_TEMPLATE,
  ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_EDIT_AUDITING_TEMPLATE,
  CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT,
} from "../actions/types";
const initialState = {
  showAddOriginalRequirmentModal: false,
  showAddAuditingTemplateModal: false,
  isEditing: false,
  underEditTemplateTitle: "",
  allAuditingTemplate: [],
  deleteAuditingTemplate: {},
  createAuditingTemplate: null,
  editAuditingTemplate: null,
  editAuditingTemplateData: null,
  singleAuditingTemplate: null,
  addSectionsForCreatingAuditTemplate: [],
  addRequirementsForCreatingAuditTemplate: [],
};

export default (state = initialState, { type, payload }) => {
  // console.log('TYPE::')
  // console.log(type)
  // console.log('PAYLOAD::')
  // console.log(payload)
  switch (type) {
    case CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT:
      return {
        ...state,
        addRequirementsForCreatingAuditTemplate: [],
        addSectionsForCreatingAuditTemplate: [],
      };
    case CHANGE_CREATE_AUDITING_TEMPLATE:
      return {
        ...state,
        createAuditingTemplate: payload,
      };
    case CHANGE_EDIT_AUDITING_TEMPLATE:
      return {
        ...state,
        editAuditingTemplate: payload,
      };
    case CHANGE_GET_ALL_AUDITING_TEMPLATE:
      return {
        ...state,
        allAuditingTemplate: payload,
      };
    case CHANGE_DELETE_AUDITING_TEMPLATE:
      return {
        ...state,
        deleteAuditingTemplate: payload,
      };
    case CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL:
      return {
        ...state,
        showAddOriginalRequirmentModal: payload,
      };
    case CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL:
      return {
        ...state,
        showAddAuditingTemplateModal: payload,
      };
    case CHANGE_IS_EDITING:
      return {
        ...state,
        isEditing: payload,
      };
    case CHANGE_SINGLE_AUDITING_TEMPLATE:
      return {
        ...state,
        singleAuditingTemplate: payload,
      };
    case ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE:
      return {
        ...state,
        addSectionsForCreatingAuditTemplate: payload,
      };
    case ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE:
      return {
        ...state,
        addRequirementsForCreatingAuditTemplate: payload,
      };
    case CHANGE_UNDER_EDIT_TEMPLATE_TITLE:
      return {
        ...state,
        underEditTemplateTitle: payload,
      };
    case CHANGE_EDIT_AUDITING_TEMPLATE_DATA:
      const sectionsForEditAuditingTemplate = payload?.sections.map(
        (section) => section.section_id
      );
      const requirementsForEditAuditingTemplate = payload?.requirements.map(
        (requirement) => requirement.requirement_id
      );
      return {
        ...state,
        addSectionsForCreatingAuditTemplate: sectionsForEditAuditingTemplate,
        addRequirementsForCreatingAuditTemplate:
          requirementsForEditAuditingTemplate,
        editAuditingTemplateData: payload,
      };
    default:
      return state;
  }
};
