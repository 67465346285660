import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap";
import { CheckBox } from "../CheckBox";

import {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  CreateAuditingTemplate,
  GetAllAuditingTemplate,
  EditAuditingTemplate,
  ClearAuditTemplateSectionAndRequirement,
} from "../../../store/actions/auditingTemplateActions";
import { ChangeShowAddRequirmentsModal } from "../../../store/actions/auditActions";
import Requirments from "views/Audit/AllAudits/Requirments";
import Sections from "views/Audit/AllAudits/Sections";
import { AddRequirementsForCreatingAuditTemplate } from "store/actions/auditingTemplateActions";
import { AddSectionsForCreatingAuditTemplate } from "store/actions/auditingTemplateActions";

const AddNewAuditingTemplate = (props) => {
  const {
    showAddOriginalRequirmentModal,
    showAddAuditingTemplateModal,
    isEditing,
    underEditTemplateTitle,
    editAuditingTemplateData,
    addSectionsForCreatingAuditTemplate,
    addRequirementsForCreatingAuditTemplate,
  } = props.auditingTemplate;
  const { allSections, allRequirements } = props.requirment;
  const {
    ChangeShowAddOriginalRequirmentModal,
    ChangeShowAddAuditingTemplateModal,
    ChangeIsEditing,
    ChangeUnderEditTemplateTitle,
    ChangeShowAddRequirmentsModal,
    CreateAuditingTemplate,
    GetAllAuditingTemplate,
    AddRequirementsForCreatingAuditTemplate,
    AddSectionsForCreatingAuditTemplate,
    EditAuditingTemplate,
    ClearAuditTemplateSectionAndRequirement,
  } = props;

  const [templateName, setTemplateName] = useState(
    isEditing && editAuditingTemplateData?.name
      ? editAuditingTemplateData?.name
      : ""
  );
  const [selectedSectionsData, setSelectedSectionsData] = useState([]);
  const [selectedSectionsRequirements, setSelectedSectionsRequirements] =
    useState([]);
  const [selectedRequirementsData, setSelectedRequirementsData] = useState([]);

  console.log(
    ":::::::::::::::::::::::::::editAuditingTemplateData::::::::::::::::::::"
  );
  console.log(editAuditingTemplateData);

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };
  var dataTable = useMemo(
    () => [
      {
        section: "Section 1",
        standardRef: "Requirment 1",
        desc: "Requirment 1 desc",
        requirmentList: "Req List 1",
        references: RenderTags(["ref#1", "ref#2"]),
        requirementMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Standard Ref.",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirment List",
        accessor: "requirmentList",
      },
      {
        Header: "Requirement Mapping",
        accessor: "requirementMapping",
      },
    ],
    []
  );
  const [data, SetData] = useState(dataTable);
  const [horizontalTabs, setHorizontalTabs] = React.useState("sections");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = React.useState(null);
  const successDelete = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Removed!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement is removed from audit template
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  console.log(editAuditingTemplateData);

  const [templateNameError, setTemplateNameError] = useState("");

  const handleTemplateDataSubmit = () => {
    // const requirementsId = selectedSectionsRequirements.map(
    //   (requirement) => requirement.id
    // );

    // console.log("REQUIREMENT IDSSSS");
    // console.log(requirementsId);

    if (templateName === "") {
      setTemplateNameError("Please enter template name");
    } else {
      const data = {
        name: templateName,
        type: 1,
        section_id: addSectionsForCreatingAuditTemplate,
        // requirement_id: requirementsId,
        requirement_id: addRequirementsForCreatingAuditTemplate,
      };
      CreateAuditingTemplate(data);
      ChangeShowAddAuditingTemplateModal(false);
      setTemplateNameError("");
      console.log("CREATE AUDITING TEMPLATE DEBUGGING");
      console.log(allSections);
      console.log(data);
    }
  };

  const handleEditAuditTemplateHandle = () => {
    // const requirementsId = selectedSectionsRequirements.map(
    //   (requirement) => requirement.id
    // );

    if (templateName === "") {
      setTemplateNameError("Please enter template name");
    } else {
      const data = {
        id: editAuditingTemplateData.id,
        name: templateName,
        type: 1,
        section_id: addSectionsForCreatingAuditTemplate,
        requirement_id: addRequirementsForCreatingAuditTemplate,
      };
      EditAuditingTemplate(data);
      // console.log(allSections);
      console.log("EDIT AUDITING TEMPLATE DEBUGGING");
      console.log(data);
    }
  };

  useEffect(() => {
    let sectionsData = allSections.filter((section) =>
      addSectionsForCreatingAuditTemplate.includes(section.id)
    );
    setSelectedSectionsData(sectionsData.flat());
  }, [addSectionsForCreatingAuditTemplate]);

  useEffect(() => {
    // const sectionsForEditAuditingTemplate =
    //   editAuditingTemplateData?.sections.map((section) => section.section_id);
    // AddSectionsForCreatingAuditTemplate([
    //   ...addSectionsForCreatingAuditTemplate,
    //   ...sectionsForEditAuditingTemplate,
    // ]);
    // console.log(sectionsForEditAuditingTemplate);
    // let sectionsData = allSections.filter((section) =>
    //   sectionsForEditAuditingTemplate.includes(section.id)
    // );
    // console.log(selectedSectionsData);
    // setSelectedSectionsData([...selectedSectionsData, ...sectionsData.flat()]);
    // console.log(sectionsData);
  }, [editAuditingTemplateData]);

  useEffect(() => {
    // const requirementsForEditAuditingTemplate =
    //   editAuditingTemplateData?.requirements.map(
    //     (requirement) => requirement.requirement_id
    //   );
    // AddRequirementsForCreatingAuditTemplate([
    //   ...addRequirementsForCreatingAuditTemplate,
    //   ...requirementsForEditAuditingTemplate,
    // ]);
    // console.log(requirementsForEditAuditingTemplate);
    // let requirementsData = allRequirements.filter((requirement) =>
    //   requirementsForEditAuditingTemplate.includes(requirement.id)
    // );
    // console.log(requirementsData);
    // setSelectedRequirementsData(requirementsData.flat());
    // console.log(requirementsData);
  }, [editAuditingTemplateData]);

  console.log("SELECTED SECTION DATA========");
  console.log(selectedSectionsData);
  console.log(editAuditingTemplateData);

  useEffect(() => {
    let requirementsData = allRequirements.filter((requirement) =>
      addRequirementsForCreatingAuditTemplate.includes(requirement.id)
    );
    setSelectedRequirementsData(requirementsData.flat());
    console.log(requirementsData);
  }, [addRequirementsForCreatingAuditTemplate]);

  useEffect(() => {
    let sectionsRequirements = selectedSectionsData.map(
      (section) => section.requirements
    );
    setSelectedSectionsRequirements(sectionsRequirements.flat());
    console.log("SELECTED SECTIONS REQUIREMENTS DATA========");
    console.log(sectionsRequirements);
  }, [selectedSectionsData]);

  // useEffect(() => {
  //   setSelectedSectionsRequirements(
  //     [...selectedSectionsRequirements, selectedRequirementsData].flat()
  //   );
  // }, [selectedRequirementsData]);
  useEffect(() => {
    setSelectedSectionsRequirements(selectedSectionsRequirements);
  }, [selectedRequirementsData]);

  console.log(selectedSectionsData);
  console.log(selectedRequirementsData);
  console.log(selectedSectionsRequirements);

  // useEffect(() => {
  //   console.log(addSectionsForCreatingAuditTemplate);
  // }, [addSectionsForCreatingAuditTemplate]);

  return (
    <>
      {alert}
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            ChangeShowAddAuditingTemplateModal(false);
            ClearAuditTemplateSectionAndRequirement();
            setTemplateName("");
          }}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">
          {isEditing ? "Edit Assessment Template" : "New Assessment Template"}
        </h4>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col md="12">
              <FormGroup>
                <Label>Template Name</Label>
                <Input
                  invalid={templateNameError !== "" ? true : false}
                  type="text"
                  placeholder="Enter Template Name"
                  value={templateName}
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                  }}
                />
                <FormFeedback>{templateNameError}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav id="tabs" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    aria-expanded={horizontalTabs === "sections"}
                    data-toggle="tab"
                    role="tab"
                    className={horizontalTabs === "sections" ? "active" : ""}
                    onClick={() => setHorizontalTabs("sections")}
                  >
                    Sections
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-expanded={horizontalTabs === "requirements"}
                    data-toggle="tab"
                    role="tab"
                    className={
                      horizontalTabs === "requirements" ? "active" : ""
                    }
                    onClick={() => setHorizontalTabs("requirements")}
                  >
                    Requirments
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent
            className="text-center"
            id="my-tab-content"
            activeTab={horizontalTabs}
          >
            <TabPane tabId="sections" role="tabpanel">
              <Sections
                isEditing={true}
                selectedSectionsData={selectedSectionsData}
              />
            </TabPane>
            <TabPane tabId="requirements" role="tabpanel">
              <Requirments
                // selectedSectionsRequirements={selectedSectionsRequirements}
                selectedSectionsRequirements={[
                  ...selectedSectionsRequirements,
                  ...selectedRequirementsData,
                ].flat()}
              />
            </TabPane>
          </TabContent>
          <div className="text-right">
            {isEditing ? (
              <Button
                color="success"
                onClick={() => {
                  handleEditAuditTemplateHandle();
                  ChangeShowAddAuditingTemplateModal(false);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="success"
                onClick={() => {
                  handleTemplateDataSubmit();
                  // ChangeShowAddAuditingTemplateModal(false);
                  // GetAllAuditingTemplate();
                }}
              >
                Save
              </Button>
            )}
            <Button
              color="danger"
              onClick={() => {
                ChangeShowAddAuditingTemplateModal(false);
                ClearAuditTemplateSectionAndRequirement();
                setTemplateName("");
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = ({
  requirment,
  shared,
  styling,
  auditingTemplate,
}) => ({
  shared,
  styling,
  auditingTemplate,
  requirment,
});

export default connect(mapStateToProps, {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  ChangeShowAddRequirmentsModal,
  CreateAuditingTemplate,
  GetAllAuditingTemplate,
  AddRequirementsForCreatingAuditTemplate,
  AddSectionsForCreatingAuditTemplate,
  EditAuditingTemplate,
  ClearAuditTemplateSectionAndRequirement,
})(AddNewAuditingTemplate);
