import {
  CHANGE_SET_SIDEBAR_MINI,
  CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL,
  CHANGE_SHOW_VIEW_REQUIREMENT_MODAL,
  CHANGE_VIEWING_SUB_REQUIREMENTS,
  CHANGE_GLOBAL_LOADER,
  CHANGE_GET_ALL_COUNTRIES,
  CHANGE_GET_STATES_BY_COUNTRY,
  CHANGE_GET_CITIES_BY_STATE,
  CHANGE_IS_ADD_NEW_AUDIT,
} from "./types";
import { baseUrl } from "config/baseUrl";

export const ChangeViewingSubRequirements = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_VIEWING_SUB_REQUIREMENTS, payload: val });
  };
};
export const ChangeShowViewRequirementModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_VIEW_REQUIREMENT_MODAL, payload: val });
  };
};
export const ChangeShowRequirementListDeailsModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL, payload: val });
  };
};
export const ChangeSetSidebarMini = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SET_SIDEBAR_MINI, payload: val });
  };
};
export const ChangeGlobalLoader = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_GLOBAL_LOADER, payload: val });
  };
};
export const ChangeIsAddNewAudit = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_ADD_NEW_AUDIT, payload: val });
  };
};

// Get All Countries
export const GetAllCountries = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let countries = await fetch(
        `${baseUrl}/api/auditor/get-all-countries`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (countries.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_COUNTRIES,
          payload: countries.data.data,
        });
        console.log(countries);
      } else {
        console.log("ERROR");
        console.log(countries);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// // Get States By Country
// export const GetStatesByCountry = (val) => {
//   return async (dispatch) => {
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append("Accept", "Application/json");
//       myHeaders.append(
//         "Authorization",
//         `Bearer ${window.localStorage.getItem("token")}`
//       );

//       var formdata = new FormData();
//       formdata.append("id", val);

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: formdata,
//         redirect: "follow",
//       };

//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: true,
//       });

//       let states = await fetch(
//         `${baseUrl}/api/auditor/get-states-by-country`,
//         requestOptions
//       ).then((response) => {
//         return response.json().then((data) => ({
//           status: response.status,
//           data,
//         }));
//       });
//       if (states.status == 200) {
//         dispatch({
//           type: CHANGE_GET_STATES_BY_COUNTRY,
//           payload: states.data.data,
//         });
//         console.log(states);
//       } else {
//         console.log("ERROR");
//         console.log(states);
//       }
//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: false,
//       });
//     } catch (error) {
//       console.log(error);
//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: false,
//       });
//     }
//   };
// };

// Get Cities By State
// export const GetCitiesByState = (val) => {
//   return async (dispatch) => {
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append("Accept", "Application/json");
//       myHeaders.append(
//         "Authorization",
//         `Bearer ${window.localStorage.getItem("token")}`
//       );

//       var formdata = new FormData();
//       formdata.append("id", val);

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: formdata,
//         redirect: "follow",
//       };

//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: true,
//       });

//       let cities = await fetch(
//         `${baseUrl}/api/auditor/get-cities-by-state`,
//         requestOptions
//       ).then((response) => {
//         return response.json().then((data) => ({
//           status: response.status,
//           data,
//         }));
//       });
//       if (cities.status == 200) {
//         dispatch({
//           type: CHANGE_GET_CITIES_BY_STATE,
//           payload: cities.data.data,
//         });
//         console.log(cities);
//       } else {
//         console.log("ERROR");
//         console.log(cities);
//       }
//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: false,
//       });
//     } catch (error) {
//       console.log(error);
//       dispatch({
//         type: CHANGE_GLOBAL_LOADER,
//         payload: false,
//       });
//     }
//   };
// };
