import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import img from "../../assets/img/faces/default-avatar.png";
import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import PerfectScrollbar from "perfect-scrollbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import ReactFileReader from "react-file-reader";

import fileDownload from "js-file-download";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Form,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap";

import "../../styling/life-cycle.css";
import { styles } from "styling/custom";
import moment from "moment";
import { GetAllRequirements } from "store/actions/requirmentActions";
import { GetAuditorLifeCycle } from "store/actions/lifeCycleAction";
import { DeleteAuditorLifeCycleRequirement } from "store/actions/lifeCycleAction";
import { MoveAuditorLifeCycleRequirementsToResponse } from "store/actions/lifeCycleAction";
import { ShowAuditRequirementAnswer } from "store/actions/lifeCycleAction";
import {
  GiveAnswerAgainstRequirementOrSubRequirement,
  ClearAllTaskData,
} from "store/actions/lifeCycleAction";
import {
  CreateTask,
  GetAllTask,
  GetVersionFiles,
} from "store/actions/lifeCycleAction";
import { ShowAuditRequirementAnswerTask } from "store/actions/lifeCycleAction";
import { ClearAuditAnswerTaskData } from "store/actions/lifeCycleAction";
import {
  CreateDiscussionData,
  GetDiscussionData,
  GetTotalComment,
  DeleteComment,
} from "store/actions/auditActions.js";
import {
  ClearAuditRequirementAnswerData,
  ClearAuditAnswerTaskAnswerIteration,
} from "store/actions/lifeCycleAction";
import { messageHandler } from "utils/popupMessage";
import { GetRadarChart } from "store/actions/lifeCycleAction";

const data = {
  labels: ["Yes", "No", "Partial"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
var ps;

// var ps1;
function AssessorLifeCycle(props) {
  // `<a href=${process.env.REACT_APP_BASE_URL}/public/${showAuditAnswer?.evidence_attachment} download target="_blank"><i class="fas fa-file text-info"></i></a>`

  //download or view file
  function downloadFile(fileURL, fileName) {
    // window.open(link, '_blank');
    // var element = document.createElement('a');
    // element.setAttribute('href', link);
    // link.download = 'file.pdf';
    // link.dispatchEvent(new MouseEvent('click'));

    // element.style.display = 'none';
    // document.body.appendChild(element);

    // element.click();

    // document.body.removeChild(element);

    console.log(fileURL);
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL?.substring(fileURL?.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent?.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const [
    currentSelectedRequirementDetails,
    setCurrentSelectedRequirementDetails,
  ] = useState(null);
  console.log("currentSelectedRequirementDetails");
  console.log(currentSelectedRequirementDetails);
  useEffect(() => {
    const container = document.querySelector("#scroll-bar");
    ps = new PerfectScrollbar(container, {
      // useBothWheelAxes: true,
      suppressScrollX: true,
    });
  }, []);
  const [modalClassic, setModalClassic] = useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };
  const [alert, setAlert] = useState(null);
  const [multipleSelect, setMultipleSelect] = useState(null);
  const [repeatFrequency, setRepeatFrequency] = useState("once");
  const [singleSelect, setSingleSelect] = useState(null);
  const [auditStatus, setAuditStatus] = useState(false);
  // const [auditAnswer, setAuditAnswer] = useState("No");
  const [assessorDecision, setAssessorDecision] = useState("");
  const [showingRequirments, ChangeShowingRequirments] = useState(true);
  const [showingCompliance, ChangeShowingCompliance] = useState(true);
  const [showingPartial, ChangeShowingPartial] = useState(true);
  const [selectedItem, ChangeSelectedItem] = useState(null);
  const [taskModal, ChangeTaskModal] = useState(false);
  const [lifeCycleTabs, ChangeLifeCycleTabs] = useState("lifecycle");
  const [requirementTabs, ChangeRequirementTabs] = useState("details");
  const [attachmentTabs, ChangeAttachmentTabs] = useState("table");
  const [showCreateTaskModal, ChangeShowCreateTaskModal] = useState(false);
  const [AssessorComment, ChangeAssessorComment] = useState("");
  const [taskName, setTaskName] = useState("");
  const [standardRef, setStandardRef] = useState("");
  const [auditAnswer, setAuditAnswer] = useState("No");
  const [partialPercent, setPartialPercent] = useState();
  const [partialDescription, setPartialDescription] = useState();
  const [evidenceDetail, setEvidenceDetail] = useState();
  const [contributeComment, SetContributeComment] = useState();
  const [auditAnswerTask, setAuditAnswerTask] = useState("No");
  const [partialPercentTask, setPartialPercentTask] = useState();
  const [partialDescriptionTask, setPartialDescriptionTask] = useState();
  const [evidenceDetailTask, setEvidenceDetailTask] = useState();
  const [contributeCommentTask, SetContributeCommentTask] = useState();
  const [getSubRequirementId, setGetSubRequirementId] = useState();
  const [assessorCommentTask, setAssessorCommentTask] = useState("");
  const [generalDescription, setGeneralDescription] = useState("");
  const [generalClarification, setGeneralClarification] = useState("");
  const [chatComment, setChatComment] = useState("");
  const [checkallrequirements, setCheckallrequirements] = useState(false);
  const [getError, setGetError] = useState(null);

  console.log(checkallrequirements);
  console.log("getError", getError);
  console.log("assessorDecision", assessorDecision);

  console.log("getSubRequirementId", getSubRequirementId);
  console.log("assessorComemnt", assessorCommentTask);
  const {
    GetAllRequirements,
    GetAuditorLifeCycle,
    DeleteAuditorLifeCycleRequirement,
    MoveAuditorLifeCycleRequirementsToResponse,
    ShowAuditRequirementAnswer,
    GiveAnswerAgainstRequirementOrSubRequirement,
    ClearAllTaskData,
    CreateTask,
    GetAllTask,
    ShowAuditRequirementAnswerTask,
    ClearAuditAnswerTaskData,
    ClearAuditRequirementAnswerData,
    ClearAuditAnswerTaskAnswerIteration,
    GetVersionFiles,
    GetTotalComment,
    CreateDiscussionData,
    GetRadarChart,
    GetDiscussionData,
    DeleteComment,
  } = props;
  const {
    discussion,
    singleAudit,
    getCommentCount,
    createDiscussion,
    deleteComment,
  } = props.audit;
  const { allRequirements, getSubRequirementById } = props.requirment;
  const {
    getAuditorLifeCycle,
    showAuditAnswer,
    allTask,
    showAuditAnswerTask,
    versionFiles,
    getRadarChart,
  } = props.lifeCycle;

  console.log("showTask", allTask);
  console.log("versionFiles", versionFiles);
  console.log("discussion", discussion);
  console.log("getSubRequirementById", getSubRequirementById);


 

  const history = useHistory();

  //TODO: Requirement show answer

  useEffect(() => {
    setAuditAnswer(
      showAuditAnswer && showAuditAnswer?.answer_status
        ? {
            label: showAuditAnswer?.answer_status,
            value: showAuditAnswer?.id,
          }
        : "No"
    );
    setPartialDescription(
      showAuditAnswer && showAuditAnswer?.partial_description
        ? showAuditAnswer?.partial_description
        : ""
    );
    setPartialPercent(
      showAuditAnswer && showAuditAnswer?.partial_percentage
        ? showAuditAnswer?.partial_percentage
        : ""
    ),
      setEvidenceDetail(
        showAuditAnswer && showAuditAnswer?.evidence_details
          ? showAuditAnswer?.evidence_details
          : ""
      ),
      SetContributeComment(
        showAuditAnswer && showAuditAnswer?.contribute_comments
          ? showAuditAnswer?.contribute_comments
          : ""
      );
    setAssessorDecision(
      showAuditAnswer?.status === 1
        ? { label: "Accepted", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 2
        ? { label: "Rejected", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 3
        ? { label: "Minor Revision", value: showAuditAnswer?.status }
        : showAuditAnswer?.status === 4 && {
            label: "Major Revision",
            value: showAuditAnswer?.status,
          }
    );
    ChangeAssessorComment(
      showAuditAnswer?.auditor_comments ? showAuditAnswer?.auditor_comments : ""
    );
  }, [showAuditAnswer]);

  // TODO: Task Show Answer

  useEffect(() => {
    setAuditAnswerTask(
      showAuditAnswerTask && showAuditAnswerTask?.answer_status
        ? {
            label: showAuditAnswerTask?.answer_status,
            value: showAuditAnswerTask?.id,
          }
        : "No"
    );
    setPartialDescriptionTask(
      showAuditAnswerTask && showAuditAnswerTask?.partial_description
        ? showAuditAnswerTask?.partial_description
        : ""
    );
    setPartialPercentTask(
      showAuditAnswerTask && showAuditAnswerTask?.partial_percentage
        ? showAuditAnswerTask?.partial_percentage
        : ""
    );
    setEvidenceDetailTask(
      showAuditAnswerTask && showAuditAnswerTask?.evidence_details
        ? showAuditAnswerTask?.evidence_details
        : ""
    );
    SetContributeCommentTask(
      showAuditAnswerTask && showAuditAnswerTask?.contribute_comments
        ? showAuditAnswerTask?.contribute_comments
        : ""
    );
    // setAssessorDecision(
    //   showAuditAnswerTask?.status === 1
    //     ? { label: "Accepted", value: showAuditAnswerTask?.status }
    //     : showAuditAnswerTask?.status === 2
    //     ? { label: "Rejected", value: showAuditAnswerTask?.status }
    //     : showAuditAnswerTask?.status === 3
    //     ? { label: "Minor", value: showAuditAnswerTask?.status }
    //     : showAuditAnswerTask?.status === 4 && {
    //         label: "Major",
    //         value: showAuditAnswerTask?.status,
    //       }
    // );
    // setAssessorCommentTask(
    //   showAuditAnswerTask?.auditor_comments
    //     ? showAuditAnswerTask?.auditor_comments
    //     : ""
    // );
  }, [showAuditAnswerTask]);

  useEffect(() => {
    setGeneralDescription(
      getSubRequirementById?.data?.description
        ? getSubRequirementById?.data?.description
        : currentSelectedRequirementDetails?.description
    );
    setGeneralClarification(
      getSubRequirementById?.data?.clarification
        ? getSubRequirementById?.data?.clarification
        : currentSelectedRequirementDetails?.clarification
    );
  }, [getSubRequirementById, currentSelectedRequirementDetails]);

  // let dValue =
  //   currentSelectedRequirementDetails?.sub_requirements?.length > 0
  //     ? assessorCommentTask
  //     : showAuditAnswerTask?.answer_iteration >= 2
  //     ? assessorCommentTask
  //     : showAuditAnswer?.answer_iteration === 1
  //     ? AssessorComment
  //     : "";

  const [assessorCommentAutoPopulate, setAssessorCommentAutoPopulate] =
    useState("");

  useEffect(() => {
    setAssessorCommentAutoPopulate(AssessorComment);
  }, [AssessorComment]);

  // ------------------------ DND Stuff ----------------------------

  const [text, setText] = useState("");

  useEffect(() => {
    GetAllRequirements();
    GetAuditorLifeCycle(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  console.log("AUDITOR LIFE CYCLE DATA ACCESS=============");
  console.log(getAuditorLifeCycle);

  const handleTaskData = () => {
    const taskData = {
      // answer_id: currentSelectedRequirementDetails?.answerId,
      answer_id: showAuditAnswerTask?.id
        ? showAuditAnswerTask?.id
        : showAuditAnswer?.id,
      name: taskName,
      standard_ref:
        getSubRequirementById?.data?.standard_ref ||
        currentSelectedRequirementDetails?.standard_ref,
      description: assessorCommentAutoPopulate,
      status: 1,
      decision: assessorDecision?.value,
      audit_requirement_id:
        currentSelectedRequirementDetails?.auditRequirementId,
    };

    console.log("taskData", taskData);
    // console.log(showAuditAnswerTask?.id);
    CreateTask(taskData, setAlert, setGetError);
    setAssessorDecision("");
    ChangeAssessorComment("");
  };
  const assignMemberDetails = getAuditorLifeCycle?.audit?.members?.map(
    (member) => ({
      assignMemberId: member?.member_data?.id,
      assignMemberName: member?.member_data?.name,
      assignMemberEmail: member?.member_data?.email,
    })
  );

  const originalItems = getAuditorLifeCycle?.audit?.requirements?.map(
    (requirement) => ({
      ...requirement.requirement_data,
      // id: requirement.requirement_data.id,
      requirementId: requirement.requirement_data.id,
      id: requirement.id,
      name: requirement.requirement_data.standard_ref,
      desc: requirement.requirement_data.description,
      task_state: "Compliant",
      section: requirement.requirement_data.section.standard_ref,
      sectionDec: requirement.requirement_data.section.description,
      auditRequirementId: requirement.id,
      auditId: getAuditorLifeCycle.audit_id,
      mainStatus: getAuditorLifeCycle?.main_status,
      subStatus: requirement.sub_status,
      answerId: requirement?.answer_id,
      assignMemberData: assignMemberDetails?.filter(
        (mem) => mem.assignMemberId === requirement?.support_id
      ),
      contributeLeadName: getAuditorLifeCycle?.audit?.contribute_lead?.name,
      checkContributeLead: requirement?.entity_id,
      versionId: getAuditorLifeCycle?.version_id,
      versionName: getAuditorLifeCycle?.versions?.name,
      evidenceRequired: requirement?.requirement_data?.evidence,
      overallPercentage: requirement?.final_percentage,
    })
  );

  console.log("JKHKJHKJHKJHJKHJKHKJHKJHKJHKJHKJHKJHKJHKJ");
  // console.log(originalItems?.filter((item) => item.subStatus === 2));
  console.log(originalItems);

  const [wholeAuditStatus, ChangeWholeAuditStatus] = useState("");
  const [state, setState] = useState([]);
  useEffect(() => {
    setState([
      {
        title: "Initialization",
        // items: originalItems,
        items: originalItems?.filter((item) => item.subStatus === 1),
      },
      {
        title: "In Response",
        // items: [item4],
        // items: [],
        items: originalItems?.filter(
          (item) =>
            item.subStatus === 2 ||
            item.subStatus === 3 ||
            item.subStatus === 4 ||
            item.subStatus === 5 ||
            (item.subStatus === 6 && item.mainStatus === 3) ||
            item.subStatus === 7
        ),
      },
      {
        title: "In Review",
        // items: [item5, item6, item7],
        items: originalItems?.filter(
          (item) => item.subStatus === 6 && item.mainStatus === 4
        ),
      },
      {
        title: "Compliant",
        // items: [item8],
        items: originalItems?.filter((item) => item.subStatus === 9),
      },
      {
        title: "Not-compliant",
        // items: [item4],
        items: originalItems?.filter((item) => item.subStatus === 10),
      },
      {
        title: "Partial-compliant",
        items: originalItems?.filter((item) => item.subStatus === 11),
      },
    ]);

    ChangeWholeAuditStatus(
      getAuditorLifeCycle?.main_status === 1
        ? "Initiated"
        : getAuditorLifeCycle?.main_status === 2 ||
          getAuditorLifeCycle?.main_status === 3
        ? "In Response"
        : getAuditorLifeCycle?.main_status === 4
        ? "In Review"
        : getAuditorLifeCycle?.main_status === 5
        ? "Completed"
        : "DUMMY STATUS"
    );
  }, [getAuditorLifeCycle]);
  const [checkedItemsState, setCheckedItemsState] = useState([
    {
      title: "Initialization",
      checkedItems: [],
    },
    {
      title: "In Response",
      checkedItems: [],
    },
    {
      title: "In Review",
      checkedItems: [],
    },
    {
      title: "Compliant",
      checkedItems: [],
    },
    {
      title: "Not-compliant",
      checkedItems: [],
    },
    {
      title: "Partial-compliant",
      checkedItems: [],
    },
  ]);
  const [justToRender, ChangeJustToRender] = useState(false);

  // useEffect for dynamic render chat
  useEffect(() => {
    const data = {
      audit_requirement_id:
        currentSelectedRequirementDetails?.auditRequirementId,
      audit_id: window.localStorage.getItem("audit_life_cycle"),
    };
    GetDiscussionData(data);
  }, [createDiscussion, deleteComment]);

  useEffect(() => {
    const data = {
      audit_requirement_id:
        currentSelectedRequirementDetails?.auditRequirementId,
      audit_id: window.localStorage.getItem("audit_life_cycle"),
    };
    GetTotalComment(data);
  }, [createDiscussion, deleteComment]);

  useEffect(() => {
    if (window.localStorage.getItem("audit_life_cycle")) {
      console.log(
        "i am calling : " + window.localStorage.getItem("audit_life_cycle")
      );
      GetRadarChart(window.localStorage.getItem("audit_life_cycle"));
    }
  }, []);
  useEffect(()=>{


  },[checkallrequirements])

  const AddItem = () => {
    setState((prev) => {
      return {
        ...prev,
        todo: {
          title: "Initialization",
          items: [
            {
              id: v4(),
              name: text,
              task_state: "Initialization",
            },
            ...prev.todo.items,
          ],
        },
      };
    });
    setText("");
  };
  function IsSectionChecked(parentKey, section) {
    var itemIdsTemp = state[parentKey].items
      .filter((obj) => {
        if (obj.section == section) return obj;
      })
      .map((item, key) => {
        return item.id;
      });
    let checkedItems = checkedItemsState[parentKey].checkedItems;
    return itemIdsTemp.every((v) => checkedItems.includes(v));
  }
  const HandleDragEnd = ({ destination, source }) => {
    console.log("------HANDLE DRAG IN-----");
    console.log("from", source);
    console.log("to", destination);
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }
    if (source.droppableId === "0" && destination.droppableId === "2") return;

    // setCheckedItemsState(prev => {
    //     prev[parseInt(source.droppableId)].checkedItems = []
    //     prev[parseInt(destination.droppableId)].checkedItems = []
    //     return prev
    // })

    var itemCopy = state[source.droppableId].items[source.index];
    state[source.droppableId].items.splice(source.index, 1);
    state[destination.droppableId].items.splice(destination.index, 0, itemCopy);

    state[destination.droppableId].items.sort((item1, item2) => {
      if (item1.section < item2.section) return -1;
      if (item1.section > item2.section) return 1;
      return 0;
    });

    console.log(itemCopy);
    console.log(itemCopy.auditRequirementId);
    console.log(itemCopy.auditId);

    if (source.droppableId === "0" && destination.droppableId === "1") {
      const data = {
        auditId: itemCopy.auditId,
        auditRequirementIds: [itemCopy.auditRequirementId],
        version_id: itemCopy?.versionId,
      };
      console.log(data);
      MoveAuditorLifeCycleRequirementsToResponse(data);
    }

    if (source.droppableId === "1" && destination.droppableId === "2") {
      const data = {
        auditId: itemCopy.auditId,
        auditRequirementIds: [itemCopy.auditRequirementId],
      };
      console.log(data);
      if (
        (itemCopy?.subStatus === 6 && itemCopy?.mainStatus === 3) ||
        itemCopy?.subStatus === 2 ||
        itemCopy?.subStatus === 4 ||
        itemCopy?.subStatus === 5 ||
        itemCopy?.subStatus === 7
      ) {
        // window.alert("RELOAD");
        window.location.reload();
      }
    }

    var itemCopy =
      checkedItemsState[source.droppableId].checkedItems[source.index];
    console.log("ITEMCOPY");
    console.log(itemCopy);
    if (itemCopy != undefined) {
      checkedItemsState[source.droppableId].checkedItems.splice(
        source.index,
        1
      );
      checkedItemsState[destination.droppableId].checkedItems.splice(
        destination.index,
        0,
        itemCopy
      );
    }
  };
  const RenderDiscussion = () => {
    return discussion.map((item, key) => {
      const { image, name, role, date, comment, id } = item;
      console.log("id", id);
      return (
        <div key={key}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "0px solid red",
              // alignItems: 'center'
            }}
          >
            <img
              src={image}
              className="rounded-circle"
              width="40"
              height="40"
              alt=""
            />
            <div
              style={{
                border: "0px solid red",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  border: "0px solid red",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    border: "0px solid red",
                  }}
                >
                  <div
                    className="single-line-text"
                    style={{
                      marginLeft: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#595974",
                    }}
                  >
                    {name}
                  </div>
                  <div
                    style={{
                      background: "#F4F5F7",
                      marginLeft: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 5,
                    }}
                  >
                    {role}
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    {moment(date).format("MMM Do, YYYY")}
                  </div>
                </div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  onClick={() => {
                    DeleteComment(id);
                  }}
                >
                  <i
                    style={{ fontSize: 16, cursor: "pointer" }}
                    class="fas fa-trash-alt"
                  ></i>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  border: "0px solid red",
                  padding: 10,
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                  }}
                >
                  {comment}
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };
  console.log("ima check", checkallrequirements)
  const RenderItems = (items, parentKey) => {
    let previousSection = "";
    // let allSections = [];
    return items?.map((item, key) => {
      let showSectionTile = false;
      console.log(item);
      const {
        id,
        name,
        desc,
        task_state,
        description,
        section,
        auditRequirementId,
        auditId,
        subStatus,
        sectionDec,
      } = item;
      if (previousSection != section) {
        previousSection = section;
        showSectionTile = true;
      }
      // if (!allSections.includes(section)) {
      //   allSections.push(section);
      //   showSectionTile = false;
      //   console.log(allSections);
      // }
      return (
        <>
          {alert}
          {showSectionTile && (
            <div className={"life-cycle-req-section"}>
              <Input
                checked={IsSectionChecked(parentKey, section) ? "checked" : ""}
                defaultChecked={""}
                onChange={(e) => {
                  var itemIdsTemp = [];
                  console.log("E.TARGET.CHECKED");
                  console.log(e.target.checked);
                  itemIdsTemp = state[parentKey].items
                    .filter((obj) => {
                      if (obj.section == section) return obj;
                    })
                    .map((item, key) => {
                      return item.auditRequirementId;
                    });
                  var checkedItemsStateTemp = checkedItemsState;
                  var isAdding = e.target.checked;
                  itemIdsTemp.map((item, key) => {
                    // if (key == 0) {
                    //     if (!checkedItemsStateTemp[parentKey].checkedItems.includes(item))
                    //         isAdding = true
                    //     else
                    //         isAdding = false
                    // }
                    if (isAdding) {
                      // console.log("ADDING")
                      if (
                        !checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      )
                        checkedItemsStateTemp[parentKey].checkedItems.push(
                          item
                        );
                    } else {
                      // console.log("REMOVING")
                      if (
                        checkedItemsStateTemp[parentKey].checkedItems.includes(
                          item
                        )
                      ) {
                        var index =
                          checkedItemsStateTemp[parentKey].checkedItems.indexOf(
                            item
                          );
                        checkedItemsStateTemp[parentKey].checkedItems.splice(
                          index,
                          1
                        );
                      }
                    }
                  });
                  // setCheckedItemsState(checkedItemsStateTemp)
                  console.log(checkedItemsState);
                  ChangeJustToRender(!justToRender);
                }}
                onDoubleClick={() => {
                  console.log("DOUBLE CLICKED");
                }}
                type="checkbox"
              />
              <span className={"section-span"}>Section {section} </span>
              <span>{sectionDec}</span>
            </div>
          )}
          <Draggable
            onClick={(e) => {
              // console.log("Clicked")
            }}
            draggableId={parentKey + "" + key}
            index={key}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={"item text-left"}
                // style={{ boxShadow: "none" }}
                id="insertBefore"
                onKeyDown={(event) => {
                  // ! Multi drag not working, will implement it later
                  // this.onKeyDown(event, snapshot)
                  if (snapshot.isDragging) return;
                  // // console.log("EVENT.KEYCODE")
                  // // console.log(event.keyCode)
                  if (event.keyCode !== 17) return;
                  // // we are using the event for selection
                  event.preventDefault();
                  // console.log(props)
                  // const {
                  //     task,
                  //     toggleSelection,
                  //     toggleSelectionInGroup,
                  //     multiSelectTo,
                  // } = props
                  // // console.log("TASK.ID")
                  // // console.log(task.id)
                  // if (this.wasToggleInSelectionGroupKeyUsed(event)) {
                  //     toggleSelectionInGroup(task.id);
                  //     return;
                  // }

                  // if (this.wasMultiSelectKeyUsed(event)) {
                  //     multiSelectTo(task.id);
                  //     return;
                  // }

                  // toggleSelection(task.id);
                }}
              >
                <div className="row">
                  <div
                    className="col"
                    style={{ border: "0px solid red", paddingLeft: 35 }}
                  >
                    <Input
                      checked={
                        checkedItemsState[parentKey]?.checkedItems?.includes(
                          auditRequirementId
                        )
                          ? "checked"
                          : ""
                      }
                      onChange={(e) => {
                        var itemIdsTemp = [];
                        if (window.event.ctrlKey) {
                          itemIdsTemp = state[parentKey].items
                            .filter((obj) => {
                              if (obj.section == section) return obj;
                            })
                            .map((item, key) => {
                              return item.auditRequirementId;
                            });
                        } else {
                          itemIdsTemp.push(auditRequirementId);
                        }
                        // console.log("ITEMIDSTEMP")
                        // console.log(itemIdsTemp)
                        var checkedItemsStateTemp = checkedItemsState;
                        var isAdding;
                        itemIdsTemp.map((item, key) => {
                          if (key == 0) {
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              isAdding = true;
                            else isAdding = false;
                          }
                          if (isAdding) {
                            // console.log("ADDING")
                            if (
                              !checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            )
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.push(item);
                          } else {
                            // console.log("REMOVING")
                            if (
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.includes(item)
                            ) {
                              var index =
                                checkedItemsStateTemp[
                                  parentKey
                                ].checkedItems.indexOf(item);
                              checkedItemsStateTemp[
                                parentKey
                              ].checkedItems.splice(index, 1);
                            }
                          }
                        });
                        // setCheckedItemsState(checkedItemsStateTemp)
                        console.log(checkedItemsState);
                        // setGetCheckAuidtRequirementId(getCheckAuditRequirementId?.push(auditRequirementId))
                        ChangeJustToRender(!justToRender);
                      }}
                      onDoubleClick={() => {
                        console.log("DOUBLE CLICKED");
                      }}
                      type="checkbox"
                    />
                    <p>{" " + name}</p>
                  </div>
                  <div className="col text-right">
                    <span
                      onClick={() => {
                        toggleModalClassic();
                        setGetSubRequirementId("");
                        // ShowAuditRequirementAnswer(toggleModalClassic);
                        setCurrentSelectedRequirementDetails(item);

                        ChangeAssessorComment("");
                        setAssessorCommentTask("");
                        ClearAuditRequirementAnswerData();

                        console.log("item1 :", item);

                        const answerShowData = {
                          audit_requirement_id: item?.auditRequirementId,
                          requirement_id: item?.requirementId,
                          sub_requirement_id: "",
                        };
                        ShowAuditRequirementAnswer(answerShowData);

                        console.log(
                          "pingping" + JSON.stringify(item?.sub_requirement_id)
                        );
                        const showTask = {
                          audit_requirement_id:
                            currentSelectedRequirementDetails?.auditRequirementId,
                        };
                        GetAllTask(showTask); 

                        const cc = {
                          audit_requirement_id: item?.auditRequirementId,
                          audit_id:
                            window.localStorage.getItem("audit_life_cycle"),
                        };

                        console.log("totalp" + JSON.stringify(cc));
                        GetTotalComment(cc);
                      }}
                      style={{
                        border: "0px solid red",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <i class="fas fa-eye"></i>
                    </span>
                    <span
                      {...provided.dragHandleProps}
                      // FIXME: Tesing
                      onClick={() => console.log(item)}
                      style={{ border: "0px solid red" }}
                    >
                      <i class="fas fa-bars"></i>
                    </span>
                  </div>
                </div>
                <div>{desc}</div>
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                    }}
                  >
                    {/* {section} */}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      border: "0px solid red",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {/* {task_state === "Ready" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {task_state === "In Process" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-warning"
                        ></i>{" "}
                        {task_state}
                      </>
                    )} */}
                    {/* {task_state === "Compliant" && (
                      <> */}
                    {/* <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {task_state} */}
                    {/* <Button
                          className="btn btn-sm"
                          color="danger"
                          onClick={() => {
                            console.log(provided);
                            console.log(snapshot);
                            console.log(auditRequirementId);
                            console.log(auditId);
                            DeleteAuditorLifeCycleRequirement(
                              auditRequirementId,
                              auditId
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    )} */}
                    {/* TODO: TESTING ON INDIVIDUAL Status */}
                    {(subStatus === 2 ||
                      subStatus === 3 ||
                      subStatus === 4 ||
                      subStatus === 5 ||
                      subStatus === 7) && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {/* {task_state} */}
                        Pending
                      </>
                    )}
                    {subStatus === 6 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {/* {task_state} */}
                        Review Pending
                      </>
                    )}
                    {subStatus === 9 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {/* {task_state} */}
                        Compliant
                      </>
                    )}
                    {subStatus === 10 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-danger"
                        ></i>{" "}
                        {/* {task_state} */}
                        Not-compliant
                      </>
                    )}
                    {subStatus === 11 && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-info"
                        ></i>{" "}
                        {/* {task_state} */}
                        Partial-compliant
                      </>
                    )}
                    {subStatus === "In Process" && (
                      <>
                        <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-warning"
                        ></i>{" "}
                        {task_state}
                      </>
                    )}
                    {subStatus === 1 && (
                      <>
                        {/* <i
                          style={{ marginRight: 5 }}
                          className="fas fa-circle text-success"
                        ></i>{" "}
                        {task_state} */}
                        <Button
                          className="btn btn-sm"
                          color="danger"
                          onClick={() => {
                            console.log(provided);
                            console.log(snapshot);
                            console.log(auditRequirementId);
                            console.log(auditId);
                            DeleteAuditorLifeCycleRequirement(
                              auditRequirementId,
                              auditId
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        </>
      );
    });
  };
  const RenderColumns = () => {
    // console.log(checkedItemsState)
    return state.map((item, key) => {
      const { title, items } = item;
      console.log(title);
      console.log(items);
      // if (title == "Compliant" && showingCompliance && showingRequirments)
      //   return null;
      // if (title == "Not-compliant" && showingRequirments && !showingCompliance)
      //   return null;

      if (title == "Partial-compliant") {
        if (showingRequirments) return null;
        if (showingCompliance) return null;
        if (!showingPartial) return null;
        if (!showingRequirments && showingCompliance) return null;
      }

      if (title == "Not-compliant") {
        if (showingRequirments) return null;
        if (showingCompliance) return null;
        if (showingPartial) return null;
        if (!showingRequirments && showingCompliance) return null;
      }
      if (title == "Compliant") {
        if (showingRequirments) return null;
        if (!showingCompliance) return null;
        if (showingPartial) return null;
        if (showingRequirments && !showingCompliance) return null;
      }
      if (title == "Initialization" && !showingRequirments) return null;
      return (
        <Droppable droppableId={key.toString()}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps} // TODO: these props can be changed later
              style={{
                border: "0px solid red",
                paddingLeft: 5,
                paddingRight: 5,
              }}
              className="col"
            >
              <div
                style={{
                  backgroundColor: "#F8F9FA",
                  // backgroundColor: "#f4f3ef",
                  // 1px solid rgba(0, 0, 0, 0.1);
                  borderRight: "3px solid #f4f3ef",
                  borderRadius: "0px",
                  height: "100%",
                  paddingBottom: 10,
                  padding: 10,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div
                      className="text-left"
                      style={{ fontWeight: "bold" }}
                      contentEditable="true"
                    >
                      {title}
                    </div>
                  </div>
                  <div className="col text-right" style={{ minHeight: 25 }}>
                   {

                    console.log("chch", checkedItemsState)
                   } {title === "Initialization" && (
                      // checkedItemsState[key].checkedItems.length > 0 && (
                      <>
                     {
                      checkedItemsState[0]?.checkedItems > 0 ?<Input
                      // className="btn btn-sm"
                      // color="info"

                      type="checkbox"
                      value={checkallrequirements }
                      checked={checkallrequirements}
                      onChange={(e) => {
                        setCheckallrequirements(e.target.checked);
                      }}
                      onClick={() => {}}
                    >
                    </Input> :null
                     }
                      </>
                    )}
                
                    {title != "Not-compliant" &&
                      checkedItemsState[key].checkedItems.length > 0 && (
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key + 1).toString();
                            console.log(srcDroppableId);
                            console.log(destDroppableId);
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              console.log(prev[destDroppableId].items);
                              console.log(prev[srcDroppableId].items);
                              console.log(state);
                              console.log(prev);
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                          }}
                        >
                        <i
                            class="fas fa-hand-point-right"
                            onClick={() => {
                              if (
                                checkedItemsState[key].checkedItems.length > 0
                              ) {
                                var srcDroppableId = key.toString();
                                var destDroppableId = (key + 1).toString();

                                const data = {
                                  auditId: items[0].auditId,
                                  // auditRequirementIds: items.map((reqId) => (reqId?.auditRequirementId)),
                                  auditRequirementIds:
                                    checkedItemsState[key].checkedItems,
                                  version_id: items[0].versionId,
                                };

                                // console.log(
                                //   "itemsDDDD",
                                //   checkedItemsState[key]
                                // );
                                console.log(
                                  "itemsDDDD",
                                  checkedItemsState[key].checkedItems
                                );

                                console.log(data);

                                // req to res
                                if (
                                  srcDroppableId == 0 &&
                                  destDroppableId == 1
                                ) {
                                  MoveAuditorLifeCycleRequirementsToResponse(
                                    data
                                  );
                                }
                                // res to rev
                                if (
                                  srcDroppableId == 1 &&
                                  destDroppableId == 2
                                ) {
                                  // console.log(items)
                                  // window.alert("hi")
                                  if (
                                    items.filter(
                                      (i) =>
                                        i.subStatus === 2 ||
                                        i.subStatus === 3 ||
                                        i?.subStatus === 4 ||
                                        i?.subStatus === 5 ||
                                        i?.subStatus === 6
                                    ).length > 0
                                  ) {
                                    window.location.reload();
                                  }
                                }
                              }
                            }}
                          ></i>
                        </span>
                      )}

                    {/* only for all requirement select to push Start */}
                    {title === "Initialization" &&
                      checkallrequirements &&
                      items.length > 0 && (
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            var srcDroppableId = key.toString();
                            var destDroppableId = (key + 1).toString();
                         
                            setState((prev) => {
                              prev[destDroppableId].items = [
                                ...prev[srcDroppableId].items.filter((obj) =>
                                  checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                                ),
                                ...prev[destDroppableId].items,
                              ];
                              prev[srcDroppableId].items = prev[
                                srcDroppableId
                              ].items.filter(
                                (obj) =>
                                  !checkedItemsState[key].checkedItems.includes(
                                    obj.id
                                  )
                              );
                              console.log(prev[destDroppableId].items);
                              console.log(prev[srcDroppableId].items);
                              console.log(state);
                              console.log(prev);
                              return prev;
                            });
                            setCheckedItemsState((prev) => {
                              prev[parseInt(srcDroppableId)].checkedItems = [];
                              return prev;
                            });
                            ChangeJustToRender(!justToRender);
                         setCheckallrequirements(false)
                        // console.log("ima check", checkallrequirements)
                          }}
                        >
                          <i
                            className="fas fa-hand-point-right"
                            onClick={() => {
                              console.log(items);
                              if (
                                // checkedItemsState[key].checkedItems.length > 0
                                checkallrequirements
                              ) {
                                var srcDroppableId = key.toString();
                                var destDroppableId = (key + 1).toString();

                                const data = {
                                  auditId: items[0].auditId,
                                  auditRequirementIds: items.map(
                                    (reqId) => reqId?.auditRequirementId
                                  ),
                                  // auditRequirementIds:
                                  //   checkedItemsState[key].checkedItems,
                                  version_id: items[0].versionId,
                                };

                                // console.log(
                                //   "itemsDDDD",
                                //   checkedItemsState[key]
                                // );
                                console.log(
                                  "itemsDDDD",
                                  checkedItemsState[key].checkedItems
                                );

                                console.log(data);

                                // req to res
                                if (
                                  srcDroppableId == 0 &&
                                  destDroppableId == 1
                                ) {
                                  MoveAuditorLifeCycleRequirementsToResponse(
                                    data
                                  );
                                }
                                // res to rev
                                if (
                                  srcDroppableId == 1 &&
                                  destDroppableId == 2
                                ) {
                                  // console.log(items)
                                  // window.alert("hi")
                                  if (
                                    items.filter(
                                      (i) =>
                                        i.subStatus === 2 ||
                                        i.subStatus === 3 ||
                                        i?.subStatus === 4 ||
                                        i?.subStatus === 5 ||
                                        i?.subStatus === 6
                                    ).length > 0
                                  ) {
                                    window.location.reload();
                                  }
                                }
                              }

                              

                            }}
                          >
                  Push All
                          </i>
                        </span>
                      )}
                    {/* only for all requirement select to push End */}
                  </div>
                </div>
                <hr style={{ ...styles.smartHr, marginTop: 2 }} />
                {RenderItems(items, key)}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col style={{ border: "0px solid red", display: "flex" }}>
            <p
              onClick={() => {
                history.push("/user/assessment-summary");
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
         {"Assessment Summary"}{" "}
            </p>
            <p style={{ paddingLeft: "6px" }}>
              {/* <i className="fas fa-star text-warning mx-2"></i> */}
              <i className="fas fa-users" id="tooltip366246651"></i>
            </p>
          </Col>
          <Col style={{ border: "0px solid red" }} className="text-right">
            <span style={{ border: "0px solid red" }}>
              {wholeAuditStatus === "Initiated" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-info"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "In Response" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-warning"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "In Review" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-info"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
              {wholeAuditStatus === "Completed" && (
                <>
                  <i
                    style={{ marginRight: 5 }}
                    className="fas fa-circle text-success"
                  ></i>{" "}
                  {wholeAuditStatus}
                </>
              )}
            </span>
          </Col>
        </Row>
        <UncontrolledTooltip delay={0} target="tooltip366246651">
          {/* Harry , Meghan , Ryan */}
          {getAuditorLifeCycle?.audit?.members &&
            getAuditorLifeCycle?.audit?.members?.map((member) => (
              <>
                {" "}
                {member?.member_data?.name} {","}
              </>
            ))}
          {getAuditorLifeCycle?.audit?.contribute_lead &&
            getAuditorLifeCycle?.audit?.contribute_lead?.name}
        </UncontrolledTooltip>

        <div className="nav-tabs-navigation">
          <div className="nav-tabs-wrapper">
            <Nav
              id="tabs"
              role="tablist"
              tabs
              className="justify-content-start"
            >
              <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "lifecycle"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "lifecycle" ? "active" : ""}
                  onClick={() => ChangeLifeCycleTabs("lifecycle")}
                >
                  Life Cycle
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  aria-expanded={lifeCycleTabs === "radarchart"}
                  data-toggle="tab"
                  role="tab"
                  className={lifeCycleTabs === "radarchart" ? "active" : ""}
                  onClick={() => { ChangeLifeCycleTabs("radarchart"); GetRadarChart(window.localStorage.getItem("audit_life_cycle")) }}
                >
                  Radar Chart
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </div>
        <TabContent
          className="text-center"
          id="my-tab-content"
          activeTab={lifeCycleTabs}
        >
          <TabPane tabId="lifecycle" role="tabpanel" className="boards-flex">
            <Card className="p-3" style={{ backgroundColor: "#F8F9FA" }}>
              <Row
                id="scroll-bar"
                style={{
                  height: "calc(100vh - 280px)",
                  position: "relative",
                }}
              >
                {!showingRequirments ? (
                  <div
                    onClick={() => {
                      ChangeShowingRequirments(true);
                      ChangeShowingCompliance(true);

                      ChangeShowingPartial(true);
                      console.log(showingRequirments);
                    }}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 10,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-right"></i> */}
                    {"Initialization"}
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        // setCheckedItemsState(prev => {
                        //     prev[0].checkedItems = []
                        //     return prev
                        // })
                        console.log(showingRequirments);
                        ChangeShowingCompliance(true);
                        ChangeShowingRequirments(false);

                        ChangeShowingPartial(false);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 10,
                        zIndex: 99,
                        writingMode: "vertical-lr",
                        backgroundColor: "#61B9DA88",
                        // padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                      {"Compliant"}
                    </div>

                    <div
                      onClick={() => {
                        // setCheckedItemsState(prev => {
                        //     prev[0].checkedItems = []
                        //     return prev
                        // })
                        ChangeShowingCompliance(false);
                        ChangeShowingRequirments(false);

                        ChangeShowingPartial(false);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 130,
                        zIndex: 99,
                        writingMode: "vertical-lr",
                        backgroundColor: "#61B9DA88",
                        // padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                      {"Not Compliant"}
                    </div>

                    <div
                      onClick={() => {
                        // setCheckedItemsState(prev => {
                        //     prev[0].checkedItems = []
                        //     return prev
                        // })
                        ChangeShowingPartial(true);
                        ChangeShowingCompliance(false);
                        ChangeShowingRequirments(false);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 280,
                        zIndex: 99,
                        writingMode: "vertical-lr",
                        backgroundColor: "#61B9DA88",
                        // padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                      {"Partial-compliant"}
                    </div>
                  </>
                )}
                {!showingCompliance ? (
                  <div
                    onClick={() => {
                      // setCheckedItemsState(prev => {
                      //     prev[0].checkedItems = []
                      //     return prev
                      // })
                      console.log(showingRequirments);
                      ChangeShowingCompliance(true);
                      ChangeShowingRequirments(false);
                      ChangeShowingPartial(false);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 10,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                    {"Compliant"}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      // setCheckedItemsState(prev => {
                      //     prev[0].checkedItems = []
                      //     return prev
                      // })
                      ChangeShowingCompliance(false);
                      ChangeShowingRequirments(false);
                      ChangeShowingPartial(false);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 130,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                    {"Not-compliant"}
                  </div>
                )}

                {!showingPartial ? (
                  <div
                    onClick={() => {
                      console.log(showingRequirments);
                      ChangeShowingCompliance(false);
                      ChangeShowingRequirments(false);
                      ChangeShowingPartial(true);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 280,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",

                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {"partial-compliant"}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      // setCheckedItemsState(prev => {
                      //     prev[0].checkedItems = []
                      //     return prev
                      // })
                      ChangeShowingCompliance(false);
                      ChangeShowingRequirments(false);
                      ChangeShowingPartial(false);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 130,
                      zIndex: 99,
                      writingMode: "vertical-lr",
                      backgroundColor: "#61B9DA88",
                      // padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {/* <i style={{ fontSize: 18 }} class="fas fa-caret-left"></i> */}
                    {"Not-compliant"}
                  </div>
                )}
                <DragDropContext onDragEnd={HandleDragEnd}>
                  {RenderColumns()}
                </DragDropContext>
              </Row>
            </Card>
          </TabPane>
          <TabPane tabId="radarchart" role="tabpanel">
            <Card className="p-2">
              <Row className="w-100 h-100">
                <Col md="10" className="ml-auto mr-auto">
                  {getRadarChart?.datasets?.length > 0 ? (
                    <Radar data={getRadarChart} options={options} />
                  ) : (
                    <div
                      style={{
                        height: "calc(100vh - 280px)",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        color: "gray",
                      }}
                    >
                      <i
                        className="fa fa-line-chart"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <h5> No Chart to show</h5>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </TabPane>
        </TabContent>
        {/* ----------------------------------------- Modal ------------------------- */}
        <Modal
          Modal
          isOpen={modalClassic}
          size="xl"
          toggle={toggleModalClassic}
          className="overflow-hidden"
        >
          <div className="modal-header overflow-hidden ">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassic();
                ClearAllTaskData();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                {currentSelectedRequirementDetails?.standard_ref}
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    width: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {currentSelectedRequirementDetails?.desc}
                </span>
              </h4>
            </div>
          </div>
          <div className="modal-body p-1 ">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class=" d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  {currentSelectedRequirementDetails?.supportAssignMemberData &&
                  !currentSelectedRequirementDetails?.checkContributeLead ? (
                    currentSelectedRequirementDetails?.supportAssignMemberData?.map(
                      (mem) => (
                        <a href="javascript:void(0)" className="text-dark">
                          Assigned to {mem?.assignMemberName}
                        </a>
                      )
                    )
                  ) : (
                    <a href="javascript:void(0)" className="text-dark">
                      Assigned to{" "}
                      {currentSelectedRequirementDetails?.contributeLeadName}
                    </a>
                  )}
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i>{" "}
                  {getCommentCount} Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                Requirment List
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {currentSelectedRequirementDetails?.req_list?.name}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Section
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {currentSelectedRequirementDetails?.sectionDec}
                  </span>
                </Col>
                <Col className="text-right" md="6">
                  {/* {(assessorDecision.value == 2 ||
                    assessorDecision.value == 3) && (
                      <Button
                        className="btn btn-sm"
                        color="info"
                        onClick={() => {
                          ChangeShowCreateTaskModal(true);
                          handleTaskData()
                        }}
                      >
                        {" "}
                        <i className="fas fa-plus"></i> Create Task
                      </Button>
                    )} */}
                </Col>
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="8">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        {/* <th>Area</th> */}
                        <th>Criticality</th>
                        <th>Version</th>
                        <th>Reference</th>
                        <th>Decision</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">
                          {currentSelectedRequirementDetails?.standard_ref}
                        </td>
                        <td className="border-0">
                          {currentSelectedRequirementDetails?.weight}
                        </td>
                        {/* <td className="border-0">
                          {currentSelectedRequirementDetails?.area}
                        </td> */}
                        <td className="border-0">
                          {currentSelectedRequirementDetails?.criticality}
                        </td>
                        <td className="border-0">
                          {currentSelectedRequirementDetails?.versionName}
                        </td>
                        <td className="border-0">
                          {currentSelectedRequirementDetails?.reference?.name}
                        </td>
                        {/* {assessorDecision.value == 1 || showAuditAnswer?.answer_status == "yes" ? (
                          <td className="border-0 text-success">
                            {"Compliant"}
                          </td>
                        ) : (
                          <td className="border-0 text-danger">
                            {"Not Compliant "}
                          </td>
                        )} */}
                        {showAuditAnswer?.final_percentage == 100 && (
                          <td className="border-0 text-success">
                            {"Compliant"}
                          </td>
                        )}
                        {showAuditAnswer?.final_percentage == 0 && (
                          <td className="border-0 text-danger">
                            {"Not Compliant "}
                          </td>
                        )}
                        {showAuditAnswer?.final_percentage > 0 &&
                          showAuditAnswer?.final_percentage < 100 && (
                            <td className="border-0 text-warning">
                              {"Partial Compliant "}
                            </td>
                          )}
                        {!showAuditAnswer?.final_percentage &&
                          currentSelectedRequirementDetails?.overallPercentage && (
                            <td className="border-0 ">
                              {currentSelectedRequirementDetails?.overallPercentage >
                                0 &&
                                currentSelectedRequirementDetails?.overallPercentage <
                                  100 && (
                                  <td className="border-0 text-warning">
                                    {"Partial Compliant "}
                                  </td>
                                )}
                              {currentSelectedRequirementDetails?.overallPercentage ==
                                0 && (
                                <td className="border-0 text-success">
                                  {"Compliant"}
                                </td>
                              )}
                              {currentSelectedRequirementDetails?.overallPercentage ==
                                100 && (
                                <td className="border-0 text-danger">
                                  {"Not Compliant "}
                                </td>
                              )}
                            </td>
                          )}

                        {showAuditAnswer?.final_percentage == null &&
                          currentSelectedRequirementDetails?.overallPercentage ==
                            null && (
                            <>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip366"
                              >
                                {" "}
                                Decision has not yet been made by reviewer{" "}
                              </UncontrolledTooltip>
                              <td className="border-0 ">
                                <p
                                  id="tooltip366"
                                  style={{ cursor: "pointer" }}
                                >
                                  {"N/A "}
                                </p>
                              </td>
                            </>
                          )}
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="4" className="text-right">
                  {/* <p className="p-2">Updated By Zeesha Ahmad.</p> */}
                  <div className="nav-tabs-navigation border-0">
                    <div className="nav-tabs-wrapper border-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end border-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "details"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "details" ? "active" : ""
                            }
                            onClick={() => ChangeRequirementTabs("details")}
                          >
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "tasks"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "tasks" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("tasks");

                              const showTask = {
                                audit_requirement_id:
                                  currentSelectedRequirementDetails?.auditRequirementId,
                              };
                              GetAllTask(showTask);
                            }}
                          >
                            Tasks
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "discussion"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "discussion" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("discussion");
                              const data = {
                                audit_requirement_id:
                                  currentSelectedRequirementDetails?.auditRequirementId,
                                audit_id:
                                  window.localStorage.getItem(
                                    "audit_life_cycle"
                                  ),
                              };
                              GetDiscussionData(data);
                              // var elem = document.getElementById('modal_scroll'); //! scroll to bottom not working
                              // var elem2 = document.getElementById('my-tab-content');
                              // console.log("ELEM.SCROLLHEIGHT")
                              // console.log(elem.scrollHeight)
                              // console.log("ELEM")
                              // console.log(elem)
                              // console.log("ELEM2")
                              // console.log(elem2)
                              // elem.scrollTop = elem2.scrollHeight;
                            }}
                          >
                            <i class="far fa-comments"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={requirementTabs === "attachments"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              requirementTabs === "attachments" ? "active" : ""
                            }
                            onClick={() => {
                              ChangeRequirementTabs("attachments");

                              GetVersionFiles(
                                window.localStorage.getItem("audit_life_cycle")
                              );
                            }}
                          >
                            <i class="fas fa-paperclip"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "calc(100vh - 500px)",
                // height: '500px',
                position: "relative",
                overflowY: "auto",
              }}
            >
              <TabContent
                className="text-center"
                id="my-tab-content"
                activeTab={requirementTabs}
              >
                <TabPane tabId="details" role="tabpanel">
                  <Row>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                        Description
                      </h6>
                      <textarea
                        value={generalDescription}
                        style={{
                          paddingLeft: 10,
                          backgroundColor: "#f4f3ef",
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                        disabled
                      />
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                            Contributor Answer
                          </span>
                        </Col>
                        <Col className="text-right">
                          {/* <span style={{ fontSize: 14 }}>Answered by: Zeeshan</span> */}
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <div className="d-flex align-items-center justify-content-start">
                        <Row>
                          <Col
                            style={{ paddingRight: 0, minWidth: 130 }}
                            md="3"
                          >
                            <FormGroup className="mr-2">
                              <Select
                                styles={{
                                  container: () => ({}),
                                }}
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isDisabled={
                                  window.localStorage.getItem(
                                    "currentLoginUserRole"
                                  ) == 0
                                    ? true
                                    : false
                                }
                                name="answer"
                                closeMenuOnSelect={false}
                                isMulti={false}
                                value={auditAnswer}
                                onChange={(value) => {
                                  // console.log("SELECTED ANSWER")
                                  // console.log(value)
                                  setAuditAnswer(value);
                                }}
                                options={[
                                  {
                                    value: 0,
                                    label: "Select Answer",
                                    isDisabled: true,
                                  },
                                  { value: 1, label: "No " },
                                  { value: 2, label: "Yes" },
                                  { value: 3, label: "Partial" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          {auditAnswer.value == 3 && (
                            <>
                              <Col
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                md="3"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Input percentage %"
                                    value={partialPercent}
                                    onChange={(e) => {
                                      setPartialPercent(e.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              {/* <Col
                                style={{ paddingLeft: 0, paddingRight: 8 }}
                                md="6"
                              >
                                <FormGroup className="mr-2">
                                  <Input
                                    type="text"
                                    placeholder="Partial Description"
                                    value={partialDescription}
                                    onChange={(e) => {
                                      // console.log(e.target.value)
                                      setPartialDescription(e.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col> */}
                            </>
                          )}
                        </Row>
                      </div>
                      <Row>
                        {currentSelectedRequirementDetails?.evidenceRequired ==
                          "Yes" && (
                          <>
                            <Col
                              md="3"
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <Button
                                style={{
                                  marginTop: 0,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                color="info"
                                onClick={() => {
                                  downloadFile(
                                    `${process.env.REACT_APP_BASE_URL}/public/${showAuditAnswer?.evidence_attachment}`
                                  );
                                }}
                              >
                                <i
                                  class="fa fa-download"
                                  aria-hidden="true"
                                ></i>{" "}
                                Evidence
                              </Button>
                            </Col>
                            <Col md="9" style={{ paddingLeft: 0 }}>
                              <FormGroup>
                                <Input
                                  placeholder="Evidence Detail"
                                  value={evidenceDetail}
                                  onChange={(e) => {
                                    setEvidenceDetail(e.target.value);
                                  }}
                                  style={
                                    window.localStorage.getItem(
                                      "currentLoginUserRole"
                                    ) == 0
                                      ? { backgroundColor: "#f4f3ef" }
                                      : {}
                                  }
                                  readOnly={
                                    window.localStorage.getItem(
                                      "currentLoginUserRole"
                                    ) == 0
                                      ? true
                                      : false
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      </Row>
                      <textarea
                        value={contributeComment}
                        onChange={(e) => {
                          SetContributeComment(e.target.value);
                        }}
                        placeholder="Contributor Comment"
                        style={
                          window.localStorage.getItem("currentLoginUserRole") ==
                          0
                            ? { backgroundColor: "#f4f3ef", paddingLeft: 10 }
                            : {}
                        }
                        readOnly={
                          window.localStorage.getItem("currentLoginUserRole") ==
                          0
                            ? true
                            : false
                        }
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                      <Row style={{ marginBottom: 0, marginTop: 10 }}>
                        <Col className="text-left" style={{}}>
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                          Assessor Section
                          </span>
                        </Col>
                      </Row>
                      <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      <FormGroup className="mr-2">
                        <Select
                          styles={{
                            container: () => ({}),
                          }}
                          // isDisabled={
                          //   showAuditAnswer?.status === 1 ||
                          //   showAuditAnswer?.status === 2 ||
                          //   currentSelectedRequirementDetails?.subStatus ===
                          //     9 ||
                          //   currentSelectedRequirementDetails?.subStatus ===
                          //     10 ||
                          //   (currentSelectedRequirementDetails?.subStatus ===
                          //     6 &&
                          //     currentSelectedRequirementDetails?.mainStatus ===
                          //       4 &&
                          //     assessorCommentTask === "")
                          //     ? true
                          //     : false
                          // }
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          name="answer"
                          closeMenuOnSelect={true}
                          isMulti={false}
                          value={assessorDecision}
                          onChange={(value) => {
                            setAssessorDecision(value);
                          }}
                          options={[
                            {
                              value: 0,
                              label: "Select Decision",
                              isDisabled: true,
                            },
                            { value: 1, label: "Accepted " },
                            { value: 2, label: "Rejected" },
                            { value: 3, label: "Minor Revision " },
                            { value: 4, label: "Major Revision" },
                          ]}
                        />
                      </FormGroup>
                      <textarea
                        value={AssessorComment}
                        onChange={(e) => ChangeAssessorComment(e.target.value)}
                        placeholder="Assessor Comment"
                        style={{
                          paddingLeft: 10,
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {assessorDecision.value == 3 ||
                        assessorDecision.value == 4 ? (
                          <Button
                            className="btn btn-sm"
                            color="info"
                            onClick={() => {
                              ChangeShowCreateTaskModal(true);
                              if (
                                currentSelectedRequirementDetails?.subStatus ===
                                  6 &&
                                showAuditAnswer?.status &&
                                assessorCommentTask === "" &&
                                allTask?.length > 0
                              ) {
                                ChangeShowCreateTaskModal(false);
                                return messageHandler(
                                  setAlert,
                                  "got to task tab select the task and answer in comment and click save then create task  accept or reject ",
                                  "comment against task.",
                                  "danger"
                                );
                              }
                            }}
                            disabled={
                              currentSelectedRequirementDetails?.subStatus ==
                                4 ||
                              currentSelectedRequirementDetails?.subStatus ===
                                5 ||
                              currentSelectedRequirementDetails?.subStatus ===
                                9 ||
                              currentSelectedRequirementDetails?.subStatus ===
                                10 ||
                              // currentSelectedRequirementDetails?.subStatus === 6 && currentSelectedRequirementDetails.mainStatus === 3 &&
                              // (showAuditAnswerTask?.status === 3 || showAuditAnswerTask?.status === 4) && currentSelectedRequirementDetails?.sub_requirements?.length > 0

                              //for sub requirement
                              (currentSelectedRequirementDetails?.subStatus ===
                                6 &&
                                currentSelectedRequirementDetails?.mainStatus ===
                                  4 &&
                                showAuditAnswer?.status &&
                                currentSelectedRequirementDetails
                                  ?.sub_requirements?.length > 0 &&
                                showAuditAnswerTask?.answer_iteration > 1) ||
                              //for main  requirement
                              (currentSelectedRequirementDetails?.subStatus ===
                                6 &&
                                currentSelectedRequirementDetails?.mainStatus ===
                                  4 &&
                                showAuditAnswer?.status &&
                                currentSelectedRequirementDetails
                                  ?.sub_requirements?.length === 0 &&
                                showAuditAnswerTask?.answer_iteration > 1) ||
                              // for main requirement after minor major and we accept or reject
                              (currentSelectedRequirementDetails?.subStatus ===
                                6 &&
                                showAuditAnswer?.status &&
                                currentSelectedRequirementDetails
                                  ?.sub_requirements?.length === 0 &&
                                showAuditAnswerTask?.answer_iteration > 1) ||
                              // for sub requirement after minor major and we accept or reject
                              (currentSelectedRequirementDetails?.subStatus ===
                                6 &&
                                showAuditAnswer?.status &&
                                currentSelectedRequirementDetails
                                  ?.sub_requirements?.length > 0 &&
                                showAuditAnswerTask?.answer_iteration > 1)
                                ? true
                                : false
                            }
                          >
                            {" "}
                            <i className="fas fa-plus"></i> Create Task
                          </Button>
                        ) : (
                          <Button
                            color="info"
//m

                            // disabled={
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     1 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     2 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     3 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     4 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     5 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     9 ||
                            //   currentSelectedRequirementDetails?.subStatus ===
                            //     10 ||
                            //   //for sub requirement
                            //   (currentSelectedRequirementDetails?.subStatus ===
                            //     6 &&
                            //     getSubRequirementId === "" &&
                            //     currentSelectedRequirementDetails
                            //       ?.sub_requirements?.length > 0) ||
                            //   // currentSelectedRequirementDetails?.subStatus === 6 && showAuditAnswer?.status && currentSelectedRequirementDetails?.sub_requirements?.length > 0 ||

                            //   //for main requirement
                            //   (currentSelectedRequirementDetails?.subStatus ===
                            //     6 &&
                            //     showAuditAnswer?.status &&
                            //     currentSelectedRequirementDetails
                            //       ?.sub_requirements?.length === 0 &&
                            //     showAuditAnswerTask?.answer_iteration > 1) ||
                            //   // for main requirement after minor major and we accept or reject
                            //   (currentSelectedRequirementDetails?.subStatus ===
                            //     6 &&
                            //     showAuditAnswer?.status &&
                            //     currentSelectedRequirementDetails
                            //       ?.sub_requirements?.length === 0 &&
                            //     showAuditAnswerTask?.answer_iteration > 1) ||
                            //   // for sub requirement after minor major and we accept or reject
                            //   (currentSelectedRequirementDetails?.subStatus ===
                            //     6 &&
                            //     showAuditAnswer?.status &&
                            //     currentSelectedRequirementDetails
                            //       ?.sub_requirements?.length > 0 &&
                            //     showAuditAnswerTask?.answer_iteration > 1)
                            //     ? true
                            //     : false
                         //   }
                            onClick={() => {
                              if (
                                currentSelectedRequirementDetails?.subStatus ===
                                  6 &&
                                showAuditAnswer?.status &&
                                assessorCommentTask === "" &&
                                allTask?.length > 0
                              )
                                return messageHandler(
                                  setAlert,
                                  "got to task tab select the task and answer in comment and click save then create task  accept or reject ",
                                  "comment against task.",
                                  "danger"
                                );
                              // if (
                              //   // showAuditAnswerTask?.answer_iteration >= 2 &&
                              //   assessorCommentTask === "" &&
                              //   AssessorComment !== "" &&
                              //   currentSelectedRequirementDetails?.subStatus !==
                              //   6 &&
                              //   currentSelectedRequirementDetails?.mainStatus !==
                              //   4
                              // )
                              //   return messageHandler(
                              //     setAlert,
                              //     "Please comment against task.",
                              //     "Task Comment",
                              //     "danger"
                              //   );

                              const data = {
                                auditRequirementId:
                                  showAuditAnswer?.audit_requirement_id,
                                answerId: !showAuditAnswerTask?.id
                                  ? showAuditAnswer?.id
                                  : showAuditAnswerTask?.id,
                                decision: assessorDecision?.value,
                                auditorComments:
                                  currentSelectedRequirementDetails
                                    ?.sub_requirements?.length > 0 &&
                                  assessorCommentTask !== ""
                                    ? assessorCommentTask
                                    : showAuditAnswerTask?.answer_iteration >= 2
                                    ? assessorCommentTask
                                    : showAuditAnswer?.answer_iteration === 1
                                    ? AssessorComment
                                    : "DUMMY COMMENT",
                              };

                              console.log("111" + data.auditRequirementId); console.log("111" + data.answerId); console.log("111" + data.auditorComments);
                              GiveAnswerAgainstRequirementOrSubRequirement(
                                data,
                                setAlert
                              );
                              GetAuditorLifeCycle(
                                localStorage.getItem("audit_life_cycle")
                              );
                          
                              ChangeAssessorComment("") 
                            }
                          
                          
                            
                        }
                          >
          Submit
                          </Button>
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <h6 className="p-2 border-bottom text-left">
                        Clarification
                      </h6>
                      <textarea
                        value={generalClarification}
                        placeholder="Enter clarification"
                        style={{
                          paddingLeft: 10,
                          backgroundColor: "#f5f5f5",
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                        disabled
                      />
                      {currentSelectedRequirementDetails?.sub_requirements
                        ?.length > 0 && (
                        <>
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                            className="text-left"
                          >
                            Sub Requirements
                          </div>
                          <hr style={{ marginTop: 5, marginBottom: 5 }} />
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Ref.</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentSelectedRequirementDetails
                                ?.sub_requirements?.length > 0 ? (
                                currentSelectedRequirementDetails?.sub_requirements.map(
                                  (subRequirement) => (
                                    <tr>
                                      <td>
                                        <Button
                                          style={{ marginTop: 0 }}
                                          outline
                                          color="info"
                                          onClick={() => {
                                            setGetSubRequirementId(
                                              subRequirement?.id
                                            );
                                            const answerShowData = {
                                              audit_requirement_id:
                                                currentSelectedRequirementDetails?.auditRequirementId,
                                              requirement_id:
                                                currentSelectedRequirementDetails?.requirementId,
                                              sub_requirement_id:
                                                subRequirement?.id,
                                            };
                                            ShowAuditRequirementAnswer(
                                              answerShowData
                                            );
                                            console.log(
                                              "9090: " +
                                                JSON.stringify(
                                                  currentSelectedRequirementDetails
                                                )
                                            );

                                            const showTask = {
                                              audit_requirement_id:
                                                currentSelectedRequirementDetails?.auditRequirementId,
                                            };
                                            GetAllTask(showTask);
                                          }}
                                        >
                                          Select to Review
                                        </Button>
                                      </td>
                                      <td>{subRequirement?.reference?.name}</td>
                                      <td>{subRequirement?.description}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <p>No Record Found</p>
                              )}
                              {/* <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                style={{ marginTop: 0 }}
                                color="info"
                                onClick={() => {}}
                              >
                                Answer
                              </Button>
                            </td>
                            <td>420.6.9</td>
                            <td>
                              Use photons to represent bits instead of electrons
                            </td>
                          </tr> */}
                            </tbody>
                          </table>
                        </>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="attachments" role="tabpanel">
                  {/* <div
                    className="text-left font-small"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      style={{ marginRight: 5 }}
                      className="fas fa-plus text-success"
                    ></i>
                    Add Attachement
                  </div> */}

                  <div className="nav-tabs-navigation p-0">
                    <div className="nav-tabs-wrapper p-0">
                      <Nav
                        id="tabs"
                        role="tablist"
                        tabs
                        className="justify-content-end p-0"
                      >
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "table"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "table" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("table")}
                          >
                            <i class="fas fa-table"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={attachmentTabs === "grid"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              attachmentTabs === "grid" ? "active" : ""
                            }
                            onClick={() => ChangeAttachmentTabs("grid")}
                          >
                            <i class="fas fa-th"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={attachmentTabs}
                  >
                    <TabPane tabId="table" role="tabpanel">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Attached</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {versionFiles?.data?.map((file) => (
                            <>
                              <tr>
                                <td>{file?.evidence_name}</td>
                                <td>{file?.evidence_size + "KB"}</td>
                                <td>
                                  {moment(file?.evidence_date).format(
                                    "MMM Do, YYYY"
                                  )}
                                </td>
                                <td>{file?.evidence_details}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </TabPane>
                    <TabPane tabId="grid" role="tabpanel">
                      <Row>
                        {versionFiles?.data?.map((file, key) => (
                          <Col md="3">
                            <Card>
                              <CardBody>
                                <i class="fas fa-file-pdf text-danger fa-3x"></i>
                              </CardBody>
                              <CardFooter>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>{file?.evidence_name}</p>
                                  <Button
                                    color="info"
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px 10px",
                                    }}
                                    onClick={() => {
                                      downloadFile(
                                        `${process.env.REACT_APP_BASE_URL}/public/${file?.evidence_attachment}`
                                      );
                                    }}
                                  >
                                    <i class="fas fa-download"></i>
                                  </Button>
                                  {/* <UncontrolledDropdown>
                                      <DropdownToggle
                                        aria-expanded={false}
                                        aria-haspopup={true}
                                        caret
                                        className="btn-round btn-block"
                                        color="primary"
                                        data-toggle="dropdown"
                                        id={key}
                                        type="button"
                                      >
                                        <i class="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        persist
                                        aria-labelledby={key}
                                        right
                                      >
                                        <DropdownItem
                                          download
                                          href="./Untitled.pdf"
                                          target="_blank"
                                        onClick={() => {

                                          downloadFile(`${process.env.REACT_APP_BASE_URL}/public/${file?.evidence_attachment}`)
                                        }}
                                        >
                                          Download
                                        </DropdownItem>
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          View
                                        </DropdownItem>
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </TabPane>
                <TabPane tabId="tasks" role="tabpanel">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Stand Ref.</th>
                        <th>Description</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Iteration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTask?.map((task) => (
                        <tr>
                          <td>{task?.name}</td>
                          <td>{task?.standard_ref}</td>
                          <td>{task?.description}</td>
                          <td>
                            {moment(task?.created_at).format("MMM Do, YYYY")}
                          </td>
                          <td>{task?.status}</td>
                          <td>{task?.Iteration}</td>
                          <td>
                         
                            <i
                              className="fas fa-eye text-info"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                ChangeTaskModal(true);
                                ShowAuditRequirementAnswerTask(task?.id);

                                // setTaskAnswerId(task?.id)
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabPane>
                <TabPane
                  tabId="discussion"
                  role="tabpanel"
                  id="chat-parent-tab"
                >
                  {RenderDiscussion()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      border: "0px solid red",
                      // alignItems: 'center'
                    }}
                  >
                    <img
                      src={img}
                      className="rounded-circle"
                      width="40"
                      height="40"
                      alt=""
                    />
                    <div
                      style={{
                        border: "0px solid red",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          border: "0px solid red",
                        }}
                      >
                        <div
                          className="single-line-text"
                          style={{
                            marginLeft: 10,
                            fontSize: 16,
                            fontWeight: "bold",
                            textAlign: "left",
                            color: "#595974",
                          }}
                        >
                          {/* {"Dani Charles"} */}
                          {
                            JSON.parse(window.localStorage.getItem("user"))
                              ?.name
                          }
                        </div>
                        <div
                          style={{
                            background: "#F4F5F7",
                            marginLeft: 10,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 5,
                          }}
                        >
                          {/* {"Assessor"} */}

                          {JSON.parse(window.localStorage.getItem("user"))
                            ?.role == "0" && "Assessor"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          border: "0px solid red",
                          padding: 10,
                        }}
                      >
                        <textarea
                          placeholder="type your comment here . . ."
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          value={chatComment}
                          onChange={(e) => {
                            setChatComment(e.target.value);
                          }}
                          className="form-control"
                          style={{
                            padding: 5,
                          }}
                        >
                          {
                            "Photons should represent 2 bits instead of a single bit"
                          }
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      className="btn-blue "
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {
                        const data = {
                          // image:img

                          message: chatComment,
                          audit_id:
                            window.localStorage.getItem("audit_life_cycle"),
                          audit_requirement_id:
                            currentSelectedRequirementDetails?.auditRequirementId,
                        };
                        console.log("comment", data);
                        CreateDiscussionData(data);
                        // toggleModalClassic()
                        setChatComment("");
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {/* <Button
                  color="success"
                  onClick={() => toggleModalClassic(false)}
                >
                  Save
                </Button> */}
                <Button
                  color="danger"
                  onClick={() => {
                    toggleModalClassic(false);
                    ClearAllTaskData();
                  }}
                >
                  Close
                </Button>
              </div>
            </CardFooter>
          </div>
        </Modal>
        <Modal
          isOpen={taskModal}
          size="xl"
          toggle={() => ChangeTaskModal(false)}
        >
          <div className="modal-header">
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                ClearAuditAnswerTaskData();
                ChangeTaskModal(false);
                setAssessorCommentTask("");
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <div>
              <h4
                style={{ marginTop: 0, marginBottom: 0 }}
                className="text-left"
              >
                {" "}
                {/* Task Title */}
                {getSubRequirementById?.standard_ref}
              </h4>
            </div>
          </div>
          <div className="modal-body p-1">
            <div className="d-flex my-3 px-3 align-items-center justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <i className="fas fa-user-circle mr-1"></i>{" "}
                  {currentSelectedRequirementDetails?.supportAssignMemberData &&
                  !currentSelectedRequirementDetails?.checkContributeLead ? (
                    currentSelectedRequirementDetails?.supportAssignMemberData?.map(
                      (mem) => (
                        <a href="javascript:void(0)" className="text-dark">
                          Assigned to {mem?.assignMemberName}
                        </a>
                      )
                    )
                  ) : (
                    <a href="javascript:void(0)" className="text-dark">
                      Assigned to{" "}
                      {currentSelectedRequirementDetails?.contributeLeadName}
                    </a>
                  )}
                </div>
                <div className="ml-4">
                  <i className="fas fa-comment text-info mr-1"></i>
                  {getCommentCount} Comments
                </div>
              </div>

              <div className="ml-4">
                {auditAnswer == "No" ? (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-warning"></i>{" "}
                    {"In Process"}
                  </span>
                ) : (
                  <span>
                    {" "}
                    <i className="fas fa-circle text-info"></i> {"Ready"}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-light">
              <Row style={{ padding: 10 }}>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Requirment List
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getSubRequirementById?.data?.req_list?.name}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Section
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getSubRequirementById?.data?.sections?.standard_ref}
                  </span>
                </Col>
                <Col md="3">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Requirment
                  </span>
                  <span style={{ fontSize: 12, display: "block" }}>
                    {getSubRequirementById?.data?.requirement?.standard_ref}
                  </span>
                </Col>
              </Row>
            </div>
            <hr style={{ margin: 0, padding: 0 }} />
            <div className="bg-light">
              <Row>
                <Col md="7">
                  <table className="table ">
                    <thead className="bg-transparent border-0">
                      <tr className="border-0">
                        <th className="border-0">Std Ref.</th>
                        <th>Weight</th>
                        <th>Criticality</th>
                        <th>Reference</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-0">
                        <td className="border-0">
                          {getSubRequirementById?.data?.standard_ref ||
                            currentSelectedRequirementDetails?.standard_ref}
                        </td>
                        <td className="border-0">
                          {getSubRequirementById?.data?.weight ||
                            currentSelectedRequirementDetails?.weight}
                        </td>
                        <td className="border-0">
                          {getSubRequirementById?.data?.criticality ||
                            currentSelectedRequirementDetails?.criticality}
                        </td>
                        <td className="border-0">
                          {getSubRequirementById?.data?.references ||
                            currentSelectedRequirementDetails?.reference?.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                {/* <Col md="5" className="text-right">
                  <p className="p-2">Created By: Moon.</p>
                </Col> */}
              </Row>
            </div>
            <CardBody
              id="modal_scroll"
              style={{
                height: "500px",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <Row>
                <Col md="12">
                  <h6 className="p-2 border-bottom text-left">Description</h6>
                  <textarea
                    value={getSubRequirementById?.data?.description}
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                 Contributor Answer Section
                      </span>
                    </Col>
                    <Col className="text-right">
                      {/* <span style={{ fontSize: 14 }}>Answered by: Zeeshan</span> */}
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                  <div className="d-flex align-items-center justify-content-start">
                    <Row>
                      <Col style={{ paddingRight: 0, minWidth: 130 }} md="3">
                        <FormGroup className="mr-2">
                          <Select
                            isDisabled
                            styles={{
                              container: () => ({}),
                            }}
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            name="answer"
                            closeMenuOnSelect={true}
                            isMulti={false}
                            value={auditAnswerTask}
                            onChange={(value) => {
                              // console.log("SELECTED ANSWER")
                              // console.log(value)
                              setAuditAnswerTask(value);
                            }}
                            options={[
                              {
                                value: 0,
                                label: "Select Answer",
                                isDisabled: true,
                              },
                              { value: 1, label: "No " },
                              { value: 2, label: "Yes" },
                              { value: 3, label: "Partial" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                      {auditAnswer.value == 3 && (
                        <>
                          <Col
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            md="3"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                disabled
                                type="text"
                                placeholder="Input percentage %"
                                value={""}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col
                            style={{ paddingLeft: 0, paddingRight: 8 }}
                            md="6"
                          >
                            <FormGroup className="mr-2">
                              <Input
                                disabled
                                type="text"
                                placeholder="Partial Description"
                                value={""}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col> */}
                        </>
                      )}
                    </Row>
                  </div>
                  <Row>
                    {showAuditAnswerTask?.answer_status == "yes" && (
                      <>
                        <Col
                          md="2"
                          style={{ paddingLeft: 15, paddingRight: 0 }}
                        >
                          <Button
                            // disabled
                            style={{
                              marginTop: 0,
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                            color="info"
                            onClick={() => {
                              downloadFile(
                                `${process.env.REACT_APP_BASE_URL}/public/${showAuditAnswerTask?.evidence_attachment}`
                              );
                            }}
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>{" "}
                            Evidence
                          </Button>
                        </Col>
                        <Col md="10" style={{ paddingLeft: 0 }}>
                          <FormGroup>
                            <Input
                              placeholder="Evidence Detail"
                              disabled
                              value={evidenceDetailTask}
                              onChange={(e) =>
                                setEvidenceDetailTask(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <textarea
                    disabled
                    value={contributeCommentTask}
                    placeholder="Contributor Comment"
                    onChange={(e) => {
                      SetContributeCommentTask(e.target.value);
                    }}
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                  <Row style={{ marginBottom: 0, marginTop: 10 }}>
                    <Col className="text-left" style={{}}>
                      <span style={{ fontSize: 16, fontWeight: "bold" }}>
                        Assessor Section
                      </span>
                    </Col>
                  </Row>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />
                <textarea
                    value={assessorCommentTask}
                    onChange={(e) => setAssessorCommentTask(e.target.value)}
                    placeholder="Assessor Comment"
                    style={{
                      paddingLeft: 10,
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                    // disabled={showAuditAnswerTask?.status !== 0 ? true : false}
                  />
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  color="success"
                  // disabled={showAuditAnswerTask?.status !== 0 ? true : false}
                  onClick={() => {
                    ChangeTaskModal(false);
                    // ClearAuditAnswerTaskData()
                    // let prevAssessorComment = AssessorComment;
                    // setAssessorCommentAutoPopulate(prevAssessorComment);
                    // ChangeAssessorComment("");
                    ClearAuditAnswerTaskAnswerIteration();

                    messageHandler(
                      setAlert,
                      "Successfully saved.",
                      "Task",
                      "success"
                    );
                  }}
                >
            Save
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    ChangeTaskModal(false);
                    ClearAuditAnswerTaskData();
                    setAssessorCommentTask("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </div>
        </Modal>
        <Modal
          isOpen={showCreateTaskModal}
          size="xl"
          toggle={() => {
            ChangeShowCreateTaskModal(false);
          }}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                ChangeShowCreateTaskModal(false);
                setTaskName("");
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">New Task</h4>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Task Name</Label>
                    <Input
                      invalid={getError && getError?.name ? true : false}
                      type="text"
                      placeholder="Task Name"
                      value={taskName}
                      onChange={(e) => {
                        setTaskName(e.target.value);
                      }}
                    />
                    <FormFeedback>{getError && getError?.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Stand Ref.</Label>
                    <Input
                      disabled={true}
                      type="text"
                      placeholder="Stand Ref."
                      // defaultValue={
                      //   getSubRequirementIdReducer?.data?.standard_ref
                      // }
                      // value={standardRef}
                      value={
                        getSubRequirementById?.data?.standard_ref ||
                        currentSelectedRequirementDetails?.standard_ref
                      }
                      onChange={(e) => {
                        setStandardRef(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup>
                    <Label>Description</Label>
                    <textarea
                      value={assessorCommentAutoPopulate}
                      onChange={(e) =>
                        setAssessorCommentAutoPopulate(e.target.value)
                      }
                      style={{
                        paddingLeft: 10,
                      }}
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  color="success"
                  onClick={() => {
                    getError?.name === false
                      ? ChangeShowCreateTaskModal(false)
                      : "";
                    handleTaskData();
                    GetAuditorLifeCycle(
                      localStorage.getItem("audit_life_cycle")
                    );
                    setTaskName("");
                  }}
                >
                  Save
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    ChangeShowCreateTaskModal(false);
                    setTaskName("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}
const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  lifeCycle,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  lifeCycle,
});

export default connect(mapStateToProps, {
  GetAllRequirements,
  GetAuditorLifeCycle,
  DeleteAuditorLifeCycleRequirement,
  MoveAuditorLifeCycleRequirementsToResponse,
  ShowAuditRequirementAnswer,
  GiveAnswerAgainstRequirementOrSubRequirement,
  CreateTask,
  GetAllTask,
  ShowAuditRequirementAnswerTask,
  ClearAuditAnswerTaskData,
  ClearAuditRequirementAnswerData,
  ClearAuditAnswerTaskAnswerIteration,
  ClearAllTaskData,
  GetVersionFiles,
  CreateDiscussionData,
  GetDiscussionData,
  GetTotalComment,
  DeleteComment,
  GetRadarChart,
})(AssessorLifeCycle);
