import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "views/tables/GlobalFilter";
import { useExportData } from "react-table-plugins";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "../../tables/CheckBox";

import {
  AddRequirementsForCreatingAudit,
  ChangeShowAddAuditModal,
  ChangeShowAddRequirmentsModal,
} from "../../../store/actions/auditActions";
import { AddRequirementsForCreatingAuditTemplate } from "store/actions/auditingTemplateActions";

const AddRequirments = (props) => {
  const { addRequirementsForCreatingAudit } = props.audit;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddRequirmentsModal,
    requirment,
    AddRequirementsForCreatingAuditTemplate,
    AddRequirementsForCreatingAudit,
  } = props;
  const { allRequirements } = requirment;
  const { isAddNewAudit } = props.shared;
  const { addRequirementsForCreatingAuditTemplate } = props.auditingTemplate;

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };

  const [data, SetData] = useState([]);

  let dataMap = allRequirements.map((data) => ({
    requirementList: data.req_list.name,
    desc: data.description,
    standardRef: data.standard_ref,
    references: data.reference?.name,
    id: data.id,
    mapped: data?.mapped_requirements
  }));
  let dataTable = React.useMemo(() => dataMap, [allRequirements]);

  useEffect(() => {
    SetData(dataTable);
  }, [allRequirements]);

  const handleRequirementsSubmit = () => {
    let requirements = selectedFlatRows.map((row) => row.original.id);

    isAddNewAudit
      ? AddRequirementsForCreatingAudit([
        ...addRequirementsForCreatingAudit,
        ...requirements,
      ])
      : AddRequirementsForCreatingAuditTemplate([
        ...addRequirementsForCreatingAuditTemplate,
        ...requirements,
      ]);
    console.log(requirements);
  };

  // var dataTable = React.useMemo(
  //   () => [
  //     {
  //       standardRef: "Requirment 1",
  //       desc: "Requirment 1 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
  //       mapped: true,
  //     },
  //     {
  //       standardRef: "Requirment 2",
  //       desc: "Requirment 2 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags([]),
  //       mapped: false,
  //     },
  //     {
  //       standardRef: "Requirment 3",
  //       desc: "Requirment 3 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags([]),
  //       mapped: false,
  //     },
  //   ],
  //   []
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Req: Standard Ref",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirement List",
        accessor: "requirementList",
      },
      {
        Header: "References",
        accessor: "references",
      },
      // {
      //   Header: "Requirment Mapping",
      //   accessor: "reqMapping",
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const filterDataTable = (e) => {
    console.log("E.TARGET.VALUE");
    console.log(e.target.value);
    var elements;
    if (e.target.value == 1) {
      elements = dataTable.filter((el) => {
        console.log(el)
        return el.mapped.length > 0;
      });
    } else if (e.target.value == 2) {
      elements = dataTable.filter((el) => {
        return el.mapped.length == 0;
      });
    } else {
      console.log(dataTable)
      elements = dataTable;
    }
    console.log("ELEMENTS");
    console.log(elements);
    SetData(elements);
  };

  return (
    <>
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            ChangeShowAddRequirmentsModal(false);
          }}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">Add Requirements</h4>
      </div>
      <div className="modal-body">
        <div className="row p-3 align-items-center">
          <div className="col-md-2">
            <div className="d-flex align-items-center justify-content-between">
              Show
              <select
                value={pageSize}
                className="form-control pagination-select"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              Entries
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3 text-right">
            <FormGroup className="custom-form-group">
              <select
                name=""
                id=""
                className="form-control custom-input"
                onChange={(e) => filterDataTable(e)}
              >
                <option value="" defaultValue disabled>
                  Filters
                </option>
                <option value={0}>All</option>
                <option value={1}>Mapped</option>
                <option value={2}>Not Mapped</option>
              </select>
            </FormGroup>
          </div>
          <div className="col-md-3 text-right">
            <div className="form-group custom-form-group">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </div>
        <div className="tableWrap">
          <table {...getTableProps()} className="table-striped table-hover ">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-info text-white"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border border-left-0 border-right "
                      style={{
                        borderBottom: "1px solid #cacaca",
                        background: "rgba(0, 0, 0, 0.05)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down  text-secondary"></i>
                          ) : (
                            <i className="fas fa-arrow-up text-secondary"></i>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <div className="">
            <button
              className="btn btn-info btn-sm"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <span className="pagination-button">
              {pageOptions.map((page) => (
                <button
                  className={
                    pageIndex === page
                      ? "btn border-info border btn-info  bg-transparent text-info "
                      : "btn border-info border btn-info btn-sm bg-transparent text-info"
                  }
                  onClick={() => {
                    const pageNumber = page;
                    gotoPage(pageNumber);
                  }}
                >
                  {page + 1}
                </button>
              ))}
            </span>
            <button
              className="btn btn-info  btn-sm"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
        <div className="text-right">
          <Button
            color="success"
            onClick={() => {
              handleRequirementsSubmit();
              ChangeShowAddRequirmentsModal(false);
            }}
          >
            Add
          </Button>
          <Button
            color="danger"
            onClick={() => {
              ChangeShowAddRequirmentsModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddRequirmentsModal,
  AddRequirementsForCreatingAuditTemplate,
  AddRequirementsForCreatingAudit,
})(AddRequirments);
