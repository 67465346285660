import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import avatar from "../../assets/img/faces/default-avatar.png";
import { Link, useHistory } from "react-router-dom";

import { styles } from "../../styling/custom";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  Form,
} from "reactstrap";
import AddNewAuditingTemplate from "views/tables/AuditingTemplates/AddNewAuditingTemplate";
import AddAudit from "./AllAudits/AddAudit";

import {
  ChangeShowAddAuditModal,
  ChangeShowAddRequirmentsModal,
  ChangeShowAddSectionsModal,
  EditAuditData,
  EmptySingleAuditData,
  GetAllAudit,
  GetSingleAudit,
  GetSingleAuditAndPushToAuditSummary,
} from "../../store/actions/auditActions";
import AddSections from "./AllAudits/AddSections";
import AddRequirments from "./AllAudits/AddRequirments";
import { ChangeIsAddNewAudit } from "store/actions/sharedAction";
import { GetAllAuditingTemplate } from "store/actions/auditingTemplateActions";
import { GetAllSections } from "store/actions/requirmentActions";
import { GetAllRequirements } from "store/actions/requirmentActions";
import {
  GetAuditorLifeCycle,
  GetContributeLifeCycle,
} from "store/actions/lifeCycleAction";
import moment from "moment";
import { baseUrl } from "config/baseUrl";

const AllAudits = (props) => {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  var month = new Array();
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  var d = new Date();
  var n = month[d.getMonth()];
  var newdate = n + "-" + year;
  const [goalsSelectValue, ChangeGoalsSelectValue] = useState("Audit");
  const [goalTitle, ChangeGoalTitle] = useState("");
  const [goalAmount, ChangeGoalAmount] = useState("");
  const [isEditing, ChangeIsEditing] = useState("");
  const [popoverOpen, ChangePopoverOpen] = useState(false);
  const [goalsEnvelopeSelectValue, ChangeGoalsEnvelopeSelectValue] =
    useState("0");
  const [addGoalModal, ChangeAddGoalModal] = useState(false);
  const [auditName, ChangeAuditName] = useState(false);

  const history = useHistory();
  const { EmptySingleAuditData } = props;
  const {
    showAddSectionsModal,
    showAddAuditModal,
    showAddRequirmentsModal,
    allAudit,
  } = props.audit;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddSectionsModal,
    ChangeShowAddRequirmentsModal,
    ChangeIsAddNewAudit,
    GetAllAuditingTemplate,
    GetAllSections,
    GetAllRequirements,
    GetAllAudit,
    GetSingleAudit,
    user,
    company,
    GetSingleAuditAndPushToAuditSummary,
    EditAuditData,
    GetAuditorLifeCycle,
    GetContributeLifeCycle,
  } = props;
  const { currentClickedCompanyData } = company;

  useEffect(() => {
    GetAllAudit();
  }, []);

  const data = [1, 2, 3, 4, 5];

  console.log("allAudit is here", allAudit);
  console.log("all audit req", allAudit);
  console.log(
    "user id",
    JSON.parse(window.localStorage.getItem("user"))?.id
  );
  const [allAuditsMap, setAllAuditsMap] = useState([]);
  const [auditsFilter, setAuditsFilter] = useState("");

  useEffect(() => {
    setAllAuditsMap(allAudit);
  }, [allAudit]);

  useEffect(() => {

    // const filteredAudits = allAudit
    //     ?.filter((audit) => audit.company_id === currentClickedCompanyData)
    //     ?.filter((audit) => {
    //       return audit?.name?.toLowerCase().includes(auditsFilter?.toLowerCase());
    //     });


    //   setAllAuditsMap(filteredAudits)

    // search for auditor
    if (window.localStorage.getItem("currentLoginUserRole") == 0 && allAudit.length > 0) {
      const filteredAudits = allAudit
        ?.filter((audit) => audit.company_id === currentClickedCompanyData)
        ?.filter((audit) => {
          return audit?.name?.toLowerCase().includes(auditsFilter?.toLowerCase());
        });


      setAllAuditsMap(filteredAudits)
    }


    // serach for contribute

    if (window.localStorage.getItem("currentLoginUserRole") == 1 && allAudit.length > 0) {
      const searchFeatureContribute = allAudit
        .filter(
          (audit) =>
            JSON.parse(window.localStorage.getItem("user"))?.id === audit?.contribute_lead?.id

        ).filter((audit) => (
          audit?.name?.toLowerCase().includes(auditsFilter?.toLowerCase())
        ))

      setAllAuditsMap(searchFeatureContribute)
    }

    //seach for support team
    if (window.localStorage.getItem("currentLoginUserRole") == 2 && allAudit.length > 0) {
      const searchFeatureSupport = allAudit
        ?.filter((audit) =>
          audit.requirements.some(
            (e) =>
              e.support_id ===
              JSON.parse(window.localStorage.getItem("user"))?.id
          )
        )

      setAllAuditsMap(searchFeatureSupport)
    }


  }, [auditsFilter]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ width: "32%", marginBottom: "50px" }}>
              <FormGroup className="text-left">
                <Input
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={auditsFilter}
                  onChange={(e) => setAuditsFilter(e.target.value)}
                />
              </FormGroup>
            </div>
            <Row>
              {window.localStorage.getItem("currentLoginUserRole") == 0 && (
                <Col lg="4" style={{ marginBottom: 10 }}>
                  <Card
                    onClick={() => {
                      ChangeShowAddAuditModal(true);
                      ChangeIsEditing(false);
                      ChangeIsAddNewAudit(true);
                      GetAllAuditingTemplate();
                      GetAllSections();
                      GetAllRequirements();
                      EmptySingleAuditData();
                    }}
                    className="card-pricing all-budget-cards clickable-card"
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flex: 1 }}>
                        <CardHeader>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {/* <i style={{ color: "#B53326", fontSize: 16 }} class="fas fa-balance-scale"></i> */}
                            <p
                              style={{
                                marginBottom: 0,
                                marginLeft: 10,
                                fontSize: 16,
                              }}
                              className="font-weight-bold"
                            >
                              Add New
                            </p>
                          </div>
                          <hr style={{ marginBottom: 0, ...styles.smartHr }} />
                        </CardHeader>
                        <CardBody style={{ marginTop: 0 }}>
                          <div
                            style={{
                              height: 230,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                height: 80,
                                width: 80,
                                borderRadius: 80,
                                backgroundColor: "#51BCDA",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i
                                style={{ color: "white", fontSize: 30 }}
                                class="fas fa-plus"
                              ></i>
                            </div>
                          </div>
                          <hr
                            style={{
                              margin: 10,
                              width: "100%",
                              ...styles.smartHr,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: 15,
                            }}
                          ></div>
                        </CardBody>
                      </div>
                    </div>
                  </Card>
                </Col>
              )}

              {allAuditsMap
                .filter(
                  (audit) => audit.company_id === currentClickedCompanyData
                )
                .map((audit) => {
                  const {
                    name: auditName,
                    deadline,
                    standard,
                    description,
                    company_id,
                    id,
                    updated_at,
                  } = audit;

                  const { name: companyName } = audit?.company;
                  const auditversionLength = audit?.versions?.length;
                  const percentageCompliance =
                    audit?.versions?.[auditversionLength - 2];

                  // const { compliance_percentage: percentageCompliance } = audit?.versions[auditversionLength - 2];

                  return (
                    <Col lg="4" style={{ marginBottom: 10 }}>
                      <Card
                        className="card-pricing all-budget-cards clickable-card"
                        style={{ cursor: "default" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: 1 }}>
                            <CardHeader>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 10,
                                    fontSize: 16,
                                  }}
                                  className="font-weight-bold"
                                >
                                  {auditName}
                                </p>
                              </div>
                              <hr style={styles.smartHr} />
                            </CardHeader>
                            <CardBody
                              style={{ marginBottom: 0, cursor: "pointer" }}
                              onClick={() => {
                                window.localStorage.setItem(
                                  "audit_life_cycle",
                                  id
                                );
                                window.localStorage.setItem(
                                  "audit_name",
                                  auditName
                                );
                                GetSingleAudit(
                                  window.localStorage.getItem(
                                    "audit_life_cycle"
                                  )
                                );
                                GetAuditorLifeCycle(
                                  window.localStorage.getItem(
                                    "audit_life_cycle"
                                  )
                                );
                                history.push("/user/assessment-summary");
                                // GetSingleAuditAndPushToAuditSummary(
                                //   id,
                                //   history
                                // );
                              }}
                            >
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Company Name
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {companyName}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Standard
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {standard}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Deadline
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {deadline}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                {/* <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Assessor
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                
                                  {
                                    JSON.parse(
                                      window.localStorage.getItem("user")
                                    )?.data?.user?.name
                                  }
                                </div> */}
                              </div>
                              {/* <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Contributor Lead
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                
                                </div>
                              </div> */}
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                {/* <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Support Team
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {"4"}
                              </div> */}
                              </div>
                              <div className="left-to-right-flex">
                                {audit?.members?.map((member) => (
                                  <>
                                    <img
                                      className="avatar-image-small"
                                      src={member?.member_data?.image ? `${baseUrl}/public${member?.member_data?.image}` : avatar}
                                      alt="Avatar"
                                      id="tooltipAuditSupport"
                                    />
                                    <UncontrolledTooltip delay={0} target="tooltipAuditSupport">
                                      {
                                        member?.member_data?.name

                                      }

                                    </UncontrolledTooltip>
                                  </>
                                ))}
                                {
                                  audit?.contribute_lead && (
                                    <>
                                      <img className="avatar-image-small" src={audit?.contribute_lead?.image ? `${baseUrl}/public${audit?.contribute_lead?.image}` : avatar} alt="Avatar" id="tooltipAuditContribute" />
                                      <UncontrolledTooltip delay={0} target="tooltipAuditContribute">

                                        {
                                          audit?.contribute_lead?.name
                                        }
                                      </UncontrolledTooltip>
                                    </>

                                  )
                                }
                                {/* <img
                                  className="avatar-image-small"
                                  src={avatar}
                                  alt="Avatar"
                                /> */}
                              </div>
                              <div
                                style={{
                                  height: 30,
                                  width: "100%",
                                  flexDirection: "row",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    backgroundColor: "#239b3755",
                                    borderRadius: 5,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: `${Number(
                                        percentageCompliance?.compliance_percentage
                                      )}%`,
                                      backgroundColor: "#239b37",
                                      height: 5,
                                      borderTopLeftRadius: 5,
                                      borderBottomLeftRadius: 5,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {/* <i class="fas fa-redo"></i> */}
                                <span style={{ marginLeft: 5 }}>
                                  Last Update : {moment(updated_at)?.fromNow()}
                                </span>
                              </div>
                            </CardBody>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            GetSingleAudit(id, ChangeShowAddAuditModal);
                            // EditAuditData(audit);
                            ChangeIsAddNewAudit(true);
                            ChangeIsEditing(true);
                          }}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                        >
                          <i class="fas fa-edit"></i>
                        </div>
                      </Card>
                    </Col>
                  );
                })}

              {window.localStorage.getItem("currentLoginUserRole") == 1 &&
                allAudit.length > 0 &&
                // allAudit
                allAuditsMap
                  .filter(
                    (audit) =>
                      JSON.parse(window.localStorage.getItem("user"))?.id === audit?.contribute_lead?.id

                  )
                  .map((audit) => {
                    console.log("auditab", audit);
                    const {
                      name: auditName,
                      deadline,
                      description,
                      company_id,
                      id,
                      members,
                      updated_at,
                      standard,
                    } = audit;
                    const { name: companyName } = audit.company;

                    const auditversionLength = audit?.versions?.length;
                    const percentageCompliance =
                      audit?.versions?.[auditversionLength - 2];

                    // const { compliance_percentage: percentageCompliance } = audit?.versions[auditversionLength - 2];
                    return (
                      <Col lg="4" style={{ marginBottom: 10 }}>
                        <Card
                          className="card-pricing all-budget-cards clickable-card"
                          style={{ cursor: "default" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ flex: 1 }}>
                              <CardHeader>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: 0,
                                      marginLeft: 10,
                                      fontSize: 16,
                                    }}
                                    className="font-weight-bold"
                                  >
                                    {auditName}
                                  </p>
                                </div>
                                <hr style={styles.smartHr} />
                              </CardHeader>
                              <CardBody
                                style={{ marginBottom: 0, cursor: "pointer" }}
                                onClick={() => {
                                  window.localStorage.setItem(
                                    "audit_life_cycle",
                                    id
                                  );
                                  window.localStorage.setItem(
                                    "audit_name",
                                    auditName
                                  );
                                  GetSingleAudit(
                                    window.localStorage.getItem(
                                      "audit_life_cycle"
                                    )
                                  );

                                  GetContributeLifeCycle(
                                    window.localStorage.getItem(
                                      "audit_life_cycle"
                                    )
                                  );
                                  // GetAuditorLifeCycle(id);
                                  history.push("/user/assessment-summary");
                                  // GetSingleAuditAndPushToAuditSummary(
                                  //   id,
                                  //   history
                                  // );
                                }}
                              >
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Company Name
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {companyName}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Standard
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {standard}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Deadline
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {deadline}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Assessor
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                 
                                  </div> */}
                                </div>
                                {/* <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Contributor Lead
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                
                                  </div>
                                </div> */}
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  {/* <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Support Team
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {"4"}
                              </div> */}
                                </div>
                                <div className="left-to-right-flex">
                                  {members.length > 0 && (
                                    <>
                                      {members?.map((member) => (
                                        <>
                                          <img
                                            className="avatar-image-small"

                                            src={member?.member_data?.image ? `${baseUrl}/public${member?.member_data?.image}` : avatar}
                                            alt="Avatar"
                                            id="tooltipAuditSupport"

                                          />
                                          <UncontrolledTooltip delay={0} target="tooltipAuditSupport">
                                            {
                                              member?.member_data?.name

                                            }

                                          </UncontrolledTooltip>
                                        </>))}
                                    </>
                                  )}
                                  {
                                    audit?.contribute_lead && (
                                      <>
                                        <img className="avatar-image-small" src={audit?.contribute_lead?.image ? `${baseUrl}/public${audit?.contribute_lead?.image}` : avatar} alt="Avatar" id="tooltipAuditContribute" />
                                        <UncontrolledTooltip delay={0} target="tooltipAuditContribute">

                                          {
                                            audit?.contribute_lead?.name
                                          }
                                        </UncontrolledTooltip>
                                      </>

                                    )
                                  }
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      flexDirection: "row",
                                      backgroundColor: "#239b3755",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: `${Number(
                                          percentageCompliance?.compliance_percentage
                                        )}%`,
                                        backgroundColor: "#239b37",
                                        height: 5,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <i class="fas fa-redo"></i> */}
                                  <span style={{ marginLeft: 5 }}>
                                    Last Update :{" "}
                                    {moment(updated_at)?.fromNow()}
                                  </span>
                                </div>
                              </CardBody>
                            </div>
                          </div>
                          {/* <div
                              onClick={() => {
                                GetSingleAudit(id, ChangeShowAddAuditModal);
                                // EditAuditData(audit);
                                ChangeIsAddNewAudit(true);
                                ChangeIsEditing(true);
                              }}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              <i class="fas fa-edit"></i>
                            </div> */}
                        </Card>
                      </Col>
                    );
                  })}

              {window.localStorage.getItem("currentLoginUserRole") == 2 &&
                allAudit.length > 0 &&
                // allAudit
                allAuditsMap
                  ?.filter((audit) =>
                    audit.requirements.some(
                      (e) =>
                        e.support_id ===
                        JSON.parse(window.localStorage.getItem("user"))?.id
                    )
                  )

                  .map((audit) => {
                    const {
                      name: auditName,
                      deadline,
                      description,
                      company_id,
                      id,
                      members,
                      updated_at,
                    } = audit;
                    const { name: companyName } = audit.company;

                    const auditversionLength = audit?.versions?.length;
                    const percentageCompliance =
                      audit?.versions?.[auditversionLength - 2];

                    // const { compliance_percentage: percentageCompliance } = audit?.versions[auditversionLength - 2];
                    return (
                      <Col lg="4" style={{ marginBottom: 10 }}>
                        <Card
                          className="card-pricing all-budget-cards clickable-card"
                          style={{ cursor: "default" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ flex: 1 }}>
                              <CardHeader>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: 0,
                                      marginLeft: 10,
                                      fontSize: 16,
                                    }}
                                    className="font-weight-bold"
                                  >
                                    {auditName}
                                  </p>
                                </div>
                                <hr style={styles.smartHr} />
                              </CardHeader>
                              <CardBody
                                style={{ marginBottom: 0, cursor: "pointer" }}
                                onClick={() => {
                                  window.localStorage.setItem(
                                    "audit_life_cycle",
                                    id
                                  );
                                  window.localStorage.setItem(
                                    "audit_name",
                                    auditName
                                  );
                                  GetSingleAudit(id);
                                  GetContributeLifeCycle(id);
                                  // GetSingleAudit(window.localStorage.getItem("audit_life_cycle"));
                                  // GetContributeLifeCycle(window.localStorage.getItem("audit_life_cycle"));

                                  // GetAuditorLifeCycle(id);
                                  history.push("/user/assessment-summary");
                                  // GetSingleAuditAndPushToAuditSummary(
                                  //   id,
                                  //   history
                                  // );
                                }}
                              >
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Company Name
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {companyName}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Standard
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {/* {"ISO "} */}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Deadline
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {deadline}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Assessor
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                 
                                  </div> */}
                                </div>
                                {/* <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Contributor Lead
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                             
                                  
                                  </div>
                                </div> */}
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  {/* <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Support Team
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {"4"}
                              </div> */}
                                </div>
                                <div className="left-to-right-flex">
                                  {members.length > 0 && (
                                    <>
                                      {members?.map((member) => (
                                        <>
                                          <img
                                            className="avatar-image-small"

                                            src={member?.member_data?.image ? `${baseUrl}/public${member?.member_data?.image}` : avatar}
                                            alt="Avatar"
                                            id="tooltipAuditSupport"
                                          />
                                          <UncontrolledTooltip delay={0} target="tooltipAuditSupport">
                                            {
                                              member?.member_data?.name

                                            }

                                          </UncontrolledTooltip>
                                        </>))}
                                    </>
                                  )}
                                  {
                                    audit?.contribute_lead && (
                                      <>
                                        <img className="avatar-image-small" src={audit?.contribute_lead?.image ? `${baseUrl}/public${audit?.contribute_lead?.image}` : avatar} alt="Avatar" id="tooltipAuditContribute" />
                                        <UncontrolledTooltip delay={0} target="tooltipAuditContribute">


                                          {
                                            audit?.contribute_lead?.name
                                          }
                                        </UncontrolledTooltip>
                                      </>

                                    )
                                  }
                                </div>
                                <div
                                  style={{
                                    height: 30,
                                    width: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      flexDirection: "row",
                                      backgroundColor: "#239b3755",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: `${Number(
                                          percentageCompliance?.compliance_percentage
                                        )}%`,
                                        backgroundColor: "#239b37",
                                        height: 5,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <i class="fas fa-redo"></i> */}
                                  <span style={{ marginLeft: 5 }}>
                                    Last Update :{" "}
                                    {moment(updated_at)?.fromNow()}
                                  </span>
                                </div>
                              </CardBody>
                            </div>
                          </div>
                          {/* <div
                              onClick={() => {
                                GetSingleAudit(id, ChangeShowAddAuditModal);
                                // EditAuditData(audit);
                                ChangeIsAddNewAudit(true);
                                ChangeIsEditing(true);
                              }}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              <i class="fas fa-edit"></i>
                            </div> */}
                        </Card>
                      </Col>
                    );
                  })}
            </Row>
          </Col>
        </Row>
        <Modal
          isOpen={showAddAuditModal}
          size="xl"
          toggle={() => {
            ChangeShowAddAuditModal(false);
          }}
        >
          <AddAudit isEditing={isEditing} />
        </Modal>
        <Modal
          isOpen={showAddSectionsModal}
          size="xl"
          toggle={() => {
            ChangeShowAddSectionsModal(false);
          }}
        >
          <AddSections />
        </Modal>
        <Modal
          isOpen={showAddRequirmentsModal}
          size="xl"
          toggle={() => {
            ChangeShowAddRequirmentsModal(false);
          }}
        >
          <AddRequirments />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit, user, company }) => ({
  shared,
  styling,
  audit,
  user,
  company,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
  ChangeShowAddRequirmentsModal,
  ChangeIsAddNewAudit,
  GetAllAuditingTemplate,
  GetAllSections,
  GetAllRequirements,
  GetAllAudit,
  GetSingleAudit,
  GetSingleAuditAndPushToAuditSummary,
  EditAuditData,
  GetAuditorLifeCycle,
  GetContributeLifeCycle,
  EmptySingleAuditData,
})(AllAudits);
