import {
    GET_ALL_DASHBOARD_INFO
} from "../actions/types";


const initialState = {
    dashboardInfo: null
};


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ALL_DASHBOARD_INFO:
            return {
                ...state,
                dashboardInfo: payload,
            }
        default:
            return state;
    }
}