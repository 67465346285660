import React, { useEffect, useState, useRef } from "react";
import avatar from "../../assets/img/faces/default-avatar.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import img from "../../assets/img/faces/default-avatar.png";
import { Line, Bar, Doughnut, Radar } from "react-chartjs-2";
import PerfectScrollbar from "perfect-scrollbar";
import ReactToPrint from "react-to-print";

import moment from "moment";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  HashRouter,
  useHistory,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Form,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { GetAlLSupportTeam, SupportTeamInvite } from "store/actions/userAction";
import {
  GetSingleAuditAndPushToAuditSummary,
  GetAllMainReqList,
} from "store/actions/auditActions";
import {
  GetAuditorLifeCycle,
  GetContributeLifeCycle,
  CreateVersionLifeCycle,
  GetReport,
  ShowVersionNameReport,
} from "store/actions/lifeCycleAction";
// import { chartExample1, chartExample4 } from "../../variables/charts";
import { connect } from "react-redux";
import {
  GetCompliantVsNoCompliant,
  GetAllContributeLead,
  AssignAuditToContribute,
} from "store/actions/auditActions";
import { SummaryDetails } from "store/actions/auditActions";
import { GetAuditorTaskNotification } from "store/actions/auditActions";
import { GetContributeTaskNotification } from "store/actions/auditActions";
import { InviteContributeToAudit } from "store/actions/auditActions";
import { InviteMultiSupportToAudit } from "store/actions/auditActions";
import HtmlParser from "react-html-parser";
import { baseUrl } from "config/baseUrl";
import { GetSingleRequirementList } from "store/actions/requirmentActions";
import { ChangeIsRequirementListEdit } from "store/actions/requirmentActions";
import { ChangeRequirementEditing } from "store/actions/requirmentActions";

// check report
const ComponentToPrint = React.forwardRef(({ getReportFile }, ref) => {
  return (
    <div ref={ref}>
      {/* report file */}
      <div class="front-page ">
        <p class="front-page_text">
          {/* [Assesment Name] */}
          {getReportFile?.data?.assesment_data?.name} Assesment
        </p>
        <p class="front-page_text">for</p>
        <p class="front-page_text">
          {getReportFile?.data?.assesment_data?.company?.name}
        </p>
        <p class="front-page_text">
          {getReportFile?.data?.assesment_data?.deadline}
        </p>
      </div>

      {/* assignment method page */}
      <div class="asessment-method-page pt-5">
        <h3 class="assesment-method_header">Asessment Method</h3>

        <div class="assesment-method_table col-10 ">
          <table class="table table-bordered">
            <tbody>
              <tr class="table-active"></tr>
              <tr></tr>
              {/* <tr>

              <td colspan="2" class="table-active">Assesment Method</td>
              <td colspan="4">
                Self Assesment
                Evidence-based Answer
              </td>
            </tr> */}
              <tr>
                <td colspan="2" class="table-active">
                  Assesment Method
                </td>
                <td colspan="4">
                  Self Assesment
                  <br />
                  Evidence-based Answer
                </td>
              </tr>
              <tr>
                <td colspan="2" class="table-active">
                  Assesment start Date
                </td>
                <td colspan="4">
                  {/* [Date of assigning the contributor lead] */}
                  {moment(
                    getReportFile?.data?.assesment_start_date?.created_at
                  ).format("MMM Do, YYYY")}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="table-active">
                  Assessment End Date
                </td>
                <td colspan="4">
                  {/* [Date of finishing the answering process] */}
                  {}
                  {moment(getReportFile?.data?.assesment_data?.deadline).format(
                    "MMM Do, YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="table-active">
                  Contributor & Support Team
                </td>
                <td colspan="4">
                  {/* [- List of all contributors
                - name
                - name ] */}
                  {getReportFile?.data?.assesment_data?.contribute_lead?.name}
                  {/* list of all support team */}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="table-active">
                  Summary of Compliance
                </td>
                <td colspan="4">
                  {/* Compliance is [percentage of the compliant questions] */}
                  Compliance is {getReportFile?.data?.percentage_of_compliant}%
                  <br />
                  {/* Incompliance is [percentage of the incompliant questions] */}
                  Incompliance is{" "}
                  {getReportFile?.data?.percentage_of_non_compliant}%
                  <br />
                  {/* Partial compliance is [percentage of partial compliant questions] */}
                  Partial compliance is{" "}
                  {getReportFile?.data?.percentage_of_partial_compliant}%
                  <br />
                </td>
              </tr>
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Radar data={barchartAssessement} options={options} />
            <p>Spider graph of all requirements</p>
          </div>

          {/* <canvas id="marksChart3" width="600" height="400"></canvas>
<p>[Spider graph of all sections]
Spider graph of all sections
</p> */}
          {/* <!-- <canvas id="marksChart" width="600" height="400"></canvas>
<p>[Spider graph of all requirements, ll sections]
Spider graph of all requirements
</p>
<canvas id="marksChart3" width="600" height="400"></canvas> --> */}
        </div>
      </div>
      {/* <!-- -----------------------------------------------------------------------SECTION NAME------------- --> */}
      {getReportFile?.data?.Sections.map((section, index) => (
        <div class="ref-section-name-page pt-5">
          <h3>
            {" "}
            Section:
            {/* [Std.Ref Section Name] */}
            {section?.section?.standard_ref}
          </h3>
          <p>
            {/* [Section description] */}
            {section?.section?.description}
          </p>
          <div class="ref-section_table col-10 ">
            <table class="table table-bordered">
              <tbody>
                <tr class="table-active"></tr>
                <tr></tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Summary of Compliance{" "}
                  </td>
                  <td colspan="4">
                    Compliance: {section?.per_of_compliant_sec}%
                    {/* [percentage of the compliant questions in the section] */}
                    <br />
                    Incompliance: {section?.per_of_non_compliant_sec}%
                    {/* [percentage of the incompliant questions in the section] */}
                    <br />
                    Partial Compliance: {section?.per_of_partial_compliant_sec}%
                    {/* [percentage of partial compliant questions in the section] */}
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Number of Requirements in the Section
                  </td>
                  <td colspan="4">
                    {/* [number of requirements in question] */}
                    {section?.total_req_in_section}
                    <br />
                  </td>
                </tr>
                {/* <p>[Spider graph of all requirements in the section]
                  Spider graph of requirements in the section
                </p> */}
              </tbody>
            </table>

            {/* <p>[Spider graph of all requirements, ll sections]
Spider graph of all requirements
</p>
<canvas id="marksChart2" width="600" height="400"></canvas> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Radar data={barchartAssessement} options={options} />
              <p>
                {/* [Spider graph of all sections] */}
                Spider graph of all sections
              </p>
            </div>
          </div>
        </div>
      ))}

      {/* <!-- ----------------------------------------------------------------------------REQUIRMENT NAME --> */}
      {getReportFile?.data?.Requirements.map((requirement, index) => (
        <div class="asessment-method-page pt-5">
          <h3 class="assesment-method_header">
            Requirement: {requirement?.requirement_data?.standard_ref}
            {/* [Std.Ref Requirement Name] */}
          </h3>
          <p>
            {/* [Requirement description] */}
            {requirement?.requirement_data?.description}
          </p>

          <div class="assesment-method_table col-10 ">
            <table class="table table-bordered">
              <tbody>
                <tr class="table-active"></tr>
                <tr></tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Summary of Compliance
                  </td>
                  <td
                    colspan="4"
                    style={
                      (requirement?.final_percentage <= 20 && {
                        color: "red",
                      }) ||
                      (requirement?.final_percentage >= 30 &&
                        requirement?.final_percentage <= 50 && {
                          color: "orange",
                        }) ||
                      (requirement?.final_percentage >= 60 &&
                        requirement?.final_percentage <= 70 && {
                          color: "yellow",
                        }) ||
                      (requirement?.final_percentage >= 80 &&
                        requirement?.final_percentage <= 100 && {
                          color: "green",
                        })
                    }
                  >
                    {/* Compliant / Incompliant / Partially compliant [percentage] */}

                    {
                      //   requirement?.final_percentage === 100 ? ( `Compliance:", ${requirement?.final_percentage}%`): requirement?.final_percentage === 0 ?
                      //    (`Incompliance:", ${requirement?.final_percentage}%`):(`Partial Compliance:", ${requirement?.final_percentage}%`)
                      (requirement?.final_percentage == 100 &&
                        `Compliance: ${requirement?.final_percentage}%`) ||
                        (requirement?.final_percentage == 0 &&
                          `Incompliance: ${requirement?.final_percentage}%`) ||
                        (requirement?.final_percentage > 0 &&
                          requirement?.final_percentage < 0 &&
                          `Partial: ${requirement?.final_percentage}%`)
                    }
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Name of Contributor
                  </td>
                  <td colspan="4">
                    {/* [name of who answered] */}
                    {requirement?.contribute_lead === null
                      ? "None"
                      : requirement?.contribute_lead?.name}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Name of Support
                  </td>
                  <td colspan="4">
                    {requirement?.support_team === null
                      ? `None`
                      : requirement?.support_team?.name}
                    {/* [name of support if any or] None */}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Criticality
                  </td>
                  <td colspan="4">
                    {/* [Criticality] */}
                    {requirement?.requirement_data?.criticality === null
                      ? "N/A"
                      : requirement?.requirement_data?.criticality}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Evidence
                  </td>
                  <td colspan="4">
                    {/* [name of file uploaded in the evidence field] */}
                    {requirement?.answer?.evidence_name === null
                      ? "N/A"
                      : requirement?.answer?.evidence_name}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Answer
                  </td>
                  <td colspan="4">
                    {/* [Answer] */}

                    {requirement?.answer?.contribute_comments === null
                      ? "N/A"
                      : requirement?.answer?.contribute_comments}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Comment
                  </td>
                  <td colspan="4">
                    {/* [Comment] */}
                    {requirement?.answer?.auditor_comments === null
                      ? "N/A"
                      : requirement?.answer?.auditor_comments}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* 
<p>[Spider graph of all sections]
Spider graph of all sections
</p>
<canvas id="marksChart" width="600" height="400"></canvas> */}
            {/* <!-- <p>[Spider graph of all requirements, ll sections]
Spider graph of all requirements
</p>
<canvas id="marksChart2" width="600" height="400"></canvas> --> */}
          </div>
        </div>
      ))}

      {/* <!-- -----------------------------------------SUBREQUIRMENT NAME--------------------- --> */}
      {getReportFile?.data?.sub_Requirement?.map((subrequirement) => (
        <div class="subrequirment-method-page pt-5">
          <h3 class="assesment-method_header">
            Sub-requirement:{subrequirement?.sub_requirement?.standard_ref}
            {/* [Std.Ref Subrequirement Name] */}
          </h3>
          <p>
            {/* [Subrequirement description]] */}
            {subrequirement?.sub_requirement?.description}
          </p>

          <div class="assesment-method_table col-10 ">
            <table class="table table-bordered">
              <tbody>
                <tr class="table-active"></tr>
                <tr></tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Summary of Compliance
                  </td>
                  <td
                    colspan="4"
                    style={
                      (subrequirement?.final_percentage <= 20 && {
                        color: "red",
                      }) ||
                      (subrequirement?.final_percentage >= 30 &&
                        subrequirement?.final_percentage <= 50 && {
                          color: "orange",
                        }) ||
                      (subrequirement?.final_percentage >= 60 &&
                        subrequirement?.final_percentage <= 70 && {
                          color: "yellow",
                        }) ||
                      (subrequirement?.final_percentage >= 80 &&
                        subrequirement?.final_percentage <= 100 && {
                          color: "green",
                        })
                    }
                  >
                    {/* Compliant / Incompliant / Partially compliant [percentage] */}
                    {(subrequirement?.final_percentage == 100 &&
                      `Compliance: ${subrequirement?.final_percentage}%`) ||
                      (subrequirement?.final_percentage == 0 &&
                        `Incompliance: ${subrequirement?.final_percentage}%`) ||
                      (subrequirement?.final_percentage > 0 &&
                        subrequirement?.final_percentage < 0 &&
                        `Partial: ${subrequirement?.final_percentage}%`)}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Name of Contributor
                  </td>
                  <td colspan="4">
                    {/* [name of who answered] */}
                    {subrequirement?.audit_requirement?.contribute_lead === null
                      ? "N/A"
                      : subrequirement?.audit_requirement?.contribute_lead
                          ?.name}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Name of Support
                  </td>
                  <td colspan="4">
                    {/* [name of support if any or] None */}
                    {subrequirement?.audit_requirement?.support_team === null
                      ? `None`
                      : subrequirement?.audit_requirement?.support_team?.name}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Criticality
                  </td>
                  <td colspan="4">
                    {/* [Criticality] */}
                    {subrequirement?.sub_requirement?.criticality === null
                      ? "N/A"
                      : subrequirement?.sub_requirement?.criticality}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Evidence
                  </td>
                  <td colspan="4">
                    {/* [name of file uploaded in the evidence field] */}
                    {subrequirement?.evidence_name === null
                      ? "N/A"
                      : subrequirement?.evidence_name}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Answer
                  </td>
                  <td colspan="4">
                    {/* [Answer] */}
                    {subrequirement?.answer_status}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="table-active">
                    Comment
                  </td>
                  <td colspan="4">
                    {/* [Comment] */}
                    {subrequirement?.contribute_comments === null
                      ? "N/A"
                      : subrequirement?.contribute_comments}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
});

//report chart for assement method
const barchartAssessement = {
  labels: [],
  datasets: [
    {
      label: "Requirement",
      data: [],
      backgroundColor: "rgb(255, 99, 132)",
    },
  ],
};

//report chart for section
// const barchartSection = {
//   labels: [],
//   datasets: [
//     {
//       label: "Requirement",
//       data: [],
//       backgroundColor: "rgb(255, 99, 132)",
//     },

//   ],
// };

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

var ps;

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length " id="datatable-basic_length">
      <label className="d-flex justify-content-start">
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});
const { SearchBar } = Search;

function AuditSummary(props) {
  const {
    audit,
    lifeCycle,
    user,
    GetAlLSupportTeam,
    SupportTeamInvite,
    GetSingleAuditAndPushToAuditSummary,
    GetAuditorLifeCycle,
    GetContributeLifeCycle,
    CreateVersionLifeCycle,
    GetAllMainReqList,
    GetCompliantVsNoCompliant,
    SummaryDetails,
    GetContributeTaskNotification,
    GetAuditorTaskNotification,
    GetAllContributeLead,
    AssignAuditToContribute,
    InviteContributeToAudit,
    InviteMultiSupportToAudit,
    GetReport,
    ShowVersionNameReport,
    GetSingleRequirementList,
    ChangeIsRequirementListEdit,
    ChangeRequirementEditing
  } = props;
  const {
    singleAudit,
    versionGraph,
    summaryDetails,
    getContributeTaskNotification,
    getAuditorTaskNotification,
    getAllMainReqList,
    allContributeLead,
    assignAuditToContribute,
  } = audit;
  const { allSupportTeam, inviteSuppportTeam } = user;
  const { getContributeLifeCycle, getReportFile, showVersionNameReport } =
    lifeCycle;
  const history = useHistory();

  console.log("getReportFile", getReportFile);

  const [alert, setAlert] = useState(null);
  console.log("support team", allSupportTeam);
  console.log(":::::::::Assessment SUMMARY:::::::::::");
  console.log(singleAudit);
  console.log("version graph", versionGraph);
  console.log("summary details", summaryDetails);
  console.log("getContributeLifeCycle", getContributeLifeCycle);
  console.log("getContributeTaskNotification", getContributeTaskNotification);
  console.log("getAuditorTaskNotification", getAuditorTaskNotification);
  console.log("getContribute ", allContributeLead);
  // const { name, deadline, description } = singleAudit;
  const mainPanel = React.useRef();

  const componentRef = useRef();
  React.useEffect(() => {
    const container = document.querySelector("#scroll-bar");
    ps = new PerfectScrollbar(container);
  });
  //create a model for support team invitation
  const [modalClassic, setModalClassic] = React.useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  //create a model for Contribute team team invitation
  const [modalClassicContribute, setModalClassicContribute] =
    React.useState(false);
  const toggleModalClassicContribute = () => {
    setModalClassicContribute(!modalClassicContribute);
  };

  // create a model for create version
  const [modalClassicVersion, setModalClassicVersion] = React.useState(false);
  const toggleModalClassicVersion = () => {
    setModalClassicVersion(!modalClassicVersion);
  };

  // create a model for create report
  const [modalClassicReport, setModalClassicReport] = React.useState(false);
  const toggleModalClassicReport = () => {
    setModalClassicReport(!modalClassicReport);
  };

  // create a model for select version
  const [modalClassicReportVersionName, setModalClassicReportVersionName] =
    React.useState(false);
  const toggleModalClassicReportVersionName = () => {
    setModalClassicReportVersionName(!modalClassicReportVersionName);
  };

  const [multipleSelectContribute, setMultipleSelectContribute] =
    React.useState(null);
  const [multipleSelect, setMultipleSelect] = React.useState(null);
  const [startVersionDate, setStartVersionDate] = useState();
  const [auditVersionName, setAuditVersionName] = useState();
  const [endVersionDate, setEndVersionDate] = useState();
  const [selectVersion, setSelectVersion] = useState();
  const [slectMemeberError, setSlectMemeberError] = useState("");
  const [slectInviterError, setSlectInviterError] = useState("");

  const [inviteTeam, setInviteTeam] = useState();
  // useEffect(() => {
  //     var page_link = document.getElementsByClassName('page-link');
  //     for (let i = 0; i < page_link.length; i++) {
  //         console.log(page_link[1]);
  //         page_link[0].innerHTML = 'Previous'
  //         page_link[page_link.length - 1].innerHTML = 'Next'
  //     }
  // });

  // A variable that store length of member which used to

  getReportFile?.data?.assesment_graph.map((assesment_graph) => {
    barchartAssessement?.labels?.push(assesment_graph?.requirement_name);
    barchartAssessement?.datasets[0]?.data?.push(
      assesment_graph?.final_percentage
    );
  });

  const memberlengthAfterthree = singleAudit?.members.length;

  const handelInviteContributeTeam = (e) => {
    e.preventDefault();

    // const userIds = multipleSelect?.map(ids => (
    //   ids.value
    // ))
    // console.log(userIds)
    const data = {
      // user_ids: userIds,
      entity_id: multipleSelectContribute?.value,
      audit_id: singleAudit.id,
    };
    console.log("dd",data);
     SupportTeamInvite(data, setAlert)
    AssignAuditToContribute(data);
    setMultipleSelectContribute("");
    
  };

  // a function for handel support invitation
  const handelInviteSuppportTeam = (e) => {
    e.preventDefault();

    const userIds = multipleSelect?.map((ids) => ids.value);
    console.log(userIds);
    const data = {
      user_ids: userIds,
      audit_id: singleAudit.id,
    };
    console.log(data);
    SupportTeamInvite(data, setAlert);
    setMultipleSelect("");
  };

  // a function for handel create version
  const handelCreateVersion = (e) => {
    e.preventDefault();
    const createVersionData = {
      audit_id: Number(window.localStorage.getItem("audit_life_cycle")),
      status: 1,
      start_date: startVersionDate,
      end_date: endVersionDate,
      name: auditVersionName,
    };

    console.log("versionData", createVersionData);
    CreateVersionLifeCycle(createVersionData, setAlert);
  };

  // useEffect(() => {
  //   GetSingleAuditAndPushToAuditSummary();
  // }, [])

  // chart data for get compliant vs no compliant
  const chartData = {
    data: {
      labels: [
        // "14 Jan, 1990 - 1.0",
        // "14 Jan, 1998 - 2.0",
        // "14 Jan, 2006 - 3.0",
        // "14 Jan, 2012 - 4.0",
        // "14 Jan, 2018 - 5.0",
      ],
      datasets: [
        {
          label: "Compliant",
          borderColor: "#fcc468",
          fill: true,
          backgroundColor: "#fcc468",
          hoverBorderColor: "#fcc468",
          borderWidth: 8,
          barPercentage: 0.4,
          data: [
            // 100,
            // 120,
            // 80,
            // 100,
            // 90,
          ],
        },
        {
          label: "Not-Compliant",
          borderColor: "#4cbdd7",
          fill: true,
          backgroundColor: "#4cbdd7",
          hoverBorderColor: "#4cbdd7",
          borderWidth: 8,
          barPercentage: 0.4,
          data: [
            // 80,
            // 140,
            // 50,
            // 120,
            // 50,
          ],
        },
        {
          label: "Partial-Compliant",
          borderColor: "#bed322",
          fill: true,
          backgroundColor: "#bed322",
          hoverBorderColor: "#bed322",
          borderWidth: 8,
          barPercentage: 0.4,
          data: [
            // 80,
            // 140,
            // 50,
            // 120,
            // 50,
          ],
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },

        tooltips: {
          tooltipFillColor: "rgba(0,0,0,0.5)",
          tooltipFontFamily:
            "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipFontSize: 14,
          tooltipFontStyle: "normal",
          tooltipFontColor: "#fff",
          tooltipTitleFontFamily:
            "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 14,
          tooltipTitleFontStyle: "bold",
          tooltipTitleFontColor: "#fff",
          tooltipYPadding: 6,
          tooltipXPadding: 6,
          tooltipCaretSize: 8,
          tooltipCornerRadius: 6,
          tooltipXOffset: 10,
        },
      },
      scales: {
        y: {
          ticks: {
            color: "#9f9f9f",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20,
          },
          grid: {
            zeroLineColor: "transparent",
            display: true,
            drawBorder: false,
            color: "#9f9f9f",
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            color: "#9f9f9f",
          },
        },
      },
    },
  };

  versionGraph?.map((version) => {
    chartData?.data?.labels?.push(version.name);

    chartData?.data?.datasets[0]?.data?.push(version?.no_of_compliant);
    chartData?.data?.datasets[1]?.data?.push(version?.no_of_non_compliant);
    chartData?.data?.datasets[2]?.data?.push(version?.$no_of_partial_compliant);
  });

  console.log("chartData", chartData.data.datasets[1].data);

  useEffect(() => {
    GetSingleAuditAndPushToAuditSummary();
    GetCompliantVsNoCompliant(localStorage.getItem("audit_life_cycle"));
    SummaryDetails(localStorage.getItem("audit_life_cycle"));
    // GetAuditorLifeCycle(window.localStorage.getItem("audit_life_cycle"))
    GetContributeLifeCycle(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  useEffect(() => {
    GetAuditorTaskNotification(window.localStorage.getItem("audit_life_cycle"));
    GetContributeTaskNotification(
      window.localStorage.getItem("audit_life_cycle")
    );
  }, [getAuditorTaskNotification?.length]);

  useEffect(() => {
    GetAllMainReqList(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  useEffect(() => {
    GetAlLSupportTeam();
  }, [inviteSuppportTeam]);
  // getting all contributor Lead
  useEffect(() => {
    GetAllContributeLead();
    GetSingleAuditAndPushToAuditSummary();
  }, [assignAuditToContribute, singleAudit?.contribute_lead?.name]);

  useEffect(() => {
    ShowVersionNameReport(window.localStorage.getItem("audit_life_cycle"));
  }, []);

  console.log("showVersionNameReport", showVersionNameReport);

  return (
    <>
      {alert}
      <div className="content">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h5>Assessment Summary</h5> */}
          <div>
            {window.localStorage.getItem("currentLoginUserRole") == 0 && (
              <Button
                color="info"
                onClick={() => {
                  toggleModalClassicReportVersionName();
                  // toggleModalClassicReport()
                }}
              >
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Generate
              </Button>
            )}
          </div>
          <div>
            {/* <Button color="info" onClick={toggleModalClassic}>Add Category</Button> */}
            {window.localStorage.getItem("currentLoginUserRole") == 0 && (
              <>
                <Button
                  color="info"
                  onClick={() => {
                    toggleModalClassicVersion();
                  }}
                  disabled={
                    window.localStorage.getItem("audit_life_cycle")
                      ? false
                      : true
                  }
                >
                  {" "}
                  <i class="fas fa-redo"></i> Repeat
                </Button>

                <Button color="info" onClick={toggleModalClassicContribute}>
                  {" "}
                  <i className="fas fa-plus"></i> Invite CL
                </Button>
              </>
            )}
            {/* if you need to show invite button in auditor and support team you can use 0 for auditor and 2 for suppport team */}
            {/*window.localStorage.getItem("currentLoginUserRole") == 0 ||
              window.localStorage.getItem("currentLoginUserRole") == 2 || */}
            {(window.localStorage.getItem("currentLoginUserRole") == 1 ||
              window.localStorage.getItem("currentLoginUserRole") == 0) && (
              <Button color="info" onClick={toggleModalClassic}>
                {" "}
                <i className="fas fa-plus"></i> Invite ST
              </Button>
            )}
          </div>
        </div>
        <Row>
          <Col md="7">
            <Card className="">
              <CardHeader>
                <div className="" style={{ fontWeight: "bold", fontSize: 18 }}>
                  {singleAudit?.name}
                </div>
                <div className="" style={{ fontSize: 12 }}>
                  Created by{" "}
                  {JSON.parse(window.localStorage.getItem("user"))?.name} using{" "}
                  {singleAudit?.template?.name}
                </div>
                <div className="" style={{ fontSize: 12 }}>
                  Deadline: {singleAudit?.deadline}
                </div>
              </CardHeader>
              <CardBody>
                <p className="font-weight-bold ">Description</p>
                <p className="pl-2">{singleAudit?.description}</p>
                <p className="font-weight-bold ">Requirement List</p>
                <Row style={{ height: "100px", overflow: "auto" }}>
                  {getAllMainReqList?.map((ReqName) => (
                    <Col md="4" sm="6" xs="12" style={{ height: "40px" }}>
                      {console.log("reqlist", ReqName)}
                      <Link
                        to={
                          window.localStorage.getItem("currentLoginUserRole") ==
                          0
                            ?   "/user/requirements/create-requirement"
                            :  "/user/requirements/create-requirement"
                        }

                        onClick={() => {
                           history.push(
                             "/user/requirements/create-requirement"
                           );
                          GetSingleRequirementList(
                          ReqName?.req_list_id,
                            history
                          );
                          ChangeIsRequirementListEdit(true);
                          ChangeRequirementEditing({
                            isEdit: true,
                            requirementListId: ReqName?.req_list_id
                            
                          });
                        }}

                      >
                        {/* {
                          
                          GetSingleRequirementList(
                            row.original.id,
                            history
                          );
                          ChangeIsRequirementListEdit(true);
                          ChangeRequirementEditing({
                            isEdit: true,
                            requirementListId: row.original.id,
                          });
                      

                        } */}
                        <p className="pl-5"> {ReqName?.req_list_name}</p>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </CardBody>
              <CardFooter>
                <div
                  className=""
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 5 }}
                >
                  Members
                </div>
                <Row>
                  <Col
                    className=" justify-content-center 
                    "
                    style={{ borderRight: "1px solid silver" }}
                    md="4"
                  >
                    <div
                      className="text-center"
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        wordWrap: "break-word",
                      }}
                    >
                      Assessor
                    </div>
                    <div className="d-flex justify-content-center flex-column ">
                      <img
                        className="avatar-image-small avatar-image-medium align-self-center"
                        src={
                          JSON.parse(localStorage.getItem("user"))?.image
                            ? `${baseUrl}/public${
                                JSON.parse(localStorage.getItem("user"))?.image
                              }`
                            : avatar
                        }
                        alt="Avatar"
                        id="tooltipAssessorName"
                      />
                      <div
                        className="text-center"
                        style={{
                          marginBottom: 10,
                          color: "gray",
                          wordWrap: "break-word",
                        }}
                      >
                        {JSON.parse(window.localStorage.getItem("user"))?.name}
                      </div>
                    </div>
                    <UncontrolledTooltip delay={0} target="tooltipAssessorName">
                      {JSON.parse(window.localStorage.getItem("user"))?.name}
                    </UncontrolledTooltip>
                  </Col>
                  <Col style={{ borderRight: "1px solid silver" }} md="4">
                    <div
                      className="text-center"
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        wordWrap: "break-word",
                      }}
                    >
                      Contributor Lead
                    </div>
                    {singleAudit?.contribute_lead && (
                      <>
                        <div className="d-flex justify-content-center flex-column ">
                          <img
                            className="avatar-image-small avatar-image-medium align-self-center"
                            src={
                              singleAudit?.contribute_lead?.image
                                ? `${baseUrl}/public${singleAudit?.contribute_lead?.image}`
                                : avatar
                            }
                            alt="Avatar"
                            id="toolTipContributeLead"
                          />
                          <div
                            className="text-center"
                            style={{
                              marginBottom: 10,
                              color: "gray",
                              wordWrap: "break-word",
                            }}
                          >
                            {singleAudit?.contribute_lead?.name}
                          </div>
                        </div>

                        <UncontrolledTooltip
                          delay={0}
                          target="toolTipContributeLead"
                        >
                          {singleAudit?.contribute_lead?.name}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </Col>
                  <Col style={{}} md="4" className=" justify-content-center">
                    <div
                      className="text-center"
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        wordWrap: "break-word",
                      }}
                    >
                      Support Team
                    </div>

                    {singleAudit?.members?.slice(0, 3)?.map((member) => (
                      <>
                        <div className="d-flex justify-content-center flex-column ">
                          <img
                            className="avatar-image-small avatar-image-medium  align-self-center"
                            src={
                              member?.member_data?.image
                                ? `${baseUrl}/public${member?.member_data?.image}`
                                : avatar
                            }
                            alt="Avatar"
                            id={`tooltipSupportTeam${member?.member_data?.id}`}
                          />
                          <div
                            className="text-center"
                            style={{
                              marginBottom: 10,
                              color: "gray",
                              wordWrap: "break-word",
                            }}
                          >
                            {
                              // singleAudit?.members?.map(i => (
                              //   i.member_data.name
                              // ))
                              member?.member_data?.name
                            }
                          </div>
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltipSupportTeam${member?.member_data?.id}`}
                        >
                          {
                            // singleAudit?.members?.map(i => (
                            //   i.member_data.name
                            // ))
                            member?.member_data?.name
                          }
                        </UncontrolledTooltip>
                      </>
                    ))}

                    {memberlengthAfterthree > 3 && (
                      <span
                        style={{
                          backgroundColor: "#199fc4",
                          height: 50,
                          width: 50,
                          display: "flex",
                          borderRadius: 50,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          +{singleAudit?.members?.length - 3}
                        </span>
                      </span>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                {/* <CardTitle tag="h4">2021 Sales</CardTitle> */}
                <p className="card-category">
                  Assessment compliant success rate
                </p>
              </CardHeader>
              <CardBody>
                <Bar data={chartData.data} options={chartData.options} />
              </CardBody>
              <CardFooter>
                <div
                  className="legend   
                d-flex flex-md-row flex-column"
                >
                  <div className="">
                    <i
                      className="fa fa-circle text-warning"
                      style={{ marginLeft: 10 }}
                    />
                    Compliant Requirments
                  </div>
                  <div className="">
                    <i
                      className="fa fa-circle text-info"
                      style={{ marginLeft: 10 }}
                    />
                    Total Requirements
                  </div>
                  <div className="">
                    <i
                      class="fa fa-adjust "
                      style={{ color: "#bed322", marginLeft: 10 }}
                    ></i>
                    Partial Compliant Requirments
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="5">
            <Card
              id="scroll-bar"
              style={{ height: "450px", position: "relative" }}
            >
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6>Notifications</h6>
                  <div>
                    {/* <select name="" id="" className="form-control">
                                            <option value="">Last 7 Days</option>
                                            <option value="">Last Month</option>
                                        </select> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {localStorage.getItem("currentLoginUserRole") == 0 &&
                getAuditorTaskNotification?.length > 0 ? (
                  <>
                    {getAuditorTaskNotification?.map((notification) => (
                      <>
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>
                              <i className="fas fa-file text-info mr-1"></i>
                              {notification?.Task_name}
                            </p>
                            <p style={{ fontSize: 12, color: "silver" }}>
                              {/* 04:59 pm 14, Jan */}
                              {moment(notification?.Task_date)?.format(
                                "hh:mm a DD,MMM"
                              )}
                            </p>
                          </div>
                          <p className="mt-n2">{notification?.notification}</p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {localStorage.getItem("currentLoginUserRole") == 1 &&
                    getContributeLifeCycle?.length > 0 ? (
                      <>
                        {getAuditorTaskNotification?.filter((notification) =>
                          (
                            JSON.parse(window.localStorage.getItem("user"))
                              ?.id == notification?.entity_id
                          ).map((notify) => {
                            const { Task_date, Task_name, notification } =
                              notify;
                            return (
                              <div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>
                                    <i className="fas fa-file text-info mr-1"></i>
                                    {Task_name}
                                  </p>
                                  <p style={{ fontSize: 12, color: "silver" }}>
                                    {/* 04:59 pm 14, Jan */}
                                    {moment(Task_date)?.format(
                                      "hh:mm a DD,MMM"
                                    )}
                                  </p>
                                </div>
                                <p className="mt-n2">{notification}</p>
                              </div>
                            );
                          })
                        )}
                      </>
                    ) : (
                      <>
                        {localStorage.getItem("currentLoginUserRole") == 2 &&
                        getContributeLifeCycle?.length > 0 ? (
                          <>
                            {getAuditorTaskNotification?.filter(
                              (notification) =>
                                (
                                  JSON.parse(
                                    window.localStorage.getItem("user")
                                  )?.id == notification?.support_id
                                ).map((notify) => {
                                  const { Task_date, Task_name, notification } =
                                    notify;

                                  return (
                                    <div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <p>
                                          <i className="fas fa-file text-info mr-1"></i>
                                          {Task_name}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 12,
                                            color: "silver",
                                          }}
                                        >
                                          {/* 04:59 pm 14, Jan */}
                                          {moment(Task_date)?.format(
                                            "hh:mm a DD,MMM"
                                          )}
                                        </p>
                                      </div>
                                      <p className="mt-n2">{notification}</p>
                                    </div>
                                  );
                                })
                            )}
                          </>
                        ) : (
                          <>
                            <p>No Task Found</p>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div
                  className=""
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 5 }}
                >
                  Stats
                </div>
                <Row>
                  <Col style={{ borderRight: "1px solid silver" }} md="3">
                    <div
                      className="text-center "
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        // display: "-webkit-flex",
                        // lineClamp: "1" /* number of lines to show */,
                      }}
                    >
                      Sections
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: 25, fontWeight: "bold" }}
                    >
                      {summaryDetails?.no_of_seasons}
                    </div>
                  </Col>
                  <Col style={{ borderRight: "1px solid silver" }} md="3">
                    <div
                      className="text-center"
                      style={{
                        marginBottom: 10,
                        color: "gray",

                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* Answered */}
                      Requirements
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: 25, fontWeight: "bold" }}
                    >
                      {summaryDetails?.no_of_requirements}
                    </div>
                  </Col>
                  <Col style={{ borderRight: "1px solid silver" }} md="3">
                    <div
                      className="text-center "
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* Compliant */}
                      Answers
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: 25, fontWeight: "bold" }}
                    >
                      {summaryDetails?.no_of_answered}
                    </div>
                  </Col>
                  <Col style={{}} md="3">
                    <div
                      className="text-center "
                      style={{
                        marginBottom: 10,
                        color: "gray",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* Compliant */}
                      Reviews
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: 25, fontWeight: "bold" }}
                    >
                      {summaryDetails?.no_of_reviewed}
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    height: 30,
                    width: "100%",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#239b3755",
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        backgroundColor: "#239b37",
                        height: 5,
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className=""
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 5 }}
                >
                  Versions
                </div>
                <Row>
                  {singleAudit?.versions?.slice(-3).map((version) => (
                    <Col style={{ borderRight: "1px solid silver" }} md="4">
                      <div
                        className="text-center "
                        style={{ marginBottom: 10, color: "gray" }}
                      >
                        {/* 12/12/2012 */}
                        {moment(version?.created_at).format("MMM Do YY")}
                      </div>
                      <div
                        className="text-center"
                        style={{ fontSize: 16, fontWeight: "bold" }}
                      >
                        <Link to={"/user/versions"}>{version?.name}</Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* model for invite member support */}
        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassic();
                setMultipleSelect("");
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">Invite Members</h4>
          </div>
          <div className="modal-body">
            <Form onSubmit={handelInviteSuppportTeam}>
              <Row>
                <Col md="12">
                  <Row>
                    <Label md="3">Add Support Team</Label>
                    <Col md="9">
                      <FormGroup>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Select Members"
                          name="multipleSelect"
                          closeMenuOnSelect={false}
                          isMulti
                          value={multipleSelect}
                          onChange={(value) => setMultipleSelect(value)}
                          // options={[
                          //   {
                          //     value: "",
                          //     label: " Multiple Options",
                          //     isDisabled: true,
                          //   },
                          //   { value: "2", label: "Harry " },
                          //   { value: "3", label: "Ryan" },
                          //   { value: "4", label: "Emma" },
                          // ]}

                          options={allSupportTeam?.map((supportTeam) => ({
                            value: supportTeam?.id,
                            label: supportTeam?.name,
                          }))}
                        />
                      </FormGroup>
                      {slectInviterError && (
                        <div className="text-danger">{slectInviterError}</div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Label md="3">Invite and Assign</Label>
                    <Col md="6">
                      <Input
                        type="email"
                        multiple
                        placeholder="enter email to assign "
                        value={inviteTeam}
                        onChange={(e) => setInviteTeam(e.target.value)}
                      />
                    </Col>
                    <Col md="3">
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          const data = {
                            audit_id:
                              window.localStorage.getItem("audit_life_cycle"),
                            emails: inviteTeam.split(","),
                            role: 2,
                          };
                          console.log(data);
                          setInviteTeam("");
                          InviteMultiSupportToAudit(data, setAlert);
                        }}
                      >
                        {" "}
                        assign
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="text-right">
                <Button
                  className="btn-info btn-block"
                  data-dismiss="modal"
                  type="submit"
                  disabled={inviteTeam ? true : false}
                  onClick={() => {
                    multipleSelect
                      ? ""
                      : setSlectInviterError("please select member");
                    slectInviterError && toggleModalClassic();


                    
                  }}
                >
                  Add
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* model for invite member contribute */}
        <Modal
          isOpen={modalClassicContribute}
          size="lg"
          toggle={toggleModalClassicContribute}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassicContribute();
                setMultipleSelectContribute("");
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up"> Invite Contributor </h4>
          </div>
          <div className="modal-body">
            <Form onSubmit={handelInviteContributeTeam}>
              <Row>
                <Col md="12">
                  <Row>
                    <Label md="3">Add Members</Label>
                    <Col md="9">
                      <FormGroup>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Select Members"
                          name="multipleSelect"
                          closeMenuOnSelect={true}
                          // isMulti
                          value={multipleSelectContribute}
                          onChange={(value) =>
                            setMultipleSelectContribute(value)
                          }
                          // options={[
                          //   {
                          //     value: "",
                          //     label: " Multiple Options",
                          //     isDisabled: true,
                          //   },
                          //   { value: "2", label: "Harry " },
                          //   { value: "3", label: "Ryan" },
                          //   { value: "4", label: "Emma" },
                          // ]}

                          options={allContributeLead?.map((contributeTeam) => ({
                            value: contributeTeam?.id,
                            label: contributeTeam?.name,
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    {slectMemeberError && (
                      <div className="text-danger">{slectMemeberError}</div>
                    )}
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Label md="3">Invite and Assign</Label>
                    <Col md="6">
                      <Input
                        type="email"
                        placeholder="Enter Email to invite and assign"
                        value={inviteTeam}
                        onChange={(e) => setInviteTeam(e.target.value)}
                      />
                    </Col>
                    <Col md="3">
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          const data = {
                            audit_id:
                              window.localStorage.getItem("audit_life_cycle"),
                            email: inviteTeam,
                            role: 1,
                          };
                          console.log(data);
                          InviteContributeToAudit(data, setAlert);
                          setInviteTeam("")
                        }}
                      >
                        {" "}
                        assign
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="text-right">
                <Button
                  className="btn-info btn-block"
                  data-dismiss="modal"
                  type="submit"
                  disabled={inviteTeam ? true : false}
                  onClick={() => {
                    multipleSelectContribute 
                      ? ""
                      : setSlectMemeberError("please select member");
                    slectMemeberError && toggleModalClassicContribute();


                  }
             
                }
                >
          Add
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* model for create new life cycle and create version */}
        <Modal
          isOpen={modalClassicVersion}
          size="lg"
          toggle={toggleModalClassicVersion}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close button-primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassicVersion();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">Create Version</h4>
          </div>
          <div className="modal-body">
            <Form onSubmit={handelCreateVersion}>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Version Name</Label>

                    <Input
                      type="text"
                      required
                      placeholder="Enter Starting Date"
                      value={auditVersionName}
                      onChange={(e) => {
                        setAuditVersionName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Row>
                    {/* <Label md="3">Create Version</Label> */}

                    <Col md="6">
                      <FormGroup>
                        <Label>Starting Date</Label>

                        <Input
                          type="date"
                          required
                          placeholder="Enter Starting Date"
                          value={startVersionDate}
                          onChange={(e) => {
                            setStartVersionDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Ending Date</Label>

                        <Input
                          required
                          type="date"
                          placeholder="Enter Ending Date"
                          value={endVersionDate}
                          onChange={(e) => {
                            setEndVersionDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="text-right">
                <Button
                  className="btn-info btn-block"
                  data-dismiss="modal"
                  type="submit"
                  onClick={() => {
                    endVersionDate && startVersionDate
                      ? toggleModalClassicVersion()
                      : "";
                  }}
                >
                  Create
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* model to ask version name */}
        <Modal
          isOpen={modalClassicReportVersionName}
          size="lg"
          toggle={toggleModalClassicReportVersionName}
        >
          {/* <button onClick={() => { window.print() }}>download</button> */}
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassicReportVersionName();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>

            <h4 className="title title-up">Select Version</h4>
          </div>
          <div className="modal-body">
            {/* body here */}

            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Select Version for Report"
              name="selectVersion"
              closeMenuOnSelect={false}
              value={selectVersion}
              onChange={(value) => setSelectVersion(value)}
              // options={[
              //   {
              //     value: "",
              //     label: " Multiple Options",
              //     isDisabled: true,
              //   },
              //   { value: "2", label: "Harry " },
              //   { value: "3", label: "Ryan" },
              //   { value: "4", label: "Emma" },
              // ]}

              options={showVersionNameReport.data?.map((version) => ({
                value: version?.id,
                label: version?.name,
              }))}
            />

            <Button
              color="info"
              disabled={selectVersion ? false : true}
              onClick={() => {
                const data = {
                  audit_id: window.localStorage.getItem("audit_life_cycle"),
                  version_id: selectVersion?.value,
                };
                GetReport(data);

                // window.print();
                toggleModalClassicReport();
                toggleModalClassicReportVersionName();
              }}
            >
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i> preview
            </Button>
          </div>
        </Modal>

        {/* model to view html stirng file */}
        <Modal
          isOpen={modalClassicReport}
          size="lg"
          toggle={toggleModalClassicReport}
        >
          {/* <button onClick={() => { window.print() }}>download</button> */}
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModalClassicReport();
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>

            <h4 className="title title-up">PDF Preview</h4>
          </div>
          <div className="modal-body">
            {/* body here */}

            <div>
              <ReactToPrint
                trigger={() => <button>Save</button>}
                content={() => componentRef.current}
              />
              <ComponentToPrint
                ref={componentRef}
                getReportFile={getReportFile}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = ({ user, audit, lifeCycle }) => ({
  user,
  audit,
  lifeCycle,
});

export default connect(mapStateToProps, {
  GetAlLSupportTeam,
  SupportTeamInvite,
  GetSingleAuditAndPushToAuditSummary,
  GetAuditorLifeCycle,
  GetContributeLifeCycle,
  CreateVersionLifeCycle,
  GetCompliantVsNoCompliant,
  SummaryDetails,
  GetContributeTaskNotification,
  GetAuditorTaskNotification,
  GetAllMainReqList,
  GetAllContributeLead,
  AssignAuditToContribute,
  InviteContributeToAudit,
  InviteMultiSupportToAudit,
  GetReport,
  ShowVersionNameReport,
  GetSingleRequirementList,
    ChangeIsRequirementListEdit,
    ChangeRequirementEditing
})(AuditSummary);
