import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Modal,
} from "reactstrap";
import RequirementsListView from "./RequirementsList/RequirementsListView";

import {
  ChangeShowRequirementListDeailsModal,
  ChangeShowViewRequirementModal,
  ChangeViewingSubRequirements,
} from "../../store/actions/sharedAction";
import {
  ChangeShowViewSectionModal,
  GetAllRequirementList,
  GetRequirementsBySectionId,
  GetSingleRequirementListWithModal,
  GetSingleRequirementWithModal,
  GetSingleSectionWithModal,
  GetSingleSubRequirementWithModal,
  GetSubRequirementsByRequirementId,
} from "../../store/actions/requirmentActions";
import RequirementsListDetail from "./RequirementsList/RequirementsListDetail";
import ViewSection from "./RequirementsListDetail/ViewSection";
import ViewRequirement from "./RequirementMapping/ViewRequirement";

const SectionDashboard = (props) => {
  const {
    requirment,
    GetAllRequirementList,
    GetSingleRequirementListWithModal,
    GetSingleSectionWithModal,
    GetSingleRequirementWithModal,
    GetSingleSubRequirementWithModal,
    GetRequirementsBySectionId,
    GetSubRequirementsByRequirementId,
  } = props;
  const { allRequirementList } = requirment;
  const [requirements, setRequirements] = useState([]);

  let data = allRequirementList?.map((requirementList) => ({
    id: requirementList?.id,
    name: requirementList?.name,
    section: requirementList?.sections?.map((section) => ({
      id: section?.id,
      section_name: section?.standard_ref,
      requirement: section?.requirements?.map((requirement) => ({
        id: requirement?.id,
        requirement_name: requirement?.standard_ref,
        subRequirement: requirement?.sub_requirements?.map((subRequirement) => ({
          id: subRequirement?.id,
          subRequirementName: subRequirement?.standard_ref,
        })),
      })),
    })),
  }));

  useEffect(() => {
    GetAllRequirementList();
    setRequirements(data);
  }, []);

  useEffect(() => {
    setRequirements(data);
  }, [allRequirementList]);

  console.log(allRequirementList);
  const [verticalTabs, setVerticalTabs] = React?.useState("");
  const [requirementId, setRequirementId] = useState(1);
  const [modalClassic, toggleModalClassic] = useState(false);
  //   const [requirements, setRequirements] = useState([
  //     {
  //       id: 1,
  //       name: "Requirement 1",
  //       section: [
  //         {
  //           id: 1,
  //           section_name: "Req 1 Section",
  //           requirement: [
  //             {
  //               id: 1,
  //               requirement_name: "Req 1 Sec 1 Requirement Name",
  //               subRequirement: [
  //                 {
  //                   id: "1",
  //                   subRequirementName: "Req 1 Sec 1 Sub Requirement Name",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           id: 2,
  //           section_name: "Req 1 Section 2",
  //           requirement: [
  //             {
  //               id: 1,
  //               requirement_name: "Req 1 Sec 2 Requirement Name1",
  //               subRequirement: [
  //                 {
  //                   id: "1",
  //                   subRequirementName: "Req 1 Sec 2 Sub Requirement Name",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       id: 2,
  //       name: "Requirement 2",
  //       section: [
  //         {
  //           id: 1,
  //           section_name: "Req 2 Section",
  //           requirement: [
  //             {
  //               id: 1,
  //               requirement_name: "Req 2 Sec 1 Requirement 1",
  //               subRequirement: [
  //                 {
  //                   id: "1",
  //                   subRequirementName: "Req 2 Sec 1 Sub Requirement 1",
  //                 },
  //               ],
  //             },
  //             {
  //               id: 2,
  //               requirement_name: "Req 2 Sec 1 Requirement 2",
  //               subRequirement: [
  //                 {
  //                   id: "1",
  //                   subRequirementName: "Req 2 Sec 1 Sub Requirement 2",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           id: 2,
  //           section_name: "Req 2  Section2",
  //           requirement: [
  //             {
  //               id: 1,
  //               requirement_name: "Req 2 Sec 2 Requirement 1",
  //               subRequirement: [],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ]);

  const [singleRequirement, setSingleRequirement] = useState(null);
  const [singleSectionRequirement, setSingleSectionRequirement] =
    useState(null);
  const [singleSectionRequirementSubReq, setsingleSectionRequirementSubReq] =
    useState(null);

  const findRequirement = (id) => {
    const foundItems = requirements?.find((item) => {
      return item?.id === id;
    });
    setSingleRequirement(
      requirements?.find((item) => {
        return item?.id === id;
      })
    );
    setSingleSectionRequirement([]);
    setsingleSectionRequirementSubReq([]);
  };

  const findSectionRequirement = (id) => {
    setSingleSectionRequirement(
      singleRequirement.section?.find((item) => {
        return item?.id === id;
      })
    );
    setsingleSectionRequirementSubReq([]);
  };

  const findSectionSubRequirement = (id) => {
    setsingleSectionRequirementSubReq(
      singleSectionRequirement.requirement?.find((item) => {
        return item?.id === id;
      })
    );
  };

  const { showRequirementListDeailsModal, showViewRequirementModal } =
    props?.shared;
  const { showViewSectionModal } = props?.requirment;
  const {
    ChangeShowRequirementListDeailsModal,
    ChangeShowViewRequirementModal,
    ChangeShowViewSectionModal,
    ChangeViewingSubRequirements,
  } = props;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="3">
            <Card style={{ maxHeight: "600px", overflow: "auto" }}>
              <CardHeader className="text-center">
                <h6>Requirement List</h6>
              </CardHeader>
              <CardBody>
                <div className="nav-tabs-navigation verical-navs p-0">
                  <div className="nav-tabs-wrapper">
                    <Nav
                      className="flex-column nav-stacked border-0 border-right-0"
                      role="tablist"
                      tabs
                    >
                      {requirements.map((requirement) => (
                        <NavItem key={requirement?.id}>
                          <NavLink
                            data-toggle="tab"
                            onClick={() => {
                              if (window?.event?.ctrlKey) {
                                // ChangeShowRequirementListDeailsModal(true);
                                GetSingleRequirementListWithModal(
                                  requirement?.id
                                );
                                return;
                              }
                              findRequirement(requirement?.id);
                              console.log("Clicked");
                            }}
                            onDoubleClick={() => {
                              console.log("DOUBLE CLICK");
                              console.log(requirement);
                              //   ChangeShowRequirementListDeailsModal(true);
                              GetSingleRequirementListWithModal(requirement?.id);
                            }}
                          >
                            <p unselectable="on">{requirement?.name}</p>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="3">
            {/* Tab panes */}
            <Card className="" style={{ maxHeight: "600px", overflow: "auto" }}>
              <CardHeader className="text-center">
                <h6>Sections List</h6>
              </CardHeader>
              <CardBody>
                {singleRequirement?.section?.length > 0 ? (
                  singleRequirement?.section?.map((sec) => (
                    <p
                      unselectable="on"
                      style={{ cursor: "pointer" }}
                      key={sec?.id}
                      className="border text-center border-left-0 border-top-0 border-right-0 p-2"
                      onClick={() => {
                        if (window?.event?.ctrlKey) {
                          // ChangeShowViewSectionModal(true)
                          GetRequirementsBySectionId(sec?.id);
                          GetSingleSectionWithModal(sec?.id);
                          return;
                        }
                        findSectionRequirement(sec?.id);
                      }}
                      onDoubleClick={() => {
                        console.log("DOUBLE CLICK");
                        // console.log(sec.id);
                        GetRequirementsBySectionId(sec?.id);
                        GetSingleSectionWithModal(sec?.id);
                        // ChangeShowViewSectionModal(true);
                      }}
                    >
                      {sec?.section_name}
                    </p>
                  ))
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            {/* Tab panes */}
            <Card className="" style={{ maxHeight: "600px", overflow: "auto" }}>
              <CardHeader className="text-center">
                <h6>Requirements</h6>
              </CardHeader>
              <CardBody>
                {singleRequirement &&
                  singleSectionRequirement?.requirement?.length > 0 ? (
                  singleSectionRequirement?.requirement?.map((secreq) => (
                    <p
                      unselectable="on"
                      key={secreq?.id}
                      className="border border-left-0 text-center border-top-0 border-right-0 p-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (window?.event?.ctrlKey) {
                          // ChangeShowViewRequirementModal(true);
                          // ChangeViewingSubRequirements(false);
                          GetSubRequirementsByRequirementId(secreq?.id);
                          GetSingleRequirementWithModal(secreq?.id);
                          ChangeViewingSubRequirements(false);
                          return;
                        }
                        findSectionSubRequirement(secreq?.id);
                      }}
                      onDoubleClick={() => {
                        console.log("DOUBLE CLICK");
                        // ChangeShowViewRequirementModal(true);
                        GetSubRequirementsByRequirementId(secreq?.id);
                        GetSingleRequirementWithModal(secreq?.id);
                        ChangeViewingSubRequirements(false);
                      }}
                    >
                      {secreq?.requirement_name}
                    </p>
                    // <p >{secreq.requirement_name}</p>
                    // console.log(secreq)
                  ))
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md="3">
            {/* Tab panes */}
            <Card className="" style={{ maxHeight: "600px", overflow: "auto" }}>
              <CardHeader className="text-center">
                <h6>Sub Requirements </h6>
              </CardHeader>
              <CardBody>
                {singleRequirement &&
                  singleSectionRequirement &&
                  singleSectionRequirementSubReq?.subRequirement?.length > 0 ? (
                  singleSectionRequirementSubReq?.subRequirement?.length < 1 ? (
                    <p>No Data Found</p>
                  ) : (
                    singleSectionRequirementSubReq?.subRequirement?.map(
                      (secsubreq) => (
                        <p
                          unselectable="on"
                          className="border border-left-0 border-top-0 border-right-0 p-2 text-center"
                          key={secsubreq?.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (window?.event?.ctrlKey) {
                              // ChangeShowViewRequirementModal(true);
                              // ChangeViewingSubRequirements(true);
                              GetSingleSubRequirementWithModal(secsubreq?.id);
                              ChangeViewingSubRequirements(true);
                              return;
                            }
                          }}
                          onDoubleClick={() => {
                            console.log("DOUBLE CLICK");
                            // ChangeShowViewRequirementModal(true);
                            GetSingleSubRequirementWithModal(secsubreq?.id);
                            ChangeViewingSubRequirements(true);
                          }}
                        >
                          {secsubreq?.subRequirementName}
                        </p>
                      )
                    )
                  )
                ) : (
                  <p className="text-center"> No Data Found</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={showRequirementListDeailsModal}
        size="xl"
        toggle={() => {
          ChangeShowRequirementListDeailsModal(false);
        }}
      >
        <RequirementsListDetail />
      </Modal>
      <Modal
        isOpen={showViewSectionModal}
        size="xl"
        toggle={() => {
          ChangeShowViewSectionModal(false);
        }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              ChangeShowViewSectionModal(false);
            }}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Section Detail</h4>
        </div>
        <ViewSection />
      </Modal>
      <Modal
        isOpen={showViewRequirementModal}
        size="xl"
        toggle={() => {
          ChangeShowViewRequirementModal(false);
        }}
      >
        <ViewRequirement />
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowRequirementListDeailsModal,
  ChangeShowViewRequirementModal,
  ChangeShowViewSectionModal,
  ChangeViewingSubRequirements,
  GetAllRequirementList,
  GetSingleRequirementListWithModal,
  GetSingleSectionWithModal,
  GetSingleRequirementWithModal,
  GetSingleSubRequirementWithModal,
  GetRequirementsBySectionId,
  GetSubRequirementsByRequirementId,
})(SectionDashboard);
