import React from "react";
import { connect } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {
  Route,
  Switch,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import { ChangeSetSidebarMini } from "../store/actions/sharedAction";
import assessorRoutes from "assessorRoutes";
import contributeRoutes from "contributeRoutes";
import supportRoutes from "supportRoutes";
import { manageUserRole } from "store/actions/userAction";
import loginRoutes from "loginRoutes";

// import routes from "routes.js";

var ps;

function Admin(props) {
  const { ChangeSetSidebarMini, user } = props;
  const { userData } = user;
  // console.log("USERDATA" + JSON.stringify(userData));
  // console.log(user);
  const location = useLocation();
  const history = useHistory();
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const [sidebarMini, setSidebarMini] = React.useState(false);
  const mainPanel = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (window.localStorage.getItem("token") == null) {
        history.push("/auth/login");
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      ChangeSetSidebarMini(false);
    } else {
      setSidebarMini(true);
      ChangeSetSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={
          window.localStorage.getItem("currentLoginUserRole") == 0
            ? assessorRoutes
            : window.localStorage.getItem("currentLoginUserRole") == 1
              ? contributeRoutes
              : window.localStorage.getItem("currentLoginUserRole") == 2
                ? supportRoutes
                : loginRoutes
        }
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        {/* <Switch>{getRoutes(routes)}</Switch> */}
        <Switch>
          {getRoutes(
            window.localStorage.getItem("currentLoginUserRole") == 0
              ? assessorRoutes
              : window.localStorage.getItem("currentLoginUserRole") == 1
                ? contributeRoutes
                : window.localStorage.getItem("currentLoginUserRole") == 2
                  ? supportRoutes
                  : loginRoutes
          )}
        </Switch>
        {/* {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        } */}
      </div>
      {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
        handleMiniClick={handleMiniClick}
      /> */}
    </div>
  );
}

// const mapStateToProps = ({}) => ({});
const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, {
  ChangeSetSidebarMini,
  // manageUserRole,
})(Admin);
