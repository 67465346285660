import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { GlobalFilter } from "./GlobalFilter";
import PerfectScrollbar from "perfect-scrollbar";
import { GlobalFilterDropdown } from "./GlobalFilterDropdown";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory, useParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Collapse,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "./CheckBox";
import ReactFileReader from "react-file-reader";
import RequirementsListView from "./RequirementsList/RequirementsListView";
import RequirementsListDetail from "./RequirementsList/RequirementsListDetail";

import { ChangeShowRequirementListDeailsModal } from "../../store/actions/sharedAction";
import { GetAllRequirementList } from "store/actions/requirmentActions";
import { GetSingleRequirementList } from "store/actions/requirmentActions";
import { ChangeRequirementEditing } from "store/actions/requirmentActions";
import { ChangeIsRequirementListEdit } from "store/actions/requirmentActions";
import { ClearSingleRequirementListData } from "store/actions/requirmentActions";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const RequirementsList = (props) => {
  const {
    requirment,
    GetAllRequirementList,
    GetSingleRequirementList,
    ChangeRequirementEditing,
    ChangeIsRequirementListEdit,
    ClearSingleRequirementListData,
  } = props;
  const { allRequirementList, requirementEditing } = requirment;
  console.log(allRequirementList);
  const [data, SetData] = useState([]);

  const allRequirementListReverse = [...allRequirementList].reverse();


  const showTwoWords= (string)=>{


    if(string?.length > 0){

          const array  = string.split(" ");
          
          if(array.length >= 2){
              return  array[0]+" "+array[1]
          }else{
              return array
          }
  }
    else{
      return ""
    }
    

  }


  let dataMap = allRequirementListReverse.map((requirementList) => ({
    ...requirementList,
    id: requirementList.id,
    name: showTwoWords(requirementList.name),
    desc: showTwoWords(requirementList?.description),
       authority: showTwoWords(requirementList.authority),
    location: showTwoWords(requirementList.location),
    industry: showTwoWords(requirementList.industry),
    information: showTwoWords(requirementList.information),
    effective_date: showTwoWords(requirementList.effective_date),
    status:
      requirementList.status == 1
        ? "Active"
        : requirementList.status == 0
          ? "In Active"
          : "",
    // requirement_file: requirementList.file,
    requirement_file: requirementList.file
      ? ReactHtmlParser(
        `<a href=${process.env.REACT_APP_BASE_URL}/public/${requirementList.file} download target="_blank"><i class="fas fa-file text-info"></i></a>`
      )
      : "",
  }));
  let dataMemo = React.useMemo(() => dataMap, [allRequirementList]);

  // useEffect(() => {
  //   GetAllRequirementList();
  //   SetData(allRequirementList);
  // }, []);

  // useEffect(() => {
  //   SetData(allRequirementList);
  // }, [allRequirementList]);

  useEffect(() => {
    GetAllRequirementList();
    SetData(dataMemo);
  }, []);

  useEffect(() => {
    SetData(dataMemo);
  }, [dataMemo]);

  const history = useHistory();
  // const { reqId } = useParams();


  // useEffect(() => {
  //   if (reqId) {
  //     // armanali
  //     window.alert("reqId");
  //     // GetSingleRequirementList(reqId);
  //     toggleModalClassic1();
  //   }

  // }, [reqId])


  // ---------------- SETTING UP DATATABLE START ---------------------------

  //   var dataTable = React.useMemo(
  //   () => [
  //     {
  //       name: "ISO:8888",
  //       desc: "World",
  //       authority: "lorem",
  //       location: "lorem",
  //       industry: "lorem",
  //       information: "lorem",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "lorem",
  //     },
  //     {
  //       name: "ISO:9000",
  //       desc: "World",
  //       authority: "lorem",
  //       location: "lorem",
  //       industry: "lorem",
  //       information: "lorem",
  //       effective_date: "12/03/2020",
  //       status: "InActive",
  //       requirement_file: "lorem",
  //     },
  //   ],
  // () => [...allRequirementList],
  // []
  //   );
  //   const [data, SetData] = useState(dataTable);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Desc",
        accessor: "desc",
      },
      {
        Header: "Authority",
        accessor: "authority",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Industry",
        accessor: "industry",
      },
      {
        Header: "Information",
        accessor: "information",
      },
      {
        Header: "Date",
        accessor: "effective_date",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Attachement",
        accessor: "requirement_file",
      },
   
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // --------------------- Filter Table ------------------------------

  const filterDataTable = (e) => {
    console.log("E.TARGET.VALUE FILTER STATUS");
    console.log(e.target.value);
    console.log(data);
    var elements;
    if (e.target.value === "") {
      elements = dataMemo;
    } else {
      elements = dataMemo.filter((el) => {
        return el.status === e.target.value;
      });
    }
    console.log("ELEMENTS");
    console.log(elements);
    SetData(elements);
  };

  // ------------------ Filter Table End -----------------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  var ps;
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };

  const colMissmatchAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Columns mismatch!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        The header(1st row) in csv file should have same columns as in table
        header!
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
  //  console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = React.useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const setRowForEdit = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  //  ------------------------------------------------ ACCORDION FOR MODAL START ----------------------------------------------

  const [openedCollapses, setOpenedCollapses] = React.useState([
    "collapseOne",
    "collapse1",
    "sectionReq1",
    "subRequirement1",
  ]);

  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };

  const dynamicItems = rowDetails?.original?.sections?.map((item, index) => [
    `collapse${index + 1}`,
    `sectionReq${index + 1}`,
    `subRequirement${index + 1}`,
  ]);

  //console.log(dynamicItems);

  React.useLayoutEffect(() => {
    // window.alert(rowDetails?.original?.sections?.length);
    rowDetails &&
      rowDetails?.original?.sections?.length > 0 &&
      setOpenedCollapses(dynamicItems.flat());
  }, [rowDetails]);

  //console.log(openedCollapses);

  // --------------------------------------------------ACCRODTION FOR MODAL END --------------------------------------------------

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var removefBlankRows =  reader.result.split(`\r\n`);
      var rows = removefBlankRows.filter(line => line.trim() !== "")
   //   console.log("hello ROWS");
      console.log(rows);
      for (var i = 0; i < rows.length; i++) {
        var columns = rows[i].split(",");
        console.log("COLUMNS");
      //  console.log(columns);
        if (i == 0) {
          if (
            columns[0] != "Name" &&
            columns[1] != "Desc" &&
            columns[2] != "Authority" &&
            columns[3] != "Location" &&
            columns[4] != "Industry" &&
            columns[5] != "Information" &&
            columns[6] != "Date" &&
            columns[7] != "Status" &&
            columns[8] != "Attachement"
          ) {
            colMissmatchAlert();
            break;
          }
        } else {
          SetData((prev) => {
            return [
              ...prev,
              {
                name: columns[0],
                desc: columns[1],
                authority: columns[2],
                location: columns[3],
                industry: columns[4],
                information: columns[5],
                effective_date: columns[6],
                status: columns[7],
                requirement_file: columns[8],
              },
            ];
          });
        }
      }
    };
    reader.readAsText(files[0]);
  };

  const { ChangeShowRequirementListDeailsModal } = props;
  const { showRequirementListDeailsModal } = props.shared;
  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              <div className="bg-info rounded d-flex flex-wrap align-items-center justify-content-between  ">
                <div className="text-left">
              <Link
                    className="btn btn-info btn-sm"
                    to="/user/requirements/create-requirement"
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      ChangeIsRequirementListEdit(false);
                      ClearSingleRequirementListData(null);
                      ChangeRequirementEditing({
                        isEdit: false,
                        requirementListId: null,
                      });
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    {"Create New Requirement List"}
                  </Link>
                </div>
                <div className="text-right table-top-right-buttons">
                  {selectedFlatRows.length > 0
                    ? // <Button
                    //   color="danger"
                    //   size="sm"
                    //   onClick={() => successDelete()}
                    // >
                    //   Delete
                    // </Button>
                    null
                    : null}
                  <ReactFileReader
                    fileTypes={[".csv"]}
                    handleFiles={handleFiles}
                  >
                    <button className="btn btn-info btn-sm">Import CSV</button>
                  </ReactFileReader>
                  <button
                    onClick={() => {
                      exportData("csv", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export CSV
                  </button>
                  <button
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      exportData("pdf", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export PDF
                  </button>{" "}
                </div>
              </div>

              <div className="row p-3 align-items-center">
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-between">
                    Show
                    <select
                      value={pageSize}
                      className="form-control pagination-select"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    Entries
                  </div>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-3 text-right">
                  <FormGroup className="custom-form-group">
                    <select
                      name=""
                      id=""
                      className="form-control custom-input"
                      onChange={(e) => filterDataTable(e)}
                    >
                      <option value="" defaultValue disabled>
                        Filters
                      </option>
                      <option value="">All</option>
                      <option value="Active">Active</option>
                      <option value="In Active">In Active</option>
                    </select>
                  </FormGroup>
                </div>
                <div className="col-md-3 text-right">
                  <div className="form-group custom-form-group">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div>
              {/* <button onClick={resetResizing}>Reset Resizing</button> */}
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                        <th
                          className="border border-left-0 border-right "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >Actions </th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <>
                        
                                <td
                                  style={{
                                    maxWidth: "150px",
                                    whiteSpace:"noWrap",
                                    overflow:"hidden",
                                    textOverflow:"ellipsis"
                                  }}
                                  {...cell.getCellProps()}
                                 

                                // style={{
                                //     padding: '10px',
                                //     // border: 'solid 1px gray',
                                //     // background: 'papayawhip',
                                // }}
                                >
                             {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}

                          <td style={{ width: "80px" }}>
                            {/* {console.log(row)} */}
                            <div className="d-flex flex-wrap justify-content-around">
                              <i
                                className="fas fa-eye text-info"
                                onClick={() => setRow(row)}
                                style={{ cursor: "pointer" }}
                              ></i>
                           <i
                                className="fas fa-edit text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  // history.push(
                                  //   "/user/requirements/create-requirement"
                                  // );
                                  GetSingleRequirementList(
                                    row.original.id,
                                    history
                                  );
                                  ChangeIsRequirementListEdit(true);
                                  ChangeRequirementEditing({
                                    isEdit: true,
                                    requirementListId: row.original.id,
                                  });
                                }}
                              ></i>
                              {/* <i
                                className="fas fa-times text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => successDelete()}
                              ></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>

                <div className="">
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  <span className="pagination-button">
                    {pageOptions.map((page) => (
                      <button
                        className={
                          pageIndex === page
                            ? "btn border-info border btn-info  bg-transparent text-info "
                            : "btn border-info border btn-info btn-sm bg-transparent text-info"
                        }
                        onClick={() => {
                          const pageNumber = page;
                          gotoPage(pageNumber);
                        }}
                      >
                        {page + 1}
                      </button>
                    ))}
                  </span>
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>

              {/* {console.log(
                JSON.stringify(
                  {
                    selectedFlatRows: selectedFlatRows.map(
                      (row) => row.original
                    ),
                  },
                  null,
                  2
                )
              )} */}

              {/* <pre>
                                <code>
                                    {
                                        JSON.stringify(
                                            {
                                                selectedFlatRows: selectedFlatRows.map((row) => row.original),
                                            }, null, 2
                                        )
                                    }
                                </code>
                            </pre> */}
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          <>
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalClassic}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h4 className="title title-up">{rowDetails?.original?.name}</h4>
            </div>
            <div className="modal-body">
              <Form onSubmit={(e) => editSubmit(e)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Name</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.name}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.desc}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Authority</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.authority}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Location</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.location}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Industry</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.industry}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Information</Label>
                      <Input
                        type="text"
                        defaultValue={rowDetails?.original?.information}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Effective Date</Label>
                      <Input
                        type="date"
                        defaultValue={rowDetails?.original?.effective_date}
                        onChange={(e) => console.log(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Status</Label>
                      <select
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => console.log(e.target.value)}
                        defaultValue={rowDetails?.original?.status}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Requirement File</Label>
                      <Input
                        type="file"
                        onChange={(e) => console.log(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button type="submit" color="info">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </>
        </Modal>
        <Modal isOpen={modalClassic1} size="xl" toggle={toggleModalClassic1}>
          <>
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalClassic1}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="text-left">{rowDetails?.original?.name}</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-striped table-hover">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Name</td>
                    <td>{rowDetails?.original?.name}</td>
                    <td className="font-weight-bold">Description</td>
                    <td>{rowDetails?.original?.desc}</td>
                    <td className="font-weight-bold">Authority</td>
                    <td>{rowDetails?.original?.authority}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Location</td>
                    <td>{rowDetails?.original?.location}</td>
                    <td className="font-weight-bold">Industry</td>
                    <td>{rowDetails?.original?.industry}</td>
                    <td className="font-weight-bold">Information</td>
                    <td>{rowDetails?.original?.information}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Effective Date</td>
                    <td>{rowDetails?.original?.effective_date}</td>
                    <td className="font-weight-bold">Status</td>
                    <td>{rowDetails?.original?.status}</td>
                    <td className="font-weight-bold">Requirement File</td>
                    <td>{rowDetails?.original?.requirement_file}</td>
                  </tr>

                  <tr>
                    {rowDetails?.original?.extra_fields?.map((field) => (
                      <>
                        <td className="font-weight-bold">
                          {field?.field_name}
                        </td>
                        <td>{field?.field_value}</td>
                      </>
                    ))}
                  </tr>
                </tbody>
              </table>

              <div
                style={{ height: "400px", overflowY: "scroll" }}
                id="scroll-bar"
              >
                <div
                  aria-multiselectable={true}
                  className="card-collapse"
                  id="accordion"
                  role="tablist"
                >
                  {rowDetails?.original?.sections?.length > 0 ? (
                    rowDetails?.original?.sections?.map((section, index) => (
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <a
                            aria-expanded={openedCollapses.includes(
                              `collapse${index}`
                            )}
                            className="text-danger font-weight-bold"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={() => collapsesToggle(`collapse${index}`)}
                          >
                            A Section : {section.standard_ref} :{" "}
                            {section.description}
                            <i className="nc-icon nc-minimal-down" />
                          </a>
                        </CardHeader>
                        {section?.requirements?.length > 0 ? (
                          section?.requirements?.map((requirement) => (
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapses.includes(
                                `collapse${index}`
                              )}
                            >
                              <Card className="card-plain ml-3">
                                <CardHeader role="tab">
                                  <a
                                    aria-expanded={openedCollapses.includes(
                                      `sectionReq${index}`
                                    )}
                                    className="text-info font-weight-bold"
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={() =>
                                      collapsesToggle(`sectionReq${index}`)
                                    }
                                  >
                                    Section Requirement :{" "}
                                    {requirement.standard_ref} :{" "}
                                    {requirement.description}
                                    <i className="nc-icon nc-minimal-down text-info" />
                                  </a>
                                </CardHeader>
                                {requirement.sub_requirements?.length > 0 ? (
                                  requirement?.sub_requirements?.map(
                                    (subReq) => (
                                      <Collapse
                                        role="tabpanel"
                                        isOpen={openedCollapses.includes(
                                          `sectionReq${index}`
                                        )}
                                      >
                                        <Card className="card-plain ml-5">
                                          <CardHeader role="tab">
                                            <a
                                              aria-expanded={openedCollapses.includes(
                                                `subRequirement${index}`
                                              )}
                                              data-parent="#accordion"
                                              data-toggle="collapse"
                                              className="text-success font-weight-bold"
                                              onClick={() =>
                                                collapsesToggle(
                                                  `subRequirement${index}`
                                                )
                                              }
                                            >
                                              Sub Requirement :{" "}
                                              {subReq?.standard_ref} :{" "}
                                              {subReq?.description}
                                              <i className="nc-icon nc-minimal-down text-success" />
                                            </a>
                                          </CardHeader>
                                          <Collapse
                                            role="tabpanel"
                                            isOpen={openedCollapses.includes(
                                              `subRequirement${index}`
                                            )}
                                          >
                                            <CardBody>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Standard Ref.</th>
                                                    <th>Description</th>
                                                    <th>References</th>
                                                    <th>Weight</th>
                                                    <th>Criticality</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      {subReq?.standard_ref}
                                                    </td>
                                                    <td>
                                                      {subReq?.description}
                                                    </td>
                                                    <td>
                                                      {subReq?.references}
                                                    </td>
                                                    <td>{subReq?.weight}</td>
                                                    <td>
                                                      {subReq?.criticality}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </CardBody>
                                          </Collapse>
                                        </Card>
                                      </Collapse>
                                    )
                                  )
                                ) : (
                                  <p>No Sub requirement found</p>
                                )}

                               
                              </Card>
                            </Collapse>
                          ))
                        ) : (
                          <p>No requirements found</p>
                        )}
                      </Card>
                    ))
                  ) : (
                    <p>No Section Found</p>
                  )}

                
                </div>
              </div>
              {/* <div className="text-right">
                <Button
                  color="info"
                  onClick={() => {
                    ChangeShowRequirementListDeailsModal(true);
                  }}
                >
                  Details
                </Button>
              </div> */}
            </div>
          </>
        </Modal>
        <Modal
          isOpen={showRequirementListDeailsModal}
          size="xl"
          toggle={() => {
            ChangeShowRequirementListDeailsModal(false);
          }}
        >
          <RequirementsListDetail />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowRequirementListDeailsModal,
  GetAllRequirementList,
  GetSingleRequirementList,
  ChangeRequirementEditing,
  ChangeIsRequirementListEdit,
  ClearSingleRequirementListData,
})(RequirementsList);
