
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Collapse } from 'react-collapse';
import { Link, useHistory } from 'react-router-dom'
import {
    FcAcceptDatabase,
    FcApproval,
    FcBarChart,
    FcCheckmark,
    FcDataRecovery,
    FcHighPriority,
    FcRadarPlot,
} from 'react-icons/fc'

import { styles } from '../../../styling/custom'

import img from '../../../assets/img/faces/default-avatar.png';

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Input, Form,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container, Modal, ModalBody, ModalHeader, ModalFooter, DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import {
    ToggleCollapsedSections,
} from '../../../store/actions/auditActions'
import moment from "moment";
import CustomBreadCrumbs from "../Shared/CustomBreadCrumbs";
import VersionRequirementView from "./VersionRequirementView";

const VersionRequirements = (props) => {
    const {
        versionRequirementSections
    } = props.audit
    const [auditAnswer, setAuditAnswer] = useState('No')
    const [lifeCycleTabs, ChangeLifeCycleTabs] = useState("lifecycle")
    const [requirementTabs, ChangeRequirementTabs] = useState("details")
    const [attachmentTabs, ChangeAttachmentTabs] = useState("table")
    const [showCreateTaskModal, ChangeShowCreateTaskModal] = useState(false)
    const [assessorComment, ChangeAssessorComment] = useState("The tilt of photons is not detected by the sensors sometimes in quantum chip")
    const [activeRequirement, ChangeActiveRequirement] = useState(versionRequirementSections[0].requirements[0].id)
    const {
    } = props
    const {
        discussion,
    } = props.audit
    function RenderDiscussion() {
        return discussion.map((item, key) => {
            const { image, name, role, date, comment } = item
            return (
                <div key={key}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            border: '0px solid red',
                            // alignItems: 'center'
                        }}
                    >
                        <img src={image} className="rounded-circle" width="40" height="40" alt="" />
                        <div
                            style={{
                                border: '0px solid red',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    border: '0px solid red',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        border: '0px solid red',
                                    }}
                                >
                                    <div
                                        className="single-line-text"
                                        style={{
                                            marginLeft: 10,
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            color: '#595974',
                                        }}
                                    >
                                        {name}
                                    </div>
                                    <div
                                        style={{
                                            background: '#F4F5F7',
                                            marginLeft: 10,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            borderRadius: 5,
                                        }}
                                    >
                                        {role}
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        {moment(date).format("MMM Do, YYYY")}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        border: '0px solid red',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                    }}
                                >
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    border: '0px solid red',
                                    padding: 10,
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    {comment}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            )
        })
    }
    function RenderRequirements(requirements, parentKey) {
        return requirements.map((item, key) => {
            const { name, id, isCompliant } = item
            return (
                <div
                    key={id}
                    className={`version-req-left-tab ${activeRequirement == id ? "active" : ""}`}
                    onClick={() => ChangeActiveRequirement(id)}
                >
                    <div className="left-flex">
                        {name}
                    </div>
                    <div className="right-flex">
                        {isCompliant ?
                            <>
                                <div
                                    id={"tooltip264453216-top-min" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#dd4132aa',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-sync " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-top-min" + key + parentKey}
                                >
                                    Maj in Ite:1
                                </UncontrolledTooltip>
                                <div
                                    id={"tooltip264453216-top-maj" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#FFC261',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-redo-alt " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-top-maj" + key + parentKey}
                                >
                                    Min in Ite:3
                                </UncontrolledTooltip>
                            </>
                            :
                            <>
                                <div
                                    id={"tooltip264453216-bottom-maj" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#FFC261',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-redo-alt " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-bottom-maj" + key + parentKey}
                                >
                                    Min in Ite:1
                                </UncontrolledTooltip>
                                <div
                                    id={"tooltip264453216-bottom-min" + key + parentKey}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 20,
                                        backgroundColor: '#dd4132aa',
                                        marginLeft: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <i class="fas fa-sync " style={{ color: 'white', fontSize: 10 }}></i>
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-bottom-min" + key + parentKey}
                                >
                                    Maj in Ite:3
                                </UncontrolledTooltip>
                            </>
                        }
                        {isCompliant ?
                            <>
                                <i id={"tooltip264453216-acc" + key + parentKey} class="fas fa-check-circle compliant-req-icon" style={{ marginLeft: 5 }}></i>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-acc" + key + parentKey}
                                >
                                    Acc in Ite:4
                                </UncontrolledTooltip>
                            </>
                            :
                            <>
                                <i id={"tooltip264453216-rej" + key + parentKey} class="fas fa-times-circle non-compliant-req-icon" style={{ marginLeft: 5 }}></i>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip264453216-rej" + key + parentKey}
                                >
                                    Acc in Ite:4
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                </div>
            )
        })
    }
    function RenderVersionRequirementSections() {
        return versionRequirementSections.map((item, key) => {
            const { name, requirements, id } = item
            return (
                <div key={key}>
                    <div
                        className="version-left-tab-section"
                        onClick={() => props.ToggleCollapsedSections(id)}
                    >
                        <div className="left-flex">
                            <p style={{ marginBottom: 0, marginLeft: 10, fontSize: 16 }} className="font-weight-bold">{name}</p>
                        </div>
                        <div className="right-flex">
                            {!props.audit.collapsedSections.includes(id) ?
                                <i class="fas fa-caret-up"></i>
                                :
                                <i class="fas fa-caret-down"></i>
                            }
                        </div>
                    </div>
                    <hr style={styles.smartHr} />
                    <Collapse isOpened={!props.audit.collapsedSections.includes(id)}>
                        {RenderRequirements(requirements, key)}
                    </Collapse>
                </div>
            )
        })
    }
    return (
        <div className="content">
            <Row>
                <Col md="3">
                    <Card className="card-pricing all-budget-cards clickable-card" style={{ cursor: 'default' }}>
                        <CardBody style={{ marginBottom: 0 }}>
                            {RenderVersionRequirementSections()}
                        </CardBody>
                    </Card>
                </Col>
                <Col md="9">
                    <Card className="card-pricing all-budget-cards clickable-card" style={{ cursor: 'default' }}>
                        <CardBody style={{ marginBottom: 0 }}>
                            <div>
                                <h4 style={{ marginTop: 0, marginBottom: 0 }} className="text-left"> Requirement Title</h4>
                            </div>
                            <hr />
                            <VersionRequirementView />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}

const mapStateToProps = ({ shared, styling, audit }) => ({
    shared, styling, audit
})

export default connect(mapStateToProps, {
    ToggleCollapsedSections,
})(VersionRequirements)