import {
  CHANGE_CREATE_TASK,
  GET_ALL_TASK,
  CHANGE_AUDITOR_LIFE_CYCLE,
  CHANGE_GLOBAL_LOADER,
  CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT,
  CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE,
  CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE,
  ASSIGN_SUPPORT_TEAM_RESPONSE,
  MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE,
  CHANGE_SAVE_ANSWER,
  CHANGE_SHOW_AUDIT_ANSWER,
  MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW,
  GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT,
  CHANGE_SHOW_AUDIT_ANSWER_TASK,
  CLEAR_AUDIT_ANSWER_TASK_DATA,
  CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA,
  CREATE_VERSION_LIFE_CYCLE,
  CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION,
  CLEAR_ALL_TASK_DATA,
  GET_VERSION_FILES,
  GET_REPORT_FILES,
  GET_RADAR_CHART,
  SHOW_VERSION_NAME_REPORT,
} from "./types";
import { baseUrl } from "config/baseUrl";
import { messageHandler } from "utils/popupMessage";
import { GetSubRequirementById } from "./requirmentActions";
import { stat } from "fs";

export const ClearAllTaskData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_ALL_TASK_DATA });
  };
};

export const ClearAuditAnswerTaskAnswerIteration = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_AUDIT_ANSWER_TASK_ANSWER_INTERATION });
  };
};

export const ClearAuditAnswerTaskData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_AUDIT_ANSWER_TASK_DATA });
  };
};

export const ClearAuditRequirementAnswerData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_AUDIT_REQUIREMENT_ANSWER_DATA });
  };
};

// Get Auditor Life Cycle
export const GetAuditorLifeCycle = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let lifeCycle = await fetch(
        `${baseUrl}/api/auditor/show-audit-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (lifeCycle.status == 200) {
        dispatch({
          type: CHANGE_AUDITOR_LIFE_CYCLE,
          payload: lifeCycle.data.data[0],
        });
        console.log(lifeCycle);
      } else {
        console.log("ERROR");
        console.log(lifeCycle);
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Move Requirements of Auditor Life Cycle from In Requirements bucket to In Response bucket
export const MoveAuditorLifeCycleRequirementsToResponse = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.auditId);
      formdata.append(
        "audit_requirement_ids",
        JSON.stringify(val.auditRequirementIds)
      );
      formdata.append("version_id", val.version_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let lifeCycle = await fetch(
        `${baseUrl}/api/auditor/push-to-respond-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (lifeCycle.status == 200) {
        dispatch({
          type: CHANGE_MOVE_AUDITOR_LIFE_CYCLE_REQUIREMENTS_TO_RESPONSE,
          payload: lifeCycle.data,
        });
        dispatch(GetAuditorLifeCycle(val.auditId));
        console.log(lifeCycle);
      } else {
        console.log("ERROR");
        console.log(lifeCycle);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// Delete Auditor Life Cycle Requirement
export const DeleteAuditorLifeCycleRequirement = (val, auditId) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", auditId);
      formdata.append("audit_requirement_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let lifeCycle = await fetch(
        `${baseUrl}/api/auditor/delete-requirement-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (lifeCycle.status == 200) {
        dispatch({
          type: CHANGE_DELETE_AUDITOR_LIFE_CYCLE_REQUIREMENT,
          payload: lifeCycle.data,
        });
        dispatch(GetAuditorLifeCycle(auditId));
        console.log(lifeCycle);
      } else {
        console.log("ERROR");
        console.log(lifeCycle);
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

//create task for minor and major option

export const CreateTask = (val, setAlert, setGetError) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("answer_id", val.answer_id);
      formdata.append("name", val.name);
      formdata.append("standard_ref", val.standard_ref);
      formdata.append("description", val.description);
      formdata.append("status", val.status);
      formdata.append("decision", val.decision);
      formdata.append("audit_requirement_id", val.audit_requirement_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let task = await fetch(
        `${baseUrl}/api/auditor/create-task`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (task.status == 200) {
        dispatch({
          type: CHANGE_CREATE_TASK,
          payload: task.data,
        });
        dispatch(GetAuditorLifeCycle(localStorage.getItem("audit_life_cycle")));
        messageHandler(
          setAlert,
          "You have created Task successfully.",
          "Task Created",
          "success"
        );
        console.log(task);
      } else {
        console.log("ERROR");
        setGetError(task?.data?.message);
        // messageHandler(
        //   setAlert,
        //   "Unable to create task.",
        //   "Task Failed",
        //   "error"
        // );
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// GET ALL USER ROLES

export const GetAllTask = (val) => {
  console.log("val1: " + JSON.stringify(val));
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formData = new FormData();
      formData.append("audit_requirement_id", val.audit_requirement_id);

      console.log("reqid " + val.audit_requirement_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let userTask = await fetch(
        `${baseUrl}/api/auditor/show-task`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (userTask.status == 200) {
        dispatch({
          type: GET_ALL_TASK,
          payload: userTask.data.data,
        });
        console.log(userTask);
      } else {
        console.log("ERROR");
        console.log(userTask);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// contributor lead api
//get contribute life cycle
export const GetContributeLifeCycle = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let contributeLeadLifeCycle = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/contribute_lead/show-audit-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (contributeLeadLifeCycle.status == 200) {
        dispatch({
          type: CHANGE_CONTRIBUTE_LEAD_LIFE_CYCLE,
          payload: contributeLeadLifeCycle.data.data[0],
        });
        console.log(contributeLeadLifeCycle);
      } else {
        console.log("ERROR");
        console.log(contributeLeadLifeCycle);
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

//assign support team for audit life cycle
export const assignSupportTeam = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append(
        "audit_requirement_ids",
        JSON.stringify(val.audit_requirement_ids)
      );

      //replace with above
      // formdata.append("audit_requirement_id", val.audit_requirement_id);
      formdata.append("support_id", val.support_id);
      formdata.append("entity_id", val.entity_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let assignSupportTeam = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/contribute_lead/assign-to-all`,
        // `${process.env.REACT_APP_BASE_URL}/api/contribute_lead/assign-to-response`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (assignSupportTeam.status == 200) {
        dispatch({
          type: ASSIGN_SUPPORT_TEAM_RESPONSE,
          payload: assignSupportTeam.data.data,
        });

        // messageHandler(
        //   setAlert,
        //   "You assigned a member for this audit",
        //   "Assigned Member Successfuly",
        //   "success"
        // );
        dispatch(GetContributeLifeCycle(val.audit_id));
        console.log(assignSupportTeam);
      } else {
        console.log("ERROR");
        console.log(assignSupportTeam);
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Move Requirements of contribute Life Cycle from In Requirements bucket to In Response bucket
export const MoveContributeLeadReqToRes = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.auditId);
      formdata.append(
        "audit_requirement_ids",
        JSON.stringify(val.auditRequirementIds)
      );
      formdata.append("version_id", val.version_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let lifeCycleContribute = await fetch(
        `${baseUrl}/api/contribute_lead/push-to-respond-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (lifeCycleContribute.status == 200) {
        dispatch({
          type: MOVE_CONTRIBUTE_REQUIREMENT_TO_RESPONSE,
          payload: lifeCycleContribute.data,
        });
        dispatch(GetContributeLifeCycle(val.auditId));
        console.log(lifeCycleContribute);
      } else {
        console.log("ERROR");
        console.log(lifeCycleContribute);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//Save Answer contributor lead  api

export const SaveAnswerAudit = (val, setAlert) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("type", val.type);
      formdata.append("version_id", val.version_id);
      formdata.append("audit_id", val.audit_id);
      formdata.append("requirement_id", val.requirement_id);
      formdata.append("answer_status", val.answer_status);
      formdata.append("partial_percentage", val.partial_percentage);
      formdata.append("partial_description", val.partial_description);
      formdata.append("evidence_details", val.evidence_details);
      formdata.append("contribute_comments", val.contribute_comments);
      formdata.append("audit_requirement_id", val.audit_requirement_id);
      formdata.append("sub_requirement_id", val.sub_requirement_id);
      formdata.append("sub_status", val.sub_status);
      formdata.append("status", val.status);
      formdata.append("evidence_attachment", val.evidence_attachment);
      val.task_id ? formdata.append("task_id", val.task_id) : "";

      console.log("formdata", formdata);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let saveAnswerAudit = await fetch(
        `${baseUrl}/api/contribute_lead/save-answer`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (saveAnswerAudit.status == 200) {
        dispatch({
          type: CHANGE_SAVE_ANSWER,
          payload: saveAnswerAudit.data,
        });

        dispatch(GetContributeLifeCycle(val.audit_id));

        messageHandler(setAlert, "", "Answer is Submitted", "success");
        console.log(saveAnswerAudit);

        // if(saveAnswerAudit.data.data.)
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// get answer detail of specific requirement
export const ShowAuditRequirementAnswer = (val) => {
  console.log("🚀 ~ file: lifeCycleAction.js:646 ~ ShowAuditRequirementAnswer ~ val:", val)
  return async (dispatch) => {
    try {
      var headers = new Headers();
      headers.append("Accept", "Application/json");
      headers.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append(
        "audit_requirement_id",
        JSON.stringify(val.audit_requirement_id)
      );
      formdata.append("requirement_id", JSON.stringify(val.requirement_id));
      formdata.append(
        "sub_requirement_id",
        JSON.stringify(val.sub_requirement_id)
      );

      var requestOptions = {
        method: "POST",
        headers,
        body: formdata,
        redirect: "follow",
      };
      for (var key of formdata.entries()) {
        console.log("999" + key[0] + ", " + key[1]);
      }
      console.log("8899" + JSON.stringify(requestOptions));
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let showAnswerAudit = await fetch(
        `${baseUrl}/api/contribute_lead/show-answer`,

        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });

      if (showAnswerAudit.status == 200) {
        dispatch({
          type: CHANGE_SHOW_AUDIT_ANSWER,
          payload: showAnswerAudit.data.data,
        });

        if (val?.sub_requirement_id) {
          dispatch(GetSubRequirementById(val?.sub_requirement_id));
        }
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// get answer detail of specific requirement task
export const ShowAuditRequirementAnswerTask = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("task_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let taskAnswer = await fetch(
        `${baseUrl}/api/contribute_lead/show-answer-task`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (taskAnswer.status == 200) {
        dispatch({
          type: CHANGE_SHOW_AUDIT_ANSWER_TASK,
          payload: taskAnswer.data.data,
        });
        dispatch(
          GetSubRequirementById(taskAnswer?.data?.data?.sub_requirement_id)
        );
        console.log(taskAnswer.data.data);
      } else {
        console.log("ERROR");
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// move Requirement of contribute life cycle form in response buket to in Review bucket

export const MoveContributeLeadRequirementToReview = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.auditId);
      formdata.append(
        "audit_requirement_ids",
        JSON.stringify(val.auditRequirementIds)
      );
      formdata.append("version_id", val.version_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let lifeCycleContribute = await fetch(
        `${baseUrl}/api/contribute_lead/push-to-review-life-cycle`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (lifeCycleContribute.status == 200) {
        dispatch({
          type: MOVE_CONTRIBUTE_REQUIREMENT_TO_REVIEW,
          payload: lifeCycleContribute.data,
        });
        dispatch(GetContributeLifeCycle(val.auditId));
        console.log(lifeCycleContribute);
      } else {
        console.log("ERROR");
        console.log(lifeCycleContribute);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// Give answer against requirement or sub requirement
export const GiveAnswerAgainstRequirementOrSubRequirement = (val, setAlert) => {
  console.log("0909" + JSON.stringify(val));

  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_requirement_id", val.auditRequirementId);
      formdata.append("answer_id", val.answerId);
      formdata.append("decision", val.decision);
      formdata.append("auditor_comments", val.auditorComments);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let answer = await fetch(
        `${baseUrl}/api/auditor/update-answer-status`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (answer.status == 200) {
        dispatch({
          type: GIVE_ANSWER_AGAINST_REQUIREMENT_OR_SUB_REQUIREMENT,
          payload: answer.data,
        });
        console.log(answer);
        dispatch(GetAuditorLifeCycle(localStorage.getItem("audit_life_cycle")));

        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
        messageHandler(
          setAlert,
          "Successfully.",
          "Review is submitted",
          "success"
        );
      } else {
        console.log("ERROR");
        console.log(answer);
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// Create version life cycle
export const CreateVersionLifeCycle = (val, setAlert) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("status", val.status);
      formdata.append("start_date", val.start_date);
      formdata.append("end_date", val.end_date);
      formdata.append("name", val.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let version = await fetch(
        `${baseUrl}/api/auditor/create-version`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (version.status == 200) {
        dispatch({
          type: CREATE_VERSION_LIFE_CYCLE,
          payload: version.data,
        });

        messageHandler(
          setAlert,
          "Version Create successfully.",
          "Version Created",
          "success"
        );
        console.log(version);
      } else {
        console.log("ERROR");
        messageHandler(
          setAlert,
          "Unable to create Version.",
          "Version Create Failed",
          "error"
        );
      }

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};

// Create version life cycle
export const GetVersionFiles = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      let version = await fetch(
        `${baseUrl}/api/auditor/get-attachments`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (version.status == 200) {
        dispatch({
          type: GET_VERSION_FILES,
          payload: version.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
// Create version life cycle
export const GetReport = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("version_id", val.version_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      let version = await fetch(
        `${baseUrl}/api/auditor/get-report`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (version.status == 200) {
        dispatch({
          type: GET_REPORT_FILES,
          payload: version.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// get radar chart datajhjh
export const GetRadarChart = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val.audit_id);
      formdata.append("version_id", val.version_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      let version = await fetch(
        `${baseUrl}/api/auditor/radar-chart`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (version.status == 200) {
        dispatch({
          type: GET_RADAR_CHART,
          payload: version.data,
        });

        console.log(version.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// Create version life cycle
export const ShowVersionNameReport = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("audit_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      let version = await fetch(
        `${baseUrl}/api/auditor/show-version-name`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (version.status == 200) {
        dispatch({
          type: SHOW_VERSION_NAME_REPORT,
          payload: version.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
