import {
  CHANGE_SET_SIDEBAR_MINI,
  CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL,
  CHANGE_SHOW_VIEW_REQUIREMENT_MODAL,
  CHANGE_VIEWING_SUB_REQUIREMENTS,
  CHANGE_GLOBAL_LOADER,
  CHANGE_GET_ALL_COUNTRIES,
  CHANGE_GET_STATES_BY_COUNTRY,
  CHANGE_GET_CITIES_BY_STATE,
  CHANGE_IS_ADD_NEW_AUDIT,
} from "../actions/types";

const initialState = {
  setSidebarMini: false,
  allCountries: [],
  showRequirementListDeailsModal: false,
  isAddNewAudit: false,
  showViewRequirementModal: false,
  viewingSubRequirements: false,
  globalLoader: false,
  statesByCountry: [],
  citiesByState: [],
};

export default (state = initialState, { type, payload }) => {
  // console.log('TYPE::')
  // console.log(type)
  // console.log('PAYLOAD::')
  // console.log(payload)
  switch (type) {
    case CHANGE_VIEWING_SUB_REQUIREMENTS:
      return {
        ...state,
        viewingSubRequirements: payload,
      };
    case CHANGE_SHOW_VIEW_REQUIREMENT_MODAL:
      return {
        ...state,
        showViewRequirementModal: payload,
      };
    case CHANGE_IS_ADD_NEW_AUDIT:
      return {
        ...state,
        isAddNewAudit: payload,
      };
    case CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL:
      return {
        ...state,
        showRequirementListDeailsModal: payload,
      };
    case CHANGE_SET_SIDEBAR_MINI:
      return {
        ...state,
        setSidebarMini: payload,
      };
    case CHANGE_GLOBAL_LOADER:
      return {
        ...state,
        globalLoader: payload,
      };
    case CHANGE_GET_ALL_COUNTRIES:
      return {
        ...state,
        allCountries: payload,
      };
    case CHANGE_GET_STATES_BY_COUNTRY:
      return {
        ...state,
        statesByCountry: payload,
      };
    case CHANGE_GET_CITIES_BY_STATE:
      return {
        ...state,
        citiesByState: payload,
      };
    default:
      return state;
  }
};
