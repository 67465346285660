import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  ChangeShowAddAuditModal,
  ChangeShowAddRequirmentsModal,
} from "../../../store/actions/auditActions";
import { GetAllRequirements } from "store/actions/requirmentActions";

const Requirments = (props) => {
  const { } = props.audit;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddRequirmentsModal,
    GetAllRequirements,
    selectedSectionsRequirements,
    isEditing,
    status,
  } = props;

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };

  // const [data, SetData] = useState(dataTable);
  const [data, SetData] = useState([]);

  let dataMap = selectedSectionsRequirements?.map((data) => ({
    id: data?.id,
    // requirementList: data.req_list.name,
    desc: data?.description,
    standardRef: data?.standard_ref,
    references: data?.reference?.name,
    requirmentList: data?.req_list?.name,
  }));
  let dataTable = React.useMemo(() => dataMap, [selectedSectionsRequirements]);

  useEffect(() => {
    SetData(dataTable);
  }, [selectedSectionsRequirements]);

  // var dataTable = useMemo(
  //   () => [
  //     {
  //       standardRef: "Requirment 1",
  //       desc: "Requirment 1 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
  //       mapped: true,
  //     },
  //     {
  //       standardRef: "Requirment 2",
  //       desc: "Requirment 2 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags([]),
  //       mapped: false,
  //     },
  //     {
  //       standardRef: "Requirment 3",
  //       desc: "Requirment 3 desc",
  //       requirmentList: "Req List 1",
  //       references: RenderTags(["ref#1", "ref#2"]),
  //       reqMapping: RenderTags([]),
  //       mapped: false,
  //     },
  //   ],
  //   []
  // );
  const columns = useMemo(
    () => [
      {
        Header: "Req: Standard Ref",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirment List",
        accessor: "requirmentList",
      },
      {
        Header: "References",
        accessor: "references",
      },
      // {
      //   Header: "Requirment Mapping",
      //   accessor: "reqMapping",
      // },
    ],
    []
  );

  const [members, ChangeMembers] = useState(null);
  const [template, ChangeTemplate] = useState(null);
  const [entity, ChangeEntity] = useState(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = useState(null);
  const successDelete = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    console.log("ADD AUDIT FOCUSED", isEditing);
    if (!isEditing) SetData([]);
    else SetData(dataTable);
  }, []);

  return (
    <>
      {alert}
      <div className="title-with-add-btn">
        <span
          style={{
            fontWeight: "bold",
            color: "#666666",
            fontSize: 18,
          }}
        >
          {"Requirments"}
        </span>
        {status !== 1 && (
          <i
            onClick={() => {
              GetAllRequirements();
              ChangeShowAddRequirmentsModal(true);
            }}
            class="fas fa-plus-circle top-add-btn"
          ></i>
        )}
      </div>
      <div div className="tableWrap">
        <table {...getTableProps()} className="table-striped table-hover ">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table-info text-white"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border border-left-0 border-right "
                    style={{
                      borderBottom: "1px solid #cacaca",
                      background: "rgba(0, 0, 0, 0.05)",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-arrow-down  text-secondary"></i>
                        ) : (
                          <i className="fas fa-arrow-up text-secondary"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
                {/* <th
                  className="border border-left-0 border-right "
                  style={{
                    borderBottom: "1px solid #cacaca",
                    background: "rgba(0, 0, 0, 0.05)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                ></th> */}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      </>
                    );
                  })}
                  {/* <td>
                    <div className="d-flex flex-wrap justify-content-around">
                      <i
                        className="fas fa-times text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => successDelete()}
                      ></i>
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit }) => ({
  shared,
  styling,
  audit,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddRequirmentsModal,
  GetAllRequirements,
})(Requirments);
