import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import PerfectScrollbar from "perfect-scrollbar";
import { GlobalFilterDropdown } from "./GlobalFilterDropdown";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Collapse,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { CheckBox } from "./CheckBox";
import ReactFileReader from "react-file-reader";
import { connect } from "react-redux";
import { MapRequirementIds } from "store/actions/requirmentActions";

// core components

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const RequirementsSubRequirementsTable = (props) => {
  const { requirment, MapRequirementIds } = props;
  const { allRequirements, allSubRequirements } = requirment;
  const history = useHistory();

  // ---------------- SETTING UP DATATABLE START ---------------------------

  //   var dataTable = React.useMemo(
  //     () => [
  //       {
  //         name: "ISO:888800000",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "Active",
  //         requirement_file: "lorem",
  //       },
  //       {
  //         name: "ISO:9000",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "InActive",
  //         requirement_file: "lorem",
  //       },
  //     ],
  //     []
  //   );
  const [data, SetData] = useState([]);

  // let dataMemo = React.useMemo(
  //   () => dataCombineRequirements,
  //   [dataCombineRequirements]
  // );

  useEffect(() => {
    let dataMapRequirements = allRequirements.map((requirement) => ({
      requirementId: requirement.id,
      name: requirement.req_list.name,
      standardRef: requirement.standard_ref,
      desc: requirement.description,
      reference: requirement.references,
      evidence: requirement.evidence,
      questionType: requirement.question_type,
    }));

    // let dataMapSubRequirements = allSubRequirements.map((subRequirement) => ({
    //   subRequirementId: subRequirement.id,
    //   name: "Listed later 2",
    //   standardRef: subRequirement.standard_ref,
    //   desc: subRequirement.description,
    //   reference: subRequirement.references,
    //   evidence: subRequirement.evidence,
    //   questionType: subRequirement.question_type,
    // }));

    // let dataCombineRequirements = dataMapRequirements.concat(
    //   dataMapSubRequirements
    // );
    console.log(":::::::::USEEFFCT:::::::");
    // console.log(dataMemo);

    SetData(dataMapRequirements);
  }, [allRequirements]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Standard Ref",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Evidence",
        accessor: "evidence",
      },
      {
        Header: "Question Type",
        accessor: "questionType",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  // const selectedRows = selectedFlatRows.map((row) =>
  //   row.original.requirementId
  //     ? row.original.requirementId
  //     : row.original.subRequirementId
  // );

  useEffect(() => {
    const selectedRows = selectedFlatRows.map(
      (row) => row.original.requirementId
    );
    console.log(selectedRows);
    MapRequirementIds(selectedRows);
  }, [selectedFlatRows]);

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // --------------------- Filter Table ------------------------------

  const filterDataTable = (e) => {
    console.log("E.TARGET.VALUE");
    console.log(e.target.value);
    var elements;
    if (e.target.value == "") {
      elements = dataTable;
    } else {
      elements = dataTable.filter((el) => {
        return el.status === e.target.value;
      });
    }
    console.log("ELEMENTS");
    console.log(elements);
    SetData(elements);
  };

  // ------------------ Filter Table End -----------------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  var ps;
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };
  const [isLoading, setIsLoading] = useState(false);
  const successMapped = () => {
    setIsLoading(true);

    setTimeout(function () {
      setIsLoading(false);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Requirement Mapped !
        </ReactBSAlert>
      );
    }, 3000);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = React.useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const setRowForEdit = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  //  ------------------------------------------------ ACCORDION FOR MODAL START ----------------------------------------------

  const [openedCollapses, setOpenedCollapses] = React.useState([
    "collapseOne",
    "collapse1",
    "sectionReq1",
    "subRequirement1",
  ]);

  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };

  // --------------------------------------------------ACCRODTION FOR MODAL END --------------------------------------------------

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              {/* 
                            <div className="bg-info rounded d-flex flex-wrap align-items-center justify-content-between  ">

                                <div className="text-left">
                                    <Link
                                        className="btn btn-info btn-sm" to="/user/create-requirement"
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        <i className="fas fa-plus"></i>
                                        {"Create New Requirement"}
                                    </Link>
                                </div>


                            </div> */}

              <div className="row p-3 align-items-center">
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-between">
                    Show
                    <select
                      value={pageSize}
                      className="form-control pagination-select"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    Entries
                  </div>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-3 text-right">
                  <FormGroup className="custom-form-group">
                    <select
                      name=""
                      id=""
                      className="form-control custom-input"
                      onChange={(e) => filterDataTable(e)}
                    >
                      <option value="" defaultValue disabled>
                        Filters
                      </option>
                      <option value="">All</option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </select>
                  </FormGroup>
                </div>
                <div className="col-md-3 text-right">
                  <div className="form-group custom-form-group">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div>
              {/* <button onClick={resetResizing}>Reset Resizing</button> */}
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                        {/* <th
                          className="border border-left-0 border-right "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></th> */}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}

                                  // style={{
                                  //     padding: '10px',
                                  //     // border: 'solid 1px gray',
                                  //     // background: 'papayawhip',
                                  // }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}

                          {/* <td> */}
                          {/* {console.log(row)} */}

                          {/* <div className="d-flex flex-wrap justify-content-around">
                                                            <i className="fas fa-eye text-info" onClick={() => setRow(row)} style={{ cursor: 'pointer' }} ></i>
                                                            <i className="fas fa-edit text-primary" style={{ cursor: 'pointer' }} onClick={() => setRowForEdit(row)}></i>
                                                            <i className="fas fa-times text-danger" style={{ cursor: 'pointer' }} onClick={() => successDelete()}></i>


                                                        </div> */}
                          {/* </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>

                <div className="">
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  <span className="pagination-button">
                    {pageOptions.map((page) => (
                      <button
                        className={
                          pageIndex === page
                            ? "btn border-info border btn-info  bg-transparent text-info "
                            : "btn border-info border btn-info btn-sm bg-transparent text-info"
                        }
                        onClick={() => {
                          const pageNumber = page;
                          gotoPage(pageNumber);
                        }}
                      >
                        {page + 1}
                      </button>
                    ))}
                  </span>
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>

              {console.log(
                JSON.stringify(
                  {
                    selectedFlatRows: selectedFlatRows.map(
                      (row) => row.original
                    ),
                  },
                  null,
                  2
                )
              )}

              {/* <pre>
                                <code>
                                    {
                                        JSON.stringify(
                                            {
                                                selectedFlatRows: selectedFlatRows.map((row) => row.original),
                                            }, null, 2
                                        )
                                    }
                                </code>
                            </pre> */}
            </Card>
          </Col>
        </Row>

        {/* ------------------------------- EDIT MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          {modalClassic ? (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">{rowDetails?.original?.name}</h4>
              </div>
              <div className="modal-body">
                <Form onSubmit={(e) => editSubmit(e)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.name}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.desc}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Authority</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.authority}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Location</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.location}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Industry</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.industry}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Information</Label>
                        <Input
                          type="text"
                          defaultValue={rowDetails?.original?.information}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Effective Date</Label>
                        <Input
                          type="date"
                          defaultValue={rowDetails?.original?.effective_date}
                          onChange={(e) => console.log(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Status</Label>
                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={(e) => console.log(e.target.value)}
                          defaultValue={rowDetails?.original?.status}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Requirement File</Label>
                        <Input
                          type="file"
                          onChange={(e) => console.log(e.target.files[0])}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button type="submit" color="info">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          ) : null}
        </Modal>
        {/* ---------------------------- End EDIT  Modal --------------------------- */}

        {/* ------------------------------- VIEW MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic1} size="xl" toggle={toggleModalClassic1}>
          <>
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalClassic1}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="text-left">{rowDetails?.original?.name}</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-striped table-hover">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Name</td>
                    <td>{rowDetails?.original?.name}</td>
                    <td className="font-weight-bold">Description</td>
                    <td>{rowDetails?.original?.desc}</td>
                    <td className="font-weight-bold">Authority</td>
                    <td>{rowDetails?.original?.authority}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Location</td>
                    <td>{rowDetails?.original?.location}</td>
                    <td className="font-weight-bold">Industry</td>
                    <td>{rowDetails?.original?.industry}</td>
                    <td className="font-weight-bold">Information</td>
                    <td>{rowDetails?.original?.information}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Effective Date</td>
                    <td>{rowDetails?.original?.effective_date}</td>
                    <td className="font-weight-bold">Status</td>
                    <td>{rowDetails?.original?.status}</td>
                    <td className="font-weight-bold">Requirement File</td>
                    <td>{rowDetails?.original?.requirement_file}</td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{ height: "400px", overflowY: "scroll" }}
                id="scroll-bar"
              >
                <div
                  aria-multiselectable={true}
                  className="card-collapse"
                  id="accordion"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader role="tab">
                      <a
                        aria-expanded={openedCollapses.includes("collapseOne")}
                        className="text-danger font-weight-bold"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapseOne")}
                      >
                        A Section : 14.1 : Section Description{" "}
                        <i className="nc-icon nc-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={openedCollapses.includes("collapseOne")}
                    >
                      <Card className="card-plain ml-3">
                        <CardHeader role="tab">
                          <a
                            aria-expanded={openedCollapses.includes(
                              "sectionReq1"
                            )}
                            className="text-info font-weight-bold"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={() => collapsesToggle("sectionReq1")}
                          >
                            Section Requirement : 14.1.1 : Requirement
                            Description{" "}
                            <i className="nc-icon nc-minimal-down text-info" />
                          </a>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={openedCollapses.includes("sectionReq1")}
                        >
                          <Card className="card-plain ml-5">
                            <CardHeader role="tab">
                              <a
                                aria-expanded={openedCollapses.includes(
                                  "subRequirement1"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                className="text-success font-weight-bold"
                                onClick={() =>
                                  collapsesToggle("subRequirement1")
                                }
                              >
                                Sub Requirement : 14.1.1.1 : Subrequirement
                                Description{" "}
                                <i className="nc-icon nc-minimal-down text-success" />
                              </a>
                            </CardHeader>
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapses.includes(
                                "subRequirement1"
                              )}
                            >
                              <CardBody>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Standard Ref.</th>
                                      <th>Description</th>
                                      <th>References</th>
                                      <th>Weight</th>
                                      <th>Criticality</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>12.1</td>
                                      <td>
                                        This is a description of the
                                        subrequirement.
                                      </td>
                                      <td>A , B , C</td>
                                      <td>0</td>
                                      <td>Hight</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Collapse>
                      </Card>
                    </Collapse>
                  </Card>

                  <Card className="card-plain">
                    <CardHeader role="tab">
                      <a
                        aria-expanded={openedCollapses.includes("collapseTwo")}
                        className="text-danger font-weight-bold"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapseTwo")}
                      >
                        A Section : 14.2 : Section Description{" "}
                        <i className="nc-icon nc-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={openedCollapses.includes("collapseTwo")}
                    >
                      <Card className="card-plain ml-3">
                        <CardHeader role="tab">
                          <a
                            aria-expanded={openedCollapses.includes(
                              "sectionReq2"
                            )}
                            className="text-info font-weight-bold"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={() => collapsesToggle("sectionReq2")}
                          >
                            Section Requirement : 14.2.1 : Requirement
                            Description{" "}
                            <i className="nc-icon nc-minimal-down text-info" />
                          </a>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={openedCollapses.includes("sectionReq2")}
                        >
                          <Card className="card-plain ml-5">
                            <CardHeader role="tab">
                              <a
                                aria-expanded={openedCollapses.includes(
                                  "subRequirement2"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                className="text-success font-weight-bold"
                                onClick={() =>
                                  collapsesToggle("subRequirement2")
                                }
                              >
                                Sub Requirement : 14.2.1.1 : Subrequirement
                                Description{" "}
                                <i className="nc-icon nc-minimal-down text-success" />
                              </a>
                            </CardHeader>
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapses.includes(
                                "subRequirement2"
                              )}
                            >
                              <CardBody>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Standard Ref.</th>
                                      <th>Description</th>
                                      <th>References</th>
                                      <th>Weight</th>
                                      <th>Criticality</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>12.1</td>
                                      <td>
                                        This is a description of the
                                        subrequirement.
                                      </td>
                                      <td>A , B , C</td>
                                      <td>0</td>
                                      <td>Hight</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Collapse>
                      </Card>
                    </Collapse>
                  </Card>
                </div>
              </div>
              <div className="text-right">
                <Button
                  color="info"
                  onClick={() => {
                    history.push("/user/requirements-list-detail");
                  }}
                >
                  Details
                </Button>
              </div>
            </div>
          </>
        </Modal>
        {/* ---------------------------- End VIEW  Modal --------------------------- */}
      </div>
    </>
  );
};

const mapStateToProps = ({ requirment }) => ({
  requirment,
});

export default connect(mapStateToProps, { MapRequirementIds })(
  RequirementsSubRequirementsTable
);
