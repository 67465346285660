import React, { useEffect } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { GetAllDashboardInfo } from "../../src/store/actions/dashboardAction";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  FcPieChart,
  FcAreaChart,
  FcElectricalThreshold,
  FcAcceptDatabase,
  FcDatabase,
  FcViewDetails,
  FcFilingCabinet,
  FcKindle,
  FcAnswers,
  FcDocument,
  FcRules,
  FcTemplate,
  FcWorkflow,
  FcProcess,
  FcRefresh,
  FcAssistant,
  FcCollaboration,
  FcBusinessman,
} from "react-icons/fc";

import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "variables/charts.js";
import moment from "moment";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function Dashboard1(props) {
  console.log(props);
  const history = useHistory();
  const { dashboard, GetAllDashboardInfo } = props;

  useEffect(() => {
    GetAllDashboardInfo();
  }, []);

  const { dashboardInfo } = dashboard;

  console.log("dashboardInfo", dashboardInfo);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcViewDetails style={{ fontSize: 50, marginRight: 5 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Requirement Lists
                      </p>

                      <CardTitle tag="p">
                        {dashboardInfo?.requirement_lists?.count}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {dashboardInfo?.requirement_lists?.count !== 0 ? (
                  <div className="stats">
                    {/* <i className="fa fa-refresh" /> */}
                    Last Update :{" "}
                    {moment(
                      dashboardInfo?.requirement_lists?.updated_at
                    )?.format("DD MMM YYYY")}
                  </div>
                ) : (
                  <div className="stats">No Requirement List</div>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcDocument style={{ fontSize: 50, marginRight: 5 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Current Assessment
                      </p>
                      <CardTitle tag="p">
                        {dashboardInfo?.active_audits?.count}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {dashboardInfo?.active_audits?.count !== 0 ? (
                  <div className="stats">
                    {/* <i className="fa fa-refresh" /> */}
                    Last Update :{" "}
                    {moment(dashboardInfo?.active_audits?.updated_at)?.format(
                      "DD MMM YYYY"
                    )}
                  </div>
                ) : (
                  <div className="stats">No Assessment</div>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcFilingCabinet
                        style={{ fontSize: 50, marginRight: 5 }}
                      />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Requirments
                      </p>
                      <CardTitle tag="p">
                        {dashboardInfo?.requirements?.count}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {dashboardInfo?.requirements?.count !== 0 ? (
                  <div className="stats">
                    {/* <i className="fa fa-refresh" /> */}
                    Last Update :{" "}
                    {moment(dashboardInfo?.requirements?.updated_at)?.format(
                      "DD MMM YYYY"
                    )}
                  </div>
                ) : (
                  <div className="stats">No Requirement</div>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcTemplate style={{ fontSize: 50, marginRight: 5 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Assessment Templates
                      </p>
                      <CardTitle tag="p">
                        {dashboardInfo?.audit_templates?.count}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {dashboardInfo?.audit_templates?.count !== 0 ? (
                  <div className="stats">
                    {/* <i className="fa fa-refresh" /> */}
                    Last Update :{" "}
                    {moment(dashboardInfo?.audit_templates?.updated_at)?.format(
                      "DD MMM YYYY"
                    )}
                  </div>
                ) : (
                  <div className="stats">No Templates</div>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    {/* <div className="numbers pull-left">$34,657</div> */}
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      {/* <Badge color="success" pill>
                                                +18%
                                            </Badge> */}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="big-title">Assessments Reviewed</h6>
                <Line
                  data={chartExample1.data}
                  options={chartExample1.options}
                  height={380}
                  width={826}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="7">
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      Update Now
                    </div>
                  </Col>
                  {/* <Col sm="5">
                    <div className="pull-right">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        size="sm"
                        onClick={() => history.push("/user/life-cycle")}
                      >
                        <i className="nc-icon nc-button-play" />
                      </Button>
                    </div>
                  </Col> */}
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    {/* <div className="numbers pull-left">169</div> */}
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      {/* <Badge color="danger" pill>
                                                -14%
                                            </Badge> */}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="big-title">Assessment response</h6>
                <Line
                  data={chartExample2.data}
                  options={chartExample2.options}
                  height={380}
                  width={828}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="7">
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      Update Now
                    </div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        size="sm"
                        style={{ visibility: "hidden" }}
                      >
                        <i className="nc-icon nc-button-play" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    {/* <div className="numbers pull-left">8,960</div> */}
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      {/* <Badge color="warning" pill>
                                                ~51%
                                            </Badge> */}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="big-title">task created </h6>
                <Line
                  data={chartExample3.data}
                  options={chartExample3.options}
                  height={380}
                  width={826}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="7">
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      Update Now
                    </div>
                  </Col>
                  {/* <Col sm="5">
                    <div className="pull-right">
                      <Button
                        className="btn-round btn-icon"
                        color="warning"
                        size="sm"
                        onClick={() => history.push("/user/auditing-template")}
                      >
                        <i className="nc-icon nc-simple-add" />
                      </Button>
                    </div>
                  </Col> */}
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, {
  GetAllDashboardInfo,
})(Dashboard1);
