import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
} from "reactstrap";
import { CheckBox } from "../CheckBox";

import {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  CreateAuditingTemplate,
  GetAllAuditingTemplate,
} from "../../../store/actions/auditingTemplateActions";
import { ChangeShowAddRequirmentsModal } from "../../../store/actions/auditActions";
import Requirments from "views/Audit/AllAudits/Requirments";
import Sections from "views/Audit/AllAudits/Sections";
import {
  ChangeShowAddNewUserModal,
  CreateUserRole,
} from "store/actions/userAction";

const AddNewUserRole = (props) => {
  const {
    showAddOriginalRequirmentModal,
    showAddAuditingTemplateModal,
    isEditing,
    underEditTemplateTitle,
    editAuditingTemplateData,
    addSectionsForCreatingAuditTemplate,
    addRequirementsForCreatingAuditTemplate,
  } = props.auditingTemplate;
  const { allSections, allRequirements } = props.requirment;
  const {
    ChangeShowAddOriginalRequirmentModal,
    ChangeShowAddAuditingTemplateModal,
    ChangeIsEditing,
    ChangeUnderEditTemplateTitle,
    ChangeShowAddRequirmentsModal,
    CreateAuditingTemplate,
    GetAllAuditingTemplate,
    ChangeShowAddNewUserModal,
    CreateUserRole,
  } = props;

  const [email, setEmail] = useState("");
  // const [role, setRole] = useState("");

  const RenderTags = (tags) => {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {tags.map((item, key) => {
          return (
            <span
              key={key}
              style={{
                border: "1px solid silver",
                borderRadius: 10,
                marginRight: 5,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 2,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  };
  var dataTable = useMemo(
    () => [
      {
        section: "Section 1",
        standardRef: "Requirment 1",
        desc: "Requirment 1 desc",
        requirmentList: "Req List 1",
        references: RenderTags(["ref#1", "ref#2"]),
        requirementMapping: RenderTags(["Requirment 4", "Sub Req 41"]),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Standard Ref.",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirment List",
        accessor: "requirmentList",
      },
      {
        Header: "Requirement Mapping",
        accessor: "requirementMapping",
      },
    ],
    []
  );
  const [data, SetData] = useState(dataTable);
  const [horizontalTabs, setHorizontalTabs] = React.useState("sections");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = React.useState(null);
  const successSendEmail = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Email has been sent successfully.
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      role:
        window.localStorage.getItem("currentLoginUserRole") == 0
          ? 1
          : window.localStorage.getItem("currentLoginUserRole") == 1
            ? 2
            : "",
    };
    CreateUserRole(data, successSendEmail);
    // console.log(data);
    setEmail("")
  };

  return (
    <>
      {alert}
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => ChangeShowAddNewUserModal(false)}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h4 className="title title-up">
          {window.localStorage.getItem("currentLoginUserRole") == 0
            ? "Add New Contributor Lead"
            : window.localStorage.getItem("currentLoginUserRole") == 1
              ? "Add New Support Team"
              : ""}
        </h4>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="text-left">
                <Label>Email</Label>
                <Input
                  type="text"
                  name="location"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            {/* <Col md="12">
              <FormGroup className="text-left">
                <Label>Role</Label>
                <select
                  name="role"
                  id=""
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="0">Auditor</option>
                  <option value="1">Contributor Lead</option>
                </select>
              </FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col md="6">
              <Button
                color="success"
              // onClick={() => {
              //   // handleRequirementSectionUpdateAddMoreFormSubmit();

              // }}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  requirment,
  shared,
  styling,
  auditingTemplate,
}) => ({
  user,
  shared,
  styling,
  auditingTemplate,
  requirment,
});

export default connect(mapStateToProps, {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  ChangeShowAddRequirmentsModal,
  CreateAuditingTemplate,
  GetAllAuditingTemplate,
  CreateUserRole,
  ChangeShowAddNewUserModal,
})(AddNewUserRole);
