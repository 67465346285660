import React, { useState, useMemo, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

import { toast } from 'react-toastify';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "../tables/GlobalFilter";
import { CheckBox } from "../tables/CheckBox";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
  Modal,
  FormFeedback,
} from "reactstrap";
import ExistingSections from "views/tables/ExistingSections";
import ExistingSectionRequirements from "views/tables/ExistingSectionRequirements";
import RequirementMappingTable from "views/tables/RequirementMappingTable";
import ExistingSubrequirements from "views/tables/ExistingSubRequirements";
import AddRequirementListForm from "./AddRequirmentListForm";
import { connect } from "react-redux";
import {
  CreateRequirementList,
  GetAllRequirementList,
  CreateRequirementSection,
  GetSingleRequirementList,
  CreateSectionRequirement,
  CreateSubRequirement,
  GetRequirementsBySectionId,
  EditRequirementList,
  EditRequirementSection,
  ChangeIsAddRequirementList,
  ChangeIsAddRequirement,
  ChangeIsAddSection,
  ChangeIsAddSubRequirement,
  GetSubRequirementsByRequirementId,
} from "store/actions/requirmentActions";
import { ChangeRequirementEditing } from "store/actions/requirmentActions";
import { EditSectionRequirement } from "store/actions/requirmentActions";
import { EditSubRequirement } from "store/actions/requirmentActions";
import { ChangeClearRequirementData } from "store/actions/requirmentActions";
import { GetAllReferences } from "store/actions/requirmentActions";
import { GetRequirementsByRequirementListId } from "store/actions/requirmentActions";
import { GetSubRequirementsByRequirementListId } from "store/actions/requirmentActions";
import { ClearSingleRequirementListData } from "store/actions/requirmentActions";
import { ChangeIsRequirementListEdit } from "store/actions/requirmentActions";

const CreateRequirement = (props) => {
  const history = useHistory();
  const {
    CreateRequirementList,
    requirment,
    CreateRequirementSection,
    GetSingleRequirementList,
    CreateSectionRequirement,
    CreateSubRequirement,
    GetRequirementsBySectionId,
    GetAllRequirementList,
    EditRequirementList,
    EditRequirementSection,
    ChangeIsAddRequirementList,
    ChangeIsAddRequirement,
    ChangeIsAddSection,
    ChangeIsAddSubRequirement,
    GetSubRequirementsByRequirementId,
    ChangeRequirementEditing,
    EditSectionRequirement,
    EditSubRequirement,
    ChangeClearRequirementData,
    GetAllReferences,
    GetRequirementsByRequirementListId,
    GetSubRequirementsByRequirementListId,
  } = props;
  const {
    requirementList,
    requirementSection,
    sectionRequirement,
    subRequirement,
    allRequirementList,
    singleRequirementList,
    requirementsBySectionId,
    requirementEditing,
    singleSectionById,
    isAddRequirementList,
    isAddSection,
    isAddRequirement,
    isAddSubRequirement,
    getCurrentAddedRequirementListId,
    subRequirementsByRequirementId,
    allRequirementsByRequirementListId,
    allSubRequirementsByRequirementListId,
    singleRequirementById,
    getSubRequirementById,
    isRequirementListEdit,
    allReferences,
  } = requirment;

  const [horizontalTabs, setHorizontalTabs] =
    React.useState("requirement-list");
  const [questionType, setQuestionType] = useState("0");

  const [modalClassic, setModalClassic] = React.useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };
  // ---------------- SETTING UP DATATABLE START ---------------------------

  // Sections Table
  var data = React.useMemo(
    () => [
      {
        name: "ISO:8888",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "Active",
        requirement_file: "lorem",
      },
      {
        name: "ISO:9000",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "InActive",
        requirement_file: "lorem",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Requirement List",
        accessor: "industry",
      },
      {
        Header: "Section Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Desc",
        accessor: "desc",
      },
      {
        Header: "Standard Ref",
        accessor: "authority",
      },
      {
        Header: "Clairification",
        accessor: "location",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //     hooks.visibleColumns.push((columns) => {
    //         return [
    //             {
    //                 id: 'selection',
    //                 Header: ({ getToggleAllRowsSelectedProps }) => (
    //                     <CheckBox {...getToggleAllRowsSelectedProps()} />
    //                 ),
    //                 Cell: ({ row }) => (
    //                     <CheckBox {...row.getToggleRowSelectedProps()} />
    //                 )
    //             }, ...columns
    //         ]
    //     })
    // }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Added !
      </ReactBSAlert>
    );
  };
  const successMapped = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Mapped !
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ----------- Add Requirement Submit //

  const onSubmit = (e) => {
    e.preventDefault();
    successAlert();
  };

  //  Custom Add Field

  const [requirementListFields, setRequirementListFields] = useState([]);
  const [sectionFields, setSectionFields] = useState([]);
  const [requirementFields, setRequirementFields] = useState([]);
  const [subRequirementFields, setSubRequirementFields] = useState([]);
  const [addFieldId, setAddFieldId] = useState(null);

  useEffect(() => {
    setRequirementListFields(
      singleRequirementList?.data?.extra_fields
        ? singleRequirementList?.data?.extra_fields
        : []
    );
    // setSectionFields(
    //   singleRequirementList?.data?.sections?.extra_fields
    //     ? singleRequirementList?.data?.sections?.extra_fields
    //     : []
    // );
  }, [singleRequirementList]);

  const [formData, setFormData] = useState({
    field_name: "",
    field_value: "",
  });
  const { field_name, field_value } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFieldAddSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (addFieldId == 1) {
      setRequirementListFields([
        ...requirementListFields,
        {
          field_name: field_name,
          field_value: field_value,
        },
      ]);
      setRequirementListFormData({
        ...requirementListFormData,
        extra_fields: [
          ...requirementListFields,
          {
            field_name: field_name,
            field_value: field_value,
          },
        ],
      });
    }
    if (addFieldId == 2) {
      setSectionFields([
        ...sectionFields,
        {
          field_name: field_name,
          field_value: field_value,
        },
      ]);
      setRequirementSectionFormData({
        ...requirementSectionFormData,
        extra_fields: [
          ...sectionFields,
          {
            field_name: field_name,
            field_value: field_value,
          },
        ],
      });
    }
    if (addFieldId == 3) {
      setRequirementFields([
        ...requirementFields,
        {
          field_name: field_name,
          field_value: field_value,
        },
      ]);
      setSectionRequirementsFormData({
        ...sectionRequirementsFormData,
        extra_fields: [
          ...requirementFields,
          {
            field_name: field_name,
            field_value: field_value,
          },
        ],
      });
    }
    if (addFieldId == 4) {
      setSubRequirementFields([
        ...subRequirementFields,
        {
          field_name: field_name,
          field_value: field_value,
        },
      ]);
      setSubRequirementsFormData({
        ...subRequirementsFormData,
        extra_fields: [
          ...subRequirementFields,
          {
            field_name: field_name,
            field_value: field_value,
          },
        ],
      });
    }

    setFormData({
      field_name: "",
      field_value: "",
    });
    toggleModalClassic();
  };

  // useEffect(() => {
  //   if (
  //     requirementListFields.length > 0 ||
  //     sectionFields.length > 0 ||
  //     requirementFields.length > 0 ||
  //     subRequirementFields.length > 0
  //   ) {
  //     if (addFieldId == 1) {
  //       setRequirementListFormData({
  //         ...requirementListFormData,
  //         extra_fields: requirementListFields,
  //       });
  //     }
  //     if (addFieldId == 2) {
  //       setRequirementSectionFormData({
  //         ...requirementSectionFormData,
  //         extra_fields: sectionFields,
  //       });
  //     }
  //     if (addFieldId == 3) {
  //       setSectionRequirementsFormData({
  //         ...sectionRequirementsFormData,
  //         extra_fields: requirementFields,
  //       });
  //     }
  //     if (addFieldId == 4) {
  //       setSubRequirementsFormData({
  //         ...subRequirementsFormData,
  //         extra_fields: subRequirementFields,
  //       });
  //     }
  //   }
  // }, [
  //   requirementListFields,
  //   sectionFields,
  //   requirementFields,
  //   subRequirementFields,
  // ]);

  console.log(singleRequirementList);

  const clearInputFieldsAfterSubmission = () => {
    requirementListFormData.name = "";
    requirementListFormData.location = "";
    requirementListFormData.authority = "";
    requirementListFormData.industry = "";
    requirementListFormData.information = "";
    requirementListFormData.effective_date = "";
    requirementListFormData.description = "";
    requirementListFormData.status = "";
    requirementListFormData.file = {};
    requirementListFormData.extra_fields = [];

    requirementSectionFormData.clarifications = "";
    requirementSectionFormData.description = "";
    requirementSectionFormData.standard_ref = "";
    requirementSectionFormData.extra_fields = [];

    sectionRequirementsFormData.req_section_id = null;
    sectionRequirementsFormData.standard_ref = "";
    sectionRequirementsFormData.criticality = "";
    sectionRequirementsFormData.evidence = "No";
    sectionRequirementsFormData.evidence_details = "";
    sectionRequirementsFormData.question_type = "";
    sectionRequirementsFormData.yes_complaint = "";
    sectionRequirementsFormData.no_complaint = "";
    sectionRequirementsFormData.references = "";
    sectionRequirementsFormData.weight = "1";
    sectionRequirementsFormData.description = "";
    sectionRequirementsFormData.clarification = "";
    sectionRequirementsFormData.extra_fields = [];

    (subRequirementsFormData.requirement_id = null),
      (subRequirementsFormData.section_id = null),
      (subRequirementsFormData.standard_ref = "");
    subRequirementsFormData.criticality = "";
    subRequirementsFormData.evidence = "";
    subRequirementsFormData.evidence_details = "";
    subRequirementsFormData.question_type = "";
    subRequirementsFormData.yes_complaint = "";
    subRequirementsFormData.no_complaint = "";
    subRequirementsFormData.references = "";
    subRequirementsFormData.weight = "1";
    subRequirementsFormData.description = "";
    subRequirementsFormData.clarification = "";
    subRequirementsFormData.extra_fields = [];

    ChangeIsAddRequirementList(false);
    ChangeIsAddRequirement(false);
    ChangeIsAddSection(false);
    ChangeIsAddSubRequirement(false);
  };

  //   REQUIREMENT LIST HANDLERS
  const [requirementListFormData, setRequirementListFormData] = useState({
    name: "",
    location: "",
    authority: "",
    industry: "",
    information: "",
    effective_date: "",
    description: "",
    status: "",
    file: {},
    extra_fields: [],
  });

  const [requirementListFormDataErrors, setRequirementListFormDataErrors] =
    useState({
      name: "",
    });

  const [
    requirementListFormDataErrorState,
    setRequirementListFormDataErrorState,
  ] = useState(null);

  // console.log("detect error", requirementListFormDataErrorState)
  // console.log("detect section error", sectionRequirementsFormDataErrorState)
  useEffect(() => {
    console.log(singleRequirementList);
    setRequirementListFormData({
      ...requirementListFormData,
      name: singleRequirementList?.data?.name
        ? singleRequirementList?.data?.name
        : "",
      location: singleRequirementList?.data?.location
        ? singleRequirementList?.data?.location
        : "",
      authority: singleRequirementList?.data?.authority
        ? singleRequirementList?.data?.authority
        : "",
      industry: singleRequirementList?.data?.industry
        ? singleRequirementList?.data?.industry
        : "",
      information: singleRequirementList?.data?.information
        ? singleRequirementList?.data?.information
        : "",
      effective_date: singleRequirementList?.data?.effective_date
        ? singleRequirementList?.data?.effective_date
        : "",
      description: singleRequirementList?.data?.description
        ? singleRequirementList?.data?.description
        : "",
      status: singleRequirementList?.data?.status
        ? singleRequirementList?.data?.status
        : "",
      // file: {},
      file: singleRequirementList?.data?.file
        ? singleRequirementList?.data?.file
        : "",
      extra_fields: [],
    });
  }, [singleRequirementList]);

  const handleRequirementListFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "file") {
      console.log("SELECTED INPUT FILE");
      console.log(e.target.files);
      return setRequirementListFormData({
        ...requirementListFormData,
        [name]: e.target.files[0],
      });
    }
    setRequirementListFormData({
      ...requirementListFormData,
      [name]: value,
    });
  };

  const handleRequirementListFormSubmit = (moveToNext) => {
    // if (requirementListFormData.name === "") {
    //   setRequirementListFormDataErrors({ name: "Input field is required" });
    // } else if (isMoveNext && requirementListFormData.name !== "") {
    //   setHorizontalTabs("section");
    //   CreateRequirementList(requirementListFormData);
    // } else {
    //   setRequirementListFormDataErrors({ name: "" });
    //   CreateRequirementList(requirementListFormData);
    // }
    CreateRequirementList(
      requirementListFormData,
      setRequirementListFormDataErrorState,
      moveToNext
    );
    // if (
    //   isMoveNext &&
    //   !requirementListFormDataErrorState &&
    //   _.isEmpty(requirementListFormDataErrorState)
    // ) {
    //   setHorizontalTabs("section");
    // }
    console.log("REQUIREMENT LIST FORM DATA DEBUGGING EXTRA FIELDS");
    console.log(requirementListFormData);
  };

  const handleRequirementListUpdateFormSubmit = (moveToNext) => {
    EditRequirementList(
      {
        ...requirementListFormData,
        id: requirementEditing?.requirementListId,
      },
      setRequirementListFormDataErrorState,
      moveToNext
    );

    // if (
    //   isMoveNext &&
    //   !requirementListFormDataErrorState &&
    //   _.isEmpty(requirementListFormDataErrorState)
    // ) {
    //   setHorizontalTabs("section");
    // }

    // if (
    //   isMoveNext
    // ) {
    //   setHorizontalTabs("section");
    // }
  };

  const moveToNext = () => {
    setRequirementListFormDataErrorState(null);
    setHorizontalTabs("section");
  };

  // SECTION HANDLERS
  const [
    requirementSectionFormDataErrorState,
    setRequirementSectionFormDataErrorState,
  ] = useState(null);

  const [requirementSectionFormData, setRequirementSectionFormData] = useState({
    clarifications: "",
    description: "",
    standard_ref: "",
    extra_fields: [],
  });

  const [
    requirementSectionFormDataErrors,
    setRequirementSectionFormDataErrors,
  ] = useState({
    standard_ref: "",
  });

  useEffect(() => {
    console.log("CALLED");
    setRequirementSectionFormData({
      ...requirementSectionFormData,
      clarifications: singleSectionById?.clarifications
        ? singleSectionById?.clarifications
        : "",
      description: singleSectionById?.description
        ? singleSectionById?.description
        : "",
      standard_ref: singleSectionById?.standard_ref
        ? singleSectionById?.standard_ref
        : "",
      extra_fields: [],
    });
  }, [singleSectionById]);

  const handleRequirementSectionFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRequirementSectionFormData({
      ...requirementSectionFormData,
      [name]: value,
    });
  };

  const handleRequirementSectionFormSubmit = (moveToNextRequirement) => {
    // CreateRequirementSection({
    //   ...requirementSectionFormData,
    //   req_list_id: singleRequirementList?.data?.id,
    // });

    // if (requirementSectionFormData.standard_ref === "") {
    //   setRequirementSectionFormDataErrors({
    //     standard_ref: "Input field is required",
    //   });
    // } else if (isMoveNext && requirementSectionFormData.standard_ref !== "") {
    //   setHorizontalTabs("requirements");
    //   CreateRequirementSection({
    //     ...requirementSectionFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // } else {
    //   setRequirementSectionFormDataErrors({ standard_ref: "" });
    //   CreateRequirementSection({
    //     ...requirementSectionFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // }
    CreateRequirementSection(
      {
        ...requirementSectionFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setRequirementSectionFormDataErrorState,
      moveToNextRequirement
    );

    // setSectionFields([]);
    console.log(requirementSectionFormData);
  };

  const handleRequirementSectionAddMoreFormSubmit = () => {
    CreateRequirementSection(
      {
        ...requirementSectionFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setRequirementSectionFormDataErrorState
    );
    setRequirementSectionFormData({
      clarifications: "",
      description: "",
      standard_ref: "",
    });
    setSectionFields([]);
    console.log(requirementSectionFormData);
    setRequirementSectionFormDataErrorState(null);
  };

  const handleRequirementSectionUpdateFormSubmit = (moveToNextRequirement) => {

    
    console.log(singleSectionById);

    if (singleSectionById) {
      EditRequirementSection(
        {
          ...requirementSectionFormData,
          req_list_id: requirementEditing?.requirementListId,
          section_id: singleSectionById?.id,
        },
        setRequirementSectionFormDataErrorState,
        moveToNextRequirement
      );
    } else {
      toast("Please select section from below list")
    }

  };

  const handleRequirementSectionUpdateAddMoreFormSubmit = () => {
    console.log("sectionid ", singleRequirementById?.id)

    if (singleRequirementById?.id == null) {
      CreateRequirementSection(
        {
          ...requirementSectionFormData,
          req_list_id: singleRequirementList?.data?.id,
        },
        setRequirementSectionFormDataErrorState
      );
    } else {
      EditRequirementSection(
        {
          ...requirementSectionFormData,
          req_list_id: requirementEditing?.requirementListId,
          section_id: singleSectionById?.id,
        },
        setRequirementSectionFormDataErrorState
      );
    }
    setRequirementSectionFormData({
      clarifications: "",
      description: "",
      standard_ref: "",
    });
    setSectionFields([]);
  };

  const moveToNextRequirement = () => {
    setRequirementSectionFormDataErrorState(null);
    setHorizontalTabs("requirements");
  };

  // REQUIREMENTS HANDLERS
  const [
    sectionRequirementsFormDataErrorState,
    setSectionRequirementsFormDataErrorState,
  ] = useState(null);
  const [sectionRequirementsFormData, setSectionRequirementsFormData] =
    useState({
      req_section_id: null,
      standard_ref: "",
      criticality: "",
      evidence: "",
      evidence_details: "",
      question_type: "",
      yes_complaint: "",
      no_complaint: "",
      references: "",
      weight: "1",
      description: "",
      clarification: "",
      extra_fields: [],
    });

  const [
    sectionRequirementsFormDataErrors,
    setSectionRequirementsFormDataErrors,
  ] = useState({
    standard_ref: "",
  });

  useEffect(() => {
    console.log("REQUIREMENT CALLED");
    setSectionRequirementsFormData({
      ...sectionRequirementsFormData,
      req_section_id: singleRequirementById?.req_section_id
        ? singleRequirementById?.req_section_id
        : "",
      standard_ref: singleRequirementById?.standard_ref
        ? singleRequirementById?.standard_ref
        : "",
      criticality: singleRequirementById?.criticality
        ? singleRequirementById?.criticality
        : "",
      evidence: singleRequirementById?.evidence
        ? singleRequirementById?.evidence
        : "",
      evidence_details: singleRequirementById?.evidence_details
        ? singleRequirementById?.evidence_details
        : "",
      question_type: singleRequirementById?.question_type
        ? singleRequirementById?.question_type
        : "",
      yes_complaint: singleRequirementById?.yes_complaint
        ? singleRequirementById?.yes_complaint
        : "",
      no_complaint: singleRequirementById?.no_complaint
        ? singleRequirementById?.no_complaint
        : "",
      references: singleRequirementById?.references
        ? singleRequirementById?.references
        : "",
      weight: singleRequirementById?.weight
        ? singleRequirementById?.weight
        : "",
      description: singleRequirementById?.description
        ? singleRequirementById?.description
        : "",
      clarification: singleRequirementById?.clarification
        ? singleRequirementById?.clarification
        : "",
      extra_fields: [],
    });
  }, [singleRequirementById]);

  const handleSectionRequirementsFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSectionRequirementsFormData({
      ...sectionRequirementsFormData,
      [name]: value,
    });
  };

  const handleSectionRequirementsFormSubmit = (moveToNextSubRequirement) => {

    console.log("itss me")
    // CreateSectionRequirement({
    //   ...sectionRequirementsFormData,
    //   req_list_id: singleRequirementList?.data?.id,
    // });
    // if (sectionRequirementsFormData.standard_ref === "") {
    //   setSectionRequirementsFormDataErrors({
    //     standard_ref: "Input field is required",
    //   });
    // } else if (isMoveNext && sectionRequirementsFormData.standard_ref !== "") {
    //   setHorizontalTabs("subrequirements");
    //   CreateSectionRequirement({
    //     ...sectionRequirementsFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // } else {
    //   setSectionRequirementsFormDataErrors({ standard_ref: "" });
    //   CreateSectionRequirement({
    //     ...sectionRequirementsFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // }

    console.log("hiii, ", sectionRequirementsFormData)
    CreateSectionRequirement(
      {
        ...sectionRequirementsFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setSectionRequirementsFormDataErrorState,
      moveToNextSubRequirement
    );
    console.log(sectionRequirementsFormData);
  };

  const handleSectionRequirementsAddMoreFormSubmit = () => {
    CreateSectionRequirement(
      {
        ...sectionRequirementsFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setSectionRequirementsFormDataErrorState
    );
    setSectionRequirementsFormData({
      req_section_id: "",
      standard_ref: "",
      criticality: "",
      evidence: "",
      evidence_details: "",
      question_type: "",
      yes_complaint: "",
      no_complaint: "",
      references: "",
      weight: "1",
      description: "",
      clarification: "",
    });
    setRequirementFields([]);
    setSectionRequirementsFormDataErrorState(null);
    console.log(sectionRequirementsFormData);
  };

  const handleSectionRequirementsUpdateFormSubmit = (
    moveToNextSubRequirement
  ) => {
    console.log("iamhere", singleRequirementById);
    if (singleRequirementById) {
      EditSectionRequirement(
        {
          ...sectionRequirementsFormData,
          requirement_id: singleRequirementById?.id,
          req_list_id: singleRequirementById?.req_list_id,
          req_section_id: singleRequirementById?.req_section_id,
        },
        setSectionRequirementsFormDataErrorState,
        moveToNextSubRequirement
      );
    }
    else {
      toast("Please select requirement from below list")
    }


  };

  const handleSectionRequirementsUpdateAddMoreFormSubmit = () => {


    if (singleRequirementById?.id == null) {


      CreateSectionRequirement(
        {
          ...sectionRequirementsFormData,
          req_list_id: singleRequirementList?.data?.id,
        },
        setSectionRequirementsFormDataErrorState
      );

    } else {

      EditSectionRequirement(
        {
          ...sectionRequirementsFormData,
          requirement_id: singleRequirementById?.id,
          req_list_id: singleRequirementById?.req_list_id,
          req_section_id: singleRequirementById?.req_section_id,
        },
        setSectionRequirementsFormDataErrorState
      );

    }



    setSectionRequirementsFormData({
      req_section_id: "",
      standard_ref: "",
      criticality: "",
      evidence: "",
      evidence_details: "",
      question_type: "",
      yes_complaint: "",
      no_complaint: "",
      references: "",
      weight: "1",
      description: "",
      clarification: "",
    });
    setRequirementFields([]);
    setSectionRequirementsFormDataErrorState(null);
    console.log(sectionRequirementsFormData);
  };

  const moveToNextSubRequirement = () => {
    setSectionRequirementsFormDataErrorState(null);
    setHorizontalTabs("subrequirements");
  };

  // SUB REQUIREMENTS HANDLERS
  const [
    subRequirementsFormDataErrorState,
    setSubRequirementsFormDataErrorState,
  ] = useState(null);
  const [subRequirementsFormData, setSubRequirementsFormData] = useState({
    requirement_id: null,
    section_id: null,
    standard_ref: "",
    criticality: "",
    evidence: "",
    evidence_details: "",
    question_type: "",
    yes_complaint: "",
    no_complaint: "",
    references: "",
    weight: "1",
    description: "",
    clarification: "",
    extra_fields: [],
  });

  const [subRequirementsFormDataErrors, setSubRequirementsFormDataErrors] =
    useState({
      standard_ref: "",
    });

  useEffect(() => {
    console.log("SUB REQUIREMENT CALLED");
    setSubRequirementsFormData({
      ...subRequirementsFormData,
      requirement_id: getSubRequirementById?.data?.requirement_id
        ? getSubRequirementById?.data?.requirement_id
        : "",
      section_id: getSubRequirementById?.data?.section_id
        ? getSubRequirementById?.data?.section_id
        : "",
      standard_ref: getSubRequirementById?.data?.standard_ref
        ? getSubRequirementById?.data?.standard_ref
        : "",
      criticality: getSubRequirementById?.data?.criticality
        ? getSubRequirementById?.data?.criticality
        : "",
      evidence: getSubRequirementById?.data?.evidence
        ? getSubRequirementById?.data?.evidence
        : "",
      evidence_details: getSubRequirementById?.data?.evidence_details
        ? getSubRequirementById?.data?.evidence_details
        : "",
      question_type: getSubRequirementById?.data?.question_type
        ? getSubRequirementById?.data?.question_type
        : "",
      yes_complaint: getSubRequirementById?.data?.yes_complaint
        ? getSubRequirementById?.data?.yes_complaint
        : "",
      no_complaint: getSubRequirementById?.data?.no_complaint
        ? getSubRequirementById?.data?.no_complaint
        : "",
      references: getSubRequirementById?.data?.references
        ? getSubRequirementById?.data?.references
        : "",
      weight: getSubRequirementById?.data?.weight
        ? getSubRequirementById?.data?.weight
        : "",
      description: getSubRequirementById?.data?.description
        ? getSubRequirementById?.data?.description
        : "",
      clarification: getSubRequirementById?.data?.clarification
        ? getSubRequirementById?.data?.clarification
        : "",
    });
  }, [getSubRequirementById]);

  console.log(getSubRequirementById);

  // TODO: older version subRequirementsFormData.section_id !== null
  useEffect(() => {
    subRequirementsFormData.section_id &&
      GetRequirementsBySectionId(subRequirementsFormData.section_id);
  }, [subRequirementsFormData.section_id]);

  // TODO: older version subRequirementsFormData.requirement_id !== null
  useEffect(() => {
    subRequirementsFormData.requirement_id &&
      GetSubRequirementsByRequirementId(subRequirementsFormData.requirement_id);
  }, [subRequirementsFormData.requirement_id]);

  const handleSubRequirementsFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSubRequirementsFormData({
      ...subRequirementsFormData,
      [name]: value,
    });
  };

  const handleSubRequirementsFormSubmit = (isMoveNext) => {
    // CreateSubRequirement({
    //   ...subRequirementsFormData,
    //   req_list_id: singleRequirementList?.data?.id,
    // });
    // if (subRequirementsFormData.standard_ref === "") {
    //   setSubRequirementsFormDataErrors({
    //     standard_ref: "Input field is required",
    //   });
    // } else if (isMoveNext && subRequirementsFormData.standard_ref !== "") {
    //   setHorizontalTabs("subrequirements");
    //   // FIXME: UNCOMMENT THIS
    //   CreateSubRequirement({
    //     ...subRequirementsFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // } else {
    //   setSubRequirementsFormDataErrors({ standard_ref: "" });
    //   CreateSubRequirement({
    //     ...subRequirementsFormData,
    //     req_list_id: singleRequirementList?.data?.id,
    //   });
    // }
    CreateSubRequirement(
      {
        ...subRequirementsFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setSubRequirementsFormDataErrorState
    );

    console.log(subRequirementsFormData);
  };

  const handleSubRequirementsAddMoreFormSubmit = () => {
    CreateSubRequirement(
      {
        ...subRequirementsFormData,
        req_list_id: singleRequirementList?.data?.id,
      },
      setSubRequirementsFormDataErrorState
    );

    setSubRequirementsFormData({
      requirement_id: "",
      section_id: "",
      standard_ref: "",
      criticality: "",
      evidence: "",
      evidence_details: "",
      question_type: "",
      yes_complaint: "",
      no_complaint: "",
      references: "",
      weight: "1",
      description: "",
      clarification: "",
    });
    setSubRequirementFields([]);
    setSubRequirementsFormDataErrorState(null);
    console.log(subRequirementsFormData);
  };

  const handleSubRequirementsUpdateFormSubmit = () => {
    EditSubRequirement(
      {
        ...subRequirementsFormData,
        sub_requirement_id: getSubRequirementById?.data?.id,
        req_list_id: getSubRequirementById?.data?.req_list_id,
      },
      setSubRequirementsFormDataErrorState
    );
    console.log(subRequirementsFormData);
  };

  const handleSubRequirementsUpdateAddMoreFormSubmit = () => {
    EditSubRequirement(
      {
        ...subRequirementsFormData,
        sub_requirement_id: getSubRequirementById?.data?.id,
        req_list_id: getSubRequirementById?.data?.req_list_id,
      },
      setSubRequirementsFormDataErrorState
    );

    setSubRequirementsFormData({
      requirement_id: "",
      section_id: "",
      standard_ref: "",
      criticality: "",
      evidence: "",
      evidence_details: "",
      question_type: "",
      yes_complaint: "",
      no_complaint: "",
      references: "",
      weight: "1",
      description: "",
      clarification: "",
    });
    setSubRequirementFields([]);
    console.log(subRequirementsFormData);
    setSubRequirementsFormDataErrorState(null);
  };
  useEffect(() => {
    GetAllReferences();
  }, []);

  useEffect(() => {
    requirementEditing?.isEdit &&
      (GetRequirementsByRequirementListId(
        requirementEditing?.requirementListId
      ),
        GetSubRequirementsByRequirementListId(
          requirementEditing?.requirementListId
        ));
  }, [requirementEditing?.requirementListId]);

  const [isAddSections, setIsAddSections] = useState(false);
  const [isAddRequirements, setIsAddRequirements] = useState(false);
  const [isAddSubRequirements, setIsAddSubRequirements] = useState(false);

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="text-left">
                  {isRequirementListEdit
                    ? "Edit Requirement List"
                    : "Create New Requirement List"}
                </h5>
              </CardHeader>
              <Form onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            disabled={
                              !isRequirementListEdit &&
                              (isAddRequirementList
                                ? false
                                : !isAddRequirementList
                                  ? false
                                  : true)
                            }
                            aria-expanded={
                              horizontalTabs === "requirement-list"
                            }
                            data-toggle="tab"
                            role="tab"
                            className={
                              horizontalTabs === "requirement-list"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              setHorizontalTabs("requirement-list")
                            }
                          >
                            Requirement List
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            disabled={
                              !isRequirementListEdit &&
                              (!isAddRequirementList && !isAddSection
                                ? true
                                : isAddRequirementList && !isAddSection
                                  ? false
                                  : false)
                            }
                            aria-expanded={horizontalTabs === "section"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              horizontalTabs === "section" ? "active" : ""
                            }
                            onClick={() => {
                              setHorizontalTabs("section");
                              singleRequirementList?.data?.sections.length ===
                                0 && setIsAddSections(true);
                            }}
                          >
                            Section
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            disabled={
                              !isRequirementListEdit &&
                              (!isAddRequirementList &&
                                !isAddSection &&
                                !isAddRequirement
                                ? true
                                : isAddRequirementList &&
                                  isAddSection &&
                                  !isAddRequirement
                                  ? false
                                  : isAddRequirementList &&
                                    isAddSection &&
                                    isAddRequirement
                                    ? false
                                    : true)
                            }
                            aria-expanded={horizontalTabs === "requirements"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              horizontalTabs === "requirements" ? "active" : ""
                            }
                            onClick={() => {
                              setHorizontalTabs("requirements");
                              allRequirementsByRequirementListId?.length ===
                                0 && setIsAddRequirements(true);
                            }}
                          >
                            Requirements
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            disabled={
                              !isRequirementListEdit &&
                              (!isAddRequirementList &&
                                !isAddSection &&
                                !isAddRequirement &&
                                !isAddSubRequirement
                                ? true
                                : isAddRequirementList &&
                                  isAddSection &&
                                  isAddRequirement &&
                                  !isAddSubRequirement
                                  ? false
                                  : true)
                            }
                            aria-expanded={horizontalTabs === "subrequirements"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              horizontalTabs === "subrequirements"
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              setHorizontalTabs("subrequirements");
                              allSubRequirementsByRequirementListId?.length ===
                                0 && setIsAddSubRequirements(true);
                            }}
                          >
                            SubRequirements
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            aria-expanded={horizontalTabs === "mapping"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              horizontalTabs === "mapping" ? "active" : ""
                            }
                            onClick={() => setHorizontalTabs("mapping")}
                          >
                            Requirement Mapping
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={horizontalTabs}
                  >
                    <TabPane tabId="requirement-list" role="tabpanel">
                      {/* <div className="text-left">
                                                <h6>Add New Requirment</h6>
                                            </div> */}
                      {/* <AddRequirementListForm /> */}
                      <Row>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>
                              Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              invalid={
                                requirementListFormDataErrorState &&
                                  requirementListFormDataErrorState?.name
                                  ? true
                                  : false
                              }
                              type="text"
                              name="name"
                              value={requirementListFormData.name}
                              onChange={handleRequirementListFormData}
                            />
                            <FormFeedback>
                              {requirementListFormDataErrorState &&
                                requirementListFormDataErrorState?.name}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Location</Label>
                            <Input
                              type="text"
                              name="location"
                              value={requirementListFormData.location}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Authority</Label>
                            <Input
                              type="text"
                              name="authority"
                              value={requirementListFormData.authority}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Industry</Label>
                            <Input
                              type="text"
                              name="industry"
                              value={requirementListFormData.industry}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Information</Label>
                            <Input
                              type="text"
                              name="information"
                              value={requirementListFormData.information}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Status</Label>
                            <select
                              name="status"
                              id=""
                              className="form-control"
                              value={requirementListFormData.status}
                              onChange={handleRequirementListFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="1">Active</option>
                              <option value="0">InActive</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Effective Date</Label>
                            <Input
                              type="date"
                              name="effective_date"
                              value={requirementListFormData.effective_date}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Attachment</Label>
                            <Input
                              type="file"
                              name="file"
                              accept=".xls,.xlsx,.pdf"
                              //   value={requirementListFormData.file.name}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Description</Label>
                            <Input
                              type="textarea"
                              name="description"
                              value={requirementListFormData.description}
                              onChange={handleRequirementListFormData}
                            />
                          </FormGroup>
                        </Col>
                        {requirementListFields.length !== 0
                          ? requirementListFields.map((field) => (
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>{field.field_name}</Label>
                                <Input
                                  type="text"
                                  defaultValue={field.field_value}
                                />
                              </FormGroup>
                            </Col>
                          ))
                          : null}

                        {/* {requirementEditing?.isEdit
                          ? singleRequirementList?.data?.extra_fields.map(
                              (field) => (
                                <Col md="6">
                                  <FormGroup className="text-left">
                                    <Label>{field.field_name}</Label>
                                    <Input
                                      type="text"
                                      defaultValue={field.field_value}
                                    />
                                  </FormGroup>
                                </Col>
                              )
                            )
                          : null} */}
                        <Col md="12">
                          <div className="text-left">
                            <Link
                              class="btn btn-sm btn-info"
                              onClick={() => {
                                setAddFieldId(1);
                                // setfields([]);
                                toggleModalClassic();
                              }}
                            >
                              Add Field
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {!requirementEditing?.isEdit ? (
                        <div className="text-right">
                          {/* <Button color="success">Save & Add More</Button> */}
                          <Button
                            color="info"
                            onClick={() => {
                             
                              handleRequirementListFormSubmit(moveToNext);
                              ChangeIsAddRequirementList(true);
                               setHorizontalTabs("section");
                              //  GetSingleRequirementList(
                              //   row.original.id,
                              //   history
                              // );
                             ChangeIsRequirementListEdit(false);
                              // ChangeRequirementEditing({
                              //   isEdit: true,
                              //   requirementListId: row.original.id,
                              // });


                               

                            }}
                          >
          jkl      Save & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementListFormSubmit();
                              ChangeIsAddRequirementList(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              // setHorizontalTabs("requirement-list")
                              ChangeClearRequirementData();
                              clearInputFieldsAfterSubmission();
                              history.push(
                                "/user/requirements/requirements-List"
                              );
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="text-right">
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementListUpdateFormSubmit(moveToNext);
                              ChangeIsAddRequirementList(true);
                              // setHorizontalTabs("section");
                            }}
                          >
                    Update & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementListUpdateFormSubmit();
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              history.push(
                                "/user/requirements/create-requirement"
                              );
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}
                    </TabPane>

                    <TabPane tabId="section" role="tabpanel">
                      {/* <div className="text-left">
                                                <h6>Add New Section</h6>
                                            </div> */}
                      <Row>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Requirement List</Label>
                            <Input
                              type="text"
                              readOnly
                              value={
                                singleRequirementList?.data?.name
                                  ? singleRequirementList?.data?.name
                                  : ""
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>
                              Standard Ref{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              invalid={
                                requirementSectionFormDataErrorState &&
                                  requirementSectionFormDataErrorState?.standard_ref
                                  ? true
                                  : false
                              }
                              type="text"
                              name="standard_ref"
                              value={requirementSectionFormData.standard_ref}
                              onChange={handleRequirementSectionFormData}
                            />
                            <FormFeedback>
                              {requirementSectionFormDataErrorState &&
                                requirementSectionFormDataErrorState?.standard_ref}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Section</Label>
                            <Input
                              invalid={
                                requirementSectionFormDataErrorState &&
                                  requirementSectionFormDataErrorState?.description
                                  ? true
                                  : false
                              }
                              type="text"
                              name="description"
                              value={requirementSectionFormData.description}
                              onChange={handleRequirementSectionFormData}
                            />
                            <FormFeedback>
                              {requirementSectionFormDataErrorState &&
                                requirementSectionFormDataErrorState?.description}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Clarification</Label>
                            <Input
                              type="textarea"
                              name="clarifications"
                              value={requirementSectionFormData.clarifications}
                              onChange={handleRequirementSectionFormData}
                            />
                          </FormGroup>
                        </Col>

                        {sectionFields.length !== 0
                          ? sectionFields.map((field) => (
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>{field.field_name}</Label>
                                <Input
                                  type="text"
                                  defaultValue={field.field_value}
                                />
                              </FormGroup>
                            </Col>
                          ))
                          : null}
                        <Col md="12">
                          <div className="text-left">
                            <Link
                              class="btn btn-sm btn-info"
                              onClick={() => {
                                setAddFieldId(2);
                                // setfields([]);
                                toggleModalClassic();
                              }}
                            >
                              Add Field
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {requirementEditing?.isEdit === false ||
                        isAddSections === true ? (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() => {
                              handleRequirementSectionAddMoreFormSubmit();
                            }}
                          >
                            Save & Add More
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementSectionFormSubmit(
                                moveToNextRequirement
                              );
                              ChangeIsAddSection(true);
                              // setHorizontalTabs("requirements");
                            }}
                          >
                            Save & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementSectionFormSubmit();
                              ChangeIsAddSection(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              clearInputFieldsAfterSubmission();
                              history.push(
                                "/user/requirements/requirements-List"
                              );
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() => {
                              handleRequirementSectionUpdateAddMoreFormSubmit();
                            }}
                          >
                   Update & Add More
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementSectionUpdateFormSubmit(
                                moveToNextRequirement
                              );
                              ChangeIsAddSection(true);
                              // setHorizontalTabs("requirements");
                            }}
                          >
                            Update & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleRequirementSectionUpdateFormSubmit();
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              history.push(
                                "/user/requirements/create-requirement"
                              );
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}

                      {singleRequirementList?.data?.sections.length > 0 && (
                        <div className="my-4">
                          <div className="row align-items-center">
                            <div className="col-md-9 text-left">
                              <div className="form-group">
                <h4>Sections</h4>
                              </div>
                            </div>
                          </div>

                          <ExistingSections
                            data={singleRequirementList?.data?.sections}
                          />
                        </div>
                      )}
                    </TabPane>

                    <TabPane tabId="requirements" role="tabpanel">
                      {/* <div className="text-left">
                                                <h6>Add New Requirement</h6>
                                            </div> */}
                      <Row>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label> Requirement List</Label>
                            <Input
                              type="text"
                              readOnly
                              value={
                                singleRequirementList?.data?.name
                                  ? singleRequirementList?.data?.name
                                  : ""
                              }
                            />

                            {/* <input className="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search..." />
                                                        <datalist id="datalistOptions" >
                                                            <option value="ISO 900" />
                                                            <option value="lorem" />
                                                            <option value="Demo" />
                                                            <option value="Requirement List" />
                                                            <option value="Demo List" />
                                                        </datalist> */}
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Section</Label>
                            {/* <Input type="text" readOnly /> */}
                            <Input
                              invalid={
                                sectionRequirementsFormDataErrorState &&
                                  sectionRequirementsFormDataErrorState?.req_section_id
                                  ? true
                                  : false
                              }
                              type="select"
                              name="req_section_id"
                              id=""
                              className="form-control"
                              value={sectionRequirementsFormData.req_section_id}
                              onChange={handleSectionRequirementsFormData}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              {singleRequirementList?.data?.sections.length >
                                0 &&
                                singleRequirementList?.data?.sections?.map(
                                  (section) => (
                                    <option value={section.id}>
                                      {section.standard_ref}
                                    </option>
                                  )
                                )}
                            </Input>

                            <FormFeedback>
                              {sectionRequirementsFormDataErrorState &&
                                sectionRequirementsFormDataErrorState?.req_section_id}
                            </FormFeedback>

                            {/* <input
                              className="form-control"
                              list="datalistOptions"
                              id="exampleDataList"
                              placeholder="Type to search..."
                            />
                            <datalist id="datalistOptions">
                              <option value="ISO 900" />
                              <option value="lorem" />
                              <option value="Demo" />
                              <option value="Requirement List" />
                              <option value="Demo List" />
                              {singleRequirementList?.data?.sections.length >
                                0 &&
                                singleRequirementList?.data?.sections?.map(
                                  (section) => (
                                    <option value={section.standard_ref} />
                                  )
                                )}
                            </datalist> */}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>
                              Standard Ref{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              invalid={
                                sectionRequirementsFormDataErrorState &&
                                  sectionRequirementsFormDataErrorState?.standard_ref
                                  ? true
                                  : false
                              }
                              type="text"
                              name="standard_ref"
                              value={sectionRequirementsFormData.standard_ref}
                              onChange={handleSectionRequirementsFormData}
                            />
                            <FormFeedback>
                              {sectionRequirementsFormDataErrorState &&
                                sectionRequirementsFormDataErrorState?.standard_ref}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Requirement</Label>
                            <Input
                              invalid={
                                sectionRequirementsFormDataErrorState &&
                                  sectionRequirementsFormDataErrorState?.description
                                  ? true
                                  : false
                              }
                              type="text"
                              name="description"
                              value={sectionRequirementsFormData.description}
                              onChange={handleSectionRequirementsFormData}
                            />

                            <FormFeedback>
                              {sectionRequirementsFormDataErrorState &&
                                sectionRequirementsFormDataErrorState?.description}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Criticality</Label>
                            <select
                              id=""
                              className="form-control"
                              name="criticality"
                              value={sectionRequirementsFormData.criticality}
                              onChange={handleSectionRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Evidence </Label>
                            <Input
                              invalid={
                                sectionRequirementsFormDataErrorState &&
                                  sectionRequirementsFormDataErrorState?.evidence
                                  ? true
                                  : false
                              }
                              type="select"
                              id=""
                              className="form-control"
                              name="evidence"
                              value={sectionRequirementsFormData.evidence}
                              onChange={handleSectionRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Input>

                            <FormFeedback>
                              {sectionRequirementsFormDataErrorState &&
                                sectionRequirementsFormDataErrorState?.evidence}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        {sectionRequirementsFormData.evidence === "Yes" && (
                          <Col md="6">
                            <FormGroup className="text-left">
                              <Label>Evidence Description</Label>
                              <Input
                                type="text"
                                name="evidence_details"
                                value={
                                  sectionRequirementsFormData.evidence_details
                                }
                                onChange={handleSectionRequirementsFormData}
                              />
                            </FormGroup>
                          </Col>
                        )}

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>  Question Type</Label>
                            <select
                              name="question_type"
                              id=""
                              className="form-control"
                              value={sectionRequirementsFormData.question_type}
                              onChange={(e) => {
                                setQuestionType(e.target.value);
                                handleSectionRequirementsFormData(e);
                              }}
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="Yes/No">Yes / No</option>
                              <option value="Yes/No/Partial">
                                Yes / No / Partial
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        {/* {questionType === "0" ? (
                          <> */}
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Yes</Label>
                            <select
                              name="yes_complaint"
                              id=""
                              className="form-control"
                              value={sectionRequirementsFormData.yes_complaint}
                              onChange={handleSectionRequirementsFormData}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              <option value="Compliant">Compliant</option>
                              <option value="Not Compliant">
                                Not Compliant
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        {/* <Col md="6">
                              <FormGroup className="text-left">
                                <Label>No</Label>
                                <select
                                  name="no_complaint"
                                  id=""
                                  className="form-control"
                                  value={
                                    sectionRequirementsFormData.no_complaint
                                  }
                                  onChange={handleSectionRequirementsFormData}
                                >
                                  <option value="" selected disabled>
                                    Select
                                  </option>
                                  <option value="Compliant">Compliant</option>
                                  <option value="Not Compliant">
                                    Not Compliant
                                  </option>
                                </select>
                              </FormGroup>
                            </Col> */}
                        {/* </>
                        ) : questionType === "1" ? (
                          <>
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>Yes</Label>
                                <select
                                  name="yes_complaint"
                                  id=""
                                  className="form-control"
                                  value={
                                    sectionRequirementsFormData.yes_complaint
                                  }
                                  onChange={handleSectionRequirementsFormData}
                                >
                                  <option value="" selected disabled>
                                    Select
                                  </option>
                                  <option value="Compliant">Compliant</option>
                                  <option value="Not Compliant">
                                    Not Compliant
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>No</Label>
                                <select
                                  name="no_complaint"
                                  id=""
                                  className="form-control"
                                  value={
                                    sectionRequirementsFormData.no_complaint
                                  }
                                  onChange={handleSectionRequirementsFormData}
                                >
                                  <option value="" selected disabled>
                                    Select
                                  </option>
                                  <option value="Compliant">Compliant</option>
                                  <option value="Not Compliant">
                                    Not Compliant
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>Partial</Label>
                                <Input readOnly defaultValue="Compliant" />
                              </FormGroup>
                            </Col>
                          </>
                        ) : null} */}
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>References</Label>
                            <select
                              id=""
                              className="form-control"
                              name="references"
                              value={sectionRequirementsFormData.references}
                              onChange={handleSectionRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {allReferences?.map((reference) => (
                                <option value={reference?.id}>
                                  {reference?.name}
                                </option>
                              ))}
                              {/* <option value="Yes">Yes</option>
                              <option value="No">No</option> */}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Weight</Label>
                            <Input
                              type="number"
                              name="weight"
                              value={sectionRequirementsFormData.weight}
                              onChange={handleSectionRequirementsFormData}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Clarification</Label>
                            <Input
                              type="textarea"
                              name="clarification"
                              value={sectionRequirementsFormData.clarification}
                              onChange={handleSectionRequirementsFormData}
                            />
                          </FormGroup>
                        </Col>
                        {requirementFields.length !== 0
                          ? requirementFields.map((field) => (
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>{field.field_name}</Label>
                                <Input
                                  type="text"
                                  defaultValue={field.field_value}
                                />
                              </FormGroup>
                            </Col>
                          ))
                          : null}
                        <Col md="12">
                          <div className="text-left">
                            <Link
                              class="btn btn-sm btn-info"
                              onClick={() => {
                                setAddFieldId(3);
                                // setfields([]);
                                toggleModalClassic();
                              }}
                            >
                              Add Field
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {requirementEditing?.isEdit === false ||
                        isAddRequirements === true ? (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() => {
                              handleSectionRequirementsAddMoreFormSubmit();
                            }}
                          >
                            Save & Add More
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleSectionRequirementsFormSubmit(
                                moveToNextSubRequirement
                              );
                              ChangeIsAddRequirement(true);
                              // setHorizontalTabs("subrequirements");
                            }}
                          >
                            Save & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleSectionRequirementsFormSubmit();
                              ChangeIsAddRequirement(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              clearInputFieldsAfterSubmission();
                              history.push(
                                "/user/requirements/requirements-List"
                              );
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() => {
                              handleSectionRequirementsUpdateAddMoreFormSubmit();
                            }}
                          >
                            Update & Add More
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleSectionRequirementsUpdateFormSubmit(
                                moveToNextSubRequirement
                              );
                              ChangeIsAddRequirement(true);
                              // setHorizontalTabs("subrequirements");
                            }}
                          >
                            Update & Next
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              handleSectionRequirementsUpdateFormSubmit();
                              ChangeIsAddRequirement(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              history.push(
                                "/user/requirements/create-requirement"
                              );
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}

                      {/* {singleRequirementList?.data?.sections?.requirements
                        ?.length > 0 && (
                        <div className="my-4">
                          <div className="row align-items-center">
                            <div className="col-md-9 text-left">
                              <div className="form-group">
                                <h4>Requirements</h4>
                              </div>
                            </div>
                          </div>
                          <ExistingSectionRequirements
                            data={
                              singleRequirementList?.data?.sections
                                ?.requirements
                            }
                          />
                        </div>
                      )} */}
                      {
                        // singleRequirementList?.data?.sections
                        //   .map((section) => section.requirements.length > 0)
                        //   .some((value) => value === true) && (
                        requirementsBySectionId?.length > 0 &&
                        requirementEditing?.isEdit === false && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Requirements</h4>
                                </div>
                              </div>
                            </div>
                            <ExistingSectionRequirements
                              data={requirementsBySectionId}
                            />
                          </div>
                        )
                        // )
                      }

                      {requirementsBySectionId?.length > 0 &&
                        requirementEditing?.isEdit === true && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Requirements</h4>
                                </div>
                              </div>
                            </div>
                            <ExistingSectionRequirements
                              data={requirementsBySectionId}
                            />
                          </div>
                        )}

                      {allRequirementsByRequirementListId?.length > 0 &&
                        requirementEditing.isEdit && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Requirements</h4>
                                </div>
                              </div>
                            </div>
                            <ExistingSectionRequirements
                              data={allRequirementsByRequirementListId}
                            />
                          </div>
                        )}
                      <RequirementMappingTable />
                    </TabPane>
                    <TabPane tabId="subrequirements" role="tabpanel">
                      {/* <div className="text-left">
                                                <h6>Add New Requirement</h6>
                                            </div> */}
                      <Row>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label> Requirement List</Label>
                            <Input
                              type="text"
                              readOnly
                              value={
                                singleRequirementList?.data?.name
                                  ? singleRequirementList?.data?.name
                                  : ""
                              }
                            />

                            {/* <input className="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search..." />
                                                        <datalist id="datalistOptions" >
                                                            <option value="ISO 900" />
                                                            <option value="lorem" />
                                                            <option value="Demo" />
                                                            <option value="Requirement List" />
                                                            <option value="Demo List" />
                                                        </datalist> */}
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Section</Label>
                            <Input
                              invalid={
                                subRequirementsFormDataErrorState &&
                                  subRequirementsFormDataErrorState?.section_id
                                  ? true
                                  : false
                              }
                              type="select"
                              name="section_id"
                              id=""
                              className="form-control"
                              value={subRequirementsFormData.section_id}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              {singleRequirementList?.data?.sections.length >
                                0 &&
                                singleRequirementList?.data?.sections.map(
                                  (section) => (
                                    <option value={section.id}>
                                      {section.standard_ref}
                                    </option>
                                  )
                                )}
                            </Input>
                            <FormFeedback>
                              {subRequirementsFormDataErrorState &&
                                subRequirementsFormDataErrorState?.section_id}
                            </FormFeedback>
                            {/* <Input
                              type="text"
                              readOnly
                              value={
                                requirementListFormData.name
                                  ? requirementListFormData.name
                                  : ""
                              }
                            /> */}
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Requirement</Label>
                            {/* <Input type="text" readOnly /> */}

                            <Input
                              invalid={
                                subRequirementsFormDataErrorState &&
                                  subRequirementsFormDataErrorState?.requirement_id
                                  ? true
                                  : false
                              }
                              type="select"
                              name="requirement_id"
                              id=""
                              className="form-control"
                              value={subRequirementsFormData.requirement_id}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              {requirementsBySectionId !== null &&
                                requirementsBySectionId.length > 0 &&
                                requirementsBySectionId?.map((requirement) => (
                                  <option value={requirement.id}>
                                    {requirement.standard_ref}
                                  </option>
                                ))}
                            </Input>

                            <FormFeedback>
                              {subRequirementsFormDataErrorState &&
                                subRequirementsFormDataErrorState?.requirement_id}
                            </FormFeedback>
                            {/* <input
                              className="form-control"
                              list="datalistOptions"
                              id="exampleDataList"
                              placeholder="Type to search..."
                            />
                            <datalist id="datalistOptions">
                              <option value="ISO 900" />
                              <option value="lorem" />
                              <option value="Demo" />
                              <option value="Requirement List" />
                              <option value="Demo List" />
                            </datalist> */}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>
                              Standard Ref{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              invalid={
                                subRequirementsFormDataErrorState &&
                                  subRequirementsFormDataErrorState?.standard_ref
                                  ? true
                                  : false
                              }
                              type="text"
                              name="standard_ref"
                              value={subRequirementsFormData.standard_ref}
                              onChange={handleSubRequirementsFormData}
                            />
                            <FormFeedback>
                              {subRequirementsFormDataErrorState &&
                                subRequirementsFormDataErrorState?.standard_ref}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Criticality</Label>
                            <select
                              id=""
                              className="form-control"
                              name="criticality"
                              value={subRequirementsFormData.criticality}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Evidence</Label>
                            <select
                              invalid={
                                subRequirementsFormDataErrorState &&
                                  subRequirementsFormDataErrorState?.evidence
                                  ? true
                                  : false
                              }
                              id=""
                              className="form-control"
                              name="evidence"
                              value={subRequirementsFormData.evidence}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <FormFeedback>
                              {subRequirementsFormDataErrorState &&
                                subRequirementsFormDataErrorState?.evidence}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        {subRequirementsFormData.evidence === "Yes" && (
                          <Col md="6">
                            <FormGroup className="text-left">
                              <Label>Evidence Detail</Label>
                              <Input
                                type="text"
                                name="evidence_details"
                                value={subRequirementsFormData.evidence_details}
                                onChange={handleSubRequirementsFormData}
                              />
                            </FormGroup>
                          </Col>
                        )}

                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Question Type</Label>
                            <select
                              id=""
                              className="form-control"
                              onChange={(e) => {
                                setQuestionType(e.target.value);
                                handleSubRequirementsFormData(e);
                              }}
                              name="question_type"
                              value={subRequirementsFormData.question_type}
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="Yes/No">Yes / No</option>
                              <option value="Yes/No/Partial">
                                Yes / No / Partial
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        {/* {questionType === "0" ? (
                          <> */}
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Yes</Label>
                            <select
                              id=""
                              className="form-control"
                              name="yes_complaint"
                              value={subRequirementsFormData.yes_complaint}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              <option value="Compliant">Compliant</option>
                              <option value="Not Compliant">
                                Not Compliant
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                   
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>References</Label>
                            <select
                              id=""
                              className="form-control"
                              name="references"
                              value={subRequirementsFormData.references}
                              onChange={handleSubRequirementsFormData}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {allReferences?.map((reference) => (
                                <option value={reference?.id}>
                                  {reference?.name}
                                </option>
                              ))}
                              {/* <option value="Yes">Yes</option>
                              <option value="No">No</option> */}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="text-left">
                            <Label>Weight</Label>
                            <Input
                              type="number"
                              name="weight"
                              value={subRequirementsFormData.weight}
                              onChange={handleSubRequirementsFormData}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Description </Label>
                            <Input
                              invalid={
                                subRequirementsFormDataErrorState &&
                                  subRequirementsFormDataErrorState?.description
                                  ? true
                                  : false
                              }
                              type="textarea"
                              name="description"
                              value={subRequirementsFormData.description}
                              onChange={handleSubRequirementsFormData}
                            />
                            <FormFeedback>
                              {subRequirementsFormDataErrorState &&
                                subRequirementsFormDataErrorState?.description}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="text-left">
                            <Label>Clarification</Label>
                            <Input
                              type="textarea"
                              name="clarification"
                              value={subRequirementsFormData.clarification}
                              onChange={handleSubRequirementsFormData}
                            />
                          </FormGroup>
                        </Col>
                        {subRequirementFields.length !== 0
                          ? subRequirementFields.map((field) => (
                            <Col md="6">
                              <FormGroup className="text-left">
                                <Label>{field.field_name}</Label>
                                <Input
                                  type="text"
                                  defaultValue={field.field_value}
                                />
                              </FormGroup>
                            </Col>
                          ))
                          : null}
                        <Col md="12">
                          <div className="text-left">
                            <Link
                              class="btn btn-sm btn-info"
                              onClick={() => {
                                setAddFieldId(4);
                                // setfields([]);
                                toggleModalClassic();
                              }}
                            >
                              Add Field
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {requirementEditing?.isEdit === false ||
                        isAddSubRequirements === true ? (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() =>
                              handleSubRequirementsAddMoreFormSubmit()
                            }
                          >
                            Save & Add More
                          </Button>
                          <Button
                            color="info"
                            // onClick={() => setHorizontalTabs("mapping")}
                            onClick={() => {
                              handleSubRequirementsFormSubmit();
                              ChangeIsAddSubRequirement(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              clearInputFieldsAfterSubmission();
                              history.push(
                                "/user/requirements/requirements-List"
                              );
                              // setHorizontalTabs("mapping");
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="text-right">
                          <Button
                            color="success"
                            onClick={() => {
                              // handleSubRequirementsAddMoreFormSubmit()
                              handleSubRequirementsUpdateAddMoreFormSubmit();
                            }}
                          >
                        Update & Add More
                          </Button>
                          <Button
                            color="info"
                            // onClick={() => setHorizontalTabs("mapping")}
                            onClick={() => {
                              // handleSubRequirementsFormSubmit();
                              handleSubRequirementsUpdateFormSubmit();
                              ChangeIsAddSubRequirement(true);
                              ChangeRequirementEditing({
                                isEdit: false,
                                requirementListId: null,
                              });
                              ChangeClearRequirementData();
                              history.push(
                                "/user/requirements/create-requirement"
                              );
                              // setHorizontalTabs("mapping");
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}

                      {subRequirementsByRequirementId?.length > 0 &&
                        requirementEditing?.isEdit === false && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Sub Requirements</h4>
                                </div>
                              </div>
                            </div>

                            <ExistingSubrequirements
                              data={subRequirementsByRequirementId}
                            />
                          </div>
                        )}

                      {subRequirementsByRequirementId?.length > 0 &&
                        requirementEditing?.isEdit === true && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Sub Requirements</h4>
                                </div>
                              </div>
                            </div>

                            <ExistingSubrequirements
                              data={subRequirementsByRequirementId}
                            />
                          </div>
                        )}

                      {allSubRequirementsByRequirementListId?.length > 0 &&
                        requirementEditing.isEdit && (
                          <div className="my-4">
                            <div className="row align-items-center">
                              <div className="col-md-9 text-left">
                                <div className="form-group">
                                  <h4>Sub Requirements</h4>
                                </div>
                              </div>
                            </div>

                            <ExistingSubrequirements
                              data={allSubRequirementsByRequirementListId}
                            />
                          </div>
                        )}
                      {/* <RequirementMappingTable /> */}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>

        {/* Add Field Modal */}

        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalClassic}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">Add Field</h4>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <Col md="6">
                  <Label>Field Name</Label>
                  <FormGroup>
                    <Input
                      name="field_name"
                      value={field_name}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <Label>Value</Label>
                  <FormGroup>
                    <Input
                      name="field_value"
                      value={field_value}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Link
                  className="btn btn-info "
                  onClick={(e) => onFieldAddSubmit(e)}
                >
                  Submit
                </Link>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ requirment }) => ({
  requirment,
});
export default connect(mapStateToProps, {
  CreateRequirementList,
  GetAllRequirementList,
  GetSingleRequirementList,
  CreateRequirementSection,
  CreateSectionRequirement,
  CreateSubRequirement,
  GetRequirementsBySectionId,
  EditRequirementList,
  EditRequirementSection,
  ChangeIsAddRequirementList,
  ChangeIsAddRequirement,
  ChangeIsAddSection,
  ChangeIsAddSubRequirement,
  GetSubRequirementsByRequirementId,
  ChangeRequirementEditing,
  EditSectionRequirement,
  EditSubRequirement,
  ChangeClearRequirementData,
  GetAllReferences,
  GetRequirementsByRequirementListId,
  GetSubRequirementsByRequirementListId,
})(CreateRequirement);
