import { baseUrl } from "config/baseUrl";
import store from "../";
import {
  CHANGE_SHOW_ADD_SECTION_MODAL,
  CHANGE_SHOW_EDIT_SECTION_MODAL,
  CHANGE_SHOW_VIEW_SECTION_MODAL,
  CHANGE_IS_EDITING_SECTION,
  CHANGE_REQUIREMENT_LIST,
  CHANGE_GET_ALL_REQUIREMENT_LIST,
  CHANGE_REQUIREMENT_SECTION,
  CHANGE_GET_SINGLE_REQUIREMENT_LIST,
  CHANGE_CREATE_SECTION_REQUIREMENT,
  CHANGE_CREATE_SUB_REQUIREMENT,
  CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL,
  CHANGE_GET_SINGLE_SECTION,
  CHANGE_SHOW_VIEW_REQUIREMENT_MODAL,
  CHANGE_GET_SINGLE_REQUIREMENT,
  CHANGE_GET_SINGLE_SUB_REQUIREMENT,
  CHANGE_GET_REQUIREMENTS_BY_SECTION_ID,
  CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID,
  CHANGE_GET_ALL_SECTIONS,
  ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_GET_ALL_REQUIREMENTS,
  CHANGE_GLOBAL_LOADER,
  CHANGE_REQUIREMENT_EDITING,
  CHANGE_EDIT_REQUIREMENT_LIST,
  CHANGE_GET_ALL_SUB_REQUIREMENTS,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_EDIT_REQUIREMENT_SECTION,
  CHANGE_GET_SECTION_BY_ID,
  CHANGE_IS_ADD_REQUIREMENT_LIST,
  CHANGE_IS_ADD_SECTION,
  CHANGE_IS_ADD_REQUIREMENT,
  CHANGE_IS_ADD_SUB_REQUIREMENT,
  CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID,
  CHANGE_EDIT_SECTION_REQUIREMENT,
  CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
  CHANGE_GET_REQUIREMENT_BY_ID,
  CHANGE_EDIT_SUB_REQUIREMENT,
  CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
  CHANGE_GET_SUB_REQUIREMENT_BY_ID,
  CHANGE_CREATE_REFERENCE,
  CHANGE_GET_ALL_REFERENCES,
  CHANGE_EDIT_REFERENCE,
  CHANGE_CLEAR_REQUIREMENT_DATA,
  CHANGE_DELETE_REFERENCE,
  MAP_REQUIREMENT,
  MAP_REQUIREMENT_IDS,
  GET_ALL_MAP_REQUIREMENT,
  GET_SINGLE_MAP_REQUIREMENT,
  CHANGE_IS_REQUIREMENT_LIST_EDIT,
  CLEAR_SINGLE_REQUIREMENT_LIST_DATA,
  CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA,
  CLEAR_SUB_REQUIREMENT_BY_ID,
} from "./types";

export const ChangeIsRequirementListEdit = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_REQUIREMENT_LIST_EDIT, payload: val });
  };
};

export const ClearSingleRequirementListData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_SINGLE_REQUIREMENT_LIST_DATA });
  };
};

export const ClearPreviousRequirementsAndSubRequirementsData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PREVIOUS_REQUIREMENTS_AND_SUB_REQUIREMENTS_DATA });
  };
};

export const ChangeIsAddRequirementList = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_ADD_REQUIREMENT_LIST, payload: val });
  };
};
export const ChangeIsAddSection = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_ADD_SECTION, payload: val });
  };
};
export const ChangeIsAddRequirement = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_ADD_REQUIREMENT, payload: val });
  };
};
export const ChangeIsAddSubRequirement = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_ADD_SUB_REQUIREMENT, payload: val });
  };
};

export const ChangeIsEditingSection = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_EDITING_SECTION, payload: val });
  };
};
export const ChangeShowAddSectionModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_SECTION_MODAL, payload: val });
  };
};
export const ChangeShowEditSectionModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_EDIT_SECTION_MODAL, payload: val });
  };
};
export const ChangeShowViewSectionModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_VIEW_SECTION_MODAL, payload: val });
  };
};
// export const AddSectionsForCreatingAuditTemplate = (val) => {
//   return async (dispatch) => {
//     dispatch({ type: ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE, payload: val });
//   };
// };
// export const AddRequirementsForCreatingAuditTemplate = (val) => {
//   return async (dispatch) => {
//     dispatch({
//       type: ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
//       payload: val,
//     });
//   };
// };
export const ChangeRequirementEditing = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_REQUIREMENT_EDITING, payload: val });
  };
};
export const ChangeGetCurrentAddedRequirementListId = (val) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID,
      payload: val,
    });
  };
};

export const ChangeClearRequirementData = () => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_CLEAR_REQUIREMENT_DATA });
  };
};

export const MapRequirementIds = (val) => {
  return async (dispatch) => {
    dispatch({ type: MAP_REQUIREMENT_IDS, payload: val });
  };
};

// Create Requirement List
export const CreateRequirementList = (
  val,
  setRequirementListFormDataErrorState,
  moveToNext
) => {
  return async (dispatch) => {
    try {
      console.log("REQUIREMENT LIST FILE TESTING");
      console.log(val);

      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      formdata.append("name", val.name);
      formdata.append("location", val.location);
      formdata.append("authority", val.authority);
      formdata.append("industry", val.industry);
      formdata.append("information", val.information);
      formdata.append("effective_date", val.effective_date);
      formdata.append("description", val.description);
      formdata.append("status", val.status);
      formdata.append("file", val.file);
      formdata.append("extra_fields", JSON.stringify(val.extra_fields));

      // const data = JSON.stringify(val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        // body: data,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementList = await fetch(
        `${baseUrl}/api/auditor/create-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementList.status == 200) {
        dispatch({
          type: CHANGE_REQUIREMENT_LIST,
          payload: requirementList.data,
        });
        // dispatch({
        //   type: CHANGE_GET_CURRENT_ADDED_REQUIREMENT_LIST_ID,
        //   payload: requirementList.data.data.id,
        // });
        dispatch(GetSingleRequirementList(requirementList.data.data.id));
        moveToNext();
      } else {
        console.log("ERROR");
        console.log(requirementList);
        if (requirementList.data.success === false) {
          setRequirementListFormDataErrorState(requirementList.data.message);
          console.log(requirementList.message);
        }
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Map Requirement
export const MapRequirement = (val) => {
  return async (dispatch) => {
    console.log("MAP REQUIREMENT=======================");
    console.log(val);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var data = JSON.stringify(val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let mapRequirement = await fetch(
        `${baseUrl}/api/auditor/map-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (mapRequirement.status == 200) {
        dispatch({
          type: MAP_REQUIREMENT,
          payload: mapRequirement.data,
        });
        console.log(mapRequirement);
      } else {
        console.log("ERROR");
        console.log(mapRequirement);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Map Requirement
export const GetAllMapRequirement = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let mapRequirements = await fetch(
        `${baseUrl}/api/auditor/all-map-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (mapRequirements.status == 200) {
        dispatch({
          type: GET_ALL_MAP_REQUIREMENT,
          payload: mapRequirements.data.data,
        });
        console.log("MAP REQUIREMENTS ACTION");
        console.log(mapRequirements);
      } else {
        console.log("ERROR");
        console.log(mapRequirements);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Single Map Requirement
export const GetSingleMapRequirement = (val, model) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("mapped_over", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let mapRequirement = await fetch(
        `${baseUrl}/api/auditor/show-mapped-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (mapRequirement.status == 200) {
        dispatch({
          type: GET_SINGLE_MAP_REQUIREMENT,
          payload: mapRequirement.data,
        });
        model && model(true);
        console.log("SINGLE MAP REQUIREMENTS ACTION");
        console.log(mapRequirement);
      } else {
        console.log("ERROR");
        console.log(mapRequirement);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Edit Requirement List
export const EditRequirementList = (
  val,
  setRequirementListFormDataErrorState,
  moveToNext
) => {
  console.log("EDIT REQUIREMENT LIST DEBUGGING : req data  ", {val, setRequirementListFormDataErrorState, moveToNext} );
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      console.log("ohohoh", val)
      formdata.append("id", val.id);
      formdata.append("name", val.name);
      formdata.append("location", val.location);
      formdata.append("authority", val.authority);
      formdata.append("industry", val.industry);
      formdata.append("information", val.information);
      formdata.append("effective_date", val.effective_date);
      formdata.append("description", val.description);
      formdata.append("status", val.status);
      formdata.append("file", val.file);
      formdata.append("extra_fields", val.extra_fields);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementList = await fetch(
        `${baseUrl}/api/auditor/edit-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementList.status == 200) {
        console.log("EDIT REQUIREMENT LIST DEBUGGING : res data  ", requirementList );
        dispatch({
          type: CHANGE_EDIT_REQUIREMENT_LIST,
          payload: requirementList.data,
        });
        dispatch(GetSingleRequirementList(requirementList.data.data.id));
        moveToNext();
      } else {
        console.log("ERROR");
        console.log(requirementList);
        if (requirementList.data.success === false) {
          setRequirementListFormDataErrorState(requirementList.data.message);
          console.log(requirementList.message);
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Requirement List
export const GetAllRequirementList = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allReqirementList = await fetch(
        `${baseUrl}/api/auditor/all-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allReqirementList.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_REQUIREMENT_LIST,
          payload: allReqirementList.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Create Requirement Section
export const CreateRequirementSection = (
  val,
  setRequirementSectionFormDataErrorState,
  moveToNextRequirement
) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var data = JSON.stringify(val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementSection = await fetch(
        `${baseUrl}/api/auditor/create-req-section`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementSection.status == 200) {
        dispatch({
          type: CHANGE_REQUIREMENT_SECTION,
          payload: requirementSection.data,
        });
        dispatch(
          GetSingleRequirementList(requirementSection.data.data.req_list_id)
        );
        moveToNextRequirement();
        console.log(requirementSection);
      } else {
        console.log("ERROR");
        console.log(requirementSection);
        if (requirementSection.data.success === false) {
          setRequirementSectionFormDataErrorState(
            requirementSection.data.message
          );
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Edit Requirement Section
export const EditRequirementSection = (
  val,
  setRequirementSectionFormDataErrorState,
  moveToNextRequirement
) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("section_id", val.section_id);
      formdata.append("req_list_id", val.req_list_id);
      formdata.append("standard_ref", val.standard_ref);
      formdata.append("clarifications", val.clarifications);
      formdata.append("description", val.description);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementSection = await fetch(
        `${baseUrl}/api/auditor/edit-section-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementSection.status == 200) {
        console.log(requirementSection);
        dispatch({
          type: CHANGE_EDIT_REQUIREMENT_SECTION,
          payload: requirementSection.data,
        });
        dispatch(
          GetSingleRequirementList(requirementSection.data.data.req_list_id)
        );
        dispatch(
          GetRequirementsByRequirementListId(
            requirementSection.data.data.req_list_id
          )
        );
        moveToNextRequirement();
      } else {
        console.log("ERROR");
        console.log(requirementSection);
        if (requirementSection.data.success === false) {
          setRequirementSectionFormDataErrorState(
            requirementSection.data.message
          );
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// GET SINGLE REQUIREMENT LIST
export const GetSingleRequirementList = (val, history) => {
  console.log(":::::::::GET SINGLE REQUIREMENT LIST::::::");
  console.log("p1 send: val",val);
  console.log(history);
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      let formdata = new FormData();
      formdata.append("id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementList = await fetch(
        `${baseUrl}/api/auditor/show-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      console.log("p1 recieve:",requirementList )
      if (requirementList.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_REQUIREMENT_LIST,
          payload: requirementList.data,
        });
        if (history !== undefined) {
          history.push("/user/requirements/create-requirement");
        }
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// CREATE SECTION REQUIREMENT
export const CreateSectionRequirement = (
  val,
  setSectionRequirementsFormDataErrorState,
  moveToNextSubRequirement
) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var data = JSON.stringify(val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let sectionRequirement = await fetch(
        `${baseUrl}/api/auditor/create-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (sectionRequirement.status == 200) {
        dispatch({
          type: CHANGE_CREATE_SECTION_REQUIREMENT,
          payload: sectionRequirement.data,
        });
        dispatch(
          GetRequirementsBySectionId(
            sectionRequirement.data.data.req_section_id
          )
        );
        if (store.getState().requirment.mapRequirementIds.length > 0) {
          dispatch(
            MapRequirement({
              requirement_ids: store?.getState()?.requirment?.mapRequirementIds,
              mapped_over: sectionRequirement.data.data.id,
            })
          );
        }

        moveToNextSubRequirement();
        console.log(sectionRequirement);
      } else {
        console.log("ERROR");
        console.log(sectionRequirement);
        if (sectionRequirement.data.success === false) {
          setSectionRequirementsFormDataErrorState(
            sectionRequirement.data.message
          );
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// EDIT SECTION REQUIREMENT
export const EditSectionRequirement = (
  val,
  setSectionRequirementsFormDataErrorState,
  moveToNextSubRequirement
) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("requirement_id", val.requirement_id);
      formdata.append("req_list_id", val.req_list_id);
      formdata.append("req_section_id", val.req_section_id);
      formdata.append("standard_ref", val.standard_ref);
      formdata.append("criticality", val.criticality);
      formdata.append("evidence", val.evidence);
      formdata.append("evidence_details", val.evidence_details);
      formdata.append("question_type", val.question_type);
      formdata.append("yes_complaint", val.yes_complaint);
      formdata.append("no_complaint", val.no_complaint);
      formdata.append("references", val.references);
      formdata.append("weight", val.weight);
      formdata.append("description", val.description);
      formdata.append("clarification", val.clarification);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let sectionRequirement = await fetch(
        `${baseUrl}/api/auditor/edit-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (sectionRequirement.status == 200) {
        dispatch({
          type: CHANGE_EDIT_SECTION_REQUIREMENT,
          payload: sectionRequirement.data,
        });
        dispatch(
          GetSingleRequirementList(sectionRequirement.data.data.req_list_id)
        );
        dispatch(
          GetRequirementsByRequirementListId(
            sectionRequirement.data.data.req_list_id
          )
        );
        dispatch(
          GetSubRequirementsByRequirementListId(
            sectionRequirement.data.data.req_list_id
          )
        );
        moveToNextSubRequirement();
        console.log(sectionRequirement);
      } else {
        console.log("ERROR");
        console.log(sectionRequirement);
        if (sectionRequirement.data.success === false) {
          setSectionRequirementsFormDataErrorState(
            sectionRequirement.data.message
          );
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// CREATE SUB REQUIREMENT
export const CreateSubRequirement = (
  val,
  setSubRequirementsFormDataErrorState
) => {
  return async (dispatch) => {
    try {
      console.log("VAL DATA FROM ACTIONS");
      console.log(val);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var data = JSON.stringify(val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let subRequirement = await fetch(
        `${baseUrl}/api/auditor/create-sub-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (subRequirement.status == 200) {
        dispatch({
          type: CHANGE_CREATE_SUB_REQUIREMENT,
          payload: subRequirement.data,
        });
        dispatch(
          GetSubRequirementsByRequirementId(
            subRequirement.data.data.requirement_id
          )
        );
        console.log(subRequirement);
      } else {
        console.log("ERROR");
        if (subRequirement.data.success === false) {
          setSubRequirementsFormDataErrorState(subRequirement.data.message);
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// GET SINGLE REQUIREMENT LIST WITH OPEN MODAL
export const GetSingleRequirementListWithModal = (val) => {
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      let formdata = new FormData();
      formdata.append("id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });
      let requirementList = await fetch(
        `${baseUrl}/api/auditor/show-req-list`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementList.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_REQUIREMENT_LIST,
          payload: requirementList.data,
        });
        dispatch({
          type: CHANGE_SHOW_REQUIREMENT_LIST_DEAILS_MODAL,
          payload: true,
        });
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
      } else {
        console.log("ERROR");
        dispatch({
          type: CHANGE_GLOBAL_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// GET SINGLE SECTION WITH OPEN MODAL
export const GetSingleSectionWithModal = (val) => {
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("section_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let singleSection = await fetch(
        `${baseUrl}/api/auditor/show-section-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (singleSection.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_SECTION,
          payload: singleSection.data,
        });
        dispatch({
          type: CHANGE_SHOW_VIEW_SECTION_MODAL,
          payload: true,
        });
      } else {
        console.log("ERROR");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// GET SINGLE REQUIREMENT WITH MODAL
export const GetSingleRequirementWithModal = (val) => {
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("requirement_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let singleRequirement = await fetch(
        `${baseUrl}/api/auditor/show-requirement-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (singleRequirement.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_REQUIREMENT,
          payload: singleRequirement.data,
        });
        dispatch({
          type: CHANGE_SHOW_VIEW_REQUIREMENT_MODAL,
          payload: true,
        });
        dispatch(GetSingleMapRequirement(data));
      } else {
        console.log("ERROR");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// GET SINGLE SUB REQUIREMENT WITH MODAL
export const GetSingleSubRequirementWithModal = (val) => {
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("sub_requirement_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let singleSubRequirement = await fetch(
        `${baseUrl}/api/auditor/show-sub-requirement-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (singleSubRequirement.status == 200) {
        dispatch({
          type: CHANGE_GET_SINGLE_SUB_REQUIREMENT,
          payload: singleSubRequirement.data,
        });
        dispatch({
          type: CHANGE_SHOW_VIEW_REQUIREMENT_MODAL,
          payload: true,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Requirements By Section Id
export const GetRequirementsBySectionId = (val) => {
  console.log("GET REQUIREMENTS BY SECTION ID::::::::::::::::::::::::::::::");
  console.log(val);
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("section_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirementsBySectionId = await fetch(
        `${baseUrl}/api/auditor/show-requirement-by-section-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirementsBySectionId.status == 200) {
        dispatch({
          type: CHANGE_GET_REQUIREMENTS_BY_SECTION_ID,
          payload: requirementsBySectionId.data.data,
        });
      } else {
        console.log("ERROR");
        console.log(requirementsBySectionId);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Sub Requirements By Requirement Id
export const GetSubRequirementsByRequirementId = (val) => {
  return async (dispatch) => {
    try {
      let data = val.toString();
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("requirement_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let subRequirementsByRequirementId = await fetch(
        `${baseUrl}/api/auditor/show-sub-requirement-by-requirement-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (subRequirementsByRequirementId.status == 200) {
        dispatch({
          type: CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_ID,
          payload: subRequirementsByRequirementId.data.data,
        });
      } else {
        console.log("ERROR");
        console.log(subRequirementsByRequirementId);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Sections
export const GetAllSections = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let allSections = await fetch(
        `${baseUrl}/api/auditor/all-sections`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allSections.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_SECTIONS,
          payload: allSections.data.data,
        });
      } else {
        console.log("ERROR");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// Get All Requirements
export const GetAllRequirements = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allRequirements = await fetch(
        `${baseUrl}/api/auditor/get-all-requirements`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allRequirements.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_REQUIREMENTS,
          payload: allRequirements.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Sub Requirements
export const GetAllSubRequirements = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allSubRequirements = await fetch(
        `${baseUrl}/api/auditor/get-all-sub-requirement`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (allSubRequirements.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_SUB_REQUIREMENTS,
          payload: allSubRequirements.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Section By Id
export const GetSectionById = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      let data = val.toString();
      var formdata = new FormData();
      formdata.append("section_id", data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let section = await fetch(
        `${baseUrl}/api/auditor/show-section-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (section.status == 200) {
        dispatch({
          type: CHANGE_GET_SECTION_BY_ID,
          payload: section.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get Requirement By Id
export const GetRequirementById = (val) => {
  console.log("GET REQUIREMENT BY ID");
  console.log(val);
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("requirement_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirement = await fetch(
        `${baseUrl}/api/auditor/show-requirement-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirement.status == 200) {
        dispatch({
          type: CHANGE_GET_REQUIREMENT_BY_ID,
          payload: requirement.data.data,
        });
        console.log(requirement);
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Requirements By Requirement List Id
export const GetRequirementsByRequirementListId = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("req_list_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let requirements = await fetch(
        `${baseUrl}/api/auditor/get-all-requirements-by-req-list-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (requirements.status == 200) {
        dispatch({
          type: CHANGE_GET_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
          payload: requirements.data.data,
        });
        console.log(requirements);
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Get All Sub Requirements By Requirement List Id
export const GetSubRequirementsByRequirementListId = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("req_list_id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let subRequirements = await fetch(
        `${baseUrl}/api/auditor/show-sub-requirement-by-requirement-list-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (subRequirements.status == 200) {
        dispatch({
          type: CHANGE_GET_SUB_REQUIREMENTS_BY_REQUIREMENT_LIST_ID,
          payload: subRequirements.data.data,
        });
        console.log(subRequirements);
      } else {
        console.log("ERROR");
        console.log(subRequirements);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// GET SINGLE SUB REQUIREMENT By Id
export const GetSubRequirementById = (val) => {
  console.log("omg" + JSON.stringify(val));
  return async (dispatch) => {
    try {
      let data = val.toString();
      var headers = new Headers();
      headers.append("Accept", "Application/json");
      headers.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("sub_requirement_id", data);
      var requestOptions = {
        method: "POST",
        headers,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let subRequirement = await fetch(
        `${baseUrl}/api/auditor/show-sub-requirement-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (subRequirement.status == 200) {
        dispatch({
          type: CHANGE_GET_SUB_REQUIREMENT_BY_ID,
          payload: subRequirement.data,
        });
        console.log(subRequirement);
      } else {
        console.log("ERROR");
      }

      console.log("subreq : " + subRequirement);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// EDIT SUB REQUIREMENT
export const EditSubRequirement = (
  val,
  setSubRequirementsFormDataErrorState
) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("sub_requirement_id", val.sub_requirement_id);
      formdata.append("requirement_id", val.requirement_id);
      formdata.append("req_list_id", val.req_list_id);
      formdata.append("section_id", val.section_id);
      formdata.append("standard_ref", val.standard_ref);
      formdata.append("criticality", val.criticality);
      formdata.append("evidence", val.evidence);
      formdata.append("evidence_details", val.evidence_details);
      formdata.append("question_type", val.question_type);
      formdata.append("yes_complaint", val.yes_complaint);
      formdata.append("no_complaint", val.no_complaint);
      formdata.append("references", val.references);
      formdata.append("weight", val.weight);
      formdata.append("description", val.description);
      formdata.append("clarification", val.clarification);
      formdata.append("extra_fields", val.extra_fields);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let subRequirement = await fetch(
        `${baseUrl}/api/auditor/edit-sub-requirement-by-id`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (subRequirement.status == 200) {
        dispatch({
          type: CHANGE_EDIT_SUB_REQUIREMENT,
          payload: subRequirement.data,
        });
        dispatch(
          GetSubRequirementsByRequirementListId(
            Number(subRequirement.data.data.req_list_id)
          )
        );
        console.log(subRequirement);
      } else {
        console.log("ERROR");
        console.log(subRequirement);
        if (subRequirement.data.success === false) {
          setSubRequirementsFormDataErrorState(subRequirement.data.message);
        }
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// CREATE REFERENCE
export const CreateNewReference = (val) => {
  console.log(val);
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      formdata.append("name", val.name);
      formdata.append("type", val.type);
      formdata.append("description", val.description);
      formdata.append("internet_resource", val.internet_resource);
      formdata.append("internal_links", val.internal_links);
      formdata.append("compliance_standard", val.compliance_standard);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let reference = await fetch(
        `${baseUrl}/api/auditor/create-reference`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (reference.status == 200) {
        dispatch({
          type: CHANGE_CREATE_REFERENCE,
          payload: reference.data,
        });
        console.log(reference);
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// GET ALL REFERENCES
export const GetAllReferences = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: true,
      // });

      let references = await fetch(
        `${baseUrl}/api/auditor/all-references`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (references.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_REFERENCES,
          payload: references.data.data,
        });
      } else {
        console.log("ERROR");
        console.log(references);
      }
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: CHANGE_GLOBAL_LOADER,
      //   payload: false,
      // });
    }
  };
};

// FIXME: Change URL and othet Stuff

// EDIT REFERENCE
export const EditReference = (val) => {
  console.log(val);
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      formdata.append("id", val.id);
      formdata.append("name", val.name);
      formdata.append("type", val.type);
      formdata.append("description", val.description);
      formdata.append("internet_resource", val.internet_resource);
      formdata.append("internal_links", val.internal_links);
      formdata.append("compliance_standard", val.compliance_standard);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let reference = await fetch(
        `${baseUrl}/api/auditor/edit-reference`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (reference.status == 200) {
        dispatch({
          type: CHANGE_EDIT_REFERENCE,
          payload: reference.data,
        });
        dispatch(GetAllReferences());
        console.log(reference);
      } else {
        console.log("ERROR");
        console.log(reference);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      console.log(reference);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// DELETE REFERENCE
export const DeleteReference = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      formdata.append("id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let reference = await fetch(
        `${baseUrl}/api/auditor/delete-reference`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (reference.status == 200) {
        dispatch({
          type: CHANGE_DELETE_REFERENCE,
          payload: reference.data,
        });
        dispatch(GetAllReferences());
        console.log(reference);
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};
