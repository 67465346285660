import { useEffect, useState } from "react";
import {
  Redirect, Route,
  Switch, useHistory
} from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "reactstrap";
import { ManageUser } from "store/actions/userAction";


toast.configure()

const App = (props) => {
  const { ManageUser, user, shared } = props;
  const { globalLoader } = shared;
  const history = useHistory();
  const [alert, setAlert] = useState(null)



  useEffect(() => {
    const path = history.location.pathname;

    const checkPathInc = path.includes("user");

    const tokenVerify = window.localStorage.getItem("token");

    if (tokenVerify && !checkPathInc) {
      history.push("/user/dashboard");
    }
  }, []);

  return (
    <>
      {alert}
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/user" render={(props) => <AdminLayout {...props} />} />
        {window.localStorage.getItem("currentLoginUserRole") !== 0 ||
          window.localStorage.getItem("currentLoginUserRole") === 1 ||
          (window.localStorage.getItem("currentLoginUserRole") === 2 && (
            <Redirect to="/user/dashboard" />
          ))}
        <Redirect to="/auth/login" />
      </Switch>
      {globalLoader && (
        <div className="reactstrap-spinner-custom-styling">
          <Spinner type="border" color="primary" />
        </div>

      )}

    </>
  );
};

const mapStateToProps = ({ user, shared }) => ({
  user,
  shared,
});

export default connect(mapStateToProps, { ManageUser })(App);
