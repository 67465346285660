import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactFileReader from "react-file-reader";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import { CheckBox } from "./CheckBox";
import RequirementsSubRequirementsTable from "./RequirementsSubRequirementsTable";
import RequirementMappingTable from "./RequirementMappingTable";
import ExistingMappedRequirements from "./ExistingMappedRequirements";
import ExistingSubrequirements from "./ExistingSubRequirements";
import EditRequirement from "./RequirementMapping/EditRequirement";
import ViewRequirement from "./RequirementMapping/ViewRequirement";
import { ChangeShowViewRequirementModal } from "../../store/actions/sharedAction";
import { GetAllMapRequirement } from "store/actions/requirmentActions";
import { GetSingleMapRequirement } from "store/actions/requirmentActions";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const RequirementMapping = (props) => {
  const { showViewRequirementModal } = props.shared;
  const {
    ChangeShowViewRequirementModal,
    GetAllMapRequirement,
    GetSingleMapRequirement,
  } = props;
  const { allMapRequirement } = props.requirment;

  const [data, SetData] = useState([]);

  let dataMap = allMapRequirement.map((requirement) => ({
    ...requirement,
    id: requirement.details.id,
    clarification: requirement.details.clarification,
    desc: requirement.details.description,
    criticality: requirement.details.criticality,
    evidence: requirement.details.evidence,
    evidence_details: requirement.details.evidence_details,
    references: requirement.details.references,
    standard_ref: requirement.details.standard_ref,
    // status:
    //   requirement.details.status == 1
    //     ? "Active"
    //     : requirement.details.status == 0
    //     ? "In Active"
    //     : "",
    // requirement_file: requirement.details.requirement_file,
  }));
  let dataMemo = React.useMemo(() => dataMap, [allMapRequirement]);
  // ---------------- SETTING UP DATATABLE START ---------------------------
  //   const data = React.useMemo(
  //     () => [
  //       {
  //         name: "ISO:8888",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "Active",
  //         requirement_file: "lorem",
  //       },
  //       {
  //         name: "ISO:9000",
  //         desc: "World",
  //         authority: "lorem",
  //         location: "lorem",
  //         industry: "lorem",
  //         information: "lorem",
  //         effective_date: "12/03/2020",
  //         status: "Active",
  //         requirement_file: "lorem",
  //       },
  //     ],
  //     []
  //   );

  useEffect(() => {
    GetAllMapRequirement();
    SetData(dataMemo);
  }, []);

  useEffect(() => {
    SetData(dataMemo);
  }, [dataMemo]);

  console.log(data);

  const columns = React.useMemo(
    () => [
      {
        Header: "Standard Ref",
        accessor: "standard_ref", // accessor is the "key" in the data
      },
      {
        Header: "Desc",
        accessor: "desc",
      },
      {
        Header: "Clarification",
        accessor: "clarification",
      },
      {
        Header: "Criticality",
        accessor: "criticality",
      },
      {
        Header: "Evidence",
        accessor: "evidence",
      },
      {
        Header: "Evidence Details",
        accessor: "evidence_details",
      },
      {
        Header: "References",
        accessor: "references",
      },
      //   {
      //     Header: "Status",
      //     accessor: "status",
      //   },
      //   {
      //     Header: "Attachement",
      //     accessor: "requirement_file",
      //   },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };
  const colMissmatchAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Columns mismatch!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        The header(1st row) in csv file should have same columns as in table
        header!
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = React.useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const setRowForEdit = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var rows = reader.result.split(`\r\n`);
      console.log("ROWS");
      console.log(rows);
      for (var i = 0; i < rows.length; i++) {
        var columns = rows[i].split(",");
        console.log("COLUMNS");
        console.log(columns);
        if (i == 0) {
          if (
            columns[0] != "Name" &&
            columns[1] != "Desc" &&
            columns[2] != "Authority" &&
            columns[3] != "Location" &&
            columns[4] != "Industry" &&
            columns[5] != "Information" &&
            columns[6] != "Date" &&
            columns[7] != "Status" &&
            columns[8] != "Attachement"
          ) {
            colMissmatchAlert();
            break;
          }
        } else {
          SetData((prev) => {
            return [
              ...prev,
              {
                name: columns[0],
                desc: columns[1],
                authority: columns[2],
                location: columns[3],
                industry: columns[4],
                information: columns[5],
                effective_date: columns[6],
                status: columns[7],
                requirement_file: columns[8],
              },
            ];
          });
        }
      }
    };
    reader.readAsText(files[0]);
  };

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              <div className="bg-info  rounded text-right d-flex justify-content-end">
                {selectedFlatRows.length > 0
                  ? // <Button
                    //   color="danger"
                    //   size="sm"
                    //   onClick={() => successDelete()}
                    // >
                    //   Delete
                    // </Button>
                    null
                  : null}
                {/* <ReactFileReader fileTypes={[".csv"]} handleFiles={handleFiles}>
                  <button className="btn btn-info btn-sm">Import CSV</button>
                </ReactFileReader> */}
                {/* <button
                  onClick={() => {
                    exportData("csv", true);
                  }}
                  className="btn btn-info btn-sm"
                >
                  Export CSV
                </button> */}
                {/* <button
                  onClick={() => {
                    exportData("pdf", true);
                  }}
                  className="btn btn-info btn-sm"
                >
                  Export PDF
                </button> */}
              </div>

              <div className="row p-3 align-items-center">
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-between">
                    Show
                    <select
                      value={pageSize}
                      className="form-control"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    Entries
                  </div>
                </div>
                <div className="col-md-7"></div>
                <div className="col-md-3 text-right">
                  <div className="form-group">
                    {/* <label htmlFor="">Search</label> */}

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div>
              {/* <button onClick={resetResizing}>Reset Resizing</button> */}
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left-0 border-right "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                                }`}
                                                        /> */}
                          </th>
                        ))}
                        <th
                          className="border border-left-0 border-right "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}

                                   style={{
                                    maxWidth: "150px",
                                    whiteSpace:"noWrap",
                                    overflow:"hidden",
                                    textOverflow:"ellipsis"
                                 }}
                                >
                              {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}

                          <td>
                            {/* {console.log(row)} */}
                            <div className="d-flex flex-wrap justify-content-around">
                              <i
                                className="fas fa-eye text-info"
                                onClick={() => {
                                  //   ChangeShowViewRequirementModal(true);
                                  GetSingleMapRequirement(
                                    row.original.mapped_over,
                                    ChangeShowViewRequirementModal
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                              {/* <i
                                className="fas fa-edit text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => setRowForEdit(row)}
                              ></i>
                              <i
                                className="fas fa-times text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => successDelete()}
                              ></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>

                <div className="">
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  {/* <button className="btn btn-info  btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button> */}
                  <span>
                    {pageOptions.map((page) => (
                      <button
                        className={
                          pageIndex === page
                            ? "btn border-info border btn-info  bg-transparent text-info "
                            : "btn border-info border btn-info btn-sm bg-transparent text-info"
                        }
                        onClick={() => {
                          const pageNumber = page;
                          gotoPage(pageNumber);
                        }}
                      >
                        {page + 1}
                      </button>
                    ))}

                    {/* <input type="number" className="" defaultValue={pageIndex + 1} onChange={(e) => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }} style={{ width: '50px' }} /> */}
                  </span>
                  {/* <button className="btn btn-info  btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>Next</button> */}
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>

              {console.log(
                JSON.stringify(
                  {
                    selectedFlatRows: selectedFlatRows.map(
                      (row) => row.original
                    ),
                  },
                  null,
                  2
                )
              )}

              {/* <pre>
                                <code>
                                    {
                                        JSON.stringify(
                                            {
                                                selectedFlatRows: selectedFlatRows.map((row) => row.original),
                                            }, null, 2
                                        )
                                    }
                                </code>
                            </pre> */}
            </Card>
          </Col>
        </Row>

        {/* ------------------------------- EDIT MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal
          isOpen={showViewRequirementModal}
          size="xl"
          toggle={() => {
            ChangeShowViewRequirementModal(false);
          }}
        >
          <ViewRequirement />
        </Modal>
        {/* ---------------------------- End EDIT  Modal --------------------------- */}

        {/* ------------------------------- VIEW MODAL --------------------------------- */}
        {/* Classic Modal */}
        <Modal isOpen={modalClassic} size="xl" toggle={toggleModalClassic}>
          <EditRequirement />
        </Modal>
        {/* ---------------------------- End VIEW  Modal --------------------------- */}
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowViewRequirementModal,
  GetAllMapRequirement,
  GetSingleMapRequirement,
})(RequirementMapping);
