import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { CheckBox } from "../CheckBox";
import ExistingMappedRequirements from "../ExistingMappedRequirements";
import ExistingSubrequirements from "../ExistingSubRequirements";
import { ChangeShowViewRequirementModal } from "../../../store/actions/sharedAction";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const ViewRequirement = (props) => {
  // ---------------- SETTING UP DATATABLE START ---------------------------

  const data = React.useMemo(
    () => [
      {
        name: "ISO:8888",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "Active",
        requirement_file: "lorem",
      },
      {
        name: "ISO:9000",
        desc: "World",
        authority: "lorem",
        location: "lorem",
        industry: "lorem",
        information: "lorem",
        effective_date: "12/03/2020",
        status: "Active",
        requirement_file: "lorem",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Desc",
        accessor: "desc",
      },
      {
        Header: "Authority",
        accessor: "authority",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Industry",
        accessor: "industry",
      },
      {
        Header: "Information",
        accessor: "information",
      },
      {
        Header: "Date",
        accessor: "effective_date",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Attachement",
        accessor: "requirement_file",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [rowDetails, setRowDetails] = useState("");

  const { showViewRequirementModal, viewingSubRequirements } = props.shared;
  const { ChangeShowViewRequirementModal } = props;
  const {
    singleRequirement,
    singleSubRequirement,
    subRequirementsByRequirementId,
    singleMapRequirement,
  } = props.requirment || {};
  console.log(singleRequirement);
  console.log(subRequirementsByRequirementId);

  // const {
  //   standard_ref,
  //   criticality,
  //   evidence,
  //   evidence_details,
  //   question_type,
  //   yes_complaint,
  //   no_complaint,
  //   references,
  //   weight,
  //   description,
  //   clarification,
  // } = !viewingSubRequirements
  //   ? singleRequirement?.data
  //   : singleSubRequirement?.data;

  // const {

  // let standard_ref = "";
  // let criticality = "";
  // let evidence = "";
  // let evidence_details = "";
  // let question_type = "";
  // let yes_complaint = "";
  // let no_complaint = "";
  // let references = "";
  // let weight = "";
  // let description = "";
  // let clarification = "";

  // } = !viewingSubRequirements
  //   ? singleRequirement?.data
  //   : singleSubRequirement?.data;

  // const {
  //   clarification,
  //   description,
  //   criticality,
  //   evidence,
  //   evidence_details,
  //   references,
  //   standard_ref,
  // } = singleMapRequirement;

  return (
    <>
      <div className="modal-header justify-content-center">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            ChangeShowViewRequirementModal(false);
          }}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <h5 className="text-left">
          {!viewingSubRequirements ? "Requirement 1" : "Sub Requirement 1"}
        </h5>
      </div>
      <div className="modal-body">
        <h5>Requirement Details</h5>
        {singleMapRequirement && (
          <table className="table table-bordered table-striped table-hover">
            <tbody>
              <tr>
                <td className="font-weight-bold">Requirement List</td>
                <td>{singleMapRequirement?.message?.req_list?.name}</td>
                <td className="font-weight-bold">Standard Ref</td>
                <td>{singleMapRequirement?.message?.standard_ref}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Criticality</td>
                <td>{singleMapRequirement?.message?.criticality}</td>
                <td className="font-weight-bold">Evidence</td>
                <td>{singleMapRequirement?.message?.evidence}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Evidence Details</td>
                <td>{singleMapRequirement?.message?.evidence_details}</td>
                <td className="font-weight-bold">Question Type</td>
                <td>{singleMapRequirement?.message?.question_type}</td>
              </tr>
              {/* <tr>
                <td className="font-weight-bold">Yes Compliant</td>
                <td>{singleMapRequirement?.message?.yes_complaint}</td>
                <td className="font-weight-bold">No Compliant</td>
                <td>{singleMapRequirement?.message?.no_complaint}</td>
              </tr> */}
              <tr>
                <td className="font-weight-bold">References</td>
                <td>{singleMapRequirement?.message?.references}</td>
                <td className="font-weight-bold">weight</td>
                <td>{singleMapRequirement?.message?.weight}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Clarification</td>
                <td>{singleMapRequirement?.message?.clarification}</td>
                <td className="font-weight-bold">Description</td>
                <td>{singleMapRequirement?.message?.description}</td>
              </tr>
            </tbody>
          </table>
        )}
        {singleRequirement && (
          <>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  {singleRequirement?.data?.req_list?.name}
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {singleRequirement?.data?.section?.standard_ref}
                </BreadcrumbItem>
                <BreadcrumbItem className="text-info" active>
                  {singleRequirement?.data?.standard_ref}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            <table className="table table-bordered table-striped table-hover">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Requirement List</td>
                  <td> {singleRequirement?.data?.req_list?.name}</td>
                  <td className="font-weight-bold">Standard Ref</td>
                  <td>{singleRequirement?.data?.standard_ref}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Criticality</td>
                  <td>{singleRequirement?.data?.criticality}</td>
                  <td className="font-weight-bold">Evidence</td>
                  <td>{singleRequirement?.data?.evidence}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Evidence Details</td>
                  <td>{singleRequirement?.data?.evidence_details}</td>
                  <td className="font-weight-bold">Question Type</td>
                  <td>{singleRequirement?.data?.question_type}</td>
                </tr>
                {/* <tr>
                  <td className="font-weight-bold">Yes Compliant</td>
                  <td>{singleRequirement?.data?.yes_complaint}</td>
                  <td className="font-weight-bold">No Compliant</td>
                  <td>{singleRequirement?.data?.no_complaint}</td>
                </tr> */}
                <tr>
                  <td className="font-weight-bold">References</td>
                  <td>{singleRequirement?.data?.references}</td>
                  <td className="font-weight-bold">weight</td>
                  <td>{singleRequirement?.data?.weight}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Clarification</td>
                  <td>{singleRequirement?.data?.clarification}</td>
                  <td className="font-weight-bold">Description</td>
                  <td>{singleRequirement?.data?.description}</td>
                </tr>
                <tr>
                  {singleRequirement?.data?.extra_fields?.map((field) => (
                    <>
                      <td className="font-weight-bold">{field?.field_name}</td>
                      <td>{field?.field_value}</td>
                    </>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        )}
        {singleSubRequirement && (
          <>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  {singleSubRequirement?.data?.req_list?.name}
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {singleSubRequirement?.data?.sections?.standard_ref}
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {singleSubRequirement?.data?.requirement?.standard_ref}
                </BreadcrumbItem>
                <BreadcrumbItem className="text-info" active>
                  {singleSubRequirement?.data?.standard_ref}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>

            <table className="table table-bordered table-striped table-hover">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Requirement List</td>
                  <td>{singleSubRequirement?.data?.req_list?.name}</td>
                  <td className="font-weight-bold">Standard Ref</td>
                  <td>{singleSubRequirement?.data?.standard_ref}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Criticality</td>
                  <td>{singleSubRequirement?.data?.criticality}</td>
                  <td className="font-weight-bold">Evidence</td>
                  <td>{singleSubRequirement?.data?.evidence}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Evidence Details</td>
                  <td>{singleSubRequirement?.data?.evidence_details}</td>
                  <td className="font-weight-bold">Question Type</td>
                  <td>{singleSubRequirement?.data?.question_type}</td>
                </tr>
                {/* <tr>
                  <td className="font-weight-bold">Yes Compliant</td>
                  <td>{singleSubRequirement?.data?.yes_complaint}</td>
                  <td className="font-weight-bold">No Compliant</td>
                  <td>{singleSubRequirement?.data?.no_complaint}</td>
                </tr> */}
                <tr>
                  <td className="font-weight-bold">References</td>
                  <td>{singleSubRequirement?.data?.references}</td>
                  <td className="font-weight-bold">weight</td>
                  <td>{singleSubRequirement?.data?.weight}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Clarification</td>
                  <td>{singleSubRequirement?.data?.clarification}</td>
                  <td className="font-weight-bold">Description</td>
                  <td>{singleSubRequirement?.data?.description}</td>
                </tr>
                <tr>
                  {singleSubRequirement?.data?.extra_fields?.map((field) => (
                    <>
                      <td className="font-weight-bold">{field?.field_name}</td>
                      <td>{field?.field_value}</td>
                    </>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        )}
        {!viewingSubRequirements &&
          subRequirementsByRequirementId.length > 0 &&
          singleSubRequirement && (
            <>
              <h5>Sub Requirements</h5>
              {/* <ExistingSubrequirements /> */}
            </>
          )}

        {singleMapRequirement && (
          <>
            {" "}
            <h5>Mapped Requirement</h5>
            <ExistingMappedRequirements
              mappedRequirements={singleMapRequirement?.data}
            />
          </>
        )}
        <div
          onClick={() => {
            ChangeShowViewRequirementModal(false);
          }}
          className="text-right"
        >
          <Button color="danger">Close</Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
}) => ({
  shared,
  styling,
  audit,
  requirment,
  auditingTemplate,
});

export default connect(mapStateToProps, {
  ChangeShowViewRequirementModal,
})(ViewRequirement);
