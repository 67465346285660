import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { useExportData } from "react-table-plugins";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tab,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
} from "../../../store/actions/auditActions";
import AddSections from "./AddSections";
import { GetAllSections } from "store/actions/requirmentActions";

const Sections = (props) => {
  const {} = props.audit;
  const {
    ChangeShowAddAuditModal,
    ChangeShowAddSectionsModal,
    isEditing,
    GetAllSections,
    selectedSectionsData,
    status,
  } = props;

  // const [data, SetData] = useState(dataTable);
  const [data, SetData] = useState([]);

  let dataMap = selectedSectionsData?.map((data) => ({
    id: data.id,
    requirementList: data?.req_list?.name,
    desc: data?.description,
    standardRef: data?.standard_ref,
    clarification: data?.clarifications,
  }));
  let dataTable = React.useMemo(() => dataMap, [selectedSectionsData]);

  useEffect(() => {
    SetData(dataTable);
  }, [selectedSectionsData]);

  // var dataTable = useMemo(
  //   () => [
  //     {
  //       name: "ISO:8888",
  //       desc: "Section 1 Desc",
  //       authority: "ISO",
  //       location: "USA",
  //       industry: "ISO",
  //       information: "International Organization for Standardization",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "CNIC Card",
  //     },
  //     {
  //       name: "ISO:8888",
  //       desc: "Section 2 Desc",
  //       authority: "ISO",
  //       location: "USA",
  //       industry: "ISO",
  //       information: "International Organization for Standardization",
  //       effective_date: "12/03/2020",
  //       status: "Active",
  //       requirement_file: "CNIC Card",
  //     },
  //   ],
  //   []
  // );
  const columns = useMemo(
    () => [
      {
        Header: "Sec: Standard Ref",
        accessor: "standardRef",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
      {
        Header: "Requirement List",
        accessor: "requirementList",
      },
      {
        Header: "Clarification",
        accessor: "clarification",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [alert, setAlert] = useState(null);
  const successDelete = () => {
    console.log("SUCCESSDELETE");
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Deleted
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  useEffect(() => {
    console.log("ADD AUDIT FOCUSED", isEditing);
    if (!isEditing) SetData([]);
    else SetData(dataTable);
  }, []);
  return (
    <>
      {alert}
      <div className="title-with-add-btn">
        <span
          style={{
            fontWeight: "bold",
            color: "#666666",
            fontSize: 18,
          }}
        >
          {"Sections"}
        </span>
        {status !== 1 && (
          <i
            onClick={() => {
              GetAllSections();
              ChangeShowAddSectionsModal(true);
            }}
            class="fas fa-plus-circle top-add-btn"
          ></i>
        )}
      </div>
      <div div className="tableWrap">
        <table {...getTableProps()} className="table-striped table-hover ">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table-info text-white"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border border-left-0 border-right "
                    style={{
                      borderBottom: "1px solid #cacaca",
                      background: "rgba(0, 0, 0, 0.05)",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-arrow-down  text-secondary"></i>
                        ) : (
                          <i className="fas fa-arrow-up text-secondary"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
                {/* <th
                  className="border border-left-0 border-right "
                  style={{
                    borderBottom: "1px solid #cacaca",
                    background: "rgba(0, 0, 0, 0.05)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                ></th> */}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      </>
                    );
                  })}
                  {/* <td>
                    <div className="d-flex flex-wrap justify-content-around">
                      <i
                        className="fas fa-times text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => successDelete()}
                      ></i>
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToProps = ({ shared, styling, audit }) => ({
  shared,
  styling,
  audit,
});

export default connect(mapStateToProps, {
  ChangeShowAddAuditModal,
  ChangeShowAddSectionsModal,
  GetAllSections,
})(Sections);
