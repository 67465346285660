import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
// import { GlobalFilter } from "./GlobalFilter";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import $ from "jquery";
import fs from "fs";
import ReactFileReader from "react-file-reader";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Modal,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
// import { CheckBox } from "./CheckBox";

import {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  GetAllAuditingTemplate,
  DeleteAuditingTemplate,
  EditAuditingTemplateData,
} from "../../store/actions/auditingTemplateActions";

import {
  ChangeShowAddRequirmentsModal,
  ChangeShowAddSectionsModal,
} from "../../store/actions/auditActions";

import {
  GetAllUserRole,
  GetContributeUser,
  GetSupportUser,
} from "../../store/actions/userAction";

// import AddNewAuditingTemplate from "./AuditingTemplates/AddNewAuditingTemplate";
// import AddOriginalRequirment from "./AuditingTemplates/AddOriginalRequirment";
// import AddRequirments from "views/Audit/AllAudits/AddRequirments";
// import AddSections from "views/Audit/AllAudits/AddSections";
import moment from "moment";
import AddNewAuditingTemplate from "views/tables/AuditingTemplates/AddNewAuditingTemplate";
import AddRequirments from "views/Audit/AllAudits/AddRequirments";
import AddSections from "views/Audit/AllAudits/AddSections";
import AddOriginalRequirment from "views/tables/AuditingTemplates/AddOriginalRequirment";
import { CheckBox } from "views/tables/CheckBox";
import { GlobalFilter } from "views/tables/GlobalFilter";
import AddNewUserRole from "views/tables/UserRoles/AddNewUserRole";
import { ChangeShowAddNewUserModal } from "store/actions/userAction";

// core components

// ------------------------ PDF AND CSV EXPORTER START ----------------------------------------------

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

// ------------------------ PDF AND CSV EXPORTER END ----------------------------------------------

const AuditingTemplates = (props) => {
  const [tableData, SetTableData] = useState([]);
  const [alert, setAlert] = useState(null);
  const { mainColor } = props.styling;
  const { showAddRequirmentsModal, showAddSectionsModal } = props.audit;
  const {
    ChangeShowAddOriginalRequirmentModal,
    ChangeShowAddAuditingTemplateModal,
    ChangeIsEditing,
    ChangeUnderEditTemplateTitle,
    ChangeShowAddSectionsModal,
    GetAllAuditingTemplate,
    DeleteAuditingTemplate,
    EditAuditingTemplateData,
    ChangeShowAddNewUserModal,
    user,
    GetAllUserRole,
    GetContributeUser,
    GetSupportUser,
  } = props;
  const {
    showAddOriginalRequirmentModal,
    showAddAuditingTemplateModal,
    isEditing,
    underEditTemplateTitle,
    allAuditingTemplate,
    deleteAuditingTemplate,
  } = props.auditingTemplate;

  const { showAddNewUserModal, allUserRole, supportUser, contributeUser } =
    user;

  console.log(supportUser);
  console.log(contributeUser);

  useEffect(() => {
    GetAllUserRole();
    GetContributeUser(JSON.parse(window.localStorage.getItem("user"))?.id);
    GetSupportUser(JSON.parse(window.localStorage.getItem("user"))?.id);
  }, []);

  console.log(allUserRole);

  // useEffect(() => {
  //   GetAllAuditingTemplate();
  //   SetTableData(allAuditingTemplate);
  // }, [deleteAuditingTemplate]);

  // useEffect(() => {
  //   SetTableData(allAuditingTemplate);
  //   console.log(allAuditingTemplate);
  // }, [allAuditingTemplate]);

  // useEffect(() => {
  //   SetTableData(allAuditingTemplate);
  // }, []);

  // let dataMap = tableData.map((data) => ({
  //   ...data,
  //   id: data.id,
  //   name: data.name,
  //   created_at: moment(data.created_at).format("DD/MM/YYYY"),
  //   type: data.type == 1 ? "Template" : "Draft",
  // }));
  // let data = React.useMemo(() => dataMap, [tableData]);

  // ---------------- SETTING UP DATATABLE START ---------------------------

  // filter out the role from contributor lead and show only his data and suppport data
  // const userRoleData = allUserRole.filter(item => (
  //   item.role !== "0"
  // ))
  let checkAuditorOrMember =
    (window.localStorage.getItem("currentLoginUserRole") === "0" &&
      allUserRole) ||
    (window.localStorage.getItem("currentLoginUserRole") === "1" &&
      contributeUser) ||
    (window.localStorage.getItem("currentLoginUserRole") === "2" &&
      supportUser);

  const [data, SetData] = useState([]);

  var dataTable = React.useMemo(
    () =>
      checkAuditorOrMember.map((item) => ({
        name: item?.name,
        email: item?.email,
        role:
          item?.role == 0
            ? "Auditor"
            : item?.role == 1
            ? "Contributor Lead"
            : item?.role == 2
            ? "Support Team"
            : null,
        createdAt: moment(item?.created_at).format("MMM Do, YYYY"),
      })),
    [allUserRole]
  );

  useEffect(() => {
    SetData(dataTable);
  }, [dataTable]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
    ],
    []
  );

  //   console.log(allAuditingTemplate);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    resetResizing,
    exportData,
    rows,
  } = useTable(
    { columns, data, getExportFileBlob },
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          // {
          //   id: "selection",
          //   Header: ({ getToggleAllRowsSelectedProps }) => (
          //     <CheckBox {...getToggleAllRowsSelectedProps()} />
          //   ),
          //   Cell: ({ row }) => (
          //     <CheckBox {...row.getToggleRowSelectedProps()} />
          //   ),
          // },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // ---------------- SETTING UP DATATABLE END ---------------------------

  // ---------------- SWEETALERRT START ---------------------------
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const successDelete = (templateId) => {
    console.log(templateId);
    DeleteAuditingTemplate(templateId);
    setAlert(
      <ReactBSAlert
        // isOpen={success == "success" ? true : false}
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        show={false}
        btnSize=""
      >
        {/* {message} */}
        Message here
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Requirement Updated !
      </ReactBSAlert>
    );
  };

  const colMissmatchAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Columns mismatch!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        The header(1st row) in csv file should have same columns as in table
        header!
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // ---------------- SWEETALERRT END ---------------------------

  // ------------------------ EDIT AND VIEW MODALS -------------------------

  // VIEW

  const [modalClassic1, setModalClassic1] = useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };
  const setRow = (row) => {
    console.log(row);
    setRowDetails(row);
    toggleModalClassic1();
  };

  // EDIT

  const [modalClassic, setModalClassic] = useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const editSubmit = (e) => {
    e.preventDefault();
    toggleModalClassic();
    successAlert();
  };

  // ------------------------ EDIT AND VIEW MODALS END -------------------------

  // --------------------- Filter Table ------------------------------

  const filterDataTable = (e) => {
    console.log("E.TARGET.VALUE");
    console.log(e.target.value);
    var elements;
    if (e.target.value == "") {
      elements = dataTable;
    } else {
      elements = dataTable.filter((el) => {
        return el.state === e.target.value;
      });
    }
    console.log("ELEMENTS");
    console.log(elements);
    SetTableData(elements);
  };

  // ------------------ Filter Table End -----------------------------------

  const papaConfig = {
    delimiter: ",", //"" is auto-detect
    newline: "", // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: false,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
  };

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var rows = reader.result.split(`\r\n`);
      console.log("ROWS");
      console.log(rows);
      for (var i = 0; i < rows.length - 1; i++) {
        var columns = rows[i].split(",");
        console.log("COLUMNS");
        console.log(columns);
        if (i == 0) {
          if (
            columns[2] != "State" &&
            columns[1] != "Created On" &&
            columns[0] != "Template Name"
          ) {
            colMissmatchAlert();
            break;
          }
        } else {
          SetTableData((prev) => {
            return [
              ...prev,
              {
                name: columns[0],
                createdOn: columns[1],
                state: columns[2],
              },
            ];
          });
        }
      }
    };
    reader.readAsText(files[0]);
  };

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card className="table-card">
              <div
                className="rounded d-flex flex-wrap align-items-center justify-content-between  "
                style={{
                  backgroundColor: mainColor,
                }}
              >
                <div className="text-left">
                  <Link
                    className="btn btn-info btn-sm"
                    to="#"
                    onClick={() => {
                      // ChangeShowAddAuditingTemplateModal(true);
                      // ChangeIsEditing(false);
                      // ChangeUnderEditTemplateTitle("");
                      ChangeShowAddNewUserModal(true);
                    }}
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    {"Add New User"}
                  </Link>
                </div>
                {/* <div className="text-right table-top-right-buttons">
                  {selectedFlatRows.length > 0 ? (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => successDelete()}
                    >
                      Delete
                    </Button>
                  ) : null}
                  <ReactFileReader
                    fileTypes={[".csv"]}
                    handleFiles={handleFiles}
                  >
                    <button className="btn btn-info btn-sm">Import CSV</button>
                  </ReactFileReader>
                  <button
                    onClick={() => {
                      exportData("csv", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export CSV
                  </button>
                  <button
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      exportData("pdf", true);
                    }}
                    className="btn btn-info btn-sm"
                  >
                    Export PDF
                  </button>
                </div> */}
              </div>

              {/* <div className="row p-3 align-items-center">
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-between">
                    Show
                    <select
                      value={pageSize}
                      className="form-control pagination-select"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    Entries
                  </div>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-3 text-right">
                  <FormGroup className="custom-form-group">
                    <select
                      name=""
                      id=""
                      className="form-control custom-input"
                      onChange={(e) => filterDataTable(e)}
                    >
                      <option value="" defaultValue disabled>
                        Filters
                      </option>
                      <option value="">All</option>
                      <option value="Template">Template</option>
                      <option value="Draft">Draft</option>
                    </select>
                  </FormGroup>
                </div>
                <div className="col-md-3 text-right">
                  <div className="form-group custom-form-group">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div> */}
              {/* <button onClick={resetResizing}>Reset Resizing</button> */}
              <div className="tableWrap">
                <table
                  {...getTableProps()}
                  className="table-striped table-hover "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-info text-white"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border border-left border-right-0 "
                            style={{
                              borderBottom: "1px solid #cacaca",
                              background: "rgba(0, 0, 0, 0.05)",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down  text-secondary"></i>
                                ) : (
                                  <i className="fas fa-arrow-up text-secondary"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                        <th
                          className="border border-left-0 border-right-0 "
                          style={{
                            borderBottom: "1px solid #cacaca",
                            background: "rgba(0, 0, 0, 0.05)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      // console.log("ROW");
                      // console.log(row);
                      // console.log(row.allCells[1].value);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells?.map((cell) => {
                            return (
                              <>
                                <td {...cell.getCellProps()}>
                                  {cell?.render("Cell")}
                                </td>
                              </>
                            );
                          })}
                          <td>
                            <div className="d-flex flex-wrap justify-content-around">
                              {/* <i className="fas fa-eye text-info" onClick={() => setRow(row)} style={{ cursor: 'pointer' }} ></i> */}
                              {/* <i
                                className="fas fa-edit text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  ChangeIsEditing(true);
                                  ChangeUnderEditTemplateTitle(
                                    row.allCells[1].value
                                  );
                                  EditAuditingTemplateData(row.original);
                                  ChangeShowAddAuditingTemplateModal(true);
                                }}
                              ></i> */}
                              {/* <i
                                  className="fas fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => successDelete(row?.original?.id)}
                                ></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" text-right d-flex flex-wrap justify-content-between p-2 align-items-center">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>

                <div className="">
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  <span className="pagination-button">
                    {pageOptions.map((page) => (
                      <button
                        className={
                          pageIndex === page
                            ? "btn border-info border btn-info  bg-transparent text-info "
                            : "btn border-info border btn-info btn-sm bg-transparent text-info"
                        }
                        onClick={() => {
                          const pageNumber = page;
                          gotoPage(pageNumber);
                        }}
                      >
                        {page + 1}
                      </button>
                    ))}
                  </span>
                  <button
                    className="btn btn-info  btn-sm"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>

              {console.log(
                JSON.stringify(
                  {
                    selectedFlatRows: selectedFlatRows.map(
                      (row) => row.original
                    ),
                  },
                  null,
                  2
                )
              )}
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={showAddNewUserModal}
          size="lg"
          toggle={() => {
            ChangeShowAddNewUserModal(false);
          }}
        >
          <AddNewUserRole />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  shared,
  styling,
  auditingTemplate,
  audit,
}) => ({
  shared,
  styling,
  auditingTemplate,
  audit,
  user,
});

export default connect(mapStateToProps, {
  ChangeShowAddOriginalRequirmentModal,
  ChangeShowAddAuditingTemplateModal,
  ChangeIsEditing,
  ChangeUnderEditTemplateTitle,
  ChangeShowAddSectionsModal,
  GetAllAuditingTemplate,
  DeleteAuditingTemplate,
  EditAuditingTemplateData,
  ChangeShowAddNewUserModal,
  GetAllUserRole,
  GetContributeUser,
  GetSupportUser,
})(AuditingTemplates);

{
  /* <Row>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Email</Label>
              <Input
                type="text"
                name="location"
                //   value={requirementListFormData.location}
                //   onChange={handleRequirementListFormData}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Button
              color="success"
              // onClick={() => {
              //   handleRequirementSectionUpdateAddMoreFormSubmit();
              // }}
            >
              Send
            </Button>
          </Col>
        </Row> */
}
