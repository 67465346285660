import React, { useState } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
} from "reactstrap";

function AddRequirementListForm() {
  const [modalClassic, setModalClassic] = React.useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  //  Custom Add Field

  const [fields, setfields] = useState([]);
  //   const [horizontalTabs, setHorizontalTabs] = useState("");

  const [formData, setFormData] = useState({
    field_name: "",
    field_value: "",
  });
  const { field_name, field_value } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setfields([
      ...fields,
      {
        name: field_name,
        value: field_value,
      },
    ]);

    setFormData({
      field_name: "",
      field_value: "",
    });
    toggleModalClassic();
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Name</Label>
              <Input type="text" />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup className="text-left">
              <Label>Location</Label>
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Authority</Label>
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Industry</Label>
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Information</Label>
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Status</Label>
              <select name="" id="" className="form-control">
                <option value="" disabled>
                  Select
                </option>
                <option value="">Active</option>
                <option value="">InActive</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Effective Date</Label>
              <Input type="date" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <Label>Requirement File</Label>
              <Input type="file" />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="text-left">
              <Label>Description</Label>
              <Input type="textarea" />
            </FormGroup>
          </Col>
          {fields.length !== 0
            ? fields.map((field) => (
              <Col md="6">
                <FormGroup className="text-left">
                  <Label>{field.name}</Label>
                  <Input type="text" defaultValue={field.value} />
                </FormGroup>
              </Col>
            ))
            : null}
          <Col md="12">
            <div className="text-right">
              <Link class="btn btn-sm btn-info" onClick={toggleModalClassic}>
                Add Field
              </Link>
            </div>
          </Col>
        </Row>
        <div className="text-right">
          {/* <Button color="success">Save & Add More</Button> */}
          <Button color="info" onClick={() => setHorizontalTabs("section")}>
            Save & Next
          </Button>
        </div>

        {/* Add Field Modal */}

        <Modal isOpen={modalClassic} size="lg" toggle={toggleModalClassic}>
          <div className="modal-header justify-content-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalClassic}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="title title-up">Add Field</h4>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <Col md="6">
                  <Label>Field Name</Label>
                  <FormGroup>
                    <Input
                      name="field_name"
                      value={field_name}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <Label>Value</Label>
                  <FormGroup>
                    <Input
                      name="field_value"
                      value={field_value}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Link className="btn btn-info " onClick={(e) => onSubmit(e)}>
                  Submit
                </Link>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AddRequirementListForm;
