import {
  FcPieChart,
  FcAreaChart,
  FcElectricalThreshold,
  FcStatistics,
  FcAcceptDatabase,
  FcDatabase,
  FcViewDetails,
  FcFilingCabinet,
  FcKindle,
  FcAnswers,
  FcDocument,
  FcRules,
  FcTemplate,
  FcWorkflow,
  FcProcess,
  FcRefresh,
  FcAssistant,
  FcCollaboration,
  FcBusinessman,
  FcTimeline,
} from "react-icons/fc";

import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";
import Login from "../src/views/User/Login.js";
import RequirementsList from "views/tables/RequirementsList";
import RequirementMapping from "views/tables/RequirementMapping";
import CreateRequirement from "views/forms/CreateRequirement";
import EditRequirement from "views/forms/EditRequirement";
import References from "views/tables/References";
import SectionDashboard from "views/tables/SectionDashboard";
import AuditingTemplates from "views/tables/AuditingTemplates";
import AllAudits from "views/Audit/AllAudits";
import AuditSummary from "views/Audit/AuditSummary";
import LifeCycle from "views/Audit/LifeCycle";
import CreateReference from "views/forms/CreateReference";
import Dashboard1 from "views/Dashboard1";
import RequirementsListDetail from "views/tables/RequirementsListDetail";
import RequirementsSubRequirementsTable from "views/tables/RequirementsSubRequirementsTable";
import RequirementMappingTable from "views/tables/RequirementMappingTable";
import AllCompanies from "views/Audit/AllCompanies";
import Versions from "views/Audit/Versions.js";
import VersionRequirements from "views/Audit/Versions/VersionRequirements.js";
import VersionRadarChart from "views/Audit/Versions/VersionRadarChart.js";
import AssessorLifeCycle from "views/Audit/AssessorLifeCycle.js";
import UserRoles from "views/User/UserRoles.js";
import Inbox from "views/Inbox/Inbox.js";

const assessorRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FcAreaChart style={{ fontSize: 30, marginRight: 5 }} />,
    component: Dashboard1,
    layout: "/user",
  },
  {
    collapse: true,
    name: "Requirements",
    icon: <FcFilingCabinet style={{ fontSize: 30, marginRight: 5 }} />,
    state: "pagesCollapse",
    path: "/requirements",
    views: [
      {
        path: "/requirements/create-requirement",
        name: "Create Requirement",
        mini: "CR",
        component: CreateRequirement,
        layout: "/user",
      },
      {
        path: "/requirements/requirements-List",
        name: "Requirements List",
        mini: "RL",
        component: RequirementsList,
        layout: "/user",
      },

      {
        path: "/requirements/references",
        name: "References",
        mini: "R",
        component: References,
        layout: "/user",
      },
      {
        path: "/requirements/requirement-mapping",
        name: "Requirements Mapping",
        mini: "RM",
        component: RequirementMapping,
        layout: "/user",
      },
      // {
      //   path: "/lock-screen",
      //   name: "LockScreen",
      //   mini: "LS",
      //   component: LockScreen,
      //   layout: "/auth",
      // },
      // {
      //   path: "/user-profile",
      //   name: "UserProfile",
      //   mini: "UP",
      //   component: UserProfile,
      //   layout: "/user",
      // },
    ],
  },
  {
    path: "/section-dashboard",
    name: "Req List Dashboard",
    icon: <FcViewDetails style={{ fontSize: 30, marginRight: 5 }} />,
    component: SectionDashboard,
    layout: "/user",
  },
  {
    path: "/create-reference",
    name: "Create Reference",
    icon: <FcAcceptDatabase style={{ fontSize: 30, marginRight: 5 }} />,
    component: CreateReference,
    layout: "/user",
    invisible: true,
  },
  {
    path: "/requirements-list-detail",
    name: "Requirements List Detail",
    icon: <FcAcceptDatabase style={{ fontSize: 30, marginRight: 5 }} />,
    component: RequirementsListDetail,
    layout: "/user",
    invisible: true,
  },
  // {
  //   path: "/create-requirement",
  //   name: "Create Requirement",
  // //   icon: "nc-icon nc-bank",
  //   component: CreateRequirement,
  //   layout: "/user",
  //   invisible: true
  // },
  {
    path: "/auditing-template",
    name: "Assessment Templates",
    icon: <FcTemplate style={{ fontSize: 30, marginRight: 5 }} />,
    component: AuditingTemplates,
    layout: "/user",
  },
  {
    path: "/audits",
    name: "Assessment",
    icon: <FcAcceptDatabase style={{ fontSize: 30, marginRight: 5 }} />,
    component: AllAudits,
    layout: "/user",
    invisible: true,
    parentPath: "/companies",
  },
  {
    path: "/companies",
    name: "Companies",
    icon: <FcDocument style={{ fontSize: 30, marginRight: 5 }} />,
    component: AllCompanies,
    layout: "/user",
  },
  {
    name: "ASSESSMENT NAME",
    classes: "light-border-bottom font-weight-normal  text-center ",
  },
  {
    path: "/assessment-summary",
    name: "Assessment  Summary",
    icon: <FcWorkflow style={{ fontSize: 30, marginRight: 5 }} />,
    component: AuditSummary,
    layout: "/user",
  },
  {
    path: "/life-cycle",
    name: "Life Cycle",
    icon: <FcRefresh style={{ fontSize: 30, marginRight: 5 }} />,
    // component: LifeCycle,
    component: AssessorLifeCycle,
    layout: "/user",
  },
  {
    path: "/versions",
    name: "Versions",
    icon: <FcTimeline style={{ fontSize: 30, marginRight: 5 }} />,
    component: Versions,
    layout: "/user",
  },
  {
    path: "/version-requirements",
    name: "Requirements",
    component: VersionRequirements,
    layout: "/user",
    invisible: true,
    parentPath: "/versions",
  },
  {
    path: "/version-radar-chart",
    name: "Radar Chart",
    component: VersionRadarChart,
    layout: "/user",
    invisible: true,
    parentPath: "/versions",
  },
  // {
  //   name: "Work Items",
  //   classes: "light-border-bottom font-weight-normal  text-center ",
  // },
  {
    // name: "ASSESSMENT NAME",
    classes: "light-border-bottom font-weight-normal  text-center ",
  },
  {
    path: "/user-profile",
    // name: "User Roles ",
    invisible: true,

    component: UserProfile,
    layout: "/user",
  },
  {
    path: "/user-roles",
    name: "User Roles ",
    icon: <FcBusinessman style={{ fontSize: 30, marginRight: 5 }} />,
    component: UserRoles,
    layout: "/user",
  },
  // {
  //   path: "/inbox",
  //   name: "Inbox",
  //   icon: <FcCollaboration style={{ fontSize: 30, marginRight: 5 }} />,
  //   component: Inbox,
  //   layout: "/user",
  // },
  // {
  //   path: "/widgets",
  //   name: "Auditing",
  //   icon: "nc-icon nc-box",
  //   component: Widgets,
  //   layout: "/user",
  // },
  // {
  //   path: "/widgets",
  //   name: "Section Dashboard",
  //   icon: "nc-icon nc-box",
  //   component: Widgets,
  //   layout: "/user",
  // },
  // {
  //   path: "/widgets",
  //   name: "Chatting",
  //   icon: "nc-icon nc-box",
  //   component: Widgets,
  //   layout: "/user",
  // },

  // {
  //   collapse: true,
  //   name: "Settings",
  //   icon: "nc-icon nc-layout-11",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "User Management",
  //       mini: "B",
  //       component: Buttons,
  //       layout: "/user",
  //     },
  //     // {
  //     //   path: "/grid-system",
  //     //   name: "Grid System",
  //     //   mini: "GS",
  //     //   component: GridSystem,
  //     //   layout: "/user",
  //     // },
  //     // {
  //     //   path: "/panels",
  //     //   name: "Panels",
  //     //   mini: "P",
  //     //   component: Panels,
  //     //   layout: "/user",
  //     // },
  //     // {
  //     //   path: "/sweet-alert",
  //     //   name: "Sweet Alert",
  //     //   mini: "SA",
  //     //   component: SweetAlert,
  //     //   layout: "/user",
  //     // },
  //     // {
  //     //   path: "/notifications",
  //     //   name: "Notifications",
  //     //   mini: "N",
  //     //   component: Notifications,
  //     //   layout: "/user",
  //     // },
  //     // {
  //     //   path: "/icons",
  //     //   name: "Icons",
  //     //   mini: "I",
  //     //   component: Icons,
  //     //   layout: "/user",
  //     // },
  //     // {
  //     //   path: "/typography",
  //     //   name: "Typography",
  //     //   mini: "T",
  //     //   component: Typography,
  //     //   layout: "/user",
  //     // },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "nc-icon nc-ruler-pencil",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "nc-icon nc-single-copy-04",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       mini: "RT",
  //       component: ReactTables,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/user",
  //     },
  //   ],
  // },

  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  //   layout: "/user",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Calendar,
  //   layout: "/user",
  // },
];

export default assessorRoutes;
