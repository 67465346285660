import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import { Provider } from "react-redux";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "assets/css/custom.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./styling/custom.css";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import store from "./store";
import App from "./App";

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}


ReactDOM.render(
  <Provider {...{ store }}>
    <BrowserRouter>
      {/* <Switch> */}
      <App />
      {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/user" render={(props) => <AdminLayout {...props} />} />
        <Redirect to="/auth/login" /> */}
      {/* </Switch> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
