import React from 'react'

export const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <span>


            <input value={filter || ''} placeholder="Search" className="form-control custom-input" onChange={(e) => setFilter(e.target.value)} />

        </span>
    )
}
