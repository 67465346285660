import {
  CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL,
  CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL,
  CHANGE_IS_EDITING,
  CHANGE_UNDER_EDIT_TEMPLATE_TITLE,
  CHANGE_GET_ALL_AUDITING_TEMPLATE,
  CHANGE_DELETE_AUDITING_TEMPLATE,
  CHANGE_CREATE_AUDITING_TEMPLATE,
  CHANGE_GLOBAL_LOADER,
  CHANGE_EDIT_AUDITING_TEMPLATE_DATA,
  CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT,
  CHANGE_SINGLE_AUDITING_TEMPLATE,
  ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
  CHANGE_EDIT_AUDITING_TEMPLATE,
  ADD_SECTIONS_FOR_CREATING_AUDIT,
  ADD_REQUIREMENTS_FOR_CREATING_AUDIT,
} from "./types";
import { baseUrl } from "config/baseUrl";
import { messageHandler } from "utils/popupMessage";

export const ChangeShowAddOriginalRequirmentModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_ORIGINAL_REQUIRMENT_MODAL, payload: val });
  };
};
export const ChangeShowAddAuditingTemplateModal = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SHOW_ADD_AUDITING_TEMPLATE_MODAL, payload: val });
  };
};
export const ChangeIsEditing = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_IS_EDITING, payload: val });
  };
};
export const ChangeUnderEditTemplateTitle = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_UNDER_EDIT_TEMPLATE_TITLE, payload: val });
  };
};
export const ClearAuditTemplateSectionAndRequirement = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_AUDIT_TEMPLATE_SECTION_AND_REQUIREMENT,
    });
  };
};

export const AddSectionsForCreatingAuditTemplate = (val) => {
  return async (dispatch) => {
    dispatch({ type: ADD_SECTIONS_FOR_CREATING_AUDIT_TEMPLATE, payload: val });
  };
};
export const AddRequirementsForCreatingAuditTemplate = (val) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_REQUIREMENTS_FOR_CREATING_AUDIT_TEMPLATE,
      payload: val,
    });
  };
};

export const EditAuditingTemplateData = (val) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_EDIT_AUDITING_TEMPLATE_DATA, payload: val });
  };
};

// Get All Auditing Templates
export const GetAllAuditingTemplate = () => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let allAuditingTemplate = await fetch(
        `${baseUrl}/api/auditor/all-audit-templates`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      console.log("ALL AUDITING TEMPLATE");
      console.log(allAuditingTemplate);
      if (allAuditingTemplate.status == 200) {
        dispatch({
          type: CHANGE_GET_ALL_AUDITING_TEMPLATE,
          payload: allAuditingTemplate.data.data,
        });
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Delete Auditing Template
export const DeleteAuditingTemplate = (val, setAlert) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let deleteAuditingTemplate = await fetch(
        `${baseUrl}/api/auditor/delete-audit-template`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (deleteAuditingTemplate.status == 200) {
        dispatch({
          type: CHANGE_DELETE_AUDITING_TEMPLATE,
          payload: deleteAuditingTemplate.data,
        });

        messageHandler(
          setAlert,
          "Successfully Deleted Audit template",
          "Deleted!",
          "success"
        );
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Create Auditing Template
export const CreateAuditingTemplate = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("name", val.name);
      formdata.append("type", val.type);
      formdata.append("section_id", JSON.stringify(val.section_id));
      formdata.append("requirement_id", JSON.stringify(val.requirement_id));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let auditingTemplate = await fetch(
        `${baseUrl}/api/auditor/create-audit-template`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (auditingTemplate.status == 200) {
        dispatch({
          type: CHANGE_CREATE_AUDITING_TEMPLATE,
          payload: auditingTemplate.data,
        });
        dispatch(GetAllAuditingTemplate());
        dispatch(ClearAuditTemplateSectionAndRequirement());
      } else {
        console.log("ERROR");
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Edit Auditing Template
export const EditAuditingTemplate = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val.id);
      formdata.append("name", val.name);
      formdata.append("type", val.type);
      formdata.append("section_id", JSON.stringify(val.section_id));
      formdata.append("requirement_id", JSON.stringify(val.requirement_id));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let auditingTemplate = await fetch(
        `${baseUrl}/api/auditor/edit-audit-template`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (auditingTemplate.status == 200) {
        dispatch({
          type: CHANGE_EDIT_AUDITING_TEMPLATE,
          payload: auditingTemplate.data,
        });
        dispatch(GetAllAuditingTemplate());
        dispatch(ClearAuditTemplateSectionAndRequirement());
      } else {
        console.log("ERROR");
        console.log(auditingTemplate);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      console.log(auditingTemplate);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};

// Show Single Auditing Template
export const GetSingleAuditingTemplate = (val) => {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${window.localStorage.getItem("token")}`
      );

      var formdata = new FormData();
      formdata.append("id", val);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: true,
      });

      let auditingTemplate = await fetch(
        `${baseUrl}/api/auditor/show-audit-template`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (auditingTemplate.status == 200) {
        const sectionsId = auditingTemplate?.data?.data?.sections?.map(
          (section) => section.section_id
        );
        const requirementsId = auditingTemplate?.data?.data?.requirements?.map(
          (requirement) => requirement.requirement_id
        );
        console.log("-----=================---------------");
        console.log(auditingTemplate.data);
        console.log(sectionsId);
        console.log(requirementsId);
        dispatch({
          type: CHANGE_SINGLE_AUDITING_TEMPLATE,
          payload: auditingTemplate.data,
        });
        dispatch({
          type: ADD_SECTIONS_FOR_CREATING_AUDIT,
          payload: sectionsId,
        });
        dispatch({
          type: ADD_REQUIREMENTS_FOR_CREATING_AUDIT,
          payload: requirementsId,
        });
      } else {
        console.log("ERROR");
        console.log(auditingTemplate);
      }
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      // console.log(auditingTemplate);
      dispatch({
        type: CHANGE_GLOBAL_LOADER,
        payload: false,
      });
    }
  };
};
