import {
  MANAGE_CURRENT_LOGGED_IN_USER_ROLE,
  MANAGE_USER,
  CHANGE_SHOW_ADD_NEW_USER_MODAL,
  CHANGE_CREATE_USER_ROLE,
  CHANGE_GET_ALL_USER_ROLE,
  TOKEN_VIRIFICATION_USER_ROLE,
  CREATE_USER_ROLE_REGISTER,
  GET_ALL_SUPPORT_TEAM,
  INVITE_SUPPORT_TEAM,
  SUPPORT_VERIFY_MEMBER,
  CREATE_AUDIT_MEMBER_SUPPORT,
  GET_SUPPORT_USER,
  GET_CONTRIBUTE_USER,
  GET_USER_DATA,
  EDIT_USER_DATA,

} from "../actions/types";

const initialState = {
  // dummyCredentials: {
  //     email: "test@gmail.com",
  //     password: "12345678Za",
  // }
  assessorCredentials: {
    email: "auditor@compliance.com",
    password: "haroon.khan",
    role: "assessor",
  },
  contributeCredentials: {
    email: "test2@gmail.com",
    password: "12345678Za",
    role: "contribute",
  },
  supportCredentials: {
    email: "test3@gmail.com",
    password: "12345678Za",
    role: "support",
  },
  currentLoggedInUserRole: "",
  userData: null,
  showAddNewUserModal: false,
  userRole: null,
  allUserRole: [],
  userRoleToken: null,
  userRoleRegistration: null,
  allSupportTeam: null,
  inviteSuppportTeam: null,
  supportVerifyToken: null,
  addAuditMember: null,
  supportUser: [],
  contributeUser: [],
  getUserData: [],
  editUserData: null

};

export default (state = initialState, { type, payload }) => {
  // console.log("TYPE::");
  // console.log(type);
  // console.log("PAYLOAD::");
  // console.log(payload);
  switch (type) {
    case MANAGE_CURRENT_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        currentLoggedInUserRole: payload,
      };
    case MANAGE_USER:
      return {
        ...state,
        userData: payload,
      };
    case CHANGE_SHOW_ADD_NEW_USER_MODAL:
      return {
        ...state,
        showAddNewUserModal: payload,
      };
    case CHANGE_CREATE_USER_ROLE:
      return {
        ...state,
        userRole: payload,
      };

    case TOKEN_VIRIFICATION_USER_ROLE:
      return {
        ...state,
        userRoleToken: payload,
      };

    case CREATE_USER_ROLE_REGISTER:
      return {
        ...state,
        userRoleRegistration: payload,
      };
    case CHANGE_GET_ALL_USER_ROLE:
      return {
        ...state,
        allUserRole: payload,
      };

    case GET_ALL_SUPPORT_TEAM:
      const supportTeam = payload.filter((user) => user.role === "2");
      return {
        ...state,
        allSupportTeam: supportTeam,
      };

    case INVITE_SUPPORT_TEAM:
      return {
        ...state,
        inviteSuppportTeam: payload

      }

    case SUPPORT_VERIFY_MEMBER:
      return {
        ...state,
        supportVerifyToken: payload,
      };

    case CREATE_AUDIT_MEMBER_SUPPORT:
      return {
        ...state,
        addAuditMember: payload,
      };

    case GET_SUPPORT_USER:
      return {
        ...state,
        supportUser: payload,
      };

    case GET_CONTRIBUTE_USER:
      return {
        ...state,
        contributeUser: payload,
      };
    case GET_USER_DATA:
      return {
        ...state,
        getUserData: payload,
      };

    case EDIT_USER_DATA:
      return {
        ...state,
        editUserData: payload,
      };
    default:
      return state;
  }
};
